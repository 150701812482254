/**
 * @typedef {Object} EmptyState
 * estado inicial del modulo de pagos
 */
export const emptyState = {
    expireIn: null,
    ferchingAt: null,
    statusServer: 'idle',
    statusOperation: 'idle',
    didInvalidate: true,
    feedback: {
        title: null,
        message: null,
        payload: null,
    },
};

/**
 * @typedef {Object} EmptyState
 * estado inicial del modulo de pagos para la creacion de recursos
 */
