import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Typography,
    Card,
    CardContent,
    Grid,
    Box,
    CircularProgress,
} from '@mui/material';
import { DataGrid, esES } from '@mui/x-data-grid';
import { Tag } from 'antd';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectProfessors,
} from '../../../store/professors/fetchSlice';
import { getAllProfessors } from '../../../store/professors/thunks';
import DEFAULT_AVATAR_URL from './../../../../assets/images/Unknown_person.jpeg';
import { selectComponentAllProfessorsStatusServer } from '../../../store/professors/componentsStatusSelectors';
import { SkeletonProfessorsTable } from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';
import NoDataOverlay from '../../../../components/utilities/NoDataOverlay';
import { GridToolBar } from '../../../../components/utilities/GridToolBar';
import CustomPagination from '../../../../components/utilities/CustomPagination';

const AllProfessors = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentAllProfessorsStatusServer
    );

    const allProfessors = useFetchResource(
        () =>
            getAllProfessors({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectProfessors,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getAllProfessors({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            })
        );
    };

    console.log(allProfessors, 'allProfessors');
    const headers = allProfessors.value ? Object.keys(allProfessors.value) : [];
    const data = allProfessors.value || {};

    const rows =
        data[headers[0]]?.map((_, index) => {
            let row = { id: index + 1 };
            headers.forEach((header) => {
                row[header] =
                    header === 'Fotografía'
                        ? data[header][index]?.value || DEFAULT_AVATAR_URL
                        : data[header][index]?.value;
            });
            return row;
        }) || [];

    const columns = headers.map((header) => {
        if (header === 'Fotografía') {
            return {
                field: header,
                headerName: header,
                flex: 0.5,
                minWidth: 90,
                renderCell: (params) => (
                    <img
                        src={params.value}
                        alt="Foto del profesor"
                        style={{
                            width: 50,
                            height: 50,
                            borderRadius: '50%',
                            objectFit: 'cover',
                        }}
                    />
                ),
                disableExport: true,
            };
        } else if (
            [
                'Promedio de asistencias',
                'Total de materias',
                'Total de grupos',
                'Total de reportes',
                'Total de incidentes',
            ].includes(header)
        ) {
            return {
                field: header,
                headerName: header,
                flex: 1.4,
                renderCell: (params) => (
                    <Tag
                        color={header.includes('Total') ? 'blue' : 'error'}
                        style={{
                            width: '100px',
                            textAlign: 'center',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'normal',
                            wordBreak: 'break-word',
                        }}>
                        {params.value}
                    </Tag>
                ),
            };
        } else {
            return {
                field: header,
                headerName: header,
                flex: 1.2,
                renderCell: (params) => (
                    <Box
                        sx={{
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                            lineHeight: '1.2',
                        }}>
                        {params.value}
                    </Box>
                ),
            };
        }
    });

    return (
        <Box sx={{ width: '100%', overflowX: 'hidden' }}>
            {fetchingStatus === 'pending' && (
                <SkeletonProfessorsTable items={9} />
            )}

            {fetchingStatus === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {fetchingStatus === 'fulfilled' && (
                <Card
                    variant="outlined"
                    sx={{
                        borderRadius: '10px',
                        width: '100%',
                        overflow: 'hidden',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                        borderColor: '#e0e0e0',
                        mb: 4,
                    }}>
                    <CardContent sx={{ width: '100%', padding: '24px' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography
                                    variant="h6"
                                    color="textPrimary"
                                    gutterBottom>
                                    {allProfessors.title ||
                                        'Información general de los profesores'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {allProfessors.loading ? (
                                    <Box display="flex" justifyContent="center">
                                        <CircularProgress />
                                    </Box>
                                ) : allProfessors.error ? (
                                    <Typography color="error">
                                        Error: {allProfessors.error.message}
                                    </Typography>
                                ) : (
                                    <Box sx={{ height: 700, width: '100%' }}>
                                        <DataGrid
                                            localeText={{
                                                ...esES.components.MuiDataGrid
                                                    .defaultProps.localeText,
                                                toolbarColumns: '',
                                                toolbarFilters: '',
                                                toolbarDensity: '',
                                                toolbarExport: '',
                                            }}
                                            rows={rows}
                                            columns={columns}
                                            pageSize={10}
                                            rowsPerPageOptions={[]}
                                            disableDensitySelector
                                            components={{
                                                NoRowsOverlay: NoDataOverlay,
                                                NoResultsOverlay: NoDataOverlay,
                                                Toolbar: GridToolBar,
                                                Pagination: CustomPagination,
                                            }}
                                            componentsProps={{
                                                panel: {
                                                    placement: 'bottom-end',
                                                },
                                                noResultsOverlay: {
                                                    message:
                                                        'No se encontraron resultados para la búsqueda',
                                                },
                                                noRowsOverlay: {
                                                    message:
                                                        'No hay datos disponibles',
                                                },
                                            }}
                                            sx={{
                                                '&.MuiDataGrid-root': {
                                                    border: 'none',
                                                },
                                            }}
                                        />
                                    </Box>
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            )}
        </Box>
    );
};

export default AllProfessors;
