import React from 'react';
import {
    Avatar,
    Box,
    Card,
    CardHeader,
    CardContent,
    Typography,
    CircularProgress,
} from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';
import { grey } from '@mui/material/colors';

const imageView = ({
    title,
    schoolId,
    imageSrc,
    onChangeImage,
    isLoading,
    showId,
}) => {
    const avatarSX = {
        width: 200,
        height: 200,
        bgcolor: grey[500],
        marginLeft: 'auto',
        marginRight: 'auto',
        borderRadius: '50%',
        overflow: 'hidden',
        position: 'relative',
        cursor: 'pointer',
        '&:hover .hoverContent': {
            opacity: 1,
        },
    };

    const imageSX = {
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit: 'contain',
    };

    const hoverContentSX = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: 0,
        transition: 'opacity 0.3s ease',
    };

    return (
        <Card sx={{ height: 376 }}>
            <CardHeader
                title={title}
                titleTypographyProps={{ sx: { fontSize: 18 } }}
                sx={{ paddingBottom: 2 }}
            />
            <CardContent>
                <Box
                    component="div"
                    noValidate
                    autoComplete="off"
                    sx={{ padding: 1 }}
                >
                    <Box sx={{ textAlign: 'center' }}>
                        <Avatar
                            alt="Imagen"
                            sx={avatarSX}
                            onClick={onChangeImage}
                        >
                            {isLoading ? (
                                <CircularProgress size={100} color="inherit" />
                            ) : (
                                <img
                                    src={imageSrc || 'U'}
                                    alt="Imagen"
                                    style={imageSX}
                                />
                            )}
                            <Box className="hoverContent" sx={hoverContentSX}>
                                <PhotoCamera sx={{ fontSize: 40 }} />
                                <Typography variant="body2">
                                    Cambiar imagen
                                </Typography>
                            </Box>
                        </Avatar>
                    </Box>
                    {showId ? (
                        <Box sx={{ textAlign: 'center' }}>
                            <Typography>
                                <span style={{ fontWeight: 'bold' }}>ID: </span>
                                {schoolId}
                            </Typography>
                        </Box>
                    ) : (
                        <Box sx={{ textAlign: 'center' }}>
                            <Typography>
                                {
                                    'Esta imagen será visualizada en documentos de exportación'
                                }
                            </Typography>
                        </Box>
                    )}
                </Box>
            </CardContent>
        </Card>
    );
};

export default imageView;
