import { IconButton, Snackbar, Alert } from '@mui/material';
import React from 'react';
import useFeedback from '../hooks/useFeedback';
import CloseIcon from '@mui/icons-material/Close';

/**
 * Componente encargado de mostrar informacion al usuario
 *
 * @param {*} open
 *
 * @returns
 */
const UserFeedback = () => {
    ///////////// SHARED STATE ///////////////////
    const feedback = useFeedback();

    //////////// LOCAL STATE /////////////////////
    const closeFeedback = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        feedback.closeFeedback();
    };

    return (
        <Snackbar
            open={feedback.open}
            autoHideDuration={6000}
            onClose={closeFeedback}>
            <Alert
                onClose={closeFeedback}
                severity={feedback?.severity || 'info'}
                sx={{ width: '100%' }}
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={closeFeedback}>
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                }>
                {feedback?.title}
            </Alert>
        </Snackbar>
    );
};

export default UserFeedback;
