import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, CardContent, Tooltip, Typography } from '@mui/material';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectTotalGroups,
} from '../../../store/home/fetchSlice';
import { getTotalGroups } from '../../../store/home/thunks';
import { ResponsiveBar } from '@nivo/bar';
import { selectComponentHomeTotalGroupsStatusServer } from '../../../store/home/componentStatusSelectors';
import {
    SkeletongGrafics,
    SkeletonSmallGrafics,
} from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';
import { ErrorBoundary } from 'react-error-boundary';

const TotalGroupsCard = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentHomeTotalGroupsStatusServer
    );
    const totalGroups = useFetchResource(
        () =>
            getTotalGroups({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectTotalGroups,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getTotalGroups({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
            selectTotalGroups,
            [cycleSelected, schoolId]
        );
    };

    const data = totalGroups?.value?.data
        ?.map((value, index) => ({
            groupType: totalGroups.value.labels[index],
            total: value,
        }))
        .filter((item) => item.total !== null && item.total !== '');

    return (
        <ErrorBoundary>
            <Box>
                {fetchingStatus === 'pending' && <SkeletongGrafics items={9} />}

                {fetchingStatus === 'rejected' && (
                    <Error
                        onRetry={reload}
                        message={'Estamos teniendo problemas'}
                    />
                )}

                {fetchingStatus === 'fulfilled' && (
                    <Card
                        variant="outlined"
                        sx={{
                            borderRadius: '10px',
                            textAlign: 'center',
                            maxWidth: '400px',
                            minHeight: '213px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            boxShadow: 'none',
                            margin: 'auto',
                        }}
                    >
                        <CardContent sx={{ padding: '16px' }}>
                            <Typography variant="h6" gutterBottom>
                                {totalGroups?.title || 'Total de grupos'}
                            </Typography>
                            {data && data.length > 0 ? (
                                <Box
                                    sx={{
                                        height: '300px',
                                        minWidth: '200px',
                                    }}
                                >
                                    <ResponsiveBar
                                        data={data}
                                        keys={['total']}
                                        indexBy="groupType"
                                        margin={{
                                            top: 10,
                                            right: 20,
                                            bottom: 50,
                                            left: 30,
                                        }}
                                        padding={0.2}
                                        colors={'green'}
                                        borderRadius={5}
                                        groupMode="grouped"
                                        axisTop={null}
                                        axisRight={null}
                                        axisBottom={{
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 45,
                                            legend: '',
                                            legendPosition: 'middle',
                                            legendOffset: 60,
                                        }}
                                        axisLeft={{
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0,
                                            legend: 'Cantidad de Alumnos',
                                            legendPosition: 'middle',
                                            legendOffset: -50,
                                        }}
                                        labelSkipWidth={12}
                                        labelSkipHeight={12}
                                        labelTextColor="white"
                                        layers={[
                                            'grid',
                                            'axes',
                                            'bars',
                                            'markers',
                                            'legends',
                                            (props) =>
                                                props.bars.map((bar) => {
                                                    const isSmallValue =
                                                        bar.data.value <= 10;

                                                    return (
                                                        <g
                                                            transform={`translate(${bar.x}, ${bar.y})`}
                                                            key={bar.key}
                                                        >
                                                            <rect
                                                                width={
                                                                    bar.width
                                                                }
                                                                height={
                                                                    bar.height
                                                                }
                                                                fill={bar.color}
                                                            />
                                                            {bar.data.value >
                                                                10 && (
                                                                <text
                                                                    x={
                                                                        bar.width /
                                                                        2
                                                                    }
                                                                    y={
                                                                        bar.height /
                                                                        2
                                                                    }
                                                                    textAnchor="middle"
                                                                    dominantBaseline="central"
                                                                    style={{
                                                                        fill: 'white',
                                                                        fontSize: 16,
                                                                        fontWeight:
                                                                            'bold',
                                                                    }}
                                                                >
                                                                    {
                                                                        bar.data
                                                                            .value
                                                                    }
                                                                </text>
                                                            )}
                                                            {isSmallValue && (
                                                                <text
                                                                    x={
                                                                        bar.width /
                                                                        2
                                                                    }
                                                                    y={-5}
                                                                    textAnchor="middle"
                                                                    style={{
                                                                        fill: 'black',
                                                                        fontSize: 16,
                                                                        fontWeight:
                                                                            'bold',
                                                                    }}
                                                                >
                                                                    {
                                                                        bar.data
                                                                            .value
                                                                    }
                                                                </text>
                                                            )}
                                                        </g>
                                                    );
                                                }),
                                        ]}
                                        theme={{
                                            axis: {
                                                ticks: {
                                                    text: { fontSize: 12 },
                                                },
                                            },
                                            tooltip: {
                                                container: {
                                                    background: 'white',
                                                    color: 'inherit',
                                                    fontSize: 16,
                                                    borderRadius: '2px',
                                                    boxShadow:
                                                        '0 1px 2px rgba(0, 0, 0, 0.25)',
                                                },
                                            },
                                        }}
                                    />
                                </Box>
                            ) : (
                                <Box sx={{ margin: 2, padding: 2 }}>
                                    <Typography variant="body2" gutterBottom>
                                        Sin datos disponibles
                                    </Typography>
                                </Box>
                            )}
                        </CardContent>
                    </Card>
                )}
            </Box>
        </ErrorBoundary>
    );
};

export default TotalGroupsCard;
