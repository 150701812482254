import React from 'react';
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Snackbar,
    TextField,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
///////////////// SECCIONS //////////////////////

import Feedback from '../../../service/Feedback';
import Downloads from '../../Components/Downloads';

///////////////// SERVICES //////////////////////
import { setContactForm } from '../../../service/SupportService';

///////////////// ASSETS //////////////////////
import check from '../../assets/check.svg';
import directivo from '../../assets/directivo.png';
import facebook from '../../assets/facebook.svg';
import instagram from '../../assets/instagram.svg';
import whatsapp from '../../assets/whatsapp.svg';
import whatsAppFloat from '../../assets/whatsAppFloat.svg';
import logoFooter from '../../assets/logo_footer.svg';
import palomita from '../../assets/palomita.svg';
import youtube from '../../assets/youtube.svg';
import beneficiosdirector from '../../assets/beneficios_directivos.png';
import NavBar from '../../Components/NavBar';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

// Styles
// import '../style.css';

const Contacto = () => {
    const history = useHistory();

    ///////////////////// DATOS PARA EL FEEDBACK ///////////////////////////////////////////

    const showFeedback = (message) => {
        setFeedbackMessage(message);
        setOpenFeedback(true);
    };

    const closeFeedback = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenFeedback(false);
    };

    const [openFeedback, setOpenFeedback] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState('');

    const [feedback] = useState(() => new Feedback());

    ///////////////////// LOCAL STATE ///////////////////////////////////////////

    /**
     * Errors
     */
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [fieldErrors, setFieldErrors] = useState({});

    /**
     * State form
     */
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        cellphone: '',
        message: '',
    });

    ///////////////////// Actions ///////////////////////////////////////////

    const handleClick = () => {
        window.scrollTo(0, 0);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        const cleanValue =
            name === 'cellphone' ? value.replace(/[^0-9]/g, '') : value;
        setFormData({
            ...formData,
            [name]: cleanValue,
        });
    };

    /**
     * Validations
     */

    const validateForm = () => {
        const errors = {};
        if (!formData.name.trim()) {
            errors.name = 'El nombre es obligatorio';
        }
        if (!formData.email.trim()) {
            errors.email = 'El email es obligatorio';
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
            errors.email = 'El email no es válido';
        }
        if (!formData.cellphone.trim()) {
            errors.cellphone = 'El teléfono es obligatorio';
        } else if (!/^\d+$/.test(formData.cellphone)) {
            errors.cellphone = 'El teléfono debe contener solo números';
        } else if (!/^\d{10}$/.test(formData.cellphone)) {
            errors.cellphone =
                'El teléfono debe contener exactamente 10 números';
        }

        if (!formData.message.trim()) {
            errors.message = 'El mensaje es obligatorio';
        }
        return errors;
    };

    /**
     * Submit form
     */

    const handleSubmit = (e) => {
        e.preventDefault();
        const errors = validateForm();
        if (Object.keys(errors).length > 0) {
            setFieldErrors(errors);
            return;
        }
        setLoading(true);
        setError(null);
        setSuccess(false);
        setContactForm(formData)
            .then(() => {
                setSuccess(true);
                setFormData({
                    name: '',
                    cellphone: '',
                    email: '',
                    message: '',
                });
                setFieldErrors({});
            })
            .catch((err) => {
                let message = feedback.getMessage(err);
                showFeedback(message.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    /**
     * Errors Message
     */
    const DisplayErrorMessage = () =>
        error ? (
            <Snackbar
                open={openFeedback}
                autoHideDuration={3000}
                onClose={closeFeedback}
                message={feedbackMessage}
                action={
                    <Button
                        color="inherit"
                        size="small"
                        onClick={closeFeedback}>
                        X
                    </Button>
                }
            />
        ) : null;

    /**
     * success Message
     */
    const DisplaySuccessMessage = () =>
        success ? (
            <Snackbar
                open={!!success}
                autoHideDuration={6000}
                onClose={() => setSuccess(false)}>
                <Alert
                    onClose={() => setSuccess(false)}
                    severity="success"
                    sx={{ width: '100%' }}>
                    El formulario se ha enviado con éxito.
                </Alert>
            </Snackbar>
        ) : null;

    return (
        <Box>
            <NavBar />
            <div className="body">
                <div className="mweb contacto">
                    <section className="sidebar">
                        <div className="container center">
                            <h1>
                                <img src={check} alt="check" />
                                Agradecemos su interés en CURA.
                            </h1>
                        </div>
                    </section>
                    <section>
                        <div id="orientador" className="maestro orientador">
                            <div className="container">
                                <div className="des">
                                    <div className="inf">
                                        <h1>
                                            <img src={check} alt="check" />
                                            Contacto CURA
                                        </h1>

                                        <Box sx={{ paddingBottom: '40px' }}>
                                            <DisplayErrorMessage />
                                            <DisplaySuccessMessage />
                                            <form onSubmit={handleSubmit}>
                                                <TextField
                                                    label="Nombre"
                                                    variant="outlined"
                                                    fullWidth
                                                    margin="normal"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    error={!!fieldErrors.name}
                                                    helperText={
                                                        fieldErrors.name
                                                    }
                                                    required
                                                    inputProps={{
                                                        maxLength: 50,
                                                    }}
                                                />
                                                <TextField
                                                    label="Telefono"
                                                    variant="outlined"
                                                    fullWidth
                                                    margin="normal"
                                                    name="cellphone"
                                                    value={formData.cellphone}
                                                    onChange={handleChange}
                                                    error={
                                                        !!fieldErrors.cellphone
                                                    }
                                                    helperText={
                                                        fieldErrors.cellphone
                                                    }
                                                    required
                                                    inputProps={{
                                                        maxLength: 10,
                                                    }}
                                                />
                                                <TextField
                                                    label="Email"
                                                    variant="outlined"
                                                    fullWidth
                                                    margin="normal"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    error={!!fieldErrors.email}
                                                    helperText={
                                                        fieldErrors.email
                                                    }
                                                    required
                                                    inputProps={{
                                                        maxLength: 250,
                                                    }}
                                                />
                                                <TextField
                                                    label="Mensaje"
                                                    variant="outlined"
                                                    fullWidth
                                                    multiline
                                                    rows={4}
                                                    margin="normal"
                                                    name="message"
                                                    value={formData.message}
                                                    onChange={handleChange}
                                                    error={
                                                        !!fieldErrors.message
                                                    }
                                                    helperText={
                                                        fieldErrors.message
                                                    }
                                                    required
                                                    inputProps={{
                                                        maxLength: 500,
                                                    }}
                                                />
                                                <Button
                                                    className="ContactSend"
                                                    variant="contained"
                                                    color="success"
                                                    type="submit"
                                                    fullWidth
                                                    sx={{
                                                        width: '200px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        height: '50px',
                                                        my: 1,
                                                        color: '#ffffff',
                                                        display: 'flex',
                                                        borderRadius: '30px',
                                                        color: '#ffffff',
                                                        backgroundColor:
                                                            '#00C483 ',
                                                        '&:hover': {
                                                            backgroundColor:
                                                                '#029b68 ',
                                                        },
                                                        justifyContent:
                                                            'center',
                                                        margin: '0 auto',
                                                    }}
                                                    disabled={loading}>
                                                    {loading ? (
                                                        <>
                                                            <CircularProgress
                                                                size={24}
                                                                sx={{
                                                                    color: 'white',
                                                                    mr: 1,
                                                                }}
                                                            />
                                                            Enviando...
                                                        </>
                                                    ) : (
                                                        <>
                                                            <SendIcon
                                                                sx={{
                                                                    mr: 1,
                                                                }}
                                                            />
                                                            Enviar
                                                        </>
                                                    )}
                                                </Button>
                                            </form>
                                        </Box>
                                    </div>
                                </div>
                            </div>
                            <div className="avatar">
                                <img
                                    src={beneficiosdirector}
                                    alt="beneficios director"
                                />
                            </div>
                        </div>
                    </section>
                    <section className="descargas">
                        <Downloads />
                    </section>
                    <footer>
                        <img
                            className="palomita"
                            src={palomita}
                            alt="palomita"
                        />
                        <div className="container">
                            <div className="grid cinco">
                                <div>
                                    <img src={logoFooter} alt="logo footer" />
                                    <p>
                                        Transformando la manera en que Maestros,
                                        Padres de familia y Directores gestionan
                                        la educación.
                                    </p>
                                </div>
                                <div>
                                    <img
                                        src={directivo}
                                        style={{ width: '230px' }}
                                        alt="directivo"
                                    />
                                </div>
                                <div className="FBeneficios">
                                    <h4>Beneficios</h4>
                                    <ul>
                                        <li>
                                            <NavLink
                                                to="/beneficios"
                                                onClick={handleClick}>
                                                Maestros
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                to="/beneficios"
                                                onClick={handleClick}>
                                                Padres
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                to="/beneficios"
                                                onClick={handleClick}>
                                                Orientadores
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                to="/beneficios"
                                                onClick={handleClick}>
                                                Directivos
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                                <div className="FFuncionalidades">
                                    <h4>Funcionalidades</h4>
                                    <ul>
                                        <li>
                                            <NavLink
                                                to="/funcionalidades"
                                                onClick={handleClick}>
                                                Calificaciones y asistencias
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                to="/funcionalidades"
                                                onClick={handleClick}>
                                                Promedio automático
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                to="/funcionalidades"
                                                onClick={handleClick}>
                                                Notificaciones para padres
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                to="/funcionalidades"
                                                onClick={handleClick}>
                                                Reportes de comportamiento
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <ul className="redes">
                                        <li>
                                            <a
                                                href="https://www.facebook.com/curaeducacion"
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                <img
                                                    src={facebook}
                                                    alt="facebook"
                                                />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.instagram.com/curaeducacion/"
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                <img
                                                    src={instagram}
                                                    alt="instagram"
                                                />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.youtube.com/@cura-educacionefectiva-oficial"
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                <img
                                                    src={youtube}
                                                    alt="youtube"
                                                />
                                            </a>
                                        </li>
                                        {/* <li>
                                            <a href="https://wa.me/5216181448945" target="_blank">
                                                <img src={whatsapp} />
                                            </a>
                                        </li> */}
                                    </ul>
                                    <a
                                        href="https://wa.me/5216181448945"
                                        target="_blank">
                                        <h2>
                                            <img src={whatsapp} /> 618 144 8945
                                        </h2>
                                    </a>
                                    <a
                                        href="https://wa.me/5216181448945"
                                        className="float-wa"
                                        target="_blank">
                                        <img
                                            src={whatsAppFloat}
                                            className="wa-icon"
                                        />
                                    </a>
                                    <h2>Email: curati2023@gmail.com</h2>
                                    <h2>Dirección</h2>
                                    <p className="dir">
                                        Blvd. Domingo Arrieta 1700, Gral Domingo
                                        Arrieta, CP 34180, Durango, Dgo., Mexico
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="bottom">
                            <Button
                                size="small"
                                sx={{
                                    color: '#ffffffbe',
                                    textDecoration: 'underline',
                                    fontWeight: 'bold',
                                    padding: '0',
                                }}
                                onClick={() => {
                                    history.push({
                                        pathname: '/TerminosCondiciones',
                                    });
                                }}>
                                Términos y condiciones
                            </Button>
                            <div className="container">
                                <span>
                                    Cura App ® Todos los Derechos Reservados •
                                    Durango, Dgo. México • 2023
                                </span>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </Box>
    );
};

export default Contacto;
