import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import {
    fetchClassroomSubjectData,
    invalidate,
    selectClassroomSubjectDetails,
    selectClassroomViewmodelFetchStatusById,
} from '../../../store/meGroups/classrooms';
import { useAuth, useFeedback } from '../../../../hooks';
import { DateTime } from 'luxon';
import { Error } from '../../../../components/Feedback';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import SkeletonPage from './../../../components/SkeletonPage';
import ClassroomSubjectPreviewCard from './../../../components/ClassroomPreviewCard';
import SubjectScoresTable from './components/SubjectScoresTable';

const ClassroomSubjectAssistance = () => {
    ////////// HOOKS //////////
    let { id } = useParams();
    const Auth = useAuth();
    const dispatch = useDispatch();
    const feedbackApp = useFeedback();

    ///////// SHARED STATE ////////

    const classroomDetails = useSelector(
        selectClassroomSubjectDetails(id, Auth.user.school_id)
    );

    const statusServer = useSelector(
        selectClassroomViewmodelFetchStatusById(id)
    );

    ///////// HANDLERS ////////

    const reload = () => {
        dispatch(
            fetchClassroomSubjectData({
                groupId: classroomDetails.group.group_id,
                subjectId: classroomDetails.subject.subject_id,
                curretnDate: DateTime.local().toFormat('yyyy-MM-dd'),
                schoolId: classroomDetails.school.school_id,
                uuid: classroomDetails.viewmodel.uuid,
            })
        );
    };

    /**
     * Invalida la UI
     */
    const invalidateUI = () => {
        dispatch(invalidate(id));
        reload();
    };

    /**
     * Evento lanzado cuanto el proceso
     * de guardar o crear calificaciones ha terminado
     */
    const handleSaveScores = (type, data) => {
        if (type == 'done') {
            feedbackApp.showFeedback({
                title: 'Calificaciones registradas',
            });
        } else {
            feedbackApp.showFeedback({
                title: data.feedback.title,
            });
        }
    };

    // const getCurrentDate = () => {
    //     const fecha = DateTime.local();
    //     const formattedDate = fecha
    //         .setLocale('es')
    //         .toFormat("dd 'de' LLLL yyyy");
    //     return formattedDate;
    // };

    return (
        <Box
            sx={{
                flexGrow: 1,
                paddingTop: {
                    xs: 1,
                    sm: 2,
                    md: 2,
                },
                paddingLeft: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingRight: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingBottom: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
            }}>
            {statusServer === 'pending' && <SkeletonPage />}

            {statusServer === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}
            {statusServer === 'fulfilled' && (
                <>
                    <Box
                        sx={{
                            marginBottom: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                        }}>
                        <Tooltip title="Sincronizar información">
                            <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                onClick={invalidateUI}
                                startIcon={<SyncIcon />}>
                                Sincronizar
                            </Button>
                        </Tooltip>
                    </Box>
                    <ClassroomSubjectPreviewCard classroom={classroomDetails} />
                    <SubjectScoresTable
                        uuid={classroomDetails.viewmodel.uuid}
                        schoolId={classroomDetails.school.school_id}
                        groupId={classroomDetails.group.group_id}
                        subjectId={classroomDetails.subject.subject_id}
                        onSave={handleSaveScores}
                    />
                </>
            )}
        </Box>
    );
};

export default ClassroomSubjectAssistance;
