import React, { useRef } from 'react';
import ImageView from './ImageView';
import { useImage } from '../hooks/useImage';

export const MainImageContainer = () => {
    const { schoolId, imageSrc, changePhotoEvent, isLoading } =
        useImage('theme-logo-main');
    const refInputFile = useRef(null);
    const defaultimageCura = '/images/buttonCURA.jpeg';

    const onChangeImage = () => {
        refInputFile.current.click();
    };

    return (
        <>
            <ImageView
                title="Membrete documentos"
                schoolId={schoolId}
                imageSrc={imageSrc || defaultimageCura}
                onChangeImage={onChangeImage}
                isLoading={isLoading}
                showId={false}
            />
            <input
                ref={refInputFile}
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={changePhotoEvent}
            />
        </>
    );
};

export default MainImageContainer;
