import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Card, CardContent, Box, Tooltip } from '@mui/material';
import {
    DataGrid,
    esES,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectLastIncident,
} from '../../../store/professors/fetchSlice';
import { getLastIncident } from '../../../store/professors/thunks';
import { selectComponentProfessorsLastInsidentStatusServer } from '../../../store/professors/componentsStatusSelectors';
import Error from '../../../../components/Feedback/Error';
import { SkeletonProfessorsTable } from '../../../components/Skeleton';
import NoDataOverlay from '../../../../components/utilities/NoDataOverlay';
import { GridToolBar } from '../../../../components/utilities/GridToolBar';
import CustomPagination from '../../../../components/utilities/CustomPagination';

const LastIncidents = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentProfessorsLastInsidentStatusServer
    );

    const lastIncident = useFetchResource(
        () =>
            getLastIncident({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectLastIncident,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getLastIncident({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            })
        );
    };

    const [pageSize, setPageSize] = useState(10);

    const headers = lastIncident?.value ? Object.keys(lastIncident.value) : [];
    const data = lastIncident?.value || {};

    const rows =
        headers.length > 0
            ? data[headers[0]]?.map((_, index) => {
                  const row = { id: index + 1 };
                  headers.forEach((header) => {
                      row[header] = data[header][index]?.value || '';
                  });
                  return row;
              })
            : [];

    const columns = headers.map((header) => ({
        field: header,
        headerName: header,
        flex: header === 'Descripción' ? 2 : 1,
        renderCell: (params) => (
            <Box
                sx={{
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                    lineHeight: '1.2',
                }}
            >
                {params.value}
            </Box>
        ),
    }));

    const CustomToolbar = () => (
        <GridToolbarContainer sx={{ justifyContent: 'flex-end' }}>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarExport
                csvOptions={{
                    utf8WithBom: true,
                    delimiter: ',',
                }}
            />
        </GridToolbarContainer>
    );

    return (
        <Box sx={{ width: '100%', overflowX: 'hidden' }}>
            {fetchingStatus === 'pending' && (
                <SkeletonProfessorsTable items={9} />
            )}
            {fetchingStatus === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}
            {fetchingStatus === 'fulfilled' && (
                <Card
                    variant="outlined"
                    sx={{
                        borderRadius: '10px',
                        width: '100%',
                        height: 'auto',
                        overflow: 'hidden',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                        backgroundColor: '#fff',
                        borderColor: '#e0e0e0',
                        mb: 4,
                    }}
                >
                    <CardContent sx={{ width: '100%', padding: '24px' }}>
                        <Tooltip
                            title={
                                lastIncident?.description || 'Último incidente'
                            }
                            followCursor
                        >
                            <Typography
                                variant="h6"
                                color="textPrimary"
                                gutterBottom
                            >
                                {lastIncident?.title || 'Último incidente'}
                            </Typography>
                        </Tooltip>
                        <Box sx={{ width: '100%' }}>
                            <DataGrid
                                localeText={{
                                    ...esES.components.MuiDataGrid.defaultProps
                                        .localeText,
                                    toolbarColumns: '',
                                    toolbarFilters: '',
                                    toolbarDensity: '',
                                    toolbarExport: '',
                                }}
                                rows={rows}
                                columns={columns}
                                pageSize={10}
                                rowsPerPageOptions={[]}
                                disableSelectionOnClick
                                disableDensitySelector
                                components={{
                                    NoRowsOverlay: NoDataOverlay,
                                    NoResultsOverlay: NoDataOverlay,
                                    Toolbar: GridToolBar,
                                    Pagination: CustomPagination,
                                }}
                                componentsProps={{
                                    panel: {
                                        placement: 'bottom-end',
                                    },
                                    noResultsOverlay: {
                                        message:
                                            'No se encontraron resultados para la búsqueda',
                                    },
                                    noRowsOverlay: {
                                        message: 'No hay datos disponibles',
                                    },
                                }}
                                getRowHeight={() => 'auto'}
                                sx={{
                                    '& .MuiDataGrid-cell': {
                                        whiteSpace: 'normal',
                                        wordWrap: 'break-word',
                                        overflow: 'visible',
                                        lineHeight: '3',
                                    },
                                    '&.MuiDataGrid-root': {
                                        border: 'none',
                                    },
                                }}
                                autoHeight
                            />
                        </Box>
                    </CardContent>
                </Card>
            )}
        </Box>
    );
};

export default LastIncidents;
