import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectTotalStudents,
} from '../../../store/home/fetchSlice';
import { getTotalStudents } from '../../../store/home/thunks';
import SchoolIcon from '@mui/icons-material/School';
import { selectComponentHomeTotalStudentsStatusServer } from '../../../store/home/componentStatusSelectors';
import Skeleton, { SkeletonCard } from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';
import { ErrorBoundary } from 'react-error-boundary';

const TotalStudentCard = () => {
    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id;
    const dispatch = useDispatch();
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentHomeTotalStudentsStatusServer
    );
    const totalStudent = useFetchResource(
        () =>
            getTotalStudents({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectTotalStudents,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getTotalStudents({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
            selectTotalStudents,
            [cycleSelected, schoolId]
        );
    };

    return (
        <ErrorBoundary>
            <Box>
                {fetchingStatus === 'pending' && <SkeletonCard items={9} />}

                {fetchingStatus === 'rejected' && (
                    <Error
                        onRetry={reload}
                        message={'Estamos teniendo problemas'}
                    />
                )}

                {fetchingStatus === 'fulfilled' && (
                    <Card
                        style={{
                            backgroundColor: '#03a9f4',
                            borderRadius: '10px',
                            textAlign: 'center',
                            maxWidth: '400px',
                            minHeight: '213px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            boxShadow: 'none',
                            margin: 'auto',
                        }}
                    >
                        <CardContent style={{ padding: '16px', width: '100%' }}>
                            <SchoolIcon
                                style={{
                                    fontSize: 40,
                                    color: 'rgb(232, 247, 255)',
                                    marginBottom: '10px',
                                }}
                            />
                            <Typography
                                variant="h6"
                                align="center"
                                style={{
                                    color: 'rgb(232, 247, 255)',
                                    marginBottom: '10px',
                                    whiteSpace: 'pre-wrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                {totalStudent.title}
                            </Typography>
                            <Typography
                                variant="h4"
                                style={{
                                    color: 'rgb(232, 247, 255)',
                                }}
                            >
                                {totalStudent.value || 0}
                            </Typography>
                        </CardContent>
                    </Card>
                )}
            </Box>
        </ErrorBoundary>
    );
};

export default TotalStudentCard;
