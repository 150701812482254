import {
    createAsyncThunk,
    createSelector,
    createSlice,
} from '@reduxjs/toolkit';
import Connection from '../../../service/Connection';
import Feedback from '../../../service/Feedback';
import { selectPaymentConcepts } from './selectors';
import {
    setAllConcepts,
    upsertManyConcepts,
} from '../../../store/slices/entities/payments/concepts';
import { upsertManyCataloConcepts } from '../../../store/slices/entities/payments/cat_concepts';
import { upsertManyStudents } from '../../../store/slices/entities/students';
import { upsertManyDiscounts } from '../../../store/slices/entities/payments/discounts';
import { upsertManySurcharges } from '../../../store/slices/entities/payments/surcharges';
import { upsertManyPayments } from '../../../store/slices/entities/payments/income';
import { PAYMENTS_HOME_EXPIRE_TIME } from '../../../service/const';
import { emptyState } from '../emptyState';
import Services from '../../../service/Connection';

////////////////////////////////////// SLICE //////////////////////////////////////
const ConceptsSlice = createSlice({
    name: 'ConceptsUI/Server',
    initialState: emptyState,
    reducers: {
        /**
         * Invalidar datos de la UI
         */
        invalidate: (state, action) => {
            state.didInvalidate = true;
        },
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return emptyState;
        });

        const pendingServerStatus = (state, action) => {
            state.statusServer = 'pending';
        };
        /**
         * Termina la carga de informacion
         */

        builder.addCase(loadUI.fulfilled, (state, action) => {
            state.expireIn = new Date().setMinutes(
                new Date().getMinutes() + PAYMENTS_HOME_EXPIRE_TIME
            );
            state.ferchingAt = Date.now();
            state.didInvalidate = false;

            state.statusServer = 'fulfilled';
        });
        builder.addCase(loadUI.pending, pendingServerStatus);
        builder.addCase(loadUI.rejected, (state, action) => {
            state.statusServer = 'rejected';
            state.feedback = action.payload.feedback;
        });
    },
});

export const { invalidate } = ConceptsSlice.actions;

export default ConceptsSlice.reducer;

/////////////////////////////////////////////// TRUNKS ///////////////////////////////////////////////

export const loadUI = createAsyncThunk(
    'ConceptsUI/server/fetch/data',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback();
        try {
            const concepts = await Services.getConceptsBySchool(schoolId).then(
                (res) => res.data.data
            );

            const concept_catalog = await Services.getSchoolsByConceptsCatalog(
                schoolId
            ).then((res) => res.data.data);

            const students = await Services.StudentsBySchool(schoolId).then(
                (res) => res.data.data
            );

            thunkAPI.dispatch(upsertManyConcepts(concepts));
            thunkAPI.dispatch(upsertManyCataloConcepts(concept_catalog));
            thunkAPI.dispatch(upsertManyStudents(students));

            return {
                concept_catalog,
                concepts,
                students,
            };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    },
    {
        // Condición para ejecutar el thunk solo si es necesario
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } = selectConceptsFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);
//////////////////////////////////// SELECTORES ////////////////////////////////////
/**
 * Selector para recuperar el estado de la petición de conceptos de pago
 */
export const selectConceptsFetch = createSelector(
    selectPaymentConcepts,
    (state) => state.fetch
);

/**
 * Selector para recuperar el estado del servidor
 */
export const selectStatusServer = createSelector(
    selectPaymentConcepts,
    (state) => state.fetch.statusServer
);
