import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
    Grid,
    Button,
    Snackbar,
} from '@mui/material';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../hooks';
import { selectSettingBySchoolId } from '../../store/slices/entities/settings';
import {
    selectChangeNumberStatusOperation,
    selectStatusOperation,
    updateUsername,
} from '../../store/slices/usersUI';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import useFeedback from '../../hooks/useFeedback';

/**
 * Modal para actualizar el numero de telefono
 *
 * @param {*} param0
 *
 * @returns
 */
const ModalUsuarioCelular = ({ openModal, setOpenModal, user = null }) => {
    const dispatch = useDispatch();
    const feedbackApp = useFeedback();

    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id;

    //////////// SELECTORES /////////////

    const config = useSelector(selectSettingBySchoolId(schoolId));
    const statusOperation = useSelector(selectChangeNumberStatusOperation);

    let titleBarBackground = config.find(
        (res) => res.key === 'theme-color-title-bar'
    );
    let fontColor = config.find((res) => res.key === 'theme-color-font');

    const [cellphone, setCellphone] = useState('');

    /**
     * Actualizacion de los inputs de formulario de nombre de usuario
     *
     * @param {*} event
     */
    const onInputChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;

        setCellphone(value);
    };

    /**
     * Actualziar contraseña
     */
    const enviarDatos = async () => {
        dispatch(
            updateUsername({
                userId: user.user_id,
                cellphone: {
                    cellphone,
                },
            })
        )
            .unwrap()
            .then((response) => {
                feedbackApp.showFeedback({
                    title: 'Celular actualizado',
                });
                setOpenModal(false);
            })
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    ////////////////////////// VALIDACIONES //////////////////////////////////

    const cellphoneValid = () => {
        const isPhone = /^[0-9]{10}$/.test(cellphone);

        return {
            invalid: !isPhone,
            message: 'Campo requerido o incorrecto',
        };
    };

    /**
     * Validacion global para el formulario
     *
     * @returns
     */
    const formInvalid = () => {
        return cellphoneValid().invalid;
    };

    useEffect(() => {
        if (!openModal) {
            setCellphone('');
        }
    }, [openModal]);

    /* No cerrar click afuera del modal */
    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        setOpenModal(false);
    };

    ////////////////////// VIEW //////////////////////////

    return (
        <Dialog
            open={openModal}
            fullWidth={true}
            maxWidth="sm"
            disableEscapeKeyDown={statusOperation == 'pending'}
            onClose={handleClose}
        >
            <DialogTitle
                style={{
                    backgroundColor: titleBarBackground.value,
                    color: fontColor.value,
                }}
            >
                <div style={{ display: 'flex' }}>
                    <Typography
                        variant="h6"
                        component="div"
                        style={{ flexGrow: 1, color: fontColor.value }}
                    >
                        Cambiar teléfono celular
                    </Typography>
                </div>
            </DialogTitle>

            <DialogContent dividers>
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    <Grid container spacing={2}>
                        <Grid container item xl={12}>
                            <TextField
                                id="cellphone"
                                name="cellphone"
                                label="Celular"
                                size="small"
                                variant="outlined"
                                value={cellphone}
                                onChange={onInputChange}
                                inputProps={{ maxLength: 10 }}
                                fullWidth
                                type={'text'}
                                error={cellphoneValid().invalid}
                                helperText={
                                    cellphoneValid().invalid
                                        ? cellphoneValid().message
                                        : false
                                }
                            />
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>

            <DialogActions>
                <LoadingButton
                    size="small"
                    color="primary"
                    onClick={enviarDatos}
                    loading={statusOperation == 'pending'}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    disabled={formInvalid()}
                >
                    Cambiar
                </LoadingButton>
                <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={handleClose}
                    disabled={statusOperation == 'pending'}
                >
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ModalUsuarioCelular;
