import { Box, Divider, Typography } from '@mui/material';
import React, { useState } from 'react';

export const ChartTooltip = ({ id, value, color, indexValue }) => (
    <Box
        sx={{
            backgroundColor: 'white',
            padding: '10px',
            borderRadius: '8px',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
        }}>
        <Typography variant="subtitle1" fontWeight="bold">
            {indexValue}
        </Typography>
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '5px',
            }}>
            <Box
                sx={{
                    width: '15px',
                    height: '15px',
                    backgroundColor: color,
                    marginRight: '10px',
                }}
            />
            <Typography
                variant="body2"
                sx={{
                    fontSize: '0.8rem',
                    color: 'rgba(0, 0, 0, 0.957)',
                }}>
                {value}
            </Typography>
        </Box>
    </Box>
);
