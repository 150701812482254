import { Box, Card, Stack, Typography } from '@mui/material';
import { DataGrid, esES } from '@mui/x-data-grid';
import React, { useState } from 'react';
import NoDataOverlay from '../../../components/utilities/NoDataOverlay';
import CustomPagination from '../../../components/utilities/CustomPagination';
import MakeSnaphotModal from './MakeSnaphotModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxesPacking } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { selectCyclesData } from '../../../store/slices/entities/cycles';
import { DateTime } from 'luxon';

import ErrorIcon from '@mui/icons-material/Error';
import DoneIcon from '@mui/icons-material/Done';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import { CycleStatus, levelsNames } from '../../../service/const';
import {
    selectsettingsUI,
    updateSnaphotFilter,
} from '../../../store/slices/settingsUI';
import Header from '../../../components/utilities/Header';
import { getLevelName } from '../../../libs/utils';
import * as constants from './../../../service/const';
import { NoFloatGridToolbar } from '../../../components/utilities/NoFloatGridToolbar';

const CycleCutsCard = () => {
    //////////////// SHARED STATE ///////////////////
    const DataTable = useSelector(selectCyclesData);
    const dispatch = useDispatch();

    const filtersUI = useSelector(selectsettingsUI);
    const filterCycleCenter = filtersUI.snaphot.filter;

    ///////////////// LOCAL STATE ///////////////////

    const [showMakeSnaphotModal, setShowMakeSnaphotModal] = useState(false);

    ///////////// ACTIONS /////////////////

    const onShowMakeSnaphotModal = () => {
        setShowMakeSnaphotModal(true);
    };

    let allCyclesStatus = {
        [CycleStatus.PROCCESS_FAILED]: {
            color: 'red',
            text: 'Fallido',
            icon: <ErrorIcon />,
            class: 'left-status-failed',
        },
        [CycleStatus.WAITING_PROCCESS]: {
            color: '#ddc005',
            text: 'En espera',
            icon: <AccessTimeIcon />,
            class: 'left-status-onhold',
        },
        [CycleStatus.IN_PROCCESS]: {
            color: '#036dba',
            text: 'Procesando',
            icon: <CloudSyncIcon />,
            class: 'left-status-processing',
        },
        [CycleStatus.PROCCESS_SUCCESSFULLY]: {
            color: 'green',
            text: 'Completada',
            icon: <DoneIcon />,
            class: 'left-status-imported',
        },
    };

    const paginationItems = [
        { color: 'red', text: 'Fallido', icon: <ErrorIcon /> },
        { color: '#ddc005', text: 'En espera', icon: <AccessTimeIcon /> },
        { color: '#036dba', text: 'Procesando', icon: <CloudSyncIcon /> },
        { color: 'green', text: 'Completada', icon: <DoneIcon /> },
    ];
    ///////////// COLUMNS /////////////////
    const columns = [
        {
            field: 'cycle_id',
            headerName: 'Folio',
            flex: 0.4,
            type: 'number',
            minWidth: 40,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                return params.row.cycle_id;
            },
        },
        {
            field: 'cycle',
            headerName: 'Ciclo',
            flex: 0.7,
            minWidth: 70,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                return params.row.cycle;
            },
        },
        {
            field: 'nivel',
            headerName: 'Nivel',
            type: 'singleSelect',
            valueOptions: constants.levelsNames.map((item) => item.title),
            flex: 1,
            minWidth: 100,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => {
                return getLevelName(params.row.level);
            },
        },

        {
            field: 'segment_cut',
            headerName: 'Segmento',
            type: 'number',
            flex: 0.4,
            minWidth: 40,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                return params.row.segment_cut;
            },
        },
        {
            field: 'status',
            headerName: 'Estado',
            type: 'singleSelect',
            valueOptions: Object.keys(allCyclesStatus).map((key) => {
                return allCyclesStatus[key].text;
            }),
            flex: 0.4,
            minWidth: 40,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                return (
                    <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        color={allCyclesStatus[params.row.status].color}
                    >
                        {allCyclesStatus[params.row.status].icon}
                    </Stack>
                );
            },
            valueGetter: ({ value }) => {
                return allCyclesStatus[value].text;
            },
        },
        {
            field: 'created_at',
            headerName: 'Fecha de creación',
            type: 'date',
            flex: 1,
            minWidth: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: ({ value }) => {
                return DateTime.fromSQL(value);
            },
            valueFormatter: ({ value }) => {
                return value
                    .setLocale('es')
                    .toLocaleString({ locale: 'es', ...DateTime.DATETIME_MED });
            },
        },
        {
            field: 'updated_at',
            headerName: 'Fecha de actualización',
            type: 'date',
            flex: 1,
            minWidth: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: ({ value }) => {
                return DateTime.fromSQL(value);
            },
            valueFormatter: ({ value }) => {
                return value
                    .setLocale('es')
                    .toLocaleString({ locale: 'es', ...DateTime.DATETIME_MED });
            },
        },
    ];

    const anotherButtons = [
        {
            text: 'Iniciar corte',
            icon: <FontAwesomeIcon icon={faBoxesPacking} />,
            onClick: onShowMakeSnaphotModal,
        },
    ];

    const handleCycleCenterFilterChange = (event) => {
        let newFilterValues = {};

        event.items.forEach((filter) => {
            const { columnField, operatorValue, value } = filter;

            newFilterValues = {
                columnField,
                operatorValue,
                value,
            };
        });
        dispatch(updateSnaphotFilter({ filter: newFilterValues }));
    };

    return (
        <>
            <Header
                title="Centro de cortes de ciclo"
                subtitle="Aquí puedes administrar y visualizar los cortes de ciclo."
            />
            <Card
                sx={{
                    color: 'white',
                    borderRadius: '15px',
                    width: '100%',
                    height: '100%',
                }}
            >
                <DataGrid
                    columns={columns}
                    getRowId={(row) => row.cycle_id}
                    rows={DataTable}
                    autoHeight
                    pageSize={10}
                    disableSelectionOnClick
                    disableDensitySelector
                    onFilterModelChange={(event) =>
                        handleCycleCenterFilterChange(event)
                    }
                    localeText={{
                        ...esES.components.MuiDataGrid.defaultProps.localeText,
                        toolbarColumns: '',
                        toolbarFilters: '',
                        toolbarDensity: '',
                        toolbarExport: '',
                    }}
                    components={{
                        NoRowsOverlay: NoDataOverlay,
                        NoResultsOverlay: NoDataOverlay,
                        Toolbar: NoFloatGridToolbar,
                        Pagination: CustomPagination,
                    }}
                    componentsProps={{
                        panel: {
                            placement: 'bottom-end',
                        },
                        toolbar: {
                            AnotherButtons: anotherButtons,
                        },
                        noResultsOverlay: {
                            message:
                                'No se encontraron resultados para la búsqueda',
                        },
                        noRowsOverlay: {
                            message: 'No hay Cortes de ciclo registrados',
                        },
                        pagination: {
                            items: paginationItems,
                        },
                    }}
                    sx={{
                        ' & .left-status-onhold': {
                            borderLeftColor: '#ddc005',
                            borderLeftStyle: 'solid',
                        },
                        ' & .left-status-processing': {
                            borderLeftColor: '#036dba',
                            borderLeftStyle: 'solid',
                        },
                        ' & .left-status-imported': {
                            borderLeftColor: 'green',
                            borderLeftStyle: 'solid',
                        },
                        ' & .left-status-failed': {
                            borderLeftColor: 'red',
                            borderLeftStyle: 'solid',
                        },
                        ' & .left-status-unknown': {
                            borderLeftColor: 'pink',
                            borderLeftStyle: 'solid',
                        },
                    }}
                    getCellClassName={(params) => {
                        if (params.field === 'cycle_id') {
                            let importStatus =
                                allCyclesStatus[params.row.status];
                            return importStatus.class;
                        }

                        return '';
                    }}
                    initialState={{
                        filter: {
                            filterModel: {
                                items: filterCycleCenter
                                    ? [
                                          {
                                              columnField:
                                                  filterCycleCenter.columnField ||
                                                  '',
                                              operatorValue:
                                                  filterCycleCenter.operatorValue ||
                                                  '',
                                              value:
                                                  filterCycleCenter.value || '',
                                          },
                                      ]
                                    : [],
                            },
                        },
                        sorting: {
                            sortModel: [{ field: 'cycle_id', sort: 'desc' }],
                        },
                    }}
                />
            </Card>

            {showMakeSnaphotModal && (
                <MakeSnaphotModal
                    open={showMakeSnaphotModal}
                    setOpen={setShowMakeSnaphotModal}
                />
            )}
        </>
    );
};

export default CycleCutsCard;
