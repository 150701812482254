import {
    createAsyncThunk,
    createSelector,
    createSlice,
} from '@reduxjs/toolkit';
import Connection from '../../../service/Connection';
import Feedback from '../../../service/Feedback';
import { selectPaymentCatSurcharge } from './selectors';
import {
    setAllCatalogSurcharges,
    upsertManyCatalogSurcharges,
} from '../../../store/slices/entities/payments/cat_surcharges';

// Estado inicial del slice
const emptyState = {
    expireIn: null,
    fetchingAt: null,
    status: 'idle',
    operation: 'idle',
    didInvalidate: true,
    feedback: {
        title: null,
        message: null,
        payload: null,
    },
};

// Slice para los descuentos de pago
const catSurchagesSlice = createSlice({
    name: 'cat_surcharges',
    initialState: emptyState,
    reducers: {
        // Acción para invalidar los datos actuales
        invalidate: (state) => {
            state.didInvalidate = true;
        },
    },
    extraReducers: (builder) => {
        builder
            // Manejo del estado pendiente de la petición
            .addCase(fetchCatSurchages.pending, (state) => {
                state.status = 'pending';
            })
            // Manejo del estado cumplido de la petición
            .addCase(fetchCatSurchages.fulfilled, (state, action) => {
                state.status = 'fulfilled';
                state.data = action.payload.data;
                state.didInvalidate = false;
                state.fetchingAt = Date.now();
                state.expireIn = new Date().setMinutes(
                    new Date().getMinutes() + 30
                );
            })
            // Manejo del estado rechazado de la petición
            .addCase(fetchCatSurchages.rejected, (state, action) => {
                state.status = 'rejected';
            });
    },
});

export const { invalidate } = catSurchagesSlice.actions;

export default catSurchagesSlice.reducer;

/////////////////////////////////////////////// TRUNKS ///////////////////////////////////////////////

export const fetchCatSurchages = createAsyncThunk(
    'cat_surcharges/fetch',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback();
        try {
            const response =
                await Connection.getSchoolsBySurchargesCatalog(schoolId);

            thunkAPI.dispatch(upsertManyCatalogSurcharges(response.data.data));

            return response.data.data;
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    },
    {
        // Condición para ejecutar el thunk solo si es necesario
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } = selectFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);
//////////////////////////////////// SELECTORES ////////////////////////////////////
/**
 * Selector para recuperar el estado de la petición de conceptos de pago
 */
export const selectFetch = createSelector(
    selectPaymentCatSurcharge,
    (state) => state.fetch
);
