import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useAuth } from '../../../../hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchClassroomGroupData,
    invalidate,
    selectAssistByGroup,
    selectClassroomGroupDetails,
    selectClassroomViewmodelFetchStatusById,
} from '../../../store/meGroups/classrooms';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import SkeletonPage from '../../../components/SkeletonPage';
import { Error } from '../../../../components/Feedback';
import SubjectJustifyTable from '../../ClassroomSubjects/JustifyAssists/components/SubjectJustifyTable';
import { DateTime } from 'luxon';
import * as _ from 'lodash';
import { selectStudentsByGroup } from '../../../../store/slices/entities/groups';
import ClassroomGroupPreviewCard from '../../../components/ClassroomGroupPreviewCard';
import AbsencesModal from './components/AbsencesModal';
import JustificationModal from './components/JustificationModal';

const ClassroomGroupsJustify = () => {
    ////////// HOOKS //////////
    let { id } = useParams();
    const Auth = useAuth();
    const dispatch = useDispatch();

    ////////// SHARED STATE //////////

    const classroomDetails = useSelector(
        selectClassroomGroupDetails(id, Auth.user.school_id)
    );

    const studentsInMainGroup = useSelector(
        selectStudentsByGroup(classroomDetails.group.group_id)
    );

    const statusServer = useSelector(
        selectClassroomViewmodelFetchStatusById(id)
    );

    const assistsBygroup = useSelector(
        selectAssistByGroup(classroomDetails.group.group_id, Auth.user.user_id)
    );

    const currentDate = DateTime.local().toFormat('yyyy-MM-dd');

    ///////// LOCAL STATE ////////
    const [openAbsenceModal, setOpenAbsenceModal] = useState(false);
    const [openJustificatedModal, setOpenJustificatedModal] = useState(false);
    const [partialAbsences, setPartialAbsences] = useState([]);
    const [partialUnitedAbsences, setPartialUnitedAbsences] = useState([]);

    const buildAssitsDataGrid = ({
        partials,
        students,
        assist,
        user,
        subjects,
        subjectsModule,
    }) => {
        let studentItems = [];
        let columnsItems = [];
        let columnsUI = [
            {
                column: 'name',
                title: 'ALUMNO(S)',
                sticky: true,
                className: '',
            },
        ];

        let subjectsArray = subjects;
        let professor = user;
        let subjectModulesArray = subjectsModule;

        let professorSubjects = _.filter(subjectsArray, {
            professor_id: professor.user_id,
        });

        let subjectModulesFlatten = _.flattenDeep(subjectModulesArray);

        let professorSubjetsModule = _.filter(subjectModulesFlatten, {
            professor_id: professor.user_id,
        });

        let subjectsProfessor = professorSubjects.concat(
            professorSubjetsModule
        );

        let assistsSubjectsProfessor = subjectsProfessor.map((prof) => {
            return assist.filter(
                (assist) => assist.subject_id == prof.subject_id
            );
        });

        let flattenAssists = _.flattenDeep(assistsSubjectsProfessor);

        for (let student of students.map((i) => Object.assign({}, i))) {
            let studentItem = {};

            studentItem.name = {
                name: true,
                value: `${student.last_name} ${student.second_last_name} ${student.name}`,
            };

            let partialsUI = {};

            let total = 0;
            let totalAbs = 0;
            let totalJus = 0;

            for (let partial of partials) {
                let assiten = _.filter(flattenAssists, {
                    student_id: student.student_id,
                    partial_id: partial.partial_id,
                });

                ///HERE!-------------
                let assist = assiten.filter((e) => e.assistance === 1);
                let unionAssist = _.unionBy(assist, 'date');

                let absence = assiten.filter(
                    (e) => e.assistance == 0 && e.is_justified === false
                );
                let unionAbsence = _.unionBy(absence, 'date');

                let justified = assiten.filter(
                    (e) => e.assistance == 0 && e.is_justified === true
                );
                let unionJusitfied = _.unionBy(justified, 'date');

                total += unionAssist.length;
                totalAbs += unionAbsence.length;
                totalJus += unionJusitfied.length;

                partialsUI[`Asistencias p-${partial.partial}`] = {
                    partial: true,
                    value: unionAssist.length,
                    partialActive:
                        currentDate >= partial.start_date &&
                        currentDate <= partial.limit_date,
                };
                partialsUI[`Faltas p-${partial.partial}`] = {
                    partial: true,
                    value: unionAbsence.length,
                    abs: absence,
                    absUnion: unionAbsence,
                    partialActive:
                        currentDate >= partial.start_date &&
                        currentDate <= partial.limit_date,
                };

                partialsUI[`Justificaciones p-${partial.partial}`] = {
                    partial: true,
                    value: unionJusitfied.length,
                    abs: justified,
                    absUnion: unionJusitfied,
                    partialActive:
                        currentDate >= partial.start_date &&
                        currentDate <= partial.limit_date,
                };
            }

            partialsUI['total'] = {
                total: true,
                value: total,
            };
            ///HERE!----------------
            partialsUI['total faltas'] = {
                total: true,
                value: totalAbs,
            };

            partialsUI['total justificaciones'] = {
                total: true,
                value: totalJus,
            };

            studentItems.push({ ...studentItem, ...partialsUI });
        }

        for (let partial of partials) {
            columnsItems.push({
                column: `Asistencias p-${partial.partial}`,
                title: `Asist P${partial.partial}`,
                sticky: false,
                className: 'parcial-column',
                partialActive:
                    currentDate >= partial.start_date &&
                    currentDate <= partial.limit_date,
            });
            ///HERE!-------------
            columnsItems.push({
                column: `Faltas p-${partial.partial}`,
                title: `Faltas P${partial.partial}`,
                sticky: false,
                className: 'parcial-column',
                partialActive:
                    currentDate >= partial.start_date &&
                    currentDate <= partial.limit_date,
            });

            columnsItems.push({
                column: `Justificaciones p-${partial.partial}`,
                title: `Justif P${partial.partial}`,
                sticky: false,
                className: 'parcial-column',
                partialActive:
                    currentDate >= partial.start_date &&
                    currentDate <= partial.limit_date,
            });
        }

        columnsItems.push(
            {
                column: `total`,
                title: `TOTAL ASIST`,
                sticky: false,
                className: 'parcial-column',
            },
            {
                column: `total faltas`,
                title: `TOTAL FALTAS`,
                sticky: false,
                className: 'parcial-column',
            },
            {
                column: `total justificaciones`,
                title: `TOTAL JUST`,
                sticky: false,
                className: 'parcial-column',
            }
        );

        columnsUI = [...columnsUI, ...columnsItems];

        return {
            columns: columnsUI,
            rows: studentItems,
        };
    };

    const dataAssists = buildAssitsDataGrid(assistsBygroup);

    let assistsColumns = dataAssists.columns.map((i) => {
        return {
            field: i.column,
            headerName: i.title,
            flex: 0.4,
            minWidth: i.column === 'name' ? 150 : 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            headerClassName: i.partialActive ? 'partial-active--cell' : '',
            renderCell: (params) => {
                if (params.field === 'name') {
                    return (
                        <div
                            style={{
                                whiteSpace: 'pre-line',
                                textAlign: 'center',
                            }}>
                            {params.value == null ? '' : params.value}
                        </div>
                    );
                }
                const cellValue = params.row[params.field];
                const isFaltaCell = params.field.includes('Faltas');
                const isJustificacionCell =
                    params.field.includes('Justificaciones');
                if (isFaltaCell && cellValue.value > 0) {
                    return (
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                                setPartialAbsences(cellValue.abs);
                                setPartialUnitedAbsences(cellValue.absUnion);
                                setOpenAbsenceModal(true);
                            }}>
                            {cellValue.value}
                        </Button>
                    );
                } else if (isJustificacionCell && cellValue.value > 0) {
                    return (
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                                setPartialAbsences(cellValue.abs);
                                setPartialUnitedAbsences(cellValue.absUnion);
                                setOpenJustificatedModal(true);
                            }}>
                            {cellValue.value}
                        </Button>
                    );
                }

                return cellValue.value;
            },
            cellClassName: (params) => {
                let row = params.row[params.field]?.partialActive;

                if (row) {
                    return 'partial-active--cell';
                }

                return '';
            },
            valueGetter: (params) => {
                return params.value.value;
            },
        };
    });

    let assistsRows = dataAssists.rows
        .map((i, index) => ({ id: index, ...i }))
        .sort((a, b) => {
            const lastNameA = a.name.value;
            const lastNameB = b.name.value;
            return lastNameA.localeCompare(lastNameB);
        });

    ////////// HANDLERS //////////

    const reload = () => {
        dispatch(
            fetchClassroomGroupData({
                groupId: classroomDetails.group.group_id,
                schoolId: classroomDetails.school.school_id,
                userId: Auth.user.user_id,
                currentStudents: studentsInMainGroup,
                uuid: classroomDetails.viewmodel.uuid,
            })
        );
    };

    /**
     * Invalida la UI
     */
    const invalidateUI = () => {
        dispatch(invalidate(id));
        reload();
    };

    return (
        <Box
            sx={{
                flexGrow: 1,
                paddingTop: {
                    xs: 1,
                    sm: 2,
                    md: 2,
                },
                paddingLeft: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingRight: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingBottom: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
            }}>
            {statusServer === 'pending' && <SkeletonPage />}

            {statusServer === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}
            {statusServer === 'fulfilled' && (
                <>
                    <AbsencesModal
                        title="Faltas"
                        openModal={openAbsenceModal}
                        setOpenModal={setOpenAbsenceModal}
                        absences={partialAbsences}
                        unitedAbsemces={partialUnitedAbsences}
                    />
                    <JustificationModal
                        title="Justificaciones"
                        openModal={openJustificatedModal}
                        setOpenModal={setOpenJustificatedModal}
                        absences={partialUnitedAbsences}
                    />

                    <Box
                        sx={{
                            marginBottom: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                        }}>
                        <Tooltip title="Sincronizar información">
                            <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                onClick={invalidateUI}
                                startIcon={<SyncIcon />}>
                                Sincronizar
                            </Button>
                        </Tooltip>
                    </Box>
                    <ClassroomGroupPreviewCard classroom={classroomDetails} />
                    <SubjectJustifyTable
                        rows={assistsRows}
                        columns={assistsColumns}
                    />
                </>
            )}
        </Box>
    );
};

export default ClassroomGroupsJustify;
