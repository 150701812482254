import { createAsyncThunk } from '@reduxjs/toolkit';
import Connection from '../../../service/Connection';
import {
    selectComponentGroupsBestAssistanceGroupFetch,
    selectComponentGroupsMostAbscensesGroupFetch,
    selectComponentGroupsMostReportsGroupFetch,
    selectComponentGroupsImcFetch,
    selectComponentGroupsScoresMainFetch,
    selectComponentGroupsScoresSpecialFetch,
    selectComponentGroupsScoresExtracurricularFetch,
    selectComponentGroupsAssistsAbsencesFetch,
    selectComponentGroupsReportsFetch,
    selectComponentGroupsTotalStudentsDiversityFetch,
    selectComponentGroupsMaleFemaleFetch,
    selectComponentGroupsBestScoreGroupFetch,
    selectComponentGroupsWorstScoreGroupFetch,
    selectComponentGroupsAllScholarshipStudentsFetch,
} from './componentStatusSelectors';

export const getBestAssistanceGroup = createAsyncThunk(
    'fetch/best_assistance_group',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'groups',
                    type: 'top',
                    idKey: 'best_assistance_group',
                },
            });

            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching BestAssistanceGroup:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentGroupsBestAssistanceGroupFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const getMostAbscensesGroup = createAsyncThunk(
    'fetch/most_abscenses_group',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'groups',
                    type: 'top',
                    idKey: 'most_abscenses_group',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching MostAbscensesGroup:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentGroupsMostAbscensesGroupFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const getMostReportsGroup = createAsyncThunk(
    'fetch/most_reports_group',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'groups',
                    type: 'top',
                    idKey: 'most_reports_group',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching MostReportsGroup:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentGroupsMostReportsGroupFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const getImc = createAsyncThunk(
    'fetch/imc',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'groups',
                    type: 'table',
                    idKey: 'imc',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching Imc:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } = selectComponentGroupsImcFetch(
                getState()
            );

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const getScoresMain = createAsyncThunk(
    'fetch/scores_main',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'groups',
                    type: 'table',
                    idKey: 'scores_main',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching ScoresMain:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentGroupsScoresMainFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const getScoresSpecial = createAsyncThunk(
    'fetch/scores_special',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'groups',
                    type: 'table',
                    idKey: 'scores_special',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching ScoresSpecial:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentGroupsScoresSpecialFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const getScoresExtracurricular = createAsyncThunk(
    'fetch/scores_extracurricular',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'groups',
                    type: 'table',
                    idKey: 'scores_extracurricular',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching ScoresExtracurricular:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentGroupsScoresExtracurricularFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const getAssistsAbsences = createAsyncThunk(
    'fetch/assists_absences',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'groups',
                    type: 'table',
                    idKey: 'assists_absences',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching AssistsAbsences:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentGroupsAssistsAbsencesFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const getReports = createAsyncThunk(
    'fetch/reports',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'groups',
                    type: 'table',
                    idKey: 'reports',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching Reports:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } = selectComponentGroupsReportsFetch(
                getState()
            );

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const getAllScholarshipStudents = createAsyncThunk(
    'fetch/all_scholarship_students',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'groups',
                    type: 'table',
                    idKey: 'all_scholarship_students',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching TotalScholarship:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentGroupsAllScholarshipStudentsFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const getTotalStudentsDiversity = createAsyncThunk(
    'fetch/total_students_diversity',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'groups',
                    type: 'table',
                    idKey: 'total_students_diversity',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching StudentsDiversity:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentGroupsTotalStudentsDiversityFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const getMaleFemale = createAsyncThunk(
    'fetch/male_female',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'groups',
                    type: 'table',
                    idKey: 'male_female',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching MaleFemale:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentGroupsMaleFemaleFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const getBestScoreGroup = createAsyncThunk(
    'fetch/best_score_group',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'groups',
                    type: 'table',
                    idKey: 'best_score_group',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching BestScoreGroup:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentGroupsBestScoreGroupFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const getWorstScoreGroup = createAsyncThunk(
    'fetch/worst_score_group',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'groups',
                    type: 'table',
                    idKey: 'worst_score_group',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching WorstScoreGroup:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentGroupsWorstScoreGroupFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);
