import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Typography,
    Card,
    CircularProgress,
    CardContent,
} from '@mui/material';
import {
    DataGrid,
    esES,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { Tag } from 'antd';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectAllStudentsOnDirectors,
} from '../../../store/students/fetchSlice';
import { getAllStudentsOnDirectors } from '../../../store/students/thunks';
import DEFAULT_AVATAR_URL from './../../../../assets/images/Unknown_person.jpeg';
import { selectComponentStudentsAllStudentsStatusServer } from '../../../store/students/componentsStatusSelectors';
import { SkeletonStudentsTables } from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';
import NoDataOverlay from '../../../../components/utilities/NoDataOverlay';
import { GridToolBar } from '../../../../components/utilities/GridToolBar';
import CustomPagination from '../../../../components/utilities/CustomPagination';

const AllStudents = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentStudentsAllStudentsStatusServer
    );

    const allStudents = useFetchResource(
        () =>
            getAllStudentsOnDirectors({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectAllStudentsOnDirectors,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getAllStudentsOnDirectors({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            })
        );
    };

    // Generación dinámica de los datos y las columnas
    const headers = allStudents.value ? Object.keys(allStudents.value) : [];
    const rows =
        headers.length > 0
            ? allStudents.value[headers[0]].map((_, index) => {
                  const row = { id: index };
                  headers.forEach((header) => {
                      if (header === 'Foto') {
                          row[header] =
                              allStudents.value[header][index]?.value ||
                              DEFAULT_AVATAR_URL;
                      } else {
                          row[header] = allStudents.value[header][index]?.value;
                      }
                  });
                  return row;
              })
            : [];

    const columns = headers.map((header) => ({
        field: header,
        headerName: header.charAt(0).toUpperCase() + header.slice(1),
        flex: header === 'Foto' || header === 'ID alumno' ? 0 : 1,
        minWidth: header === 'Foto' || header === 'ID alumno' ? 80 : 180,
        width: header === 'Foto' || header === 'ID alumno' ? 80 : undefined,
        renderCell: (params) => {
            if (header === 'Foto') {
                return (
                    <img
                        src={params.value || DEFAULT_AVATAR_URL}
                        alt="Foto del estudiante"
                        style={{
                            width: 50,
                            height: 50,
                            borderRadius: '50%',
                            objectFit: 'cover',
                        }}
                    />
                );
            }
            if (
                header === 'Promedio' ||
                header.includes('Faltas') ||
                header.includes('Reportes')
            ) {
                const color = header.includes('Faltas')
                    ? 'error'
                    : header.includes('Reportes')
                    ? 'warning'
                    : 'success';
                return (
                    <Tag
                        color={color}
                        style={{
                            width: '100px',
                            textAlign: 'center',
                        }}
                    >
                        {params.value}
                    </Tag>
                );
            }
            return params.value;
        },
    }));

    const CustomToolbar = (props) => (
        <GridToolbarContainer sx={{ justifyContent: 'flex-end' }}>
            <GridToolbarColumnsButton {...props} />
            <GridToolbarFilterButton {...props} />
            <GridToolbarExport
                csvOptions={{
                    utf8WithBom: true,
                    delimiter: ',',
                }}
            />
        </GridToolbarContainer>
    );

    return (
        <Box>
            {fetchingStatus === 'pending' && (
                <SkeletonStudentsTables items={9} />
            )}
            {fetchingStatus === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}
            {fetchingStatus === 'fulfilled' && (
                <Card
                    sx={{
                        mt: 5,
                        borderRadius: '15px',
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <Typography
                        sx={{ ml: 2, mt: 1 }}
                        variant="h6"
                        color="textPrimary"
                        gutterBottom
                    >
                        {allStudents.title ||
                            'Información general de los estudiantes'}
                    </Typography>
                    <CardContent>
                        <DataGrid
                            localeText={{
                                ...esES.components.MuiDataGrid.defaultProps
                                    .localeText,
                                toolbarColumns: '',
                                toolbarFilters: '',
                                toolbarDensity: '',
                                toolbarExport: '',
                            }}
                            rows={rows}
                            columns={columns}
                            pageSize={10}
                            rowsPerPageOptions={[]}
                            disableSelectionOnClick
                            disableDensitySelector
                            components={{
                                NoRowsOverlay: NoDataOverlay,
                                NoResultsOverlay: NoDataOverlay,
                                Toolbar: GridToolBar,
                                Pagination: CustomPagination,
                            }}
                            componentsProps={{
                                panel: {
                                    placement: 'bottom-end',
                                },
                                noResultsOverlay: {
                                    message:
                                        'No se encontraron resultados para la búsqueda',
                                },
                                noRowsOverlay: {
                                    message: 'No hay datos disponibles',
                                },
                            }}
                            getRowHeight={() => 'auto'}
                            sx={{
                                '& .MuiDataGrid-cell': {
                                    whiteSpace: 'normal',
                                    wordWrap: 'break-word',
                                    overflow: 'visible',
                                    lineHeight: '1',
                                },
                                '&.MuiDataGrid-root': {
                                    border: 'none',
                                },
                            }}
                            autoHeight
                        />
                    </CardContent>
                </Card>
            )}
        </Box>
    );
};

export default AllStudents;
