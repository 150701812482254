import { createSelector } from '@reduxjs/toolkit';
import { selectStudents } from '../../../store/slices/entities/students';
import { selectCatSurcharge } from '../cat_surcharges/selectors';
import { selectConcepts } from '../concepts/selectors';
import { selectCatalogConcepts } from '../income/selectors';
import { selectCatConcepts } from '../cat_concepts/selectors';

/**
 * Selector para recuperar home de reportes
 */
export const selectPaymentSurcharges = (state) =>
    state.paymentsModule.surcharges;

export const selectSurcharges = (state) =>
    Object.values(state.entities.surcharges.entities);

/**
 * Selector para recuperar los datos de la tabla
 */

export const selectTableData = createSelector(
    [
        selectSurcharges,
        selectCatSurcharge,
        selectConcepts,
        selectCatConcepts,
        selectStudents,
    ],
    (surcharges, cat_Surcharges, concepts, catConcepts, student) => {
        return surcharges.map((surcharges) => {
            const catsurcharges = cat_Surcharges.find(
                (catSurcharges) =>
                    surcharges.surcharge_catalog_id ===
                    catSurcharges.surcharge_catalog_id
            );

            const conceptsArray = Object.values(concepts);
            const studentsArray = Object.values(student);

            const concept = conceptsArray.find(
                (concepts) => concepts.concept_id === surcharges.concept_id
            );

            const user = studentsArray.find(
                (student) => concept.student_id === student?.student_id
            );

            const catConcept = catConcepts.find(
                (catConcept) =>
                    catConcept.concept_catalog_id === concept.concept_catalog_id
            );

            return {
                ...surcharges,
                catsurcharges,
                concept,
                catConcept,
                user,
            };
        });
    }
);
