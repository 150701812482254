import React, {
    useCallback,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import { useHistory, useParams } from 'react-router';
import { DataGrid, esES } from '@mui/x-data-grid';
import { Box } from '@mui/system';
import {
    Button,
    Card,
    CardContent,
    Grid,
    LinearProgress,
    Switch,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faUsers } from '@fortawesome/free-solid-svg-icons';
import CardItem from '../../../../components/Card/CardItem';
import SyncIcon from '@mui/icons-material/Sync';

import SkeletonPage from './components/SkeletonPage';
import { Error } from '../../../../components/Feedback';
import { getLevelName, getTurnLevel } from '../../../../libs/utils';
import {
    invalidateGroupItem,
    loadGroupsDetailsUI,
    selectAllStudentsFromGroupItemSelected,
    selectGroupItemSelected,
    selectGroupsStatusServerItemSelected,
    selectStudentItem,
} from '../../../../views/Scores/store/ItemsSlice';
import { useDispatch, useSelector } from 'react-redux';
import GroupsToolBar from './components/GroupsToolBar';
import useFeedback from '../../../../hooks/useFeedback';
import { useAuth } from '../../../../hooks';
import { selectPartialsBySchoolId } from '../../../../store/slices/entities/partials';
import _ from 'lodash';
import NoDataOverlay from '../../../../components/utilities/NoDataOverlay';
import { GridToolBar } from '../../../../components/utilities/GridToolBar';
import CustomPagination from '../../../../components/utilities/CustomPagination';

/**
 * Pagina para detalle de grupos
 */
const GroupsScores = () => {
    let { id } = useParams();
    let history = useHistory();
    const dispatch = useDispatch();
    const feedbackApp = useFeedback();

    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id;

    ///////////////// SHARES STATE //////////////////////

    const statusServer = useSelector(selectGroupsStatusServerItemSelected);
    const group = useSelector(selectGroupItemSelected());
    const students = useSelector(selectAllStudentsFromGroupItemSelected);
    const partials = useSelector(selectPartialsBySchoolId(schoolId));

    //////////////// LOCAL STATE //////////////////////
    const [partialActives, setPartialActives] = useState('');

    ////////////// ACTIONS //////////////////////

    /**
     * Inicia el proceso de recuperacion de datos
     */
    useEffect(() => {
        dispatch(loadGroupsDetailsUI());
    }, []);

    /**
     * Invalida la UI
     */
    const invalidateUI = () => {
        dispatch(invalidateGroupItem(id));
        reload();
    };

    /**
     * Funcion para recargar los datos del usuario
     */
    const reload = () => {
        dispatch(loadGroupsDetailsUI(id))
            .unwrap()
            .then((response) => {})
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    const getCurrentDate = () => new Date();
    const getPartials = (partials) => {
        const currentDate = getCurrentDate();

        const activePartials = partials.filter(
            (partial) =>
                new Date(partial.start_date) <= currentDate &&
                new Date(partial.end_date) >= currentDate
        );

        const pastPartials = partials
            .filter((partial) => new Date(partial.end_date) < currentDate)
            .sort((a, b) => new Date(b.end_date) - new Date(a.end_date));

        const partialsToShow = [...activePartials, ...pastPartials].slice(0, 3);

        return partialsToShow.map((partial) => partial.partial).join(', ');
    };

    useEffect(() => {
        if (partials.length > 0) {
            const names = getPartials(partials);
            setPartialActives(names);
        }
    }, [partials]);

    /**
     * Alumno seleccionado
     *
     * @param {*} student
     */
    const onSelectStudent = (student) => {
        dispatch(
            selectStudentItem({
                itemGroupId: id,
                itemStudentId: student.student_id,
            })
        );

        history.push({
            pathname: `/calificaciones/${id}/alumno/${student.student_id}`,
        });
    };

    const LinearProgressBar = ({ value }) => {
        let color;
        let textColor;
        let textShadow;

        if (value === 0.0) {
            color = '#FF6060';
            textColor = '#FFEBEE';
            textShadow = '0 0 3px #840005';
        } else if (value > 0.0 && value < 33) {
            color = '#FF6060';
            textColor = '#FFEBEE';
            textShadow = '0 0 3px #840005';
        } else if (value >= 33 && value < 100) {
            color = '#FFBF43';
            textColor = '#FFF9C4';
            textShadow = '0 0 3px #840005';
        } else {
            color = '#00C483';
            textColor = '#A7FFEB';
            textShadow = '0 0 3px #840005';
        }

        return (
            <div style={{ width: '100%', position: 'relative' }}>
                <LinearProgress
                    variant="determinate"
                    value={value}
                    style={{
                        width: '100%',
                        backgroundColor:
                            value === 0.0 ? '#FF6060' : 'transparent',
                        borderRadius: '10px',
                        height: '25px',
                    }}
                    sx={{
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: color,
                            borderRadius: '10px',
                        },
                    }}
                />
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        color: textColor,
                        fontWeight: 'bold',
                        fontSize: '1.2em',
                        textShadow: textShadow,
                    }}>
                    {`${value}%`}
                </div>
            </div>
        );
    };

    ////////////////////// FOLTRO SWITCH CURP //////////////////////////

    function SwitchInputValue(props) {
        const { item, applyValue, focusElementRef } = props;

        const switchRef = useRef(null);
        useImperativeHandle(focusElementRef, () => ({
            focus: () => {
                switchRef.current.focus();
            },
        }));

        const handleFilterChange = (event, newValue) => {
            // Filtra por '1' si el Switch está activado

            const filterValue = newValue ? '1' : '';
            applyValue({ ...item, value: filterValue });
        };

        return (
            <Box
                sx={{
                    display: 'inline-flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    height: 48,
                    pl: '20px',
                }}>
                <Switch
                    checked={item.value === '1'} // Mapea el valor '1' a true y cualquier otro valor a false
                    onChange={handleFilterChange}
                    inputProps={{ ref: switchRef }}
                />
            </Box>
        );
    }

    const TextInputValue = (props) => {
        const { item, applyValue, focusElementRef } = props;

        const inputRef = useRef(null);
        const [localValue, setLocalValue] = useState(item.value || '');

        useImperativeHandle(focusElementRef, () => ({
            focus: () => {
                inputRef.current.focus();
            },
        }));

        const handleDebouncedFilterChange = useCallback(
            _.debounce((newValue) => {
                applyValue({ ...item, value: newValue.toLowerCase() });
                if (inputRef.current) {
                    inputRef.current.focus();
                }
            }, 500),
            [item, applyValue]
        );

        const handleFilterChange = (event) => {
            const newValue = event.target.value;
            setLocalValue(newValue);
            handleDebouncedFilterChange(newValue);
        };

        useEffect(() => {
            return () => {
                handleDebouncedFilterChange.cancel();
            };
        }, [handleDebouncedFilterChange]);

        useEffect(() => {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }, [localValue]);

        return (
            <Box
                sx={{
                    display: 'inline-flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    height: 48,
                    pl: '5px',
                    mt: '8px',
                }}>
                <TextField
                    value={localValue}
                    onChange={handleFilterChange}
                    inputProps={{ ref: inputRef }}
                    variant="standard"
                    placeholder="Buscar CURP"
                />
            </Box>
        );
    };

    const normalizeString = (str) => (str ? str.toLowerCase() : '');

    const seatchCURPOperatos = {
        label: 'Buscar CURP',
        value: 'searchCurp',
        getApplyFilterFn: (filterItem) => {
            return (params) => {
                if (filterItem.value) {
                    const normalizedFilterValue = normalizeString(
                        filterItem.value
                    );
                    const normalizedCurpValue = normalizeString(
                        params.row?.curp
                    );
                    return normalizedCurpValue.includes(normalizedFilterValue);
                }
                return true;
            };
        },
        InputComponent: TextInputValue,
        InputComponentProps: { type: 'text' },
        getValueAsString: (value) => value || '',
    };

    const curpSwitchOperator = [
        {
            label: 'Con CURP',
            value: 'hasCurp',
            getApplyFilterFn: (filterItem) => {
                return (params) => {
                    if (filterItem.value === '1') {
                        return (
                            params.row &&
                            params.row.curp &&
                            params.row.curp.trim() !== ''
                        );
                    }
                    return true;
                };
            },
            InputComponent: SwitchInputValue,
            InputComponentProps: { type: 'boolean' },
            getValueAsString: (value) => (value ? '1' : '0'),
        },
        {
            label: 'Sin CURP',
            value: 'noCurp',
            getApplyFilterFn: (filterItem) => {
                return (params) => {
                    if (filterItem.value === '1') {
                        return (
                            !params.row ||
                            !params.row.curp ||
                            params.row.curp.trim() === ''
                        );
                    }
                    return true;
                };
            },
            InputComponent: SwitchInputValue,
            InputComponentProps: { type: 'boolean' },
            getValueAsString: (value) => (value ? '1' : '0'),
        },
    ];

    /**
     * Columnas del DataGrid
     */
    const columns = [
        {
            field: 'student_id',
            headerName: 'ID',
            flex: 0.5,
            minWidth: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'folio',
            headerName: 'Matrícula',
            flex: 1,
            minWidth: 100,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const FullFolio = params.row.folio
                    ? `${params.row.folio}`
                    : 'Sin Matricula';
                return (
                    <div
                        style={{
                            whiteSpace: 'pre-line',
                            textAlign: 'center',
                        }}>
                        {FullFolio}
                    </div>
                );
            },
        },
        {
            field: 'curp',
            headerName: 'CURP',
            flex: 1.5,
            minWidth: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const curpValue =
                    params.row && params.row.curp
                        ? `${params.row.curp}`
                        : 'Sin CURP';
                return (
                    <div
                        style={{
                            whiteSpace: 'pre-line',
                            textAlign: 'center',
                        }}>
                        {curpValue}
                    </div>
                );
            },
            filterOperators: [...curpSwitchOperator, seatchCURPOperatos],
        },
        {
            field: 'last_name',
            headerName: 'Apellido Paterno',
            flex: 1,
            minWidth: 130,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'second_last_name',
            headerName: 'Apellido Materno',
            flex: 1,
            minWidth: 130,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'name',
            headerName: 'Nombre(s)',
            flex: 1,
            minWidth: 130,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'progress',
            renderHeader: () => (
                <Tooltip title="Porcentaje de materias calificadas">
                    <Typography>Parcial: {partialActives}</Typography>
                </Tooltip>
            ),
            type: 'singleSelect',
            flex: 1,
            minWidth: 100,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <LinearProgressBar value={params.row.average_scored_subjects} />
            ),
        },
        {
            field: 'total_presence',
            headerName: 'Asistencias',
            type: 'number',
            flex: 0.8,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'total_absence',
            headerName: 'Faltas',
            type: 'number',
            flex: 0.6,
            minWidth: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'average_rating',
            headerName: 'Promedio',
            type: 'number',
            flex: 0.8,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'acciones',
            headerName: 'Acciones',
            sortable: false,
            filterable: false,
            flex: 1,
            minWidth: 100,
            headerAlign: 'center',
            align: 'center',
            disableExport: true,
            renderCell: (params) => (
                <strong>
                    <Button
                        size="small"
                        variant="outlined"
                        startIcon={<FontAwesomeIcon icon={faEye} size="lg" />}
                        onClick={() => onSelectStudent(params.row)}>
                        VER
                    </Button>
                </strong>
            ),
        },
    ];

    const rows = students?.map((row) => ({ id: row.student_id, ...row }));

    const sortByCriteria = (a, b, criteria) => {
        for (const criterion of criteria) {
            const aValue = a[criterion];
            const bValue = b[criterion];

            if (aValue < bValue) return -1;
            if (aValue > bValue) return 1;
        }
        return 0;
    };

    const sortedRows = rows.sort((a, b) => {
        const criteria = ['last_name', 'second_last_name', 'name'];
        return sortByCriteria(a, b, criteria);
    });

    return (
        <Box
            sx={{
                flexGrow: 1,
                paddingTop: {
                    xs: 1,
                    sm: 2,
                    md: 2,
                },
                paddingLeft: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingRight: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingBottom: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
            }}>
            {statusServer == 'pending' && <SkeletonPage />}

            {statusServer == 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {statusServer == 'fulfilled' && (
                <>
                    <Box
                        style={{
                            marginBottom: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-end',
                            justifyContent: 'flex-end',
                        }}>
                        <Tooltip title="Sincronizar información">
                            <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                onClick={invalidateUI}
                                startIcon={<SyncIcon />}>
                                Sincronizar
                            </Button>
                        </Tooltip>
                    </Box>

                    <Grid container spacing={2} sx={{ mt: 2 }}>
                        <Grid
                            item
                            xl={3}
                            md={3}
                            sm={12}
                            xs={12}
                            style={{ alignContent: 'center' }}>
                            <CardItem
                                backgroundColor={'#2196f3'}
                                icon={faUsers}
                                iconColor={'#1769aa'}
                                text={
                                    'Grupo ' +
                                    group?.grade +
                                    '° ' +
                                    group?.group +
                                    ' ' +
                                    getLevelName(group?.level)
                                }
                                textColor="#fff"
                                title={getTurnLevel(group?.turn)}
                            />
                        </Grid>
                        <Grid
                            item
                            xl={3}
                            md={3}
                            sm={12}
                            xs={12}
                            style={{ alignContent: 'center' }}>
                            <CardItem
                                backgroundColor={'#2196f3'}
                                icon={faUsers}
                                iconColor={'#1769aa'}
                                text="Promedio"
                                textColor="#fff"
                                title={group?.average_rating}
                            />
                        </Grid>
                        <Grid
                            item
                            xl={3}
                            md={3}
                            sm={12}
                            xs={12}
                            style={{ alignContent: 'center' }}>
                            <CardItem
                                backgroundColor={'#2196f3'}
                                icon={faUsers}
                                iconColor={'#1769aa'}
                                text="Asistencias"
                                textColor="#fff"
                                title={group?.total_presence}
                            />
                        </Grid>
                        <Grid
                            item
                            xl={3}
                            md={3}
                            sm={12}
                            xs={12}
                            style={{ alignContent: 'center' }}>
                            <CardItem
                                backgroundColor={'#2196f3'}
                                icon={faUsers}
                                iconColor={'#1769aa'}
                                text="Faltas"
                                textColor="#fff"
                                title={group?.total_absence}
                            />
                        </Grid>
                    </Grid>

                    <Card sx={{ mt: 2 }}>
                        <DataGrid
                            localeText={{
                                ...esES.components.MuiDataGrid.defaultProps
                                    .localeText,
                                toolbarColumns: '',
                                toolbarFilters: '',
                                toolbarDensity: '',
                                toolbarExport: '',
                            }}
                            rows={sortedRows}
                            columns={columns}
                            pageSize={20}
                            rowsPerPageOptions={[5]}
                            disableSelectionOnClick
                            autoHeight
                            components={{
                                NoRowsOverlay: NoDataOverlay,
                                NoResultsOverlay: NoDataOverlay,
                                Toolbar: GridToolBar,
                                Pagination: CustomPagination,
                            }}
                            componentsProps={{
                                toolbar: {
                                    headerName: 'Alumnos',
                                },
                                noResultsOverlay: {
                                    message:
                                        'No se encontraron resultados para la búsqueda',
                                },
                                noRowsOverlay: {
                                    message: 'No hay Alumnos registrados',
                                },
                            }}
                            disableDensitySelector
                        />
                    </Card>
                </>
            )}
        </Box>
    );
};

export default GroupsScores;
