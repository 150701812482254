import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import useFeedback from '../../../hooks/useFeedback';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    createTheme,
    Stack,
    Switch,
    ThemeProvider,
    Tooltip,
    Typography,
} from '@mui/material';
import { DataGrid, esES, GridToolbar } from '@mui/x-data-grid';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DescriptionIcon from '@mui/icons-material/Description';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import SyncIcon from '@mui/icons-material/Sync';

import { Error } from '../../../components/Feedback';
import ExportablesModal from '../../../components/Chart/ExportableModal/ExportablesModal';
import { SubjectPreviewCard } from '../../components';
import FeatureFlags from '../../../service/FeatureFlags';

import {
    selectGroupById,
    selectStudentsByGroup,
    selectSubjectById,
} from '../../../store/slices/professorModule/entitiesSlice';
import {
    fetchSubjectAditionalData,
    invalidate,
    selectItemFetchingStatusSelected,
    selectItemOperationStatusSelected,
    selectItemSelected,
    storeAssitsList,
} from '../../../store/slices/professorModule/ItemsSlice';
import { selectItem } from '../../../store/slices/professorModule/uiSlice';

import { ReportCreateModal, SkeletonBlock } from './components';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { DateTime } from 'luxon';

function getCurrentDate() {
    const fecha = DateTime.local();
    const formattedDate = fecha.setLocale('es').toFormat("dd 'de' LLLL yyyy");
    return formattedDate;
}

const theme = createTheme({
    palette: {
        neutral: {
            main: '#006837',
            contrastText: 'white',
        },
    },
});

/**
 * Pagina para nombrar lista
 *
 * @returns
 */
const AssistanceList = () => {
    ////////// HOOKS //////////

    const history = useHistory();
    const { id } = useParams();
    const dispatch = useDispatch();

    const currDate = getCurrentDate();

    ////////////// SHARED STATE //////////////////

    const subjectItem = useSelector(selectItemSelected);

    const fetchingStatus = useSelector(selectItemFetchingStatusSelected);
    const operationStatus = useSelector(selectItemOperationStatusSelected);

    const subject = useSelector(selectSubjectById(id));

    const groupsStudentsList = useSelector(
        selectStudentsByGroup(subject.group_id)
    );

    const group = useSelector(selectGroupById(subject.group_id));

    /////////////////////// LOCAL STATE ////////////////////////////

    const [students, setStudents] = useState([]);
    const [checkedStudent, setCheckedStudent] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [openExportModal, setOpenExportModal] = useState(false);
    const [selectAllAttendance, setSelectAllAttendance] = useState(true);
    const [selectedTitle, setSelectedTitle] = useState(null);
    const feedbackApp = useFeedback();
    const [reportData, setReportData] = useState({
        report: null,
        title: null,
        query: { subject_id: null, group_id: null },
    });

    /*
     * Función que se encarga
     * de cerrar el modal
     */
    const handlerCloseExportableModal = () => {
        setOpenExportModal(false);
    };

    const featureFlagEnabledAssistance = FeatureFlags.isFeatureFlagActive(
        'NEW_JUSTIFY_ASSISTANCE_PAGE'
    );

    const allowCreateAssitsList = subjectItem.listPerformed;

    /*
     * Definicion de las columnas
     */
    const studentColumns = [
        {
            field: 'url_photo_profile',
            headerName: 'Alumno',
            flex: 0.5,
            minWidth: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            filterable: false,
            renderCell: (props) => (
                <Avatar
                    alt="Estudiante"
                    src={props.value}
                    sx={{ display: { xs: 'none', sm: 'flex' } }}
                />
            ),
        },
        {
            field: 'Nombre',
            headerName: 'Nombre',
            flex: 1,
            minWidth: 100,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            valueGetter: (params) => {
                return `${params.row.last_name} ${params.row.second_last_name} ${params.row.name}`;
            },
        },
        {
            field: 'assits',
            headerName: 'Asistencia',
            flex: 0.5,
            minWidth: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            filterable: false,
            renderCell: (props) => (
                <Switch
                    edge="end"
                    onChange={($event) => {
                        handlerChangeAssitsList(props.id, $event);
                    }}
                    checked={props.value}
                    color="neutral"
                    disabled={allowCreateAssitsList}
                />
            ),
        },
    ];

    //////////////////// ACTIONS ////////////////////////

    /**
     * Invalida la UI
     */
    const invalidateUI = () => {
        dispatch(invalidate(id));
        reload();
    };

    /*
     * Función que se encarga
     * del manejo de cambio
     * de estado de la selección
     */
    const handleSelectionChange = (selectionModel) => {
        const selectedStudents = selectionModel.map((studentId) => {
            return students.find((student) => student.student_id === studentId);
        });
        setCheckedStudent(selectedStudents);
    };

    /**
     * Recargar la informacion adicional
     */
    const reload = () => {
        dispatch(
            fetchSubjectAditionalData({
                groupId: subject.group_id,
                subjectId: subject.subject_id,
                curretnDate: DateTime.local().toFormat('yyyy-MM-dd'),
            })
        );
    };

    /**
     * Recargar los datos
     */
    useEffect(() => {
        if (id) {
            reload();
        }
    }, [id]);

    /**
     * Efecto para crear agregarle a alumno la
     * propiedad de asistencia y ordenarlos
     * alfabéticamente
     */
    useEffect(() => {
        const formattedStudents = groupsStudentsList
            .map((s) => ({
                ...s,
                assits: true,
            }))
            .sort((a, b) => {
                const lastNameA = a.last_name;
                const lastNameB = b.last_name;

                return lastNameA.localeCompare(lastNameB);
            });
        setStudents(formattedStudents);
    }, [fetchingStatus]);

    /*
     * Registra las asistencias en webservices
     */
    const handlerSaveAssitsList = () => {
        let assitsRequest = students.map((item) => ({
            assistance: item.assits == true ? 1 : 0,
            date: DateTime.local().toFormat('yyyy-MM-dd'),
            student_id: item.student_id,
            subject_id: id,
        }));

        dispatch(
            storeAssitsList({
                postData: assitsRequest,
                groupId: subject.group_id,
                subjectId: id,
            })
        )
            .unwrap()
            .then(() => {
                feedbackApp.showFeedback({
                    title: 'Guardado correctamente',
                });
            })
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    /*
     * Cuando se cambia la lista de asistencia
     */
    const handlerChangeAssitsList = (studentID, event) => {
        const student = students.map((item) => ({
            ...item,
            assits:
                item.student_id == studentID
                    ? event.target.checked
                    : item.assits,
        }));

        setStudents(student);
    };

    /*
     * Función que te redirecciona
     * a la vista de registrar calificaciones
     */
    const handlerCalificationList = () => {
        dispatch(selectItem(subject.subject_id));
        history.push({
            pathname: `/inicio-maestros/calificaciones/${subject.subject_id}`,
        });
    };

    /*
     * Función que te redirecciona
     * a la vista de registrar calificaciones
     */
    const handlerJustifyAssistantPage = () => {
        dispatch(selectItem(subject.subject_id));
        history.push({
            pathname: `/inicio-maestros/justify-assists-page/${subject.subject_id}`,
        });
    };

    /*
     * Función que abre el modal
     * de reportes
     */
    const openReportModal = () => {
        if (
            group.assessor_id === null ||
            group.assessor_id === 0 ||
            group.assessor_id === undefined
        ) {
            feedbackApp.showFeedback({
                title: 'El grupo no cuenta con un orientador',
            });
        } else {
            setOpenModal(true);
        }
    };

    /*
     * Función que activa/desactiva
     * todas las asistencias
     */
    const handlerSelectAllAttendance = () => {
        setSelectAllAttendance(!selectAllAttendance);

        const updatedStudents = students.map((student) => ({
            ...student,
            assits: !selectAllAttendance,
        }));

        setStudents(updatedStudents);
    };

    /*
     * Función que abre el modal
     * de exportaciones
     */
    const handlerExport = (exportOptions) => {
        const { id, title, report } = exportOptions;
        const pre = {
            id,
            title,
            report,
            query: {
                subject_id: id,
                group_id: group.group_id,
            },
        };

        setSelectedTitle(title);
        setReportData(pre);
        setOpenExportModal(true);
    };

    return (
        <Box
            sx={{
                paddingTop: {
                    xs: 1,
                    sm: 2,
                    md: 2,
                },
                paddingLeft: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingRight: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingBottom: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
            }}>
            {fetchingStatus == 'pending' && <SkeletonBlock />}

            {fetchingStatus == 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {fetchingStatus == 'fulfilled' && (
                <>
                    <Box
                        sx={{
                            marginBottom: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}>
                        <Typography variant="h4" component="div">
                            Control de asistencia del dia: {currDate}{' '}
                        </Typography>

                        <Tooltip title="Sincronizar información">
                            <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                onClick={invalidateUI}
                                startIcon={<SyncIcon />}>
                                Sincronizar
                            </Button>
                        </Tooltip>
                    </Box>

                    <SubjectPreviewCard subjectId={subject.subject_id} />
                    <Stack direction="row" spacing={2}>
                        <Button
                            size="small"
                            color="primary"
                            variant="contained"
                            disabled={checkedStudent.length === 0}
                            onClick={openReportModal}
                            startIcon={<DescriptionIcon />}>
                            {`Reportar Alumno(s)`}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={allowCreateAssitsList}
                            onClick={handlerSelectAllAttendance}>
                            {selectAllAttendance
                                ? 'Desactivar todas las asistencias'
                                : 'Activar todas las asistencias'}
                        </Button>
                        {featureFlagEnabledAssistance && (
                            <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                onClick={handlerJustifyAssistantPage}
                                startIcon={<AssignmentTurnedInIcon />}>
                                Justificacion de Asistencias
                            </Button>
                        )}
                        {/* <Button
                            size="small"
                            color="primary"
                            variant="contained"
                            onClick={handlerJustifyAssistantPage}
                            startIcon={<AssignmentTurnedInIcon />}
                        >
                            Justificacion de Asistencias
                        </Button> */}
                        <Button
                            size="small"
                            color="primary"
                            variant="contained"
                            onClick={handlerCalificationList}
                            startIcon={<CalendarTodayIcon />}>
                            Registrar calificación
                        </Button>

                        <Button
                            size="small"
                            color="primary"
                            variant="contained"
                            onClick={() =>
                                handlerExport({
                                    id: subject.subject_id,
                                    title: 'Asistencias',
                                    report: 'subject-assists',
                                })
                            }
                            startIcon={<FileDownloadIcon />}>
                            Exportar asistencias
                        </Button>
                    </Stack>
                    <Card sx={{ mt: 3 }}>
                        <CardContent
                            sx={{
                                pt: 0,
                                pl: 0,
                                pr: 0,
                                pb: 0,
                                ' & .MuiDataGrid-root': {
                                    border: 'none',
                                },
                            }}>
                            <ThemeProvider theme={theme}>
                                <DataGrid
                                    getRowId={(row) => row.student_id}
                                    localeText={
                                        esES.components.MuiDataGrid.defaultProps
                                            .localeText
                                    }
                                    rows={students}
                                    checkboxSelection
                                    disableRowSelectionOnClick
                                    columns={studentColumns}
                                    components={{
                                        Toolbar: () => (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                    alignItems: 'center',
                                                }}>
                                                <GridToolbar />
                                            </div>
                                        ),
                                    }}
                                    componentsProps={{
                                        panel: {
                                            placement: 'bottom-end',
                                        },
                                    }}
                                    pageSize={100}
                                    rowsPerPageOptions={[100]}
                                    disableSelectionOnClick
                                    disableColumnMenu
                                    autoHeight
                                    disableDensitySelector
                                    onSelectionModelChange={
                                        handleSelectionChange
                                    }
                                />
                            </ThemeProvider>
                        </CardContent>
                        <CardActions
                            sx={{
                                direction: 'row',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                            }}>
                            <LoadingButton
                                size="small"
                                endIcon={<SendIcon />}
                                loading={operationStatus === 'pending'}
                                loadingPosition="end"
                                variant="contained"
                                onClick={handlerSaveAssitsList}
                                disabled={allowCreateAssitsList}>
                                Guardar
                            </LoadingButton>
                        </CardActions>
                    </Card>
                </>
            )}

            <ExportablesModal
                show={openExportModal}
                data={reportData}
                selectedTitle={selectedTitle}
                onClose={handlerCloseExportableModal}
            />

            <ReportCreateModal
                openModal={openModal}
                setOpenModal={setOpenModal}
                students={checkedStudent}
                groupId={group.group_id}
            />
        </Box>
    );
};

export default AssistanceList;
