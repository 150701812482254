import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
    Typography,
    useTheme,
    Avatar,
    Tooltip,
    Divider,
} from '@mui/material';
import { Typography as AntTypography } from 'antd';

import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Delete } from '@mui/icons-material';
import palomita from '../../../../public/assets/palomita.svg';
import femaleStudent from '../../../assets/img/female_student.png';
import MaleStudent from '../../../assets/img/male_student.png';
import defaultStudent from '../../../assets/img/default_student.png';

import Feedback from '../../../../service/Feedback';
import { useFeedback } from '../../../../hooks';
import { UpdateSurcharges } from '../../../store/surcharges/operations';

const ModalUpdateSurcharges = ({ open, onclose, selectedRows }) => {
    ///////////////// LOCAL STATE /////////////
    const [surcharges, setsurcharges] = useState(selectedRows);
    const [initialValues, setInitialValues] = useState({});
    ///////////// SHARED STATE /////////////

    const theme = useTheme();
    const dispatch = useDispatch();
    const feedbackApp = useFeedback();

    ///////////// FORM SETUP /////////////
    const formik = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,
        validationSchema: Yup.object().shape(
            surcharges.reduce((acc, concept) => {
                acc[concept.surcharge_id] = Yup.object().shape({
                    description: Yup.string().required(
                        'Descripción es requerida'
                    ),
                });
                return acc;
            }, {})
        ),
        onSubmit: (values) => {
            const updatesurcharges = Object.keys(values).map((id) => ({
                ...values[id],
                surcharge_id: Number(id),
            }));
            dispatch(UpdateSurcharges(updatesurcharges))
                .then(() => {
                    feedbackApp.showFeedback({
                        title: 'Recargos Actualizados',
                    });
                    handleClose();
                })
                .catch((error) => {
                    feedbackApp.showFeedback({
                        title: error.feedback.title,
                        severity: 'error',
                    });
                });
        },
    });

    ///////////// USE EFFECTS /////////////
    useEffect(() => {
        setsurcharges(selectedRows);
        const newInitialValues = selectedRows.reduce(
            (acc, concept) => ({
                ...acc,
                [concept.surcharge_id]: {
                    description: concept.description || '',
                },
            }),
            {}
        );
        setInitialValues(newInitialValues);
        formik.resetForm({ values: newInitialValues });
    }, [selectedRows]);

    ///////////// HANDLERS /////////////
    const handleClose = () => {
        onclose();
        formik.resetForm();
    };

    ///////////// RENDER /////////////
    return (
        <Dialog
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    onclose();
                }
            }}
            disableEscapeKeyDown
            sx={{
                '& .MuiDialog-paper': {
                    borderRadius: 5,
                    width: '90%',
                    maxWidth: 900,
                    height: '80%',
                    maxHeight: 500,
                },
            }}>
            {' '}
            <DialogTitle
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: 1,
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                    }}>
                    <Box
                        component="img"
                        src={palomita}
                        alt="Descripción de la imagen"
                        sx={{
                            width: '15%',
                            height: 'auto',
                        }}
                    />
                    <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        sx={{
                            display: 'flex',
                            ml: 2,
                            mr: 2,
                        }}
                    />
                    <Box>
                        <AntTypography.Title level={5}>
                            Actualizar Recargos
                        </AntTypography.Title>
                        <Typography
                            variant="body2"
                            sx={{
                                width: '100%',
                                mt: -1,
                            }}>
                            Aquí podrás actualizar los Recargos
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                    }}>
                    <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        sx={{
                            display: 'flex',
                            ml: 2,
                            mr: 2,
                        }}
                    />
                    <IconButton
                        onClick={() => {
                            formik.resetForm();
                            onclose();
                        }}>
                        <CloseIcon
                            sx={{
                                color: '#000000ff',
                                width: 20,
                            }}
                        />
                    </IconButton>
                </Box>
            </DialogTitle>
            <Divider
                sx={{
                    width: '95%',
                }}
                flexItem
                variant="middle"
            />
            <DialogContent>
                {surcharges.map((concept) => {
                    const avatarSrc =
                        concept.user.url_photo_profile ||
                        (concept.user.gender === 'M'
                            ? femaleStudent
                            : concept.user.gender === 'H'
                            ? MaleStudent
                            : defaultStudent);

                    return (
                        <>
                            <Box key={concept.surcharge_id}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        mb: 2,
                                    }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            width: '60%',
                                        }}>
                                        <Avatar
                                            src={avatarSrc}
                                            alt={`${concept.user.name} ${concept.user.last_name}`}
                                            style={{
                                                width: 56,
                                                height: 56,
                                                marginRight: '10px',
                                            }}
                                        />
                                        <Box>
                                            <Typography variant="body1">
                                                {`${concept.user.name} ${concept.user.last_name}`}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                                noWrap>
                                                ID: {concept.user.student_id}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                                noWrap>
                                                Teléfono:
                                                {concept.user.cellphone}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider
                                        orientation="vertical"
                                        variant="middle"
                                        flexItem
                                        sx={{
                                            display: 'flex',
                                            ml: 2,
                                            mr: 2,
                                        }}
                                    />

                                    <Box
                                        sx={{
                                            width: '100%',
                                        }}>
                                        <TextField
                                            label="Descripción"
                                            value={
                                                formik.values[
                                                    concept.surcharge_id
                                                ]?.description || ''
                                            }
                                            onChange={formik.handleChange}
                                            name={`${concept.surcharge_id}.description`}
                                            fullWidth
                                            sx={{ mb: 2 }}
                                            error={Boolean(
                                                formik.touched[
                                                    concept.surcharge_id
                                                ]?.description &&
                                                    formik.errors[
                                                        concept.surcharge_id
                                                    ]?.description
                                            )}
                                            helperText={
                                                formik.touched[
                                                    concept.surcharge_id
                                                ]?.description &&
                                                formik.errors[
                                                    concept.surcharge_id
                                                ]?.description
                                            }
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </>
                    );
                })}
            </DialogContent>
            <Divider
                sx={{
                    width: '95%',
                }}
                flexItem
                variant="middle"
            />
            <DialogActions
                sx={{
                    justifyContent: 'space-between',
                }}>
                <Box sx={{ mb: 2 }}>
                    <Button
                        sx={{ mt: 1, mr: 1 }}
                        onClick={() => {
                            formik.resetForm();
                            onclose();
                        }}>
                        Cancelar
                    </Button>
                </Box>

                <Box sx={{ mb: 2 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        size="small"
                        onClick={formik.handleSubmit}
                        disabled={
                            formik.isSubmitting ||
                            !formik.isValid ||
                            !formik.dirty
                        }>
                        {formik.isSubmitting ? (
                            <CircularProgress size={24} />
                        ) : (
                            'Actualizar'
                        )}
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

 
export default ModalUpdateSurcharges;
