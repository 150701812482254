import React, { useEffect, useState } from 'react';
import {
    Box,
    Stack,
    Tab,
    Tabs,
    Grid,
    CssBaseline,
    Tooltip,
    Card,
    Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useLocation,
} from 'react-router-dom';
import { useAuth } from '../../../hooks';
import Skeleton, { SkeletonIndexSmallTable } from '../../components/Skeleton';
import Error from '../../../components/Feedback/Error';
import { CyclesSelectOnDirector } from '../../../components/Director';
import AllProfessors from './components/AllProfessors';
import LagAssists from './components/LagAssists';
import LagCalifications from './components/LagCalifications';
import ProfessorsWithoutGroup from './components/ProfessorsWithoutGroup';
import TotalReports from './components/TotalReports';
import ProfessorsMostReports from './components/ProfessorsMostReports';
import {
    getCurrentTab,
    getCycleSelected,
    getLoading,
    invalidate as invalidateComponents,
} from '../../store/professors/fetchSlice';
import SyncIcon from '@mui/icons-material/Sync';
import {
    fetchAllResources,
    selectEntitiesFetchStatus,
    invalidate as invalidateEntities,
} from '../../store/entities';
import DirectorNewHome from '../Home';
import DirectorGroups from '../Groups';
import DirectorSubjects from '../Subjects';
import DirectorStudents from '../Students';
import { setCurrentTab } from '../../store/professors/uiSlice';
import { LoadingButton } from '@mui/lab';
import LastInsidents from './components/LastInsidents';
import ErrorDirectorBoundary from '../../../components/ErrorDirectorBoundary';
import { ErrorBoundary } from 'react-error-boundary';

const DirectorProfessors = () => {
    const dispatch = useDispatch();
    let match = useRouteMatch();
    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id;
    let user = Auth.getUser();

    const schoolCycle = useSelector(getCycleSelected);
    const serverState = useSelector(getLoading);
    const currentTab = useSelector(getCurrentTab);
    const fetchingStatus = useSelector(selectEntitiesFetchStatus);

    const [selectedTurn, setSelectedTurn] = useState('');
    const [tabIndex, setTabIndex] = useState(0);

    const onChangeTab = (event, newValue) => {
        dispatch(setCurrentTab(newValue));
    };

    const onChangeCycle = (cycle) => {
        dispatch(invalidateEntities());
        dispatch(
            fetchAllResources({
                school_id: user.school_id,
                cycle: schoolCycle,
                turn: selectedTurn,
                //limit: 5,
            })
        );
        dispatch(invalidateComponents());
        fetchAllInformation(cycle);
    };

    const reload = () => {
        dispatch(invalidateComponents());
    };

    const handleTurnChange = (event, cycle) => {
        const selectedValue = event.target.value;
        setSelectedTurn(selectedValue);
        dispatch(invalidateEntities());
        dispatch(
            fetchAllResources({
                school_id: user.school_id,
                cycle: schoolCycle,
                turn: selectedTurn,
                //limit: 5,
            })
        );
        dispatch(invalidateComponents());
        fetchAllInformation(cycle);
    };

    const invalidateUI = () => {
        dispatch(invalidateEntities());
        dispatch(
            fetchAllResources({
                school_id: user.school_id,
                cycle: schoolCycle,
                turn: selectedTurn,
                //limit: 5,
            })
        );
        dispatch(invalidateComponents());
    };

    async function fetchAllInformation(cycle = null) {
        let dataRequest = {
            school_id: user.school_id,
            cycle: cycle ? cycle : schoolCycle.cycle_id,
        };
        dispatch(fetchAllResources(dataRequest))
            .unwrap()
            .then((response) => {})
            .catch((err) => {
                let message = 'feedback.getMessage(err)';
            });
    }

    function LinkTab(props) {
        return <Tab component={Link} {...props} />;
    }

    const handleChangeTab = (event, newIndex) => {
        setTabIndex(newIndex);
    };

    return (
        <Box
            sx={{
                paddingTop: {
                    xs: 1,
                    sm: 2,
                    md: 2,
                },
                paddingLeft: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingRight: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingBottom: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
            }}
        >
            {fetchingStatus == 'pending' && <Skeleton items={9} />}

            {fetchingStatus == 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {fetchingStatus == 'fulfilled' && (
                <>
                    <Grid container spacing={2} sx={{ mb: 4 }}>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <Tabs
                                value={currentTab}
                                onChange={onChangeTab}
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="tabs"
                                sx={{
                                    [`& .MuiTabs-scrollButtons`]: {
                                        '&.Mui-disabled': { opacity: 0.3 },
                                    },
                                    [`& .MuiTabs-flexContainer`]: {
                                        flexDirection: {
                                            xs: 'column',
                                            sm: 'row',
                                        },
                                    },
                                }}
                            >
                                <Tab
                                    label="Inicio"
                                    value="inicio"
                                    component={Link}
                                    to="/NewDirector/inicio"
                                />
                                <Tab
                                    label="Grupos"
                                    value="groups"
                                    component={Link}
                                    to="/NewDirector/groups"
                                />
                                <Tab
                                    label="Materias"
                                    value="subjects"
                                    component={Link}
                                    to="/NewDirector/subjects"
                                />
                                <Tab
                                    label="Profesores"
                                    value="professors"
                                    component={Link}
                                    to="/NewDirector/professors"
                                />
                                <Tab
                                    label="Alumnos"
                                    value="students"
                                    component={Link}
                                    to="/NewDirector/students"
                                />
                            </Tabs>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <Stack
                                spacing={2}
                                direction={{ xs: 'column', sm: 'row' }}
                                justifyContent="flex-end"
                                alignItems="center"
                            >
                                <CyclesSelectOnDirector
                                    disabled={serverState === 'pending'}
                                    onChange={onChangeCycle}
                                />
                                <Tooltip title="Sincronizar información">
                                    <LoadingButton
                                        onClick={invalidateUI}
                                        startIcon={<SyncIcon />}
                                        loading={serverState == 'pending'}
                                        loadingPosition="start"
                                        variant="contained"
                                        size="small"
                                    >
                                        Sincronizar
                                    </LoadingButton>
                                </Tooltip>
                            </Stack>
                        </Grid>
                    </Grid>

                    <Box>
                        <Switch>
                            <Route path="/NewDirector/inicio">
                                <DirectorNewHome />
                            </Route>
                            <Route path="/NewDirector/groups">
                                <DirectorGroups />
                            </Route>
                            <Route path="/NewDirector/subjects">
                                <DirectorSubjects />
                            </Route>
                            <Route path="/NewDirector/professors">
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={6}
                                    sx={{ mb: 5, mt: 5 }}
                                >
                                    <ErrorBoundary
                                        FallbackComponent={
                                            ErrorDirectorBoundary
                                        }
                                    >
                                        <ProfessorsMostReports />
                                    </ErrorBoundary>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={4}>
                                        <ErrorBoundary
                                            FallbackComponent={
                                                ErrorDirectorBoundary
                                            }
                                        >
                                            <TotalReports />
                                        </ErrorBoundary>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={4}>
                                        <ErrorBoundary
                                            FallbackComponent={
                                                ErrorDirectorBoundary
                                            }
                                        >
                                            <ProfessorsWithoutGroup />
                                        </ErrorBoundary>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={4}>
                                        <Card
                                            sx={{
                                                borderRadius: '10px',
                                                boxShadow: 'none',
                                                maxWidth: '100%',
                                                margin: '20px auto',
                                                padding: '15px',
                                                overflow: 'visible',
                                                position: 'relative',
                                            }}
                                        >
                                            <Typography
                                                variant="h6"
                                                sx={{ ml: 1, mt: 3, mb: 1 }}
                                            >
                                                Profesores con Rezago
                                            </Typography>
                                            <Tabs
                                                value={tabIndex}
                                                onChange={handleChangeTab}
                                            >
                                                <Tab
                                                    label="Asistencias"
                                                    sx={{
                                                        fontSize: '1rem',
                                                        fontWeight: 600,
                                                        color: '#575959',
                                                        '&:hover': {
                                                            color: '#0c47a1',
                                                        },
                                                    }}
                                                />
                                                <Tab
                                                    label="Calificaciones"
                                                    sx={{
                                                        fontSize: '1rem',
                                                        fontWeight: 600,
                                                        color: '#575959',
                                                        '&:hover': {
                                                            color: '#0c47a1',
                                                        },
                                                    }}
                                                />
                                            </Tabs>
                                            <Grid
                                                container
                                                item
                                                spacing={2}
                                                justifyContent="start"
                                                sx={{ mt: 1 }}
                                            >
                                                {tabIndex === 0 && (
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                    >
                                                        <ErrorBoundary
                                                            FallbackComponent={
                                                                ErrorDirectorBoundary
                                                            }
                                                        >
                                                            <LagAssists />
                                                        </ErrorBoundary>
                                                    </Grid>
                                                )}
                                                {tabIndex === 1 && (
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                    >
                                                        <ErrorBoundary
                                                            FallbackComponent={
                                                                ErrorDirectorBoundary
                                                            }
                                                        >
                                                            <LagCalifications />
                                                        </ErrorBoundary>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Card>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <ErrorBoundary
                                            FallbackComponent={
                                                ErrorDirectorBoundary
                                            }
                                        >
                                            <LastInsidents />
                                        </ErrorBoundary>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <ErrorBoundary
                                            FallbackComponent={
                                                ErrorDirectorBoundary
                                            }
                                        >
                                            <AllProfessors />
                                        </ErrorBoundary>
                                    </Grid>
                                </Grid>
                            </Route>
                            <Route path="/NewDirector/students">
                                <DirectorStudents />
                            </Route>
                        </Switch>
                    </Box>
                </>
            )}
        </Box>
    );
};

export default DirectorProfessors;
