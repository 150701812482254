import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    useHistory,
    useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import { useAuth } from '../../../../hooks';
import { Box, Button, Tooltip } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import {
    fetchClassroomSubjectData,
    invalidate,
    selectAtendenceForSubject,
    selectClassroomSubjectDetails,
    selectClassroomViewmodelFetchStatusById,
} from '../../../store/meGroups/classrooms';
import { Error } from '../../../../components/Feedback';
import SkeletonPage from './../../../components/SkeletonPage';
import ClassroomSubjectPreviewCard from './../../../components/ClassroomPreviewCard';
import SubjectAssistanceTable from './components/SubjectAssistanceTable';
import { DateTime } from 'luxon';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import './index.scss';
import ExportablesModal from '../../../../components/Chart/ExportableModal/ExportablesModal';

const ClassroomSubjectAssistance = () => {
    ////////// LOCAL STATE //////////
    const currentDate = DateTime.local().toFormat('yyyy-MM-dd');
    const [openExportModal, setOpenExportModal] = useState(false);
    const [selectedTitle, setSelectedTitle] = useState(null);
    const [reportData, setReportData] = useState({
        report: null,
        title: null,
        query: { subject_id: null, group_id: null },
    });

    ////////// HOOKS //////////
    let { id } = useParams();
    const Auth = useAuth();
    const dispatch = useDispatch();
    const history = useHistory();

    const classroomDetails = useSelector(
        selectClassroomSubjectDetails(id, Auth.user.school_id)
    );
    const assists = useSelector(selectAtendenceForSubject(id, currentDate));
    const { subject } = assists;

    const statusServer = useSelector(
        selectClassroomViewmodelFetchStatusById(id)
    );

    const reload = () => {
        dispatch(
            fetchClassroomSubjectData({
                groupId: classroomDetails.group.group_id,
                subjectId: classroomDetails.subject.subject_id,
                curretnDate: DateTime.local().toFormat('yyyy-MM-dd'),
                schoolId: Auth.user.school_id,
                uuid: classroomDetails.viewmodel.uuid,
            })
        );
    };

    /**
     * UseEffect que ejecuta el proceso
     * de recuperación de datos
     */
    useEffect(() => {
        reload();
    }, []);

    /**
     * Invalida la UI
     */
    const invalidateUI = () => {
        dispatch(invalidate(id));
        reload();
    };

    const handlerCloseExportableModal = () => {
        setOpenExportModal(false);
    };

    const handlerExport = (exportOptions) => {
        const { id, title, report } = exportOptions;
        const pre = {
            id,
            title,
            report,
            query: {
                subject_id: subject.subject_id,
                group_id: subject.group_id,
            },
        };

        setSelectedTitle(title);
        setReportData(pre);
        setOpenExportModal(true);
    };

    const handlerCalificationList = () => {
        history.push({
            pathname: `/inicio-maestros/classroom-subject/${id}/scores/${subject.subject_id}`,
        });
    };

    const handlerJustifyAssistantPage = () => {
        history.push({
            pathname: `/inicio-maestros/classroom-subject/${id}/justify/${subject.subject_id}`,
        });
    };
    return (
        <Box
            sx={{
                flexGrow: 1,
                paddingTop: {
                    xs: 1,
                    sm: 2,
                    md: 2,
                },
                paddingLeft: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingRight: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingBottom: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
            }}>
            {statusServer === 'pending' && <SkeletonPage />}

            {statusServer === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}
            {statusServer === 'fulfilled' && (
                <>
                    <Box
                        sx={{
                            marginBottom: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}>
                        <Box>
                            <Button
                                size="small"
                                variant="contained"
                                onClick={handlerJustifyAssistantPage}
                                startIcon={<AssignmentTurnedInIcon />}
                                sx={{ marginRight: 1 }}>
                                Justificación de Faltas
                            </Button>
                            <Button
                                size="small"
                                variant="contained"
                                onClick={handlerCalificationList}
                                startIcon={<CalendarTodayIcon />}
                                sx={{ marginRight: 1 }}>
                                Registrar calificación
                            </Button>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                            }}>
                            <Button
                                size="small"
                                variant="contained"
                                onClick={() =>
                                    handlerExport({
                                        id: assists.subject.subject_id,
                                        title: 'Asistencias',
                                        report: 'subject-assists',
                                    })
                                }
                                startIcon={<FileDownloadIcon />}
                                sx={{ marginRight: 1 }}>
                                Exportar asistencias
                            </Button>
                            <Tooltip title="Sincronizar información">
                                <Button
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    onClick={invalidateUI}
                                    startIcon={<SyncIcon />}>
                                    Sincronizar
                                </Button>
                            </Tooltip>
                        </Box>
                    </Box>
                    <ClassroomSubjectPreviewCard classroom={classroomDetails} />
                    <SubjectAssistanceTable classroom={id} />

                    {openExportModal && (
                        <ExportablesModal
                            show={openExportModal}
                            data={reportData}
                            selectedTitle={selectedTitle}
                            onClose={handlerCloseExportableModal}
                        />
                    )}
                </>
            )}
        </Box>
    );
};

export default ClassroomSubjectAssistance;
