import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Step,
    StepLabel,
    Stepper,
    TextField,
    Typography,
    useTheme,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Checkbox,
    FormControlLabel,
    Divider,
    DialogActions,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth, useFeedback } from '../../../../hooks';
import {
    createConcept,
    fetchCreateConcept,
} from '../../../store/concepts/operations';
import { selectEntitiesStudents } from '../../../../store/slices/entities/students';
import { selectCatConcepts } from '../../../store/cat_concepts/selectors';
import { selectGroupsWithStudents } from '../../../store/concepts/selectors';
import * as Yup from 'yup';
import { Transfer } from 'antd';
import { Formik, Form, Field, useFormik } from 'formik';
import { selectCatSurcharge } from '../../../store/cat_surcharges/selectors';
import { Typography as AntTypography, Avatar } from 'antd';
import palomita from '../../../../public/assets/palomita.svg';

const CreateConcept = ({ open, onclose }) => {
    //////////////////////////// LOCAL STATE ////////////////////////////

    const [loading, setLoading] = useState(true);
    const [activeStep, setActiveStep] = useState(0);
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [stepType, setStepType] = useState(null);
    const [automaticSurcharge, setAutomaticSurcharge] = useState(false);
    const [selectedSurcharge, setSelectedSurcharge] = useState(null);
    const [filteredStudentsData, setFilteredStudentsData] = useState([]);

    //////////////////////////// SHARE STATE ////////////////////////////

    const auth = useAuth();
    const schoolId = auth.getUser().school_id;
    const dispatch = useDispatch();
    const theme = useTheme();
    const feedbackApp = useFeedback();

    const studentsData = useSelector((state) =>
        Object.values(selectEntitiesStudents(state)).map((student) => ({
            key: student.student_id,
            name:
                student.name +
                ' ' +
                student.last_name +
                ' ' +
                student.second_last_name,
        }))
    );

    const catalogConcepts = useSelector((state) =>
        Object.values(selectCatConcepts(state))
    );
    const groups = useSelector(selectGroupsWithStudents);

    const surcharge = useSelector((state) =>
        Object.values(selectCatSurcharge(state))
    );
    //////////////////////////// USE EFFECT ////////////////////////////

    useEffect(() => {
        if (open) {
            dispatch(fetchCreateConcept(schoolId)).finally(() =>
                setLoading(false)
            );
        }
    }, [dispatch, schoolId, open]);

    //////////////////////////// FUNCTIONS ////////////////////////////

    const steps = [
        'Información General',
        'Seleccionar Alumnos o Grupos',
        'Asignar Alumnos',
    ];

    const handleStudentSelect = (targetKeys) => {
        setSelectedStudents(targetKeys);
    };
    const handleGroupSelect = (event, value) => {
        const newStudentsInGroups = value.flatMap((group) =>
            group.students.map((student) => student.student_id)
        );

        const previousStudentsInGroups = selectedGroups.flatMap((group) =>
            group.students.map((student) => student.student_id)
        );

        const studentsToRemove = previousStudentsInGroups.filter(
            (studentId) => !newStudentsInGroups.includes(studentId)
        );

        const updatedSelectedStudents = selectedStudents.filter(
            (studentId) => !studentsToRemove.includes(studentId)
        );

        const studentsToAdd = newStudentsInGroups.filter(
            (studentId) => !selectedStudents.includes(studentId)
        );

        setSelectedStudents([...updatedSelectedStudents, ...studentsToAdd]);

        setFilteredStudentsData(
            studentsData.filter((student) =>
                updatedSelectedStudents
                    .concat(studentsToAdd)
                    .includes(student.key)
            )
        );

        setSelectedGroups(value);
    };
    const handleNext = (type = null) => {
        if (activeStep === steps.length - 1) {
            formik.handleSubmit();
        } else {
            setStepType(type);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setSelectedStudents([]);
        setSelectedGroups([]);
        setAutomaticSurcharge(false);
    };

    const handleReset = () => {
        formik.resetForm();
        setSelectedStudents([]);
        setActiveStep(0);
        setSelectedStudents([]);
        setSelectedGroups([]);
        setStepType(null);
        setAutomaticSurcharge(false);
        onclose();
    };

    const normalizeString = (str) =>
        str
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase();

    //////////////////////////// VALIDATION SCHEMA ////////////////////////////

    const validationSchema = Yup.object({
        description: Yup.string().required('Descripción es requerida'),
        limit_date: Yup.date()
            .min(
                new Date(new Date().setHours(0, 0, 0, 0)),
                'La fecha no puede ser en el pasado'
            )
            .required('Fecha límite es requerida'),
        concept_catalog_id: Yup.number().required(
            'Catálogo de concepto es requerido'
        ),
    });

    const formik = useFormik({
        initialValues: {
            description: '',
            limit_date: '',
            concept_catalog_id: null,
            automatic_surcharge: 0,
            surcharge_catalog_id: null,
            selectedGroups: [],
        },
        validationSchema,
        onSubmit: async (values) => {
            const data = selectedStudents.map((studentId) => {
                const item = {
                    description: values.description,
                    limit_date: values.limit_date,
                    concept_catalog_id: values.concept_catalog_id,
                    student: studentId,
                };

                if (automaticSurcharge) {
                    item.automatic_surcharge = 1;

                    if (selectedSurcharge?.surcharge_catalog_id) {
                        item.surcharge_catalog_id =
                            selectedSurcharge.surcharge_catalog_id;
                    }
                }

                return item;
            });
            await dispatch(createConcept(data))
                .unwrap()
                .then(() => {
                    feedbackApp.showFeedback({
                        title: 'Concepto registrado correctamente',
                        severity: 'success',
                    });
                    handleReset();
                })
                .catch((error) => {
                    feedbackApp.showFeedback({
                        title: error.feedback.title,
                        severity: 'error',
                    });
                });
        },
    });

    const sortedGroups = groups.sort((a, b) => {
        if (a.annexed_name && !b.annexed_name) return 1;
        if (!a.annexed_name && b.annexed_name) return -1;
        return 0;
    });

    //////////////////////////// RENDER ////////////////////////////

    return (
        <Dialog
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    onclose();
                }
            }}
            disableEscapeKeyDown
            sx={{
                '& .MuiDialog-paper': {
                    borderRadius: 5,
                    width: '90%',
                    maxWidth: 900,
                    height: 'auto',
                },
            }}>
            {' '}
            <DialogTitle
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: 1,
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                    }}>
                    <Box
                        component="img"
                        src={palomita}
                        alt="Descripción de la imagen"
                        sx={{
                            width: '15%',
                            height: 'auto',
                        }}
                    />
                    <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        sx={{
                            display: 'flex',
                            ml: 2,
                            mr: 2,
                        }}
                    />
                    <Box>
                        <AntTypography.Title level={5}>
                            Registra un Concepto
                        </AntTypography.Title>
                        <Typography
                            variant="body2"
                            sx={{
                                width: '100%',
                                mt: -1,
                            }}>
                            Aquí podrás registrar un nuevo concepto
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                    }}>
                    <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        sx={{
                            display: 'flex',
                            ml: 2,
                            mr: 2,
                        }}
                    />
                    <IconButton
                        onClick={() => {
                            // formik.resetForm();
                            handleReset();
                        }}>
                        <CloseIcon
                            sx={{
                                color: '#000000ff',
                                width: 20,
                            }}
                        />
                    </IconButton>
                </Box>
            </DialogTitle>
            <Divider
                sx={{
                    width: '95%',
                }}
                flexItem
                variant="middle"
            />
            <DialogContent>
                <form onSubmit={formik.handleSubmit}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    {activeStep === 0 && (
                        <Box sx={{ mt: 3 }}>
                            <TextField
                                name="description"
                                fullWidth
                                label="Descripción"
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.description &&
                                    Boolean(formik.errors.description)
                                }
                                helperText={
                                    formik.touched.description &&
                                    formik.errors.description
                                }
                                variant="outlined"
                                margin="normal"
                            />
                            <TextField
                                name="limit_date"
                                fullWidth
                                type="date"
                                label="Fecha límite"
                                value={formik.values.limit_date}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.limit_date &&
                                    Boolean(formik.errors.limit_date)
                                }
                                helperText={
                                    formik.touched.limit_date &&
                                    formik.errors.limit_date
                                }
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                margin="normal"
                                inputProps={{
                                    min: new Date().toISOString().split('T')[0],
                                }}
                            />
                            <Autocomplete
                                noOptionsText="No hay opciones disponibles"
                                value={
                                    catalogConcepts.find(
                                        (concept) =>
                                            concept.concept_catalog_id ===
                                            formik.values.concept_catalog_id
                                    ) || null
                                }
                                options={catalogConcepts.filter(
                                    (concept) => concept.active === true
                                )}
                                getOptionLabel={(option) => option.concept}
                                onChange={(event, value) =>
                                    formik.setFieldValue(
                                        'concept_catalog_id',
                                        value?.concept_catalog_id
                                    )
                                }
                                renderOption={(props, option) => (
                                    <Box
                                        component="li"
                                        {...props}
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            borderBottom: '1px solid #ddd',
                                            padding: '8px 16px',
                                        }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                            }}>
                                            <Typography
                                                variant="body1"
                                                sx={{ fontWeight: 'bold' }}>
                                                {option.concept}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary">
                                                ID: {option.concept_catalog_id}
                                            </Typography>
                                        </Box>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            sx={{ marginLeft: 'auto' }}>
                                            Total: ${option.value}
                                        </Typography>
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Catálogo de concepto"
                                        variant="outlined"
                                        margin="normal"
                                        error={
                                            formik.touched.concept_catalog_id &&
                                            Boolean(
                                                formik.errors.concept_catalog_id
                                            )
                                        }
                                        helperText={
                                            formik.touched.concept_catalog_id &&
                                            formik.errors.concept_catalog_id
                                        }
                                    />
                                )}
                            />
                        </Box>
                    )}
                    {activeStep === 1 && (
                        <Box sx={{ mt: 5, textAlign: 'center' }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: 6,
                                    mt: 4,
                                    flexWrap: 'wrap', // For better responsiveness
                                }}>
                                <CardActionArea
                                    sx={{
                                        width: 350,
                                        boxShadow: 3,
                                        borderRadius: 2,
                                        transition:
                                            'transform 0.2s ease-in-out',
                                        '&:hover': {
                                            transform: 'scale(1.05)',
                                        },
                                    }}
                                    onClick={() => handleNext('students')}>
                                    <Card
                                        sx={{ width: '100%', borderRadius: 2 }}>
                                        <CardMedia
                                            component="img"
                                            height="200"
                                            image="/images/GroupPrincipal.jpeg"
                                            alt="Alumnos"
                                            sx={{
                                                borderTopLeftRadius: 8,
                                                borderTopRightRadius: 8,
                                            }}
                                        />
                                        <CardContent
                                            sx={{ textAlign: 'center' }}>
                                            <Typography
                                                gutterBottom
                                                variant="h5"
                                                component="div"
                                                color="primary">
                                                Alumnos
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="text.secondary">
                                                Selecciona para gestionar los
                                                alumnos.
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </CardActionArea>
                                <CardActionArea
                                    sx={{
                                        width: 350,
                                        boxShadow: 3,
                                        borderRadius: 2,
                                        transition:
                                            'transform 0.2s ease-in-out',
                                        '&:hover': {
                                            transform: 'scale(1.05)',
                                        },
                                    }}
                                    onClick={() => handleNext('groups')}>
                                    <Card
                                        sx={{ width: '100%', borderRadius: 2 }}>
                                        <CardMedia
                                            component="img"
                                            height="200"
                                            image="/images/GroupSpecial.jpeg"
                                            alt="Grupos"
                                            sx={{
                                                borderTopLeftRadius: 8,
                                                borderTopRightRadius: 8,
                                            }}
                                        />
                                        <CardContent
                                            sx={{ textAlign: 'center' }}>
                                            <Typography
                                                gutterBottom
                                                variant="h5"
                                                component="div"
                                                color="primary">
                                                Grupos
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="text.secondary">
                                                Selecciona para gestionar los
                                                grupos.
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </CardActionArea>
                            </Box>
                        </Box>
                    )}
                    {activeStep === 2 && (
                        <Box sx={{ mt: 3 }}>
                            {stepType === 'students' && (
                                <>
                                    <Typography
                                        variant="h6"
                                        textAlign="center"
                                        sx={{
                                            mt: 2,
                                            padding: 2,
                                        }}>
                                        Selecciona los Alumnos
                                    </Typography>
                                    <Transfer
                                        listStyle={{
                                            width: '100%',
                                            height: 400,
                                        }}
                                        dataSource={studentsData}
                                        showSearch
                                        filterOption={(input, option) =>
                                            normalizeString(
                                                option?.name ?? ''
                                            ).includes(normalizeString(input))
                                        }
                                        targetKeys={selectedStudents}
                                        onChange={setSelectedStudents}
                                        render={(item) => item.name}
                                        rowKey={(item) => item.key}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={automaticSurcharge}
                                                onChange={(e) =>
                                                    setAutomaticSurcharge(
                                                        e.target.checked
                                                    )
                                                }
                                            />
                                        }
                                        label="Recargos Automáticos"
                                        sx={{ mt: 2 }}
                                    />
                                    {automaticSurcharge && (
                                        <Autocomplete
                                            noOptionsText="No hay opciones disponibles"
                                            options={surcharge.filter(
                                                (surcharge) =>
                                                    surcharge.active === true
                                            )}
                                            getOptionLabel={(option) =>
                                                option.title
                                            }
                                            onChange={(event, value) =>
                                                setSelectedSurcharge(
                                                    value || null
                                                )
                                            }
                                            renderOption={(props, option) => (
                                                <Box
                                                    component="li"
                                                    {...props}
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'space-between',
                                                        alignItems: 'center',
                                                        borderBottom:
                                                            '1px solid #ddd',
                                                        padding: '8px 16px',
                                                    }}>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection:
                                                                'column',
                                                        }}>
                                                        <Typography
                                                            variant="body1"
                                                            sx={{
                                                                fontWeight:
                                                                    'bold',
                                                            }}>
                                                            {option.title}
                                                        </Typography>
                                                        <Typography
                                                            variant="body2"
                                                            color="textSecondary">
                                                            ID:{' '}
                                                            {
                                                                option.surcharge_catalog_id
                                                            }
                                                        </Typography>
                                                    </Box>
                                                    <Typography
                                                        variant="body2"
                                                        color="textSecondary"
                                                        sx={{
                                                            marginLeft: 'auto',
                                                        }}>
                                                        Total:
                                                        {option.value_modality ===
                                                        1
                                                            ? `$${option.value}`
                                                            : `${option.value}%`}
                                                    </Typography>
                                                </Box>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Catálogo de recargos"
                                                    variant="outlined"
                                                    margin="normal"
                                                />
                                            )}
                                        />
                                    )}
                                </>
                            )}
                            {stepType === 'groups' && (
                                <>
                                    <Typography
                                        variant="h6"
                                        textAlign="center"
                                        sx={{
                                            mt: 2,
                                            padding: 2,
                                        }}>
                                        Selecciona los Grupos
                                    </Typography>

                                    <Autocomplete
                                        noOptionsText="No hay opciones disponibles"
                                        multiple
                                        groupBy={(option) =>
                                            option.annexed_name
                                                ? 'Módulo'
                                                : 'Grupos'
                                        }
                                        filterSelectedOptions
                                        options={sortedGroups}
                                        renderOption={(props, option) => (
                                            <Box
                                                component="li"
                                                {...props}
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent:
                                                        'space-between',
                                                    alignItems: 'center',
                                                    borderBottom:
                                                        '1px solid #ddd',
                                                    padding: '8px 16px',
                                                }}>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                    }}>
                                                    <Typography
                                                        variant="body1"
                                                        sx={{
                                                            fontWeight: 'bold',
                                                        }}>
                                                        {option.grade +
                                                            ' ' +
                                                            option.group +
                                                            ' ' +
                                                            (option.annexed_name
                                                                ? `(Módulo - ${option.annexed_name})`
                                                                : '')}
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        color="textSecondary">
                                                        Turno:{' '}
                                                        {option.turn === 1
                                                            ? 'Matutino'
                                                            : 'Vespertino'}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        )}
                                        getOptionLabel={(option) => {
                                            const turnLabel =
                                                option.turn === 1
                                                    ? 'Matutino'
                                                    : 'Vespertino';
                                            return `${option.grade} ${option.group}\n${turnLabel}`;
                                        }}
                                        onChange={(event, value) => {
                                            formik.setFieldValue(
                                                'selectedGroups',
                                                value
                                            );
                                            handleGroupSelect(event, value);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Selecciona Grupos o Módulos"
                                                variant="outlined"
                                                margin="normal"
                                            />
                                        )}
                                    />

                                    <Typography
                                        textAlign="center"
                                        variant="h6"
                                        sx={{
                                            mt: 2,
                                            padding: 2,
                                        }}>
                                        Alumnos en Grupos
                                    </Typography>
                                    <Transfer
                                        dataSource={filteredStudentsData}
                                        listStyle={{
                                            width: '100%',
                                            height: 400,
                                        }}
                                        showSearch
                                        filterOption={(input, option) =>
                                            normalizeString(
                                                option?.name ?? ''
                                            ).includes(normalizeString(input))
                                        }
                                        targetKeys={selectedStudents}
                                        onChange={setSelectedStudents}
                                        render={(item) => item.name}
                                        rowKey={(item) => item.key}
                                    />
                                    <Box>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={automaticSurcharge}
                                                    onChange={(e) =>
                                                        setAutomaticSurcharge(
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                            }
                                            label="Recargos Automáticos"
                                            sx={{ mt: 2 }}
                                        />
                                        {automaticSurcharge && (
                                            <Autocomplete
                                                noOptionsText="No hay opciones disponibles"
                                                options={surcharge.filter(
                                                    (surcharge) =>
                                                        surcharge.active ===
                                                        true
                                                )}
                                                getOptionLabel={(option) =>
                                                    option.title
                                                }
                                                onChange={(event, value) =>
                                                    setSelectedSurcharge(
                                                        value || null
                                                    )
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Catálogo de recargos"
                                                        variant="outlined"
                                                        margin="normal"
                                                    />
                                                )}
                                                renderOption={(
                                                    props,
                                                    option
                                                ) => (
                                                    <Box
                                                        component="li"
                                                        {...props}
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent:
                                                                'space-between',
                                                            alignItems:
                                                                'center',
                                                            borderBottom:
                                                                '1px solid #ddd',
                                                            padding: '8px 16px',
                                                        }}>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection:
                                                                    'column',
                                                            }}>
                                                            <Typography
                                                                variant="body1"
                                                                sx={{
                                                                    fontWeight:
                                                                        'bold',
                                                                }}>
                                                                {option.title}
                                                            </Typography>
                                                            <Typography
                                                                variant="body2"
                                                                color="textSecondary">
                                                                ID:{' '}
                                                                {
                                                                    option.surcharge_catalog_id
                                                                }
                                                            </Typography>
                                                        </Box>
                                                        <Typography
                                                            variant="body2"
                                                            color="textSecondary"
                                                            sx={{
                                                                marginLeft:
                                                                    'auto',
                                                            }}>
                                                            Total: $
                                                            {option.value}
                                                        </Typography>
                                                    </Box>
                                                )}
                                            />
                                        )}
                                    </Box>
                                </>
                            )}
                        </Box>
                    )}
                </form>
            </DialogContent>
            <Divider
                sx={{
                    width: '95%',
                }}
                flexItem
                variant="middle"
            />
            <DialogActions
                sx={{
                    justifyContent: 'space-between',
                }}>
                <Box sx={{ mb: 2 }}>
                    <Button
                        sx={{ mt: 1, mr: 1 }}
                        onClick={() => {
                            handleReset();
                        }}>
                        Cancelar
                    </Button>
                </Box>

                <Box sx={{ mb: 2 }}>
                    <Button disabled={activeStep === 0} onClick={handleBack}>
                        Atrás
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        disabled={
                            activeStep === 0
                                ? !(
                                      formik.values.description &&
                                      formik.values.limit_date &&
                                      formik.values.concept_catalog_id
                                  )
                                : formik.isSubmitting ||
                                  selectedStudents.length === 0 ||
                                  (automaticSurcharge && !selectedSurcharge)
                        }>
                        {formik.isSubmitting ? (
                            <CircularProgress size={24} />
                        ) : activeStep === steps.length - 1 ? (
                            'Enviar'
                        ) : (
                            'Siguiente'
                        )}
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default CreateConcept;
