import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth, useFeedback } from '../../../hooks';
import { LoadingButton } from '@mui/lab';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    Avatar,
    Box,
    Skeleton,
    Stack,
} from '@mui/material';
import {
    DataGrid,
    esES,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { selectSettingBySchoolId } from '../../../store/slices/entities/settings';
import * as Style from '../../../styles/index';
import {
    getParentsStudents,
    invalidateParentsStudents,
    selectParentsStudents,
    selectSelectedParentStatus,
} from '../../../store/slices/usersUI';
import { Error } from '../../../components/Feedback';
import { getLevelName, getTurnLevel } from '../../../libs/utils';

const RelationshipsModal = ({ open, setOpen, viewModel }) => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const feedbackApp = useFeedback();

    const schoolId = Auth.getUser().school_id;
    const classes = Style.tableModalStyle();

    const students = useSelector(selectParentsStudents(viewModel.uuid));
    const statusOperation = useSelector(selectSelectedParentStatus);

    const config = useSelector(selectSettingBySchoolId(schoolId));
    let titleBarBackground = config.find(
        (res) => res.key === 'theme-color-title-bar'
    );
    let fontColor = config.find((res) => res.key === 'theme-color-font');

    const parentId = viewModel.ui.user_id;

    useEffect(() => {
        if (open) {
            dispatch(getParentsStudents({ parentId, schoolId }));
        }
    }, [open]);

    const handleClose = (event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
        }
        setOpen(false);
    };

    const reloadStundents = () => {
        dispatch(getParentsStudents({ parentId, schoolId }))
            .unwrap()
            .then(() => {})
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    const invalidateUI = () => {
        dispatch(invalidateParentsStudents(viewModel.uuid));
        reloadStundents();
    };

    const columns = [
        {
            field: 'student_id',
            headerName: 'ID',
            flex: 0.1,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'url_photo_profile',
            headerName: 'Foto',
            flex: 0.1,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <Avatar src={params.value} alt={params.row.name} />
            ),
        },
        {
            field: 'name',
            headerName: 'Nombre Completo',
            flex: 0.4,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'super-app-theme--header',
            renderCell: ({ row }) => (
                <Typography>{`${row.name} ${row.last_name} ${row.second_last_name}`}</Typography>
            ),
        },
        {
            field: 'cellphone',
            headerName: 'Teléfono',
            flex: 0.2,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'group',
            headerName: 'Grupo',
            flex: 0.4,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'super-app-theme--header',
            valueGetter: ({ row }) => {
                return row.group;
            },
            renderCell: ({ row }) => {
                if (row.group) {
                    return (
                        <Typography>{`${row.group.grade} ${
                            row.group.group
                        } ${getTurnLevel(row.group.turn)} ${getLevelName(
                            row.group.level
                        )}`}</Typography>
                    );
                } else {
                    return <Typography>Sin Grupo</Typography>;
                }
            },
        },
    ];

    const CustomToolbar = () => {
        let titleBarBackground = config.find(
            (res) => res.key === 'theme-color-title-bar'
        );
        let fontColor = config.find((res) => res.key === 'theme-color-font');
        return (
            <GridToolbarContainer
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: titleBarBackground.value,
                    color: fontColor.value,
                }}
            >
                <GridToolbarColumnsButton
                    style={{
                        color: fontColor.value,
                    }}
                />
                <GridToolbarFilterButton
                    style={{
                        color: fontColor.value,
                    }}
                />
                <GridToolbarExport
                    style={{
                        color: fontColor.value,
                    }}
                    csvOptions={{
                        fields: columns.map((column) => column.field),
                        utf8WithBom: true,
                        delimiter: ',',
                    }}
                />
            </GridToolbarContainer>
        );
    };

    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth="md"
            classes={{ paper: classes.dialogWrapper }}
            onClose={handleClose}
        >
            <DialogTitle
                style={{
                    backgroundColor: titleBarBackground.value,
                    color: fontColor.value,
                }}
            >
                <div style={{ display: 'flex' }}>
                    <Typography
                        variant="h6"
                        component="div"
                        style={{ flexGrow: 1 }}
                    >
                        Alumnos
                    </Typography>
                </div>
            </DialogTitle>
            <DialogContent classes={{ root: classes.dialogContent }}>
                <Box
                    sx={{
                        '& .super-app-theme--header': {
                            backgroundColor: titleBarBackground.value,
                            color: fontColor.value,
                        },
                        '& .MuiDataGrid-root': {
                            border: 0,
                        },
                    }}
                >
                    <Box style={{ textAlign: 'center' }}>
                        {statusOperation === 'rejected' && (
                            <Error
                                onRetry={invalidateUI}
                                message={'Estamos teniendo problemas'}
                            />
                        )}
                        {statusOperation === 'pending' && (
                            <Stack spacing={2} sx={{ padding: 2 }}>
                                <Skeleton variant="rectangular" height={70} />
                                <Skeleton variant="rectangular" height={30} />
                                <Skeleton variant="rectangular" height={30} />
                            </Stack>
                        )}

                        {statusOperation === 'fulfilled' && (
                            <DataGrid
                                localeText={
                                    esES.components.MuiDataGrid.defaultProps
                                        .localeText
                                }
                                rows={students}
                                columns={columns}
                                pageSize={10}
                                components={{
                                    Toolbar: CustomToolbar,
                                }}
                                rowsPerPageOptions={[]}
                                disableSelectionOnClick
                                disableDensitySelector
                                componentsProps={{
                                    panel: {
                                        placement: 'bottom-end',
                                    },
                                }}
                                autoHeight
                                getRowId={(row) => row.student_id}
                            />
                        )}
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    color="primary"
                    loadingPosition="start"
                    size="small"
                    onClick={handleClose}
                    variant="contained"
                >
                    Cerrar
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default RelationshipsModal;
