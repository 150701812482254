import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { convertArrayToObject, getExpireIn } from '../../../libs/utils';
import Services from '../../../service/Connection';
import { SETTINGSUI_EXPIRE_TIME } from '../../../service/const';
import Feedback from '../../../service/Feedback';
import {
    addOnePartial,
    removeOnePartial,
    selectPartialOrRegularizationsBySchoolsInLevel,
    setAllPartials,
    updateManyPartials,
    upsertOnePartial,
} from '../entities/partials';
import { upsertOneSchool } from '../entities/schools';
import { upsertManySettings, upsertOneSetting } from '../entities/settings';
import _ from 'lodash';
import { setAllImports } from '../entities/imports';
import { upsertManyUsers } from '../entities/users';
import { upsertManyCycles, upsertOneCycles } from '../entities/cycles';

const emptyState = {
    data: {},
    server: {
        expireIn: null,
        ferchingAt: null,
        statusServer: 'idle',
        statusOperation: 'idle',
        didInvalidate: true,
        feedback: {
            title: null,
            message: null,
            payload: null,
        },
        snaphot: {
            server: {
                expireIn: null,
                ferchingAt: null,
                statusServer: 'idle',
                statusOperation: 'idle',
                didInvalidate: true,
                feedback: {
                    title: null,
                    message: null,
                    payload: null,
                },
            },
            create: {
                expireIn: null,
                ferchingAt: null,
                status: 'idle',
                didInvalidate: true,
            },
        },
    },
    ui: {
        partials: {
            columnField: '',
            operatorValue: '',
            value: '',
        },
        regulation: {
            columnField: '',
            operatorValue: '',
            value: '',
        },
        importCenter: {
            columnField: '',
            operatorValue: '',
            value: '',
        },
        snaphot: {
            columnField: '',
            operatorValue: '',
            value: '',
        },
    },
};

/**
 * Slice para el settings UI
 */
export const settingsUISlice = createSlice({
    name: 'settingsUI',
    initialState: emptyState,
    reducers: {
        /**
         * Invalidar datos de la UI
         */
        invalidate: (state, action) => {
            state.server.didInvalidate = true;
        },
        updatePartialFilter: (state, { payload }) => {
            const { filter } = payload;
            state.ui.partials = { ...state.filters, filter };
        },
        updateRegulationFilter: (state, { payload }) => {
            const { filter } = payload;
            state.ui.regulation = { ...state.filters, filter };
        },
        updateImportCenterFilter: (state, { payload }) => {
            const { filter } = payload;
            state.ui.importCenter = { ...state.filters, filter };
        },

        updateSnaphotFilter: (state, { payload }) => {
            const { filter } = payload;
            state.ui.snaphot = { ...state.filters, filter };
        },
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return emptyState;
        });

        const pendingServerStatus = (state, action) => {
            state.server.statusServer = 'pending';
        };

        /**
         * Termina la carga de informacion
         */
        builder.addCase(loadUI.fulfilled, (state, action) => {
            state.server.expireIn = getExpireIn('SETTINGSUI_EXPIRE_TIME');
            state.server.ferchingAt = Date.now();
            state.server.didInvalidate = false;

            state.server.statusServer = 'fulfilled';
        });
        builder.addCase(loadUI.pending, pendingServerStatus);
        builder.addCase(loadUI.rejected, (state, action) => {
            state.server.statusServer = 'rejected';
            state.server.feedback = action.payload.feedback;
        });

        ////////////////  ACTUALZIAR UN PARCIAL ///////////////
        builder.addCase(updatePartial.rejected, (state, action) => {
            state.server.statusOperation = 'rejected';
            state.server.feedback = action.payload.feedback;
        });
        builder.addCase(updatePartial.fulfilled, (state, action) => {
            state.server.statusOperation = 'fulfilled';
        });
        builder.addCase(updatePartial.pending, (state, action) => {
            state.server.statusOperation = 'pending';
        });
        /////////////// CREAR PARCIAL ////////////////////
        builder.addCase(storePartial.rejected, (state, action) => {
            state.server.statusOperation = 'rejected';
            state.server.feedback = action.payload.feedback;
        });
        builder.addCase(storePartial.fulfilled, (state, action) => {
            state.server.statusOperation = 'fulfilled';
        });
        builder.addCase(storePartial.pending, (state, action) => {
            state.server.statusOperation = 'pending';
        });
        /////////////// ELIMINAR UN PARCIAL ////////////////
        builder.addCase(deletePartial.rejected, (state, action) => {
            state.server.statusOperation = 'rejected';
            state.server.feedback = action.payload.feedback;
        });
        builder.addCase(deletePartial.fulfilled, (state, action) => {
            state.server.statusOperation = 'fulfilled';
        });
        builder.addCase(deletePartial.pending, (state, action) => {
            state.server.statusOperation = 'pending';
        });

        ///////////////// ACTUALZIAR CONFIGUIRACION /////////////////
        builder.addCase(saveSetting.rejected, (state, action) => {
            state.server.statusOperation = 'rejected';
            state.server.feedback = action.payload.feedback;
        });
        builder.addCase(saveSetting.fulfilled, (state, action) => {
            state.server.statusOperation = 'fulfilled';
        });
        builder.addCase(saveSetting.pending, (state, action) => {
            state.server.statusOperation = 'pending';
        });

        /////////////////// ACTUALZIAR LA ESCUELA /////////////////////
        builder.addCase(updateGeneralInfo.rejected, (state, action) => {
            state.server.statusOperation = 'rejected';
            state.server.feedback = action.payload.feedback;
        });
        builder.addCase(updateGeneralInfo.fulfilled, (state, action) => {
            state.server.statusOperation = 'fulfilled';
        });
        builder.addCase(updateGeneralInfo.pending, (state, action) => {
            state.server.statusOperation = 'pending';
        });
        /////////////////// ACTUALZIAR LA ESCUELA /////////////////////
        builder.addCase(updateLocationInfo.rejected, (state, action) => {
            state.server.statusOperation = 'rejected';
            state.server.feedback = action.payload.feedback;
        });
        builder.addCase(updateLocationInfo.fulfilled, (state, action) => {
            state.server.statusOperation = 'fulfilled';
        });
        builder.addCase(updateLocationInfo.pending, (state, action) => {
            state.server.statusOperation = 'pending';
        });
        /////////////////// ACTUALZIAR LA ESCUELA /////////////////////
        builder.addCase(updateImageSchool.rejected, (state, action) => {
            state.server.statusOperation = 'rejected';
            state.server.feedback = action.payload.feedback;
        });
        builder.addCase(updateImageSchool.fulfilled, (state, action) => {
            state.server.statusOperation = 'fulfilled';
        });
        builder.addCase(updateImageSchool.pending, (state, action) => {
            state.server.statusOperation = 'pending';
        });

        /////////////////// LOAD CYCLES /////////////////////
        builder.addCase(loadCyclesUI.fulfilled, (state, action) => {
            state.server.snaphot.server.expireIn = getExpireIn(
                'SETTINGSUI_EXPIRE_TIME'
            );
            state.server.snaphot.server.ferchingAt = Date.now();
            state.server.snaphot.server.didInvalidate = false;

            state.server.snaphot.server.statusServer = 'fulfilled';
        });
        builder.addCase(loadCyclesUI.pending, pendingServerStatus);
        builder.addCase(loadCyclesUI.rejected, (state, action) => {
            state.server.snaphot.server.statusServer = 'rejected';
            state.server.snaphot.server.feedback = action.payload.feedback;
        });

        /////////////////// CREAR UN CORTE DE CICLO /////////////////////

        builder.addCase(CreateSnaphot.rejected, (state, action) => {
            state.server.snaphot.create.statusOperation = 'rejected';
            state.server.snaphot.create.feedback = action.payload.feedback;
        });
        builder.addCase(CreateSnaphot.fulfilled, (state, action) => {
            state.server.snaphot.create.statusOperation = 'fulfilled';
        });
        builder.addCase(CreateSnaphot.pending, (state, action) => {
            state.server.snaphot.create.statusOperation = 'pending';
        });
    },
});

export const {
    invalidate,
    updatePartialFilter,
    updateRegulationFilter,
    updateImportCenterFilter,
    updateSnaphotFilter,
} = settingsUISlice.actions;

export default settingsUISlice.reducer;

//////////////////// SELECTORES //////////////////
/**
 * Recuperamos las configuraciones de la escuela
 *
 * @param {*} state
 * @returns
 */
export const selectsettingsUI = (state) => state.settingsUI.ui;

export const selectsettingsData = (state) => state.settingsUI.data;

export const selectsettingsServer = (state) => state.settingsUI.server;

export const selectStatusServer = (state) =>
    state.settingsUI.server.statusServer;

export const selectStatusOperation = (state) =>
    state.settingsUI.server.statusOperation;

/*
 * Recuperamos los cortes de ciclo de una escuela
 * */

export const selectStatusSnaphot = (state) =>
    state.settingsUI.server.snaphot.server.statusOperation;

export const selectCyclesUI = (state) => state.settingsUI.ui.snaphot.filter;

//////////////// TRUNCKS /////////////////

/*
 * Cargar Informacion de los ciclos
 */
export const loadCyclesUI = createAsyncThunk(
    'settingsUI/fetch/cycles',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            const cycles = await Services.getCyclesBySchool(schoolId).then(
                (res) => res.data.data
            );

            thunkAPI.dispatch(upsertManyCycles(cycles));

            return cycles;
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Cargar informacion de la UI
 */
export const loadUI = createAsyncThunk(
    'settingsUI/fetch/data',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            const partials = await Services.getPartials(schoolId).then(
                (res) => res.data.data
            );
            const school = await Services.schoolInfo(schoolId).then(
                (r) => r.data.data
            );
            const settings = await Services.getSettingsBySchool(schoolId).then(
                (res) => res.data.data
            );
            const imports = await Services.getImportsBySchoolId(schoolId).then(
                (res) => res.data.data
            );
            const administrators = await Services.getAdministratorssBySchool(
                schoolId
            ).then((res) => res.data.data);

            await thunkAPI.dispatch(loadCyclesUI(schoolId)).unwrap();
            thunkAPI.dispatch(setAllPartials(partials));
            thunkAPI.dispatch(upsertOneSchool(school));
            thunkAPI.dispatch(upsertManySettings(settings));
            thunkAPI.dispatch(setAllImports(imports));
            thunkAPI.dispatch(upsertManyUsers(administrators));

            return {
                partials,
                school,
                imports,
                administrators,
            };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } = getState().settingsUI.server;

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

/**
 * Crear o actualizar parcial
 */
export const storePartial = createAsyncThunk(
    'entities/partials/store',
    async ({ schoolId, data }, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            let partial = await Services.setPartials(schoolId, data).then(
                (res) => res.data.data
            );

            thunkAPI.dispatch(addOnePartial(partial));
            return partial;
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Crear o actualizar parcial
 */
export const updatePartial = createAsyncThunk(
    'entities/partials/update',
    async ({ partialId, data }, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            let partial = await Services.updatePartial(partialId, data).then(
                (res) => res.data.data
            );
            thunkAPI.dispatch(upsertOnePartial(partial));

            return partial;
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Eliminar parcial
 */
export const deletePartial = createAsyncThunk(
    'entities/partials/delete',
    async (partialId, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            /**
             * Se elimina el parcial normal
             */
            let partialRemoved = await Services.deletePartial(partialId).then(
                (res) => res.data.data
            );

            thunkAPI.dispatch(removeOnePartial(partialRemoved.partial_id));

            /**
             * Reordenamos los demas parciales
             */
            let partials = selectPartialOrRegularizationsBySchoolsInLevel(
                partialRemoved.school_id,
                partialRemoved.level,
                partialRemoved.is_regularized
            )(thunkAPI.getState());

            let partialOrdened = _.sortBy(
                partials.map((item) => ({ ...item })),
                ['partial_id']
            ).map((partial, index) => {
                return {
                    id: partial.partial_id,
                    changes: {
                        partial: index + 1,
                    },
                };
            });

            thunkAPI.dispatch(updateManyPartials(partialOrdened));

            return partialRemoved;
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Actualziar parcial
 */
export const saveSetting = createAsyncThunk(
    'entities/setting/update',
    async ({ settingId, value }, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            let setting = await Services.setSchoolSettings(
                settingId,
                value
            ).then((response) => response.data.data);
            thunkAPI.dispatch(upsertOneSetting(setting));

            return setting;
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Actualizar ubicación de la escuela
 */
export const updateLocationInfo = createAsyncThunk(
    'entities/school/updateLocationInfo',
    async (data, thunkAPI) => {
        let FeedbackService = new Feedback();
        try {
            let school = await Services.UpdateSchoolInfo(
                data.school_id,
                data
            ).then((r) => r.data.data);
            thunkAPI.dispatch(upsertOneSchool(school));
            return school;
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Actualizar información general de la escuela
 */
export const updateGeneralInfo = createAsyncThunk(
    'entities/school/updateGeneralInfo',
    async (data, thunkAPI) => {
        let FeedbackService = new Feedback();
        try {
            let school = await Services.UpdateSchoolInfo(
                data.school_id,
                data
            ).then((r) => r.data.data);
            thunkAPI.dispatch(upsertOneSchool(school));
            return school;
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Actualziar escuela
 */
export const updateImageSchool = createAsyncThunk(
    'entities/school/update-image',
    async ({ settingId, formData }, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            let setting = await Services.uploadSchoolImage(
                settingId,
                formData
            ).then((r) => r.data.data);
            thunkAPI.dispatch(upsertOneSetting(setting));

            return setting;
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Crear Cortes de ciclo
 */

export const CreateSnaphot = createAsyncThunk(
    'entities/school/create-snaphot',
    async ({ schoolId, cycles }, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            let snaphot = await Services.createSnaphot(schoolId, cycles).then(
                (r) => r.data.data
            );
            thunkAPI.dispatch(upsertOneCycles(snaphot));
            return snaphot;
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);
