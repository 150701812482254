import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, Typography, Paper, Grid, Divider } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import ReportIcon from '@mui/icons-material/Report';
import { useAuth } from '../../../../hooks';
import {
    getCycleSelected,
    selectSchoolPerformanceSummary,
} from '../../../store/students/fetchSlice';
import { selectComponentStudentSchoolPerformanceSummaryStatusServer } from '../../../store/students/componentsStatusSelectors';
import useFetchResource from '../../../components/HelperHook';
import { getSchoolPerformanceSummary } from '../../../store/students/thunks';
import Error from '../../../../components/Feedback/Error';
import { SkeletonStudentsFourdCards } from '../../../components/Skeleton';

const SchoolPerformanceSummary = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentStudentSchoolPerformanceSummaryStatusServer
    );
    const performanceSummary = useFetchResource(
        () =>
            getSchoolPerformanceSummary({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectSchoolPerformanceSummary,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getSchoolPerformanceSummary({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
            selectSchoolPerformanceSummary,
            [cycleSelected, schoolId]
        );
    };

    const colors = ['#03a9f4', '#9c27b0', '#ff9800', '#f44336'];
    const titles = [
        'Mejor Promedio de Nivel',
        'Peor Promedio de Nivel',
        'Más Inasistencias',
        'Más Reportes',
    ];
    const icons = [
        <StarIcon
            style={{
                fontSize: 35,
                color: 'rgb(232, 247, 255)',
                marginRight: '10px',
                marginBottom: '10px',
            }}
        />,
        <ThumbDownIcon
            style={{
                fontSize: 35,
                color: 'rgb(232, 247, 255)',
                marginRight: '10px',
                marginBottom: '10px',
            }}
        />,
        <EventBusyIcon
            style={{
                fontSize: 35,
                color: 'rgb(232, 247, 255)',
                marginRight: '10px',
                marginBottom: '10px',
            }}
        />,
        <ReportIcon
            style={{
                fontSize: 35,
                color: 'rgb(232, 247, 255)',
                marginRight: '10px',
                marginBottom: '10px',
            }}
        />,
    ];

    if (!performanceSummary) {
        return <Error message="No se pudieron cargar los datos" />;
    }
    const levels = performanceSummary?.value
        ? Object.keys(performanceSummary.value)
        : [];

    return (
        <Box>
            {fetchingStatus === 'pending' && (
                <SkeletonStudentsFourdCards items={9} />
            )}
            {fetchingStatus === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}
            {fetchingStatus === 'fulfilled' && (
                <>
                    {levels.map((levelName, levelIndex) => {
                        const levelData =
                            performanceSummary?.value?.[levelName];

                        if (!levelData) return null;

                        const cardData = [
                            levelData.best_average_level,
                            levelData.worst_average_level,
                            levelData.more_inassist_level,
                            levelData.most_report_level,
                        ];

                        return (
                            <Box key={levelIndex} mb={4}>
                                <Divider textAlign="left" sx={{ mt: 4, mb: 4 }}>
                                    <Typography variant="h5">
                                        <span>{levelName}</span>
                                    </Typography>
                                </Divider>
                                <Grid container spacing={2}>
                                    {cardData.map((data, index) => (
                                        <Grid item xs={12} md={6} key={index}>
                                            <Card
                                                sx={{
                                                    background: colors[index],
                                                    borderRadius: '10px',
                                                    boxShadow:
                                                        '0 4px 10px rgba(0, 0, 0, 0.1)',
                                                    padding: '15px',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    maxWidth: '800px',
                                                    margin: 'auto',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        marginBottom: '10px',
                                                    }}
                                                >
                                                    {icons[index]}
                                                    <Typography
                                                        variant="h6"
                                                        sx={{
                                                            fontWeight: 700,
                                                            color: '#fff',
                                                            marginBottom:
                                                                '10px',
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        {titles[index]}
                                                    </Typography>
                                                </Box>
                                                <Paper
                                                    elevation={3}
                                                    sx={{
                                                        margin: '16px 0',
                                                        padding: '16px',
                                                        width: '100%',
                                                        backgroundColor:
                                                            '#f1f1f1',
                                                        borderRadius: '10px',
                                                    }}
                                                >
                                                    <Grid container spacing={2}>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={4}
                                                        >
                                                            <Typography
                                                                variant="subtitle2"
                                                                sx={{
                                                                    color: '#808080',
                                                                }}
                                                            >
                                                                {
                                                                    data?.value
                                                                        ?.labels[0]
                                                                }
                                                            </Typography>
                                                            <Typography
                                                                variant="body1"
                                                                sx={{
                                                                    color: colors[
                                                                        index
                                                                    ],
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                {
                                                                    data?.value
                                                                        ?.data[0]
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={4}
                                                        >
                                                            <Typography
                                                                variant="subtitle2"
                                                                sx={{
                                                                    color: '#808080',
                                                                }}
                                                            >
                                                                {
                                                                    data?.value
                                                                        ?.labels[2]
                                                                }
                                                            </Typography>
                                                            <Typography
                                                                variant="body1"
                                                                sx={{
                                                                    color: colors[
                                                                        index
                                                                    ],
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                {
                                                                    data?.value
                                                                        ?.data[2]
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={4}
                                                        >
                                                            <Typography
                                                                variant="subtitle2"
                                                                sx={{
                                                                    color: '#808080',
                                                                }}
                                                            >
                                                                {
                                                                    data?.value
                                                                        ?.labels[1]
                                                                }
                                                            </Typography>
                                                            <Box
                                                                sx={{
                                                                    backgroundColor:
                                                                        colors[
                                                                            index
                                                                        ],
                                                                    borderRadius:
                                                                        '10px',
                                                                    padding:
                                                                        '10px',
                                                                    display:
                                                                        'flex',
                                                                    justifyContent:
                                                                        'center',
                                                                    alignItems:
                                                                        'center',
                                                                    color: '#fff',
                                                                    fontWeight: 700,
                                                                    fontSize:
                                                                        '2rem',
                                                                }}
                                                            >
                                                                {
                                                                    data?.value
                                                                        ?.data[1]
                                                                }
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Paper>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        );
                    })}
                </>
            )}
        </Box>
    );
};

export default SchoolPerformanceSummary;
