import { Typography, Box, useTheme } from '@mui/material';

const Header = ({ title, subtitle }) => {
    const theme = useTheme();
    return (
        <Box mt="10px" padding="10px">
            <Typography
                variant="h6"
                color={theme.palette.primary.main}
                fontWeight="bold">
                {title}
            </Typography>
            <Typography
                variant="caption"
                sx={{
                    color: '#555',
                    fontWeight: 'bold',
                    mt: '5px',
                    mb: '10px',
                    fontSize: '1rem',
                }}>
                {subtitle}
            </Typography>{' '}
        </Box>
    );
};

export default Header;
