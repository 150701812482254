import { createSlice } from '@reduxjs/toolkit';
import { DISCOUNTS_TYPE } from '../../../service/const';

const initialState = {
    statusFilter: DISCOUNTS_TYPE.ALL,
};

const filtersSlice = createSlice({
    name: 'filters/discounts',
    initialState,
    reducers: {
        setStatusFilter(state, action) {
            state.statusFilter = action.payload;
        },
    },
});

export const { setStatusFilter } = filtersSlice.actions;

export const selectStatus_DiscountsFilter = (state) =>
    state.paymentsModule.discounts.ui.statusFilter;

export default filtersSlice.reducer;
