import React from 'react';
import { Box, Card, CardContent } from '@mui/material';
import { DataGrid, esES } from '@mui/x-data-grid';
import JustifyToolbar from './JustifyToolbar';
import Header from '../../../../../components/utilities/Header';
import NoDataOverlay from '../../../../../components/utilities/NoDataOverlay';
import { GridToolBar } from '../../../../../components/utilities/GridToolBar';
import CustomPagination from '../../../../../components/utilities/CustomPagination';

const SubjectJustifyTable = ({ rows, columns }) => {
    return (
        <>
            <Header
                title="Justificación de faltas"
                subtitle={`Aquí puedes ver las justificaciones de faltas de tus alumnos`}
            />
            <Card
                sx={{
                    color: 'white',
                    borderRadius: '10px',
                    width: '100%',
                    height: '100%',
                }}>
                <Box
                    sx={{
                        '& .partial-active--cell': {
                            backgroundColor: 'rgba(0, 0, 0, 0.05)',
                            color: '#1a3e72',
                            fontWeight: '600',
                        },
                    }}>
                    <DataGrid
                        localeText={
                            esES.components.MuiDataGrid.defaultProps.localeText
                        }
                        rows={rows}
                        columns={columns}
                        pageSize={20}
                        rowsPerPageOptions={[5]}
                        disableSelectionOnClick
                        autoHeight
                        components={{
                            NoRowsOverlay: NoDataOverlay,
                            NoResultsOverlay: NoDataOverlay,
                            Toolbar: GridToolBar,
                            Pagination: CustomPagination,
                        }}
                        componentsProps={{
                            panel: {
                                placement: 'bottom-end',
                            },
                            noResultsOverlay: {
                                message:
                                    'No se encontraron resultados para la búsqueda',
                            },
                            noRowsOverlay: {
                                message:
                                    'No hay Justificaciones de faltas para mostrar',
                            },
                        }}
                        disableDensitySelector
                    />
                </Box>
            </Card>
        </>
    );
};

export default SubjectJustifyTable;
