import { useState } from 'react';
import styled from '@emotion/styled';
import {
    Box,
    Checkbox,
    IconButton,
    Menu,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

/**
 * Componente para editar las calificaciones
 */
const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: 'rgba(0, 0, 0, 0.05)',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'rgba(0, 0, 0, 0.05)',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'rgba(0, 0, 0, 0.05)',
        },
        '&:hover fieldset': {
            borderColor: 'rgba(0, 0, 0, 0.05)',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'rgba(0, 0, 0, 0.05)',
        },
    },
});

/**
 * Componente destinado a permitir la edicion de una calificacion
 */
const ScoreEditableCell = ({
    defaultValue,
    description,
    in_recovery,
    add_description,
    subjectId,
    cell,
    scoreMinimum,
    onBlur,
    onChange,
    shouldShowCheckbox,
    isCheckboxEnabled,
    key,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    let style = {};

    const isRegularizationCell = /r-[0-9]+/.test(key);

    if (defaultValue != 0) {
        style =
            parseInt(defaultValue) < parseInt(scoreMinimum)
                ? {
                      borderColor: 'red',
                      borderStyle: 'solid',
                      borderWidth: 'thin',
                  }
                : {
                      borderColor: 'green',
                      borderStyle: 'solid',
                      borderWidth: 'thin',
                  };
    }

    /*
     * Manejo del click para el anchor del menu
     */
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    /*
     * Manejo del cerrado del menu
     */
    const handleClose = () => {
        setAnchorEl(null);
    };

    /*
     * Manejo del cambio de estado
     * de las calificación
     */
    const handleCalificationChange = (e) => {
        onChange({
            subjectId: subjectId,
            cell: cell,
            isCheckbox: false,
            isComment: false,
            value: e.target.value,
        })(e);
    };

    /*
     * Manejo del cambio de estado
     * del comentario de calificación
     */
    const handleCommentChange = (e) => {
        const comment = e.target.value.slice(0, 250);
        onChange({
            subjectId: subjectId,
            cell: cell,
            isCheckbox: false,
            isComment: true,
            value: comment,
        })(e);
    };

    /*
     * Manejo del cambio de estado
     * del checkbox de recuperación
     */
    const handleCheckboxChange = (e) => {
        onChange({
            subjectId: subjectId,
            cell: cell,
            isCheckbox: true,
            isComment: false,
            value: e.target.checked,
        })(e);
    };

    /*
     * Función que evita que al presionar
     * las teclas "r" y "c" se pierda focus
     * de los inputs
     */
    const handleKeyDown = (e) => {
        e.stopPropagation();
    };

    /*
     * Función que hace el salto de linea
     * en el comentario mostrado en el menu
     */
    const splitTextIntoLines = (text, wordsPerLine) => {
        const words = text.split(' '); // Dividir el texto en palabras
        const lines = [];

        for (let i = 0; i < words.length; i += wordsPerLine) {
            lines.push(words.slice(i, i + wordsPerLine).join(' '));
        }

        return lines;
    };

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    width: '100%',
                    gap: '8px',
                }}
            >
                <CssTextField
                    id="outlined-basic"
                    key={key}
                    variant="outlined"
                    margin="dense"
                    size="small"
                    fullWidth
                    sx={{ ...style }}
                    value={defaultValue}
                    onChange={handleCalificationChange}
                    onBlur={(e) =>
                        onBlur({
                            subjectId: subjectId,
                            cell: cell,
                            isCheckbox: false,
                            isComment: false,
                            value: e.target.value,
                            in_recovery,
                        })(e)
                    }
                />

                <IconButton
                    onClick={handleClick}
                    sx={{
                        padding: '4px',
                        height: '40px',
                    }}
                >
                    <MoreVertIcon />
                </IconButton>
            </div>

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem disableRipple>
                    <Typography sx={{ pl: 1 }} variant="p">
                        {splitTextIntoLines(description, 3).map(
                            (line, index) => (
                                <div key={index}>{line}</div>
                            )
                        )}
                    </Typography>
                </MenuItem>
                <MenuItem disableRipple>
                    <Box display="flex" flexDirection="column">
                        <TextField
                            id="comment-input"
                            label="Comentario"
                            variant="outlined"
                            value={add_description}
                            multiline
                            onChange={handleCommentChange}
                            onKeyDown={handleKeyDown}
                            inputProps={{
                                maxLength: description
                                    ? 250 - description.length
                                    : 250,
                            }}
                        />
                        <Typography variant="caption" color="textSecondary">
                            {description
                                ? `${add_description.length}/${
                                      250 - description.length
                                  }`
                                : `${add_description.length}/250`}
                        </Typography>
                    </Box>
                </MenuItem>
                {!isRegularizationCell && shouldShowCheckbox && (
                    <MenuItem disableRipple>
                        <Checkbox
                            id="recovery-checkbox"
                            checked={in_recovery}
                            onChange={handleCheckboxChange}
                            disabled={isCheckboxEnabled}
                        />
                        <Typography>Recuperación</Typography>
                    </MenuItem>
                )}
            </Menu>
        </>
    );
};

export default ScoreEditableCell;
