import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    useHistory,
    useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import { useAuth } from '../../../../hooks';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import { Error } from '../../../../components/Feedback';
import SkeletonPage from './../../../components/SkeletonPage';
import ClassroomGroupPreviewCard from '../../../components/ClassroomGroupPreviewCard';
import GroupAssistanseTable from './components/GroupAssistanseTable';
import { selectStudentsByGroup } from '../../../../store/slices/entities/groups';
import {
    fetchClassroomGroupData,
    invalidate,
    selectAtendenceForGroup,
    selectClassroomGroupDetails,
    selectClassroomViewmodelFetchStatusById,
} from '../../../store/meGroups/classrooms';
import { DateTime } from 'luxon';
import SubjectModal from './components/SubjectModal';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ExportAssistanceModal from './components/ExportAssistanceModal';

const ClassroomGroupAssistance = () => {
    ////////// LOCAL STATE //////////
    const [openSubjectModal, setOpenSubjectModal] = useState(false);
    const currentDate = DateTime.local().toFormat('yyyy-MM-dd');
    const [openExportModal, setOpenExportModal] = useState(false);
    const [viewSubject, setViewSubject] = useState('');
    const [selectedTitle, setSelectedTitle] = useState(null);
    const [reportData, setReportData] = useState({
        report: null,
        title: null,
        query: { group_id: null },
    });
    ////////// HOOKS //////////
    let { id } = useParams();
    const Auth = useAuth();
    const dispatch = useDispatch();
    const history = useHistory();

    const assists = useSelector(selectAtendenceForGroup(id, currentDate));

    const { assistancePerformed, assistsToday, students, group, subjects } =
        assists;

    ////////// SHARED STATE //////////

    const classroomDetails = useSelector(
        selectClassroomGroupDetails(id, Auth.user.school_id)
    );

    const statusServer = useSelector(
        selectClassroomViewmodelFetchStatusById(id)
    );

    const studentsInMainGroup = useSelector(
        selectStudentsByGroup(classroomDetails.group.group_id)
    );

    ////////// HANDLERS //////////

    const reload = () => {
        dispatch(
            fetchClassroomGroupData({
                groupId: classroomDetails.group.group_id,
                schoolId: classroomDetails.school.school_id,
                userId: Auth.user.user_id,
                currentStudents: studentsInMainGroup,
                uuid: classroomDetails.viewmodel.uuid,
            })
        );
    };

    /**
     * UseEffect que ejecuta el proceso
     * de recuperación de datos
     */
    useEffect(() => {
        reload();
    }, []);

    /**
     * Invalida la UI
     */
    const invalidateUI = () => {
        dispatch(invalidate(id));
        reload();
    };
    /*
     * Función que abre el modal
     * de exportaciones
     */
    const handlerExport = (exportOptions) => {
        const { id, title, report } = exportOptions;
        const pre = {
            id,
            title,
            report,
            query: {
                // subject_id: subject.subject_id,
                group_id: group.group_id,
            },
        };

        setSelectedTitle(title);
        setReportData(pre);
        setOpenExportModal(true);
    };

    /*
     * Función que se encarga
     * de cerrar el modal
     */
    const handlerCloseExportableModal = () => {
        setOpenExportModal(false);
    };

    const getCurrentDate = () => {
        const fecha = DateTime.local();
        const formattedDate = fecha
            .setLocale('es')
            .toFormat("dd 'de' LLLL yyyy");
        return formattedDate;
    };

    /*
     * Función que se encarga
     * de cerrar el modal
     */
    const handlerCloseSubjectModal = () => {
        setOpenSubjectModal(false);
    };

    /*
     * Función que te redirecciona
     * a la vista de registrar calificaciones
     */
    const handleSubjectModal = () => {
        setViewSubject('scores');
        if (subjects.length === 1) {
            history.push({
                pathname: `/inicio-maestros/classroom-group/${id}/scores/${subjects[0].subject_id}`,
            });
        } else {
            setOpenSubjectModal(true);
        }
    };

    /*
     * Función que te redirecciona
     * a la vista de justificación de faltas
     */
    const handlerJustifyAssistantPage = () => {
        history.push({
            pathname: `/inicio-maestros/classroom-group/${id}/justify`,
        });
    };

    return (
        <Box
            sx={{
                flexGrow: 1,
                paddingTop: {
                    xs: 1,
                    sm: 2,
                    md: 2,
                },
                paddingLeft: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingRight: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingBottom: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
            }}>
            {statusServer === 'pending' && <SkeletonPage />}

            {statusServer === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}
            {statusServer === 'fulfilled' && (
                <>
                    <Box
                        sx={{
                            marginBottom: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}>
                        <Box>
                            <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                sx={{ marginRight: 1 }}
                                onClick={handlerJustifyAssistantPage}
                                startIcon={<AssignmentTurnedInIcon />}>
                                Justificación de Faltas
                            </Button>
                            <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                onClick={handleSubjectModal}
                                startIcon={<CalendarTodayIcon />}>
                                Registrar calificación
                            </Button>
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                            }}>
                            <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                sx={{ marginRight: 1 }}
                                onClick={() =>
                                    handlerExport({
                                        id: group.subject_id,
                                        title: 'Asistencias',
                                        report: 'subject-assists',
                                    })
                                }
                                startIcon={<FileDownloadIcon />}>
                                Exportar asistencias
                            </Button>
                            <Tooltip title="Sincronizar información">
                                <Button
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    onClick={invalidateUI}
                                    startIcon={<SyncIcon />}>
                                    Sincronizar
                                </Button>
                            </Tooltip>
                        </Box>
                    </Box>
                    <ClassroomGroupPreviewCard classroom={classroomDetails} />
                    <GroupAssistanseTable classroom={id} />

                    {openSubjectModal && (
                        <SubjectModal
                            open={openSubjectModal}
                            onClose={handlerCloseSubjectModal}
                            subjects={subjects}
                            type={viewSubject}
                            setType={setViewSubject}
                            uuid={id}
                        />
                    )}

                    {openExportModal && (
                        <ExportAssistanceModal
                            open={openExportModal}
                            data={reportData}
                            selectedTitle={selectedTitle}
                            subjects={subjects}
                            onClose={handlerCloseExportableModal}
                        />
                    )}
                </>
            )}
        </Box>
    );
};

export default ClassroomGroupAssistance;
