import React, { useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    Typography,
    TextField,
    Divider,
    Avatar,
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    Box,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { selectStudentById } from '../../store/slices/entities/students';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { LoadingButton } from '@mui/lab';
import { useAuth } from '../../hooks';
import { selectSettingBySchoolId } from '../../store/slices/entities/settings';
import useFeedback from '../../hooks/useFeedback';
import { useEffect } from 'react';
import { deleteStudent } from '../../store/slices/groupsUI/operationsSlice';
import Exportable from '../../../src/service/Exportable';
import { saveAs } from 'file-saver';
import Services from '../../service/Connection';
import Feedback from '../../service/Feedback';
import { SkeletonDeleteStudentModal } from '../../../src/views/Alumnos/components/SkeletonPage';

/**
 * Modal para dar de baja un alumno
 *
 * @param {*} param0
 *
 * @returns
 */
const DeleteStudentModal = ({ open, data, setOpen, studentId }) => {
    const dispatch = useDispatch();
    const feedbackApp = useFeedback();

    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id;

    //////////// SHARE STATE /////////////

    const student = useSelector(selectStudentById(studentId));
    const config = useSelector(selectSettingBySchoolId(schoolId));
    const [statusOperation, setStatusOperation] = useState('idle'); //useSelector(selectDeleteStudent)

    let titleBarBackground = config.find(
        (res) => res.key === 'theme-color-title-bar'
    );
    let fontColor = config.find((res) => res.key === 'theme-color-font');

    //////////// LOCAL STATE /////////////////

    const [password, setPassword] = useState('');

    const [categories] = useState([
        {
            id: 2,
            title: 'Reprobación',
        },
        {
            id: 3,
            title: 'Deserción',
        },
        // {
        //     id: 4,
        //     title: 'Expulsión',
        // },
        {
            id: 5,
            title: 'Adicciones',
        },
        {
            id: 6,
            title: 'Defunción',
        },
        {
            id: 7,
            title: 'Matrimonio',
        },
        {
            id: 8,
            title: 'Desintegración familiar',
        },
        {
            id: 9,
            title: 'Problemas de personalidad',
        },
        {
            id: 10,
            title: 'Situación económica desfavorable',
        },
        {
            id: 11,
            title: 'Traslado',
        },
        {
            id: 1,
            title: 'Otro',
        },
    ]);

    const [parents, setParents] = useState([]);
    const [parentsRequest, setParentsRequest] = useState([]);
    const [directors, setDirectors] = useState([]);
    const [directorsRequest, setDirectorsRequest] = useState('');

    const [category, setCategory] = useState('');
    const [description, setDescription] = useState('');
    const [downloading, setDownloading] = useState('');

    //////////////////// ACTIONS /////////////////

    const handleParentsRequestChange = (event) => {
        const selectedValue = event.target.value;
        setParentsRequest(selectedValue);
    };

    const handleDirectorsRequestChange = (event) => {
        const selectedValue = event.target.value;
        setDirectorsRequest(selectedValue);
    };

    useEffect(() => {
        const fetchDirectors = async () => {
            try {
                if (schoolId) {
                    const response =
                        await Services.getDirectorsBySchool(schoolId);
                    const allDirectors = response.data.data;
                    const activeDirectors = allDirectors.filter(
                        (director) => director.status === 1
                    );
                    setDirectors(activeDirectors);
                }
            } catch (error) {
                console.error('Error al obtener directores:', error);
            }
        };

        fetchDirectors();
    }, [schoolId]);

    useEffect(() => {
        const fetchParents = async () => {
            try {
                if (studentId) {
                    const response =
                        await Services.getParentsByStudent(studentId);
                    setParents(response.data.data);
                }
            } catch (error) {
                console.error('Error al obtener los padres:', error);
            }
        };

        fetchParents();
    }, [open]);

    /**
     *  No cerrar click afuera del modal
     */
    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        setDirectorsRequest('');
        setParentsRequest('');
        setOpen(false);
    };

    /**
     * Funcion para dar de baja al alumno
     */
    const enviarDatos = async (format) => {
        setStatusOperation('pending');
        let FeedbackService = new Feedback();

        const isTransfer = category === 11;
        const parent_id = parentsRequest;
        const director_id = directorsRequest;

        const unenrollmentData = {
            source: 'download',
            report: 'proof-student-unenrollment',
            title: 'Constancia Memorandum para baja',
            query: {
                student_id: student.student_id,
                school_id: schoolId,
                parent_id: parent_id,
                director_id: director_id,
            },
            format: 'pdf',
        };

        setDownloading(format);

        const handleSuccess = async () => {
            try {
                let unenrollmentExportData = await Exportable.export(
                    unenrollmentData.report,
                    unenrollmentData.format,
                    unenrollmentData.query
                );
                const ext = format === 'pdf';
                exportToFile(
                    unenrollmentExportData.data,
                    'Constancia Memorandum para baja'
                );
                feedbackApp.showFeedback({
                    title: 'Baja completada exitosamente',
                    severity: 'success',
                });
                handleClose();
            } catch (err) {
                feedbackApp.showFeedback({
                    title: FeedbackService.getMessage(err).title,
                });
            }
        };

        const handleError = (err) => {
            const errorMessage =
                err.feedback?.title || FeedbackService.getMessage(err).title;
            feedbackApp.showFeedback({
                title: errorMessage,
                severity: 'error',
                message: errorMessage.includes('contraseña')
                    ? 'Por favor, verifique sus campos.'
                    : errorMessage,
            });
        };

        if (isTransfer) {
            let transferExportData = await Exportable.export(
                'proof-student-transfer',
                'pdf',
                unenrollmentData.query
            );

            try {
                await dispatch(
                    deleteStudent({
                        studentId,
                        password,
                        category,
                        description,
                    })
                ).unwrap();

                exportToFile(transferExportData.data, 'Constancia de traslado');
                await handleSuccess();
            } catch (err) {
                handleError(err);
            }
        } else {
            try {
                await dispatch(
                    deleteStudent({
                        studentId,
                        password,
                        category,
                        description,
                    })
                ).unwrap();

                await handleSuccess();
            } catch (err) {
                handleError(err);
            }
        }
        setStatusOperation('fullfilled');
    };

    /**
     * Exportar el archivo
     *
     * @param {*} blob
     * @param {*} filename
     */
    function exportToFile(blob, filename) {
        try {
            saveAs(blob, filename);
        } catch (err) {
            throw err;
        }
    }

    /**
     * Actualizacion de los inputs de formulario de nombre de usuario
     *
     * @param {*} event
     */
    const onInputChange = (event) => {
        const value = event.target.value;
        //const name = event.target.name;

        setPassword(value);
    };

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const setOpenState = () => {
            if (!open) {
                setPassword('');
                setCategory('');
                setDescription('');
            } else {
                setLoading(true);
                setTimeout(() => {
                    setLoading(false);
                }, 2000);
            }
        };
        setOpenState();
    }, [open]);

    /**
     * Escucha eventos del teclado en input de contraseña
     *
     * @param {*} event
     */
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            enviarDatos();
        }
    };

    //////////////////////// VALIDATIONS ////////////////////

    const passwordValid = () => {
        return {
            invalid: password == '',
            message: 'Campo requerido',
        };
    };

    const descriptionValid = () => {
        return {
            invalid: description == '',
            message: 'Campo requerido',
        };
    };

    const principalValid = () => {
        return {
            invalid: directorsRequest == '',
            message: 'Campo requerido',
        };
    };

    const categoryValid = () => {
        return {
            invalid: category == '',
            message: 'Campo requerido',
        };
    };

    return (
        <Box>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={'sm'}
                fullWidth={true}
                disableEscapeKeyDown={statusOperation == 'pending'}>
                <DialogTitle
                    id="alert-dialog-title"
                    style={{
                        backgroundColor: titleBarBackground.value,
                        color: fontColor.value,
                    }}>
                    <Typography
                        variant="h6"
                        component="div"
                        style={{ color: fontColor.value }}>
                        Dar de baja
                        <br />
                        <span style={{ fontSize: '15px' }}>
                            El alumno será dado de baja de la escuela
                        </span>
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    {loading ? (
                        <SkeletonDeleteStudentModal />
                    ) : (
                        <>
                            <Stack
                                direction={'column'}
                                justifyContent={'flex-start'}
                                alignItems={'center'}
                                sx={{ mt: 1, mb: 1 }}>
                                <Avatar
                                    alt={student?.name}
                                    src={student?.url_photo_profile}
                                    sx={{ width: 74, height: 74, mb: 1 }}
                                />

                                <Typography variant="h5">
                                    {student?.student_id} - {student?.name}{' '}
                                    {student?.last_name}{' '}
                                    {student?.second_last_name}
                                </Typography>
                            </Stack>
                            <FormControl
                                variant="standard"
                                sx={{ marginTop: 1 }}
                                fullWidth
                                error={categoryValid().invalid}>
                                <InputLabel id="demo-simple-select-label">
                                    Seleccionar el motivo de baja
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={category}
                                    onChange={(e) =>
                                        setCategory(e.target.value)
                                    }
                                    size="small">
                                    {categories.map((drop) => (
                                        <MenuItem value={drop.id}>
                                            {drop.title}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {categoryValid().invalid && (
                                    <FormHelperText>
                                        {categoryValid().message}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <FormControl
                                variant="standard"
                                sx={{ marginTop: 2 }}
                                fullWidth>
                                <InputLabel id="requester-label">
                                    ¿Quién lo solicita?
                                </InputLabel>
                                <Select
                                    labelId="requester-label"
                                    id="requester"
                                    value={parentsRequest}
                                    onChange={handleParentsRequestChange}>
                                    <MenuItem value="" />
                                    {parents.map((parent) => (
                                        <MenuItem
                                            key={parent.parent_id}
                                            value={parent.parent_id}>
                                            {`${parent.name} ${parent.last_name}`}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl
                                variant="standard"
                                sx={{ marginTop: 2 }}
                                fullWidth
                                error={principalValid().invalid}>
                                <InputLabel id="directors-request-label">
                                    Director en turno
                                </InputLabel>
                                <Select
                                    labelId="directors-request-label"
                                    id="directors-request"
                                    value={directorsRequest}
                                    onChange={handleDirectorsRequestChange}>
                                    <MenuItem value="" />
                                    {directors.map((director) => (
                                        <MenuItem
                                            key={director.user_id}
                                            value={director.user_id}>
                                            {`${director.name} ${director.last_name}`}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {principalValid().invalid && (
                                    <FormHelperText>
                                        {principalValid().message}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <TextField
                                id="outlined-multiline-static"
                                label="Descripción"
                                variant="outlined"
                                size="small"
                                multiline
                                rows={4}
                                fullWidth
                                sx={{ marginTop: 2 }}
                                inputProps={{ maxLength: 250 }}
                                value={description}
                                error={descriptionValid().invalid}
                                helperText={
                                    descriptionValid().invalid
                                        ? descriptionValid().message
                                        : ` Caracteres disponibles: ${description.length}/250`
                                }
                                onChange={(e) => setDescription(e.target.value)}
                            />

                            <Divider sx={{ marginTop: 2 }}>
                                Por seguridad ingrese su contraseña
                            </Divider>
                            <TextField
                                autoFocus
                                margin="dense"
                                size="small"
                                id="name"
                                label="Contraseña"
                                fullWidth
                                variant="outlined"
                                type="password"
                                value={password}
                                onChange={onInputChange}
                                error={passwordValid().invalid}
                                helperText={
                                    passwordValid().invalid
                                        ? passwordValid().message
                                        : false
                                }
                                onKeyDown={handleKeyDown}
                            />
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        size="small"
                        color="error"
                        onClick={enviarDatos}
                        loading={statusOperation == 'pending'}
                        loadingPosition="start"
                        startIcon={<DeleteOutlineIcon />}
                        variant="contained"
                        disabled={
                            passwordValid().invalid ||
                            descriptionValid().invalid ||
                            principalValid().invalid ||
                            categoryValid().invalid
                        }>
                        Dar de baja
                    </LoadingButton>
                    <Button
                        onClick={handleClose}
                        autoFocus
                        variant="contained"
                        size="small"
                        disabled={statusOperation == 'pending'}>
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default DeleteStudentModal;
