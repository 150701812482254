import React from 'react';
import { IconButton, Tooltip, Button, Chip, Box } from '@mui/material';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import AddIcon from '@mui/icons-material/Add';
import {
    GridPreferencePanelsValue,
    GridToolbarQuickFilter,
    useGridApiContext,
} from '@mui/x-data-grid';
import { ReactComponent as WatchIcon } from '../../assets/icons/delete-trash-svgrepo-com.svg';

import { ReactComponent as FilterIcon } from '../../assets/icons/filter-svgrepo-com.svg';
import { BookmarkAddRounded } from '@mui/icons-material';
import { PopConfirm } from '../Tooltips/PopConfirm';
import DeleteSweepTwoToneIcon from '@mui/icons-material/DeleteSweepTwoTone';


export const GridToolBar = ({
    onAddButtonClick,
    ButtonText,
    onCatalogButtonClick,
    CatalogButtonText,
    massActions = [],
    tabs = [],
}) => {
    const apiRef = useGridApiContext();

    const handleFilterClick = () => {
        apiRef.current.showFilterPanel();
    };

    const handleColumnsClick = () => {
        apiRef.current?.showPreferences(GridPreferencePanelsValue.columns);
    };

    const handleExportClick = () => {
        apiRef.current.exportDataAsCsv({
            utf8WithBom: true,
        });
    };

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginLeft: '10px',
                    }}>
                    <div>
                        {CatalogButtonText && (
                            <Button
                                variant="contained"
                                size="small"
                                startIcon={<BookmarkAddRounded />}
                                onClick={onCatalogButtonClick}
                                sx={{ marginRight: 1, borderRadius: '10px' }}>
                                {CatalogButtonText}
                            </Button>
                        )}
                        <Button
                            variant="contained"
                            size="small"
                            startIcon={<AddIcon />}
                            onClick={onAddButtonClick}
                            sx={{
                                borderRadius: '10px',
                            }}>
                            {ButtonText}
                        </Button>
                    </div>
                </div>
                <div
                    style={{
                        marginRight: '10px',
                    }}>
                    <GridToolbarQuickFilter />
                    <Tooltip title="Filtrar" placement="top" arrow>
                        <IconButton onClick={handleFilterClick}>
                            <FilterIcon width={20} height={20} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Columnas" placement="top" arrow>
                        <IconButton onClick={handleColumnsClick}>
                            <ViewColumnIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Exportar" placement="top" arrow>
                        <IconButton onClick={handleExportClick}>
                            <SaveAltIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    paddingTop: '10px', justifyContent: 'space-between',

                }}>
                <div>
                    {massActions.map((action, index) => {

                      
                        if (action.tooltip === "Cancelar") {
                            return (
                                <PopConfirm
                                    key={index} 
                                    title="¿Estás seguro de que deseas continuar?"
                                    message="No podrás recuperar este registro después de eliminarlo."
                                    icon={WatchIcon}
                                    IconButton={DeleteSweepTwoToneIcon} 
                                    textButtonConfirm="Eliminar"
                                    textButtonCancel="Cancelar"
                                    color={action.color}  
                                    iconSize={50}
                                    messageButton={'Eliminar'}
                                    isDisabled={action.disabled}
                                    onConfirm={action.onClick}  
                                    placement='right'
                                    muiButtonStyles={{
                                        backgroundColor: '#dedede44',
                                        borderRadius: '10px 10px 0px 0px',
                                        borderTop: '2px solid rgba(224, 224, 224, 1)',
                                        borderLeft: '2px solid rgba(224, 224, 224, 1)',
                                        borderRight: '2px solid rgba(224, 224, 224, 1)',
                                    }}
                                    positionContainer='relative'
                                   topIcon= '-10%'
                                />
                            );
                        }

                        return (
                            <Button
                                key={index}
                                size="small"
                                variant="text"
                                disableElevation
                                disabled={action.disabled}
                                onClick={action.onClick}
                                sx={{
                                    fontWeight: 'bold',
                                    color: action.color,
                                    backgroundColor: '#dedede44',
                                    borderRadius: '10px 10px 0px 0px',
                                    borderTop: '2px solid rgba(224, 224, 224, 1)',
                                    borderLeft: '2px solid rgba(224, 224, 224, 1)',
                                    borderRight: '2px solid rgba(224, 224, 224, 1)',
                                }}
                                startIcon={action.icon}
                            >
                                {action.tooltip}
                            </Button>
                        );
                    })}
                </div>

                {tabs.length > 0 && (
                    <Box sx={{ display: 'flex', gap: '10px' }}>
                        {tabs.map((tab, index) => (
                            <Chip
                                key={index}
                                label={tab.label}
                                sx={{
                                    color:
                                        tab.chips === tab.value
                                            ? '#040404'
                                            : '#fff',
                                    backgroundColor:
                                        tab.chips === tab.value
                                            ? '#dedede44'
                                            : tab.color,
                                    borderTop:
                                        '2px solid rgba(224, 224, 224, 1)',
                                    borderLeft:
                                        '2px solid rgba(224, 224, 224, 1)',
                                    borderRight:
                                        '2px solid rgba(224, 224, 224, 1)',
                                    borderRadius: '10px 10px 0px 0px',
                                }}
                                onClick={tab.onClick}
                            />
                        ))}
                    </Box>
                )}
            </div>
        </div>
    );
};
