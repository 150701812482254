import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Card, CardContent, Box, Tooltip } from '@mui/material';
import { DataGrid, esES } from '@mui/x-data-grid';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectLagAssists,
} from '../../../store/professors/fetchSlice';
import { getLagAssists } from '../../../store/professors/thunks';
import { SkeletonProfessorsSmallTables } from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';
import { selectComponentProfessorsLagAssistsStatusServer } from '../../../store/professors/componentsStatusSelectors';
import { Tag } from 'antd';
import NoDataOverlay from '../../../../components/utilities/NoDataOverlay';
import CustomPagination from '../../../../components/utilities/CustomPagination';
import { GridToolBar } from '../../../../components/utilities/GridToolBar';

const COLUMN_STYLES = {
    defaultWidth: 200,
    assistanceWidth: 150,
    professorAlign: 'left',
    assistanceAlign: 'center',
};

const renderersByType = {
    string: (params) => (
        <Tooltip title={params.value}>
            <Typography noWrap variant="body2" style={{ width: '100%' }}>
                {params.value}
            </Typography>
        </Tooltip>
    ),
    number: (params) => (
        <Tag
            color="error"
            style={{
                width: COLUMN_STYLES.assistanceWidth,
                textAlign: COLUMN_STYLES.assistanceAlign,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'normal',
                wordBreak: 'break-word',
            }}
        >
            {params.value || '0'}
        </Tag>
    ),
};

const LagAssists = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentProfessorsLagAssistsStatusServer
    );

    const lagAssists = useFetchResource(
        () =>
            getLagAssists({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectLagAssists,
        [cycleSelected, schoolId]
    );

    const generateColumns = (data) =>
        data
            ? Object.keys(data).map((key) => ({
                  field: key,
                  headerName: key === 'Nombre' ? 'Profesor' : key,
                  flex: 1,
                  headerAlign: 'center',
                  align: 'center',
                  renderCell:
                      typeof data[key][0]?.value === 'string'
                          ? renderersByType.string
                          : renderersByType.number,
              }))
            : [];

    const generateRows = (data) =>
        data?.Nombre?.map((_, index) => {
            const row = { id: index + 1 };
            Object.keys(data).forEach((key) => {
                row[key] = data[key][index]?.value;
            });
            return row;
        }) || [];

    const columns = generateColumns(lagAssists?.value);
    const rows = generateRows(lagAssists?.value);

    const reload = () => {
        dispatch(
            getLagAssists({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            })
        );
    };

    return (
        <Box sx={{ width: '100%', overflowX: 'hidden' }}>
            {fetchingStatus === 'pending' && (
                <SkeletonProfessorsSmallTables items={9} />
            )}

            {fetchingStatus === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {fetchingStatus === 'fulfilled' && (
                <Card
                    sx={{
                        borderRadius: '10px',
                        boxShadow: 'none',
                        maxWidth: '100%',
                        margin: '20px auto',
                        padding: '16px',
                        overflow: 'visible',
                        position: 'relative',
                        mt: -4,
                    }}
                >
                    <CardContent sx={{ width: '100%', padding: '24px' }}>
                        <Box sx={{ height: 704, width: '100%' }}>
                            <DataGrid
                                localeText={{
                                    ...esES.components.MuiDataGrid.defaultProps
                                        .localeText,
                                    toolbarColumns: '',
                                    toolbarFilters: '',
                                    toolbarDensity: '',
                                    toolbarExport: '',
                                }}
                                columns={columns}
                                rows={rows}
                                rowsPerPageOptions={[10]}
                                disableDensitySelector
                                components={{
                                    NoRowsOverlay: NoDataOverlay,
                                    NoResultsOverlay: NoDataOverlay,
                                    Toolbar: GridToolBar,
                                    Pagination: CustomPagination,
                                }}
                                componentsProps={{
                                    panel: {
                                        placement: 'bottom-end',
                                    },
                                    noResultsOverlay: {
                                        message:
                                            'No se encontraron resultados para la búsqueda',
                                    },
                                    noRowsOverlay: {
                                        message: 'No hay datos disponibles',
                                    },
                                }}
                                sx={{
                                    '&.MuiDataGrid-root': {
                                        border: 'none',
                                    },
                                    '& .MuiDataGrid-cell': {
                                        whiteSpace: 'normal',
                                        wordWrap: 'break-word',
                                    },
                                }}
                            />
                        </Box>
                    </CardContent>
                </Card>
            )}
        </Box>
    );
};

export default LagAssists;
