import { Box, Card, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './styles.scss';
import { TopStudentsDiscount } from './Components/TopStudentsDiscount';
import TabsComponent from '../../components/tabs';
import Skeleton from '@mui/material/Skeleton';
import { useDispatch, useSelector } from 'react-redux';

import { useAuth, useFeedback } from '../../../hooks';
import { useHistory } from 'react-router-dom';
import ErrorPage from '../../components/Error/ErrorPage';
import {
    invalidate_lastStudentWithDiscount,
    selectCardStatus_Discount_Student,
    loadUILastStudentWithDiscountCard,
} from '../../store/home/cards';
import WelcomeCard from './Components/WelcomeCard';
import GainsCard from './Components/GainsCard';
import ToPayCard from './Components/ToPayCard';
import {
    invalidate_Concepts_Without_Payments,
    invalidate_Total_Discounts_Lost,
    invalidate_Total_Gains,
    loadUIConceptsWithoutPayment,
    loadUITotalDiscountLost,
    loadUITotalGains,
    selectKpiStatus_Concepts_Without_Payment,
    selectKpiStatus_Total_Discounts_Lost,
    selectKpiStatus_Total_Gains,
} from '../../store/home/kpis';
import { Col, Layout, Row } from 'antd';
import TopTransactionsCard from './Components/TopTransactions';
import DiscountConceptsCard from './Components/DiscountConceptsCard';
import DebtorTable from './Components/DebtorTable';
import {
    invalidate_last_student_with_discount,
    invalidate_last_students_with_payments,
    invalidate_monthly_payments_count,
    loadUILastStudentsWithPayments,
    LoadUILastStudentWithDiscountTable,
    loadUIMonthlyPaymentsCount,
    selectTableStatus_last_student_with_discount_table,
    selectTableStatus_last_students_with_payments,
    selectTableStatus_monthly_payments_count,
} from '../../store/home/tables';
import {
    invalidate_Concepts_With_Discount,
    loadUIConceptsWithDiscount,
    selectChartsStatus_Concepts_With_Discount,
} from '../../store/home/charts';
import {
    invalidate_Top_Debtors,
    loadUITopDebtors,
    selectTopStatus_Top_Debtors,
} from '../../store/home/tops';

const DashboardPayments = () => {
    //////////////////// SHARE STATE ////////////////////
    const dispatch = useDispatch();
    const Auth = useAuth();
    const history = useHistory();
    const feedbackApp = useFeedback();

    //////////////////// SHARE STATE ////////////////////

    const statusServer_Last_Student_With_Discount_card = useSelector(
        selectCardStatus_Discount_Student
    );

    const StatusServer_Total_Discounts_Lost = useSelector(
        selectKpiStatus_Total_Discounts_Lost
    );
    const StatusServer_Total_Gains = useSelector(selectKpiStatus_Total_Gains);

    const StatusServer_Concepts_Without_Payment = useSelector(
        selectKpiStatus_Concepts_Without_Payment
    );

    const StatusServer_last_student_with_discount_table = useSelector(
        selectTableStatus_last_student_with_discount_table
    );

    const StatusServer_last_students_with_payments = useSelector(
        selectTableStatus_last_students_with_payments
    );

    const StatusServer_monthly_payments_count = useSelector(
        selectTableStatus_monthly_payments_count
    );

    const StatusServer_Concepts_With_Discount = useSelector(
        selectChartsStatus_Concepts_With_Discount
    );

    const StatusServer_Top_Debtors = useSelector(selectTopStatus_Top_Debtors);

    const getCombinedStatus = (statuses) => {
        if (statuses.some((status) => status === 'pending')) {
            return 'pending';
        }
        if (statuses.every((status) => status === 'fulfilled')) {
            return 'fulfilled';
        }
        return 'rejected';
    };

    const combinedStatus = getCombinedStatus([
        statusServer_Last_Student_With_Discount_card,
        StatusServer_Total_Discounts_Lost,
        StatusServer_Total_Gains,
        StatusServer_Concepts_Without_Payment,
        StatusServer_last_student_with_discount_table,
        StatusServer_last_students_with_payments,
        StatusServer_monthly_payments_count,
        StatusServer_Concepts_With_Discount,
        StatusServer_Top_Debtors,
    ]);

    //////////////////// USE EFFECT ////////////////////

    useEffect(() => {
        dispatch(loadUILastStudentWithDiscountCard(Auth.getUser().school_id));
        dispatch(loadUITotalDiscountLost(Auth.getUser().school_id));
        dispatch(loadUIConceptsWithoutPayment(Auth.getUser().school_id));
        dispatch(loadUITotalGains(Auth.getUser().school_id));
        dispatch(LoadUILastStudentWithDiscountTable(Auth.getUser().school_id));
        dispatch(loadUILastStudentsWithPayments(Auth.getUser().school_id));
        dispatch(loadUIMonthlyPaymentsCount(Auth.getUser().school_id));
        dispatch(loadUIConceptsWithDiscount(Auth.getUser().school_id));
        dispatch(loadUITopDebtors(Auth.getUser().school_id));
    }, [history]);

    //////////////////// FUNCTIONS ////////////////////

    const reload = () => {
        dispatch(loadUILastStudentWithDiscountCard(Auth.getUser().school_id));
        dispatch(loadUITotalDiscountLost(Auth.getUser().school_id));
        dispatch(loadUIConceptsWithoutPayment(Auth.getUser().school_id));
        dispatch(loadUITotalGains(Auth.getUser().school_id));
        dispatch(LoadUILastStudentWithDiscountTable(Auth.getUser().school_id));
        dispatch(loadUILastStudentsWithPayments(Auth.getUser().school_id));
        dispatch(loadUIMonthlyPaymentsCount(Auth.getUser().school_id));
        dispatch(loadUIConceptsWithDiscount(Auth.getUser().school_id));
        dispatch(loadUITopDebtors(Auth.getUser().school_id));
    };

    const reloadloadUILastStudentWithDiscountCard = () => {
        dispatch(loadUILastStudentWithDiscountCard(Auth.getUser().school_id))
            .unwrap()
            .then((response) => {})
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    const reloadloadUITotalDiscountLost = () => {
        dispatch(loadUITotalDiscountLost(Auth.getUser().school_id))
            .unwrap()
            .then((response) => {})
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    const reloadloadUIConceptsWithoutPayment = () => {
        dispatch(loadUIConceptsWithoutPayment(Auth.getUser().school_id))
            .unwrap()
            .then((response) => {})
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    const reloadloadUITotalGains = () => {
        dispatch(loadUITotalGains(Auth.getUser().school_id))
            .unwrap()
            .then((response) => {
                reloadloadUIMonthlyPaymentsCount();
            })
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    const reloadLoadUILastStudentWithDiscountTable = () => {
        dispatch(LoadUILastStudentWithDiscountTable(Auth.getUser().school_id))
            .unwrap()
            .then((response) => {})
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    const reloadloadUILastStudentsWithPayments = () => {
        dispatch(loadUILastStudentsWithPayments(Auth.getUser().school_id))
            .unwrap()
            .then((response) => {})
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    const reloadloadUIMonthlyPaymentsCount = () => {
        dispatch(loadUIMonthlyPaymentsCount(Auth.getUser().school_id))
            .unwrap()
            .then((response) => {})
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    const reloadloadUIConceptsWithDiscount = () => {
        dispatch(loadUIConceptsWithDiscount(Auth.getUser().school_id))
            .unwrap()
            .then((response) => {})
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    const reloadloadUITopDebtors = () => {
        dispatch(loadUITopDebtors(Auth.getUser().school_id))
            .unwrap()
            .then((response) => {})
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    const invalidateUI = () => {
        dispatch(invalidate_lastStudentWithDiscount());
        dispatch(invalidate_Concepts_Without_Payments());
        dispatch(invalidate_Total_Discounts_Lost());
        dispatch(invalidate_Total_Gains());
        dispatch(invalidate_last_student_with_discount());
        dispatch(invalidate_last_students_with_payments());
        dispatch(invalidate_monthly_payments_count());
        dispatch(invalidate_Concepts_With_Discount());
        dispatch(invalidate_Top_Debtors());
        reload();
    };

    return (
        <>
            <TabsComponent
                invalidateUI={invalidateUI}
                statusServer={combinedStatus}
            />

            <Box
                sx={{
                    paddingLeft: {
                        xs: 1,
                        sm: 2,
                        md: 5,
                    },
                    paddingRight: {
                        xs: 1,
                        sm: 2,
                        md: 5,
                    },
                    paddingBottom: {
                        xs: 1,
                        sm: 2,
                        md: 5,
                    },
                }}>
                <Row gutter={[32, 24]}>
                    <Col xs={24} xl={24}>
                        <WelcomeCard statusServer={combinedStatus} />
                    </Col>
                    <Col xs={24} xl={24}>
                        <Row gutter={[32, 24]}>
                            <Col xs={24} xl={8}>
                                {StatusServer_Total_Gains === 'pending' && (
                                    <Skeleton
                                        variant="rectangular"
                                        width="100%"
                                        height={'50em'}
                                        sx={{
                                            mb: 2,
                                            borderRadius: 2,
                                            boxShadow: 3,
                                        }}
                                    />
                                )}
                                {StatusServer_Total_Gains === 'rejected' && (
                                    <ErrorPage
                                        onRetry={reloadloadUITotalGains}
                                        maxHeight={'50em'}
                                        fontHeight={'1.2rem'}
                                        message={
                                            'Ocurrió un error ¿Podrías intentarlo una vez más? ¡Por favor! 🤞'
                                        }
                                    />
                                )}
                                {StatusServer_Total_Gains === 'fulfilled' && (
                                    <GainsCard />
                                )}
                            </Col>
                            <Col xs={24} xl={8}>
                                {StatusServer_Concepts_Without_Payment ===
                                    'pending' && (
                                    <Skeleton
                                        variant="rectangular"
                                        width="100%"
                                        height={'50em'}
                                        sx={{
                                            mb: 2,
                                            borderRadius: 2,
                                            boxShadow: 3,
                                        }}
                                    />
                                )}
                                {StatusServer_Concepts_Without_Payment ===
                                    'rejected' && (
                                    <ErrorPage
                                        onRetry={
                                            reloadloadUIConceptsWithoutPayment
                                        }
                                        fontHeight={'1.2rem'}
                                        maxHeight={'50em'}
                                        message={
                                            'Ocurrió un error ¿Podrías intentarlo una vez más? ¡Por favor! 🤞'
                                        }
                                    />
                                )}
                                {StatusServer_Concepts_Without_Payment ===
                                    'fulfilled' && <ToPayCard />}
                            </Col>
                            <Col xs={24} xl={8}>
                                {StatusServer_last_students_with_payments ===
                                    'pending' && (
                                    <Skeleton
                                        variant="rectangular"
                                        width="100%"
                                        height={'50em'}
                                        sx={{
                                            mb: 2,
                                            borderRadius: 2,
                                            boxShadow: 3,
                                        }}
                                    />
                                )}

                                {StatusServer_last_students_with_payments ===
                                    'rejected' && (
                                    <ErrorPage
                                        onRetry={
                                            reloadloadUILastStudentsWithPayments
                                        }
                                        maxHeight={'50em'}
                                        fontHeight={'1.2rem'}
                                        message={
                                            'Ocurrió un error ¿Podrías intentarlo una vez más? ¡Por favor! 🤞'
                                        }
                                    />
                                )}

                                {StatusServer_last_students_with_payments ===
                                    'fulfilled' && <TopTransactionsCard />}
                            </Col>
                        </Row>
                        <Row
                            gutter={[32, 24]}
                            style={{
                                paddingTop: '20px',
                                paddingBottom: '20px',
                            }}>
                            <Col xs={24} xl={16}>
                                {StatusServer_Concepts_With_Discount ===
                                    'pending' && (
                                    <Skeleton
                                        variant="rectangular"
                                        width="100%"
                                        height={'50em'}
                                        sx={{
                                            mb: 2,
                                            borderRadius: 2,
                                            boxShadow: 3,
                                        }}
                                    />
                                )}
                                {StatusServer_Concepts_With_Discount ===
                                    'rejected' && (
                                    <ErrorPage
                                        onRetry={
                                            reloadloadUIConceptsWithDiscount
                                        }
                                        maxHeight={'50em'}
                                        fontHeight={'1.2rem'}
                                        message={
                                            'Ocurrió un error ¿Podrías intentarlo una vez más? ¡Por favor! 🤞'
                                        }
                                    />
                                )}
                                {StatusServer_Concepts_With_Discount ===
                                    'fulfilled' && <DiscountConceptsCard />}
                            </Col>
                            <Col xs={24} xl={8}>
                                {StatusServer_last_student_with_discount_table ===
                                    'pending' && (
                                    <Skeleton
                                        variant="rectangular"
                                        width="100%"
                                        height={'50em'}
                                        sx={{
                                            mb: 2,
                                            borderRadius: 2,
                                            boxShadow: 3,
                                        }}
                                    />
                                )}
                                {StatusServer_last_student_with_discount_table ===
                                    'rejected' && (
                                    <ErrorPage
                                        onRetry={
                                            reloadLoadUILastStudentWithDiscountTable
                                        }
                                        maxHeight={'50em'}
                                        fontHeight={'1.2rem'}
                                        message={
                                            'Ocurrió un error ¿Podrías intentarlo una vez más? ¡Por favor! 🤞'
                                        }
                                    />
                                )}
                                {StatusServer_last_student_with_discount_table ===
                                    'fulfilled' && <TopStudentsDiscount />}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row
                    gutter={[32, 24]}
                    style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                    <Col xs={24} xl={24}>
                        {StatusServer_Top_Debtors === 'pending' && (
                            <Skeleton
                                variant="rectangular"
                                width="100%"
                                height={'50em'}
                                sx={{
                                    mb: 2,
                                    borderRadius: 2,
                                    boxShadow: 3,
                                }}
                            />
                        )}
                        {StatusServer_Top_Debtors === 'rejected' && (
                            <ErrorPage
                                onRetry={reloadloadUITopDebtors}
                                maxHeight={'43em'}
                                fontHeight={'1.2rem'}
                                message={
                                    'Ocurrió un error ¿Podrías intentarlo una vez más? ¡Por favor! 🤞'
                                }
                            />
                        )}
                        {StatusServer_Top_Debtors === 'fulfilled' && (
                            <DebtorTable />
                        )}
                    </Col>
                </Row>
            </Box>
        </>
    );
};

export default DashboardPayments;
