import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Typography,
    Card,
    CardContent,
    Tooltip,
    CircularProgress,
} from '@mui/material';
import {
    DataGrid,
    esES,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarExport,
    GridToolbarFilterButton,
} from '@mui/x-data-grid';
import useFetchResource from '../../../components/HelperHook';
import { useAuth } from '../../../../hooks';
import { getCycleSelected, selectImc } from '../../../store/groups/fetchSlice';
import { getImc } from '../../../store/groups/thunks';
import { selectComponentGroupsImcStatusServer } from '../../../store/groups/componentStatusSelectors';
import { SkeletonGroupsTables } from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';
import { Tag } from 'antd';
import NoDataOverlay from '../../../../components/utilities/NoDataOverlay';
import { GridToolBar } from '../../../../components/utilities/GridToolBar';
import CustomPagination from '../../../../components/utilities/CustomPagination';

const IMCSchoolCard = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(selectComponentGroupsImcStatusServer);

    const averageIMCSchool = useFetchResource(
        () =>
            getImc({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectImc,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getImc({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            })
        );
    };

    const headers = averageIMCSchool.value
        ? Object.keys(averageIMCSchool.value)
        : [];
    const data = averageIMCSchool.value || {};

    const rows = headers.length
        ? data['Grado']?.map((_, index) => {
              const row = { id: index };
              headers.forEach((header) => {
                  row[header] = data[header][index]?.value;
              });
              return row;
          }) || []
        : [];

    const columns = headers.map((header) => ({
        field: header,
        headerName: header,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
            if (header === 'Promedio IMC') {
                return (
                    <Tag
                        color={'blue'}
                        style={{
                            width: '100px',
                            textAlign: 'center',
                        }}
                    >
                        {params.value}
                    </Tag>
                );
            }
            return params.value;
        },
    }));

    const CustomToolbar = (props) => (
        <GridToolbarContainer sx={{ justifyContent: 'flex-end' }}>
            <GridToolbarColumnsButton {...props} />
            <GridToolbarFilterButton {...props} />
            <GridToolbarExport
                csvOptions={{
                    utf8WithBom: true,
                    delimiter: ',',
                }}
            />
        </GridToolbarContainer>
    );

    const renderDataGrid = () => (
        <Box sx={{ minHeight: 690, width: '100%', mt: 2 }}>
            <DataGrid
                localeText={{
                    ...esES.components.MuiDataGrid.defaultProps.localeText,
                    toolbarColumns: '',
                    toolbarFilters: '',
                    toolbarDensity: '',
                    toolbarExport: '',
                }}
                rows={rows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[]}
                disableSelectionOnClick
                disableDensitySelector
                components={{
                    NoRowsOverlay: NoDataOverlay,
                    NoResultsOverlay: NoDataOverlay,
                    Toolbar: GridToolBar,
                    Pagination: CustomPagination,
                }}
                componentsProps={{
                    panel: {
                        placement: 'bottom-end',
                    },
                    noResultsOverlay: {
                        message:
                            'No se encontraron resultados para la búsqueda',
                    },
                    noRowsOverlay: {
                        message: 'No hay datos disponibles',
                    },
                }}
                getRowHeight={() => 'auto'}
                sx={{
                    '& .MuiDataGrid-cell': {
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                        overflow: 'visible',
                        lineHeight: '3',
                    },
                    '&.MuiDataGrid-root': {
                        border: 'none',
                    },
                }}
                autoHeight
            />
        </Box>
    );

    return (
        <Box sx={{ width: '100%', overflowX: 'hidden' }}>
            {fetchingStatus === 'pending' && <SkeletonGroupsTables items={9} />}

            {fetchingStatus === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {fetchingStatus === 'fulfilled' && (
                <Card
                    sx={{
                        background: '#ffffff',
                        borderRadius: '10px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        maxWidth: '100%',
                        maxHeight: '100%',
                        margin: '20px auto',
                        padding: '16px',
                        overflow: 'visible',
                        position: 'relative',
                    }}
                >
                    <CardContent>
                        <Tooltip
                            title={
                                averageIMCSchool?.description ||
                                'Promedio de IMC en la escuela'
                            }
                            followCursor
                        >
                            <Typography
                                variant="h6"
                                gutterBottom
                                sx={{ color: '#333' }}
                            >
                                {averageIMCSchool?.title ||
                                    'Promedio de IMC por grupo'}
                            </Typography>
                        </Tooltip>
                        {averageIMCSchool?.loading ? (
                            <CircularProgress
                                sx={{
                                    margin: '20px',
                                    display: 'block',
                                    mx: 'auto',
                                }}
                            />
                        ) : averageIMCSchool?.error ? (
                            <Typography
                                color="error"
                                sx={{ textAlign: 'center' }}
                            >
                                Error: {averageIMCSchool?.error.message}
                            </Typography>
                        ) : (
                            renderDataGrid()
                        )}
                    </CardContent>
                </Card>
            )}
        </Box>
    );
};

export default IMCSchoolCard;
