import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, Typography, Grid, Paper } from '@mui/material';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectFrequentReports,
} from '../../../store/home/fetchSlice';
import { getFrequentReports } from '../../../store/home/thunks';
import { SkeletonCard } from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';
import { selectComponentHomeFrequentReportsStatusServer } from '../../../store/home/componentStatusSelectors';
import { Report } from '@mui/icons-material';
import { ErrorBoundary } from 'react-error-boundary';

const FrequentReportsCard = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentHomeFrequentReportsStatusServer
    );

    const frequentReport = useFetchResource(
        () =>
            getFrequentReports({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectFrequentReports,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getFrequentReports({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
            selectFrequentReports,
            [cycleSelected, schoolId]
        );
    };

    const value = frequentReport?.value;

    return (
        <ErrorBoundary>
            <Box>
                {fetchingStatus === 'pending' && <SkeletonCard items={9} />}

                {fetchingStatus === 'rejected' && (
                    <Error
                        onRetry={reload}
                        message={'Estamos teniendo problemas'}
                    />
                )}

                {fetchingStatus === 'fulfilled' && (
                    <Card
                        sx={{
                            background: '#f4035c',
                            borderRadius: '10px',
                            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                            padding: '15px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            maxWidth: '900px',
                            height: '270px',
                            margin: 'auto',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: '10px',
                            }}
                        >
                            <Report
                                style={{
                                    fontSize: 35,
                                    color: 'rgb(232, 247, 255)',
                                    marginRight: '10px',
                                    marginBottom: '10px',
                                }}
                            />
                            <Typography
                                variant="h6"
                                sx={{
                                    fontWeight: 700,
                                    color: '#fff',
                                    marginBottom: '10px',
                                    textAlign: 'center',
                                }}
                            >
                                {frequentReport?.title || 'Reportes Frecuentes'}
                            </Typography>
                        </Box>
                        <Paper
                            elevation={3}
                            sx={{
                                margin: '16px 0',
                                padding: '16px',
                                width: '100%',
                                backgroundColor: '#f1f1f1',
                                borderRadius: '10px',
                            }}
                        >
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={4}>
                                    <Typography
                                        variant="subtitle2"
                                        sx={{
                                            color: '#808080',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {value?.labels[0]}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography
                                        variant="subtitle2"
                                        sx={{
                                            color: '#808080',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {value?.labels[1]}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography
                                        variant="subtitle2"
                                        sx={{
                                            color: '#808080',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {value?.labels[2]}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={4}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            backgroundColor: '#f4035c',
                                            borderRadius: '10px',
                                            width: '200px',
                                            padding: '10px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            color: '#fff',
                                            fontWeight: 700,
                                            fontSize: '2rem',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {value?.data[0]}
                                    </Box>
                                </Grid>
                                <Grid
                                    item
                                    xs={4}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            backgroundColor: '#f4035c',
                                            borderRadius: '10px',
                                            width: '200px',
                                            padding: '10px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            color: '#fff',
                                            fontWeight: 700,
                                            fontSize: '2rem',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {value?.data[1]}
                                    </Box>
                                </Grid>
                                <Grid
                                    item
                                    xs={4}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            backgroundColor: '#f4035c',
                                            borderRadius: '10px',
                                            width: '200px',
                                            padding: '10px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            color: '#fff',
                                            fontWeight: 700,
                                            fontSize: '2rem',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {value?.data[2]}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Card>
                )}
            </Box>
        </ErrorBoundary>
    );
};

export default FrequentReportsCard;
