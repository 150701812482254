import React, { useEffect, useState } from 'react';
import { Box, Typography, Avatar, CircularProgress, Grow } from '@mui/material';
import { useAuth } from '../../../../hooks';
import male from '../images/male.png';
import female from '../images/female.png';
import useThemeColors from '../../../../theme/themes';
import WavingHand from '../../../assets/icons/waving-hand-svgrepo-com.svg';

const WelcomeCard = ({ statusServer }) => {
    ////////////////////////// SHARE STATE //////////////////////////
    const auth = useAuth();
    const user = auth.getUser();
    const colors = useThemeColors();
    const avatarSrc = user.gender === 'M' ? female : male;
    const genderText = user.gender === 'M' ? 'Bienvenida' : 'Bienvenido';

    ////////////////////////// LOCAL STATE //////////////////////////
    const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
    const [showMessage, setShowMessage] = useState(true);

    const messages = [
        'La educación financiera es clave para asegurar un futuro brillante.',
        'Invertir en conocimiento siempre da los mejores intereses.',
        'Cada paso hacia la mejora personal también es un paso hacia un futuro más próspero.',
        'El optimismo es el motor que impulsa la superación en cualquier circunstancia.',
        'La gestión efectiva de recursos es la base del éxito académico y financiero.',
        'Hoy es un buen día para aprender algo nuevo y mejorar.',
    ];

    ////////////////////////// USE EFFECTS //////////////////////////
    useEffect(() => {
        const interval = setInterval(() => {
            setShowMessage(false);
            setTimeout(() => {
                setCurrentMessageIndex(
                    (prevIndex) => (prevIndex + 1) % messages.length
                );
                setShowMessage(true);
            }, 500);
        }, 10000);

        return () => clearInterval(interval);
    }, []);

    ////////////////////////// USE EFFECTS //////////////////////////

    return (
        <Box sx={{ display: 'flex', width: '100%' }}>
            <Box
                sx={{
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 130,
                    height: 130,
                }}>
                {/* Círculo de fondo */}
                <Box
                    sx={{
                        position: 'absolute',
                        width: 130,
                        height: 130,
                        borderRadius: '50%',
                        backgroundColor: '#6383e6',
                        zIndex: 1,
                    }}
                />
                {/* Barra de progreso */}
                <CircularProgress
                    size={120}
                    variant={
                        statusServer === 'fulfilled'
                            ? 'determinate'
                            : statusServer === 'pending'
                            ? 'indeterminate'
                            : 'determinate'
                    }
                    value={
                        statusServer === 'fulfilled'
                            ? -60
                            : statusServer === 'pending'
                            ? 0
                            : -60
                    }
                    sx={{
                        color: 'white',
                        position: 'absolute',
                        zIndex: 2,
                        animation:
                            statusServer === 'fulfilled'
                                ? 'fillAnimation 2s ease-out forwards'
                                : statusServer === 'pending'
                                ? 'none'
                                : 'fillAnimation 2s ease-out forwards',
                    }}
                />
                {/* Avatar */}
                <Avatar
                    src={avatarSrc}
                    sx={{
                        width: 100,
                        height: 100,
                        zIndex: 3,
                    }}
                />
                {/* Efecto visual al completar la carga */}
                {statusServer === 'fulfilled' && (
                    <Box
                        sx={{
                            position: 'absolute',
                            width: 130,
                            height: 130,
                            borderRadius: '50%',
                            backgroundColor: 'rgba(255, 255, 255, 0)',
                            zIndex: 2,
                            animation: 'fadeOut 1s forwards',
                        }}
                    />
                )}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    ml: 2,
                }}>
                <Box>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {genderText}, {user.name}
                    </Typography>
                    <Grow in={showMessage} timeout={500}>
                        <Typography
                            variant="body2"
                            sx={{
                                color: 'grey.600',
                            }}>
                            {messages[currentMessageIndex]}
                        </Typography>
                    </Grow>
                </Box>

                <Box ml={2}>
                    <img
                        src={WavingHand}
                        alt="hand wave"
                        width={50}
                        height={50}
                        style={{
                            animation:
                                statusServer === 'fulfilled'
                                    ? 'wave 2s ease-in-out '
                                    : 'none',
                            transformOrigin: 'bottom',
                        }}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default WelcomeCard;
