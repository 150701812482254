import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Step,
    StepLabel,
    Stepper,
    TextField,
    Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth, useFeedback } from '../../../../hooks';
import { selectCatDiscounts } from '../../../store/cat_discounts/selectors';
import {
    selectConcepts,
    selectStudentsInFilteredConceptsDiscounts,
} from '../../../store/concepts/selectors';
import { selectStudents } from '../../../../store/slices/entities/students';
import { FormikProvider, useFormik } from 'formik';
import { selectCatConcepts } from '../../../store/cat_concepts/selectors';
import { Transfer } from 'antd';
import * as Yup from 'yup';
import { createDiscount } from '../../../store/discounts/operations';
import { fetchCreateDiscount } from '../../../store/discounts/fetchSlice';
import { Typography as AntTypography } from 'antd';
import palomita from '../../../../public/assets/palomita.svg';

const CreateDiscount = ({ open, onclose }) => {
    ///////////// LOCAL STATE /////////////

    const [loading, setLoading] = useState(true);
    const [activeStep, setActiveStep] = useState(0);
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [stepType, setStepType] = useState(null);
    const [selectedCatalogId, setSelectedCatalogId] = useState(null);

    ///////////// SHARED STATE /////////////
    const auth = useAuth();
    const schoolId = auth.getUser().school_id;
    const dispatch = useDispatch();
    const feedbackApp = useFeedback();

    ///////////// SELECTORS /////////////

    const catalogDiscounts = useSelector(selectCatDiscounts);
    const students = Object.values(useSelector(selectStudents));
    const concepts = Object.values(useSelector(selectConcepts));
    const catalogConcepts = useSelector((state) =>
        Object.values(selectCatConcepts(state))
    );

    const filteredStudents = useSelector((state) =>
        selectStudentsInFilteredConceptsDiscounts(state, selectedCatalogId)
    );

    ///////////// USE EFFECTS /////////////

    useEffect(() => {
        if (open) {
            dispatch(fetchCreateDiscount(schoolId)).finally(() =>
                setLoading(false)
            );
        }
    }, [dispatch, schoolId, open]);

    ///////////// FORMIK SETUP /////////////

    const formik = useFormik({
        initialValues: {
            description: '',
            discount_catalog_id: null,
        },
        validationSchema: Yup.object({
            description: Yup.string().required('Descripción es requerida'),
            discount_catalog_id: Yup.number().required(
                'Catálogo de Descuentos es requerido'
            ),
        }),
        onSubmit: async (values, { resetForm }) => {
            const data = [];

            const selectedStudentDetails = students.filter((student) =>
                selectedStudents.includes(student.student_id)
            );

            selectedStudentDetails.forEach((student) => {
                const studentConcepts = concepts.filter(
                    (concept) =>
                        concept.student_id === student.student_id &&
                        concept.concept_catalog_id === selectedCatalogId &&
                        concept.status === 2 &&
                        !(
                            (concept.in_agreement === true &&
                                concept.in_discount === true) ||
                            (concept.in_agreement === false &&
                                concept.in_discount === true)
                        )
                );
                studentConcepts.forEach((concept) => {
                    data.push({
                        description: values.description,
                        concept_id: concept.concept_id,
                        catalog_discount_id: values.discount_catalog_id,
                    });
                });
            });

            await dispatch(createDiscount({ data, schoolId }))
                .unwrap()
                .then(() => {
                    feedbackApp.showFeedback({
                        title: 'Descuentos Registrados',
                        severity: 'success',
                    });
                    resetForm();
                    handleReset();
                })
                .catch((error) => {
                    feedbackApp.showFeedback({
                        title: error.feedback.title,
                        severity: 'error',
                    });
                });
        },
    });

    ////////// FUNCTIONS /////////////

    const steps = ['Información General', 'Asignar Alumnos'];

    const handleStudentSelect = (targetKeys) => {
        setSelectedStudents(targetKeys);
    };
    const handleNext = () => {
        if (activeStep === steps.length - 1) {
            formik.handleSubmit();
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setSelectedStudents([]);
        setSelectedGroups([]);
    };

    const handleReset = () => {
        setActiveStep(0);
        setSelectedStudents([]);
        setSelectedGroups([]);
        setStepType(null);
        onclose();
    };

    const handleCatalogChange = (selectedCatalogId) => {
        setSelectedCatalogId(selectedCatalogId);
    };

    return (
        <Dialog
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    onclose();
                }
            }}
            disableEscapeKeyDown
            sx={{
                '& .MuiDialog-paper': {
                    borderRadius: 5,
                    width: '80%',
                    maxWidth: 900,
                    minHeight: 500,
                    height: 'auto',
                    maxHeight: 650,
                },
            }}>
            <DialogTitle
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: 1,
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                    }}>
                    <Box
                        component="img"
                        src={palomita}
                        alt="Descripción de la imagen"
                        sx={{
                            width: '15%',
                            height: 'auto',
                        }}
                    />
                    <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        sx={{
                            display: 'flex',
                            ml: 2,
                            mr: 2,
                        }}
                    />
                    <Box>
                        <AntTypography.Title level={5}>
                            Registra un Descuento
                        </AntTypography.Title>
                        <Typography
                            variant="body2"
                            sx={{
                                width: '100%',
                                mt: -1,
                            }}>
                            Aquí podrás registrar un nuevo descuento
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                    }}>
                    <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        sx={{
                            display: 'flex',
                            ml: 2,
                            mr: 2,
                        }}
                    />
                    <IconButton
                        onClick={() => {
                            formik.resetForm();
                            onclose();
                        }}>
                        <CloseIcon
                            sx={{
                                color: '#000000ff',
                                width: 20,
                            }}
                        />
                    </IconButton>
                </Box>
            </DialogTitle>
            <Divider
                sx={{
                    width: '95%',
                }}
                flexItem
                variant="middle"
            />
            <FormikProvider value={formik}>
                <DialogContent>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    {activeStep === 0 && (
                        <>
                            <TextField
                                name="description"
                                fullWidth
                                label="Descripción"
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.description &&
                                    Boolean(formik.errors.description)
                                }
                                helperText={
                                    formik.touched.description &&
                                    formik.errors.description
                                }
                                variant="outlined"
                                margin="normal"
                            />
                            <Autocomplete
                                value={
                                    catalogConcepts.find(
                                        (concept) =>
                                            concept.concept_catalog_id ===
                                            formik.values.concept_catalog_id
                                    ) || null
                                }
                                noOptionsText="No hay opciones disponibles"
                                options={catalogConcepts.filter(
                                    (option) => option.active === true
                                )}
                                getOptionLabel={(option) => option.concept}
                                onChange={(event, value) => {
                                    const catalogId =
                                        value?.concept_catalog_id || null;
                                    setSelectedCatalogId(catalogId);
                                    formik.setFieldValue(
                                        'concept_catalog_id',
                                        catalogId
                                    );
                                    handleCatalogChange(catalogId);
                                }}
                                renderOption={(props, option) => (
                                    <Box
                                        component="li"
                                        {...props}
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            borderBottom: '1px solid #ddd',
                                            padding: '8px 16px',
                                        }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                            }}>
                                            <Typography
                                                variant="body1"
                                                sx={{
                                                    fontWeight: 'bold',
                                                }}>
                                                {option.concept}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary">
                                                ID: {option.concept_catalog_id}
                                            </Typography>
                                        </Box>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            sx={{
                                                marginLeft: 'auto',
                                            }}>
                                            Total: ${option.value}
                                        </Typography>
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Catálogo de concepto"
                                        variant="outlined"
                                        margin="normal"
                                    />
                                )}
                            />
                            <Autocomplete
                                value={
                                    catalogDiscounts.find(
                                        (cataloDiscounts) =>
                                            cataloDiscounts.discount_catalog_id ===
                                            formik.values.discount_catalog_id
                                    ) || null
                                }
                                options={catalogDiscounts.filter(
                                    (option) => option.active === true
                                )}
                                getOptionLabel={(option) => option.title}
                                onChange={(event, value) => {
                                    const discountId =
                                        value?.discount_catalog_id;
                                    formik.setFieldValue(
                                        'discount_catalog_id',
                                        discountId
                                    );
                                }}
                                renderOption={(props, option) => (
                                    <Box
                                        component="li"
                                        {...props}
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            borderBottom: '1px solid #ddd',
                                            padding: '8px 16px',
                                        }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                            }}>
                                            <Typography
                                                variant="body1"
                                                sx={{
                                                    fontWeight: 'bold',
                                                }}>
                                                {option.title}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary">
                                                ID: {option.discount_catalog_id}
                                            </Typography>
                                        </Box>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            sx={{
                                                marginLeft: 'auto',
                                            }}>
                                            Total:
                                            {option.value_modality === 1
                                                ? `$${option.value}`
                                                : `${option.value}%`}
                                        </Typography>
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Catálogo de Descuentos"
                                        variant="outlined"
                                        margin="normal"
                                        error={
                                            formik.touched
                                                .discount_catalog_id &&
                                            Boolean(
                                                formik.errors
                                                    .discount_catalog_id
                                            )
                                        }
                                        helperText={
                                            formik.touched
                                                .discount_catalog_id &&
                                            formik.errors.discount_catalog_id
                                        }
                                    />
                                )}
                            />
                        </>
                    )}
                    {activeStep === 1 && (
                        <>
                            <Typography
                                variant="h6"
                                textAlign="center"
                                sx={{ mt: 2, padding: 2 }}>
                                Selecciona los Alumnos
                            </Typography>
                            <Transfer
                                dataSource={filteredStudents.map((student) => ({
                                    key: student.student_id,
                                    title: `${student.name} ${student.last_name} ${student.second_last_name}`,
                                }))}
                                targetKeys={selectedStudents}
                                onChange={handleStudentSelect}
                                render={(item) => item.title}
                                showSearch
                                filterOption={(input, option) =>
                                    option.title
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                listStyle={{
                                    width: '100%',
                                    height: 300,
                                }}
                            />
                        </>
                    )}
                </DialogContent>
            </FormikProvider>
            <Divider
                sx={{
                    width: '95%',
                }}
                flexItem
                variant="middle"
            />
            <DialogActions sx={{ justifyContent: 'space-between' }}>
                <Box sx={{ mb: 2 }}>
                    <Button
                        sx={{ mt: 1, mr: 1 }}
                        onClick={() => {
                            formik.resetForm();
                            setActiveStep(0);
                            onclose();
                        }}>
                        Cancelar
                    </Button>
                </Box>
                <Box sx={{ mb: 2 }}>
                    <Button disabled={activeStep === 0} onClick={handleBack}>
                        Atrás
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        disabled={
                            activeStep === 0
                                ? !(
                                      formik.values.description &&
                                      formik.values.discount_catalog_id
                                  )
                                : selectedStudents.length === 0 ||
                                  formik.isSubmitting
                        }>
                        {formik.isSubmitting ? (
                            <CircularProgress size={24} />
                        ) : activeStep === steps.length - 1 ? (
                            'Enviar'
                        ) : (
                            'Siguiente'
                        )}
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default CreateDiscount;
