import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, CardContent, Tabs, Tab, Typography } from '@mui/material';
import { DataGrid, esES } from '@mui/x-data-grid';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectAssistsAbsences,
} from '../../../store/groups/fetchSlice';
import { getAssistsAbsences } from '../../../store/groups/thunks';
import { selectComponentGroupsAssistsAbsencesStatusServer } from '../../../store/groups/componentStatusSelectors';
import { SkeletongGrafics } from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';
import { Tag } from 'antd';
import NoDataOverlay from '../../../../components/utilities/NoDataOverlay';
import { GridToolBar } from '../../../../components/utilities/GridToolBar';
import CustomPagination from '../../../../components/utilities/CustomPagination';

const AssistsAbsences = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentGroupsAssistsAbsencesStatusServer
    );
    const assistsAbsences = useFetchResource(
        () =>
            getAssistsAbsences({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectAssistsAbsences,
        [cycleSelected, schoolId]
    );
    const reload = () => {
        dispatch(
            getAssistsAbsences({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            })
        );
    };

    const [tabIndex, setTabIndex] = useState(0);

    const handleChangeTab = (event, newIndex) => {
        setTabIndex(newIndex);
    };
    const tabs = ['Principal', 'Especial', 'Extraescolar', 'Modulo'];
    const availableTabs = tabs.filter(
        (tab) =>
            assistsAbsences?.value && assistsAbsences?.value[tab.toUpperCase()]
    );

    const renderDataGrid = () => {
        if (availableTabs.length === 0)
            return <Typography>No hay datos disponibles</Typography>;

        const category = availableTabs[tabIndex];
        const data = assistsAbsences?.value?.[category.toUpperCase()] || {};

        if (!data || Object.keys(data).length === 0) {
            return <Typography>No hay datos disponibles</Typography>;
        }

        const relevantKeys = Object.keys(data).filter((key) =>
            Array.isArray(data[key])
        );

        const renderTag = (value, type) => {
            if (type === 'grupos') {
                return value;
            }

            if (typeof value === 'object' && value !== null) {
                value = value.value;
            }

            const tagStyles = {
                asistencias: { color: 'success', label: value },
                faltas: { color: 'error', label: value },
                justificadas: { color: 'blue', label: value },
            };

            return (
                <Tag
                    color={tagStyles[type]?.color || 'default'}
                    style={{ width: '100px', textAlign: 'center' }}
                >
                    {tagStyles[type]?.label || value}
                </Tag>
            );
        };

        const columns = relevantKeys.map((key) => ({
            field: key,
            headerName: key.toUpperCase(),
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const value =
                    typeof params.value === 'object' && params.value !== null
                        ? params.value.value
                        : params.value;
                return renderTag(value, key.toLowerCase());
            },
        }));

        const rowCount = data[relevantKeys[0]]?.length || 0;
        const rows = Array.from({ length: rowCount }, (_, index) => ({
            id: `${category}_${index}`,
            ...Object.fromEntries(
                relevantKeys.map((key) => [
                    key,
                    data[key][index]?.value || data[key][index],
                ])
            ),
        }));

        return (
            <Box sx={{ width: '100%', mt: 2 }}>
                <DataGrid
                    localeText={{
                        ...esES.components.MuiDataGrid.defaultProps.localeText,
                    }}
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[]}
                    disableSelectionOnClick
                    components={{
                        NoRowsOverlay: NoDataOverlay,
                        Toolbar: GridToolBar,
                        Pagination: CustomPagination,
                    }}
                    autoHeight
                />
            </Box>
        );
    };

    return (
        <Box sx={{ width: '100%', overflowX: 'hidden' }}>
            {fetchingStatus === 'pending' && <SkeletongGrafics items={9} />}
            {fetchingStatus === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}
            {fetchingStatus === 'fulfilled' && (
                <Card
                    sx={{
                        background: '#ffffff',
                        borderRadius: '10px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        maxWidth: '100%',
                        margin: '20px auto',
                        padding: '16px',
                        overflow: 'visible',
                    }}
                >
                    <Typography variant="h6" sx={{ ml: 1 }}>
                        Lista de Asistencias
                    </Typography>
                    <CardContent sx={{ padding: '2px' }}>
                        {availableTabs.length > 0 ? (
                            <>
                                <Tabs
                                    value={tabIndex}
                                    onChange={handleChangeTab}
                                >
                                    {availableTabs.map((tab, index) => (
                                        <Tab
                                            key={index}
                                            label={tab}
                                            sx={{
                                                fontSize: '1rem',
                                                fontWeight: 600,
                                                color:
                                                    tabIndex === index
                                                        ? '#1a73e8'
                                                        : '#575959',
                                                '&:hover': { color: '#0c47a1' },
                                            }}
                                        />
                                    ))}
                                </Tabs>
                                {renderDataGrid()}
                            </>
                        ) : (
                            <Typography>No hay datos disponibles</Typography>
                        )}
                    </CardContent>
                </Card>
            )}
        </Box>
    );
};

export default AssistsAbsences;
