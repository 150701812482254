import React, { useState } from 'react';
import FeedbackContext from './FeedbackContext';
import UserFeedback from '../components/UserFeedback';

/**
 * Componente para proporcionar el servicio de Feedback
 *
 * @param {*} props
 *
 * @returns
 */
const FeedbackProvider = (props) => {
    const { children } = props;

    const [message, setMessage] = useState({
        open: false,
        title: '',
        message: '',
        severity: '',
    });

    const showFeedback = ({ message, title, severity }) => {
        setMessage({
            message,
            title,
            severity,
            open: true,
        });
    };

    const closeFeedback = () => {
        setMessage({
            message: '',
            title: '',
            severity: '',
            open: false,
        });
    };

    return (
        <FeedbackContext.Provider
            value={{
                showFeedback,
                closeFeedback,
                message: message.message,
                title: message.title,
                severity: message.severity,
                open: message.open,
            }}>
            <UserFeedback
                message={message.message}
                title={message.title}
                severity={message.severity}
                open={message.open}
                onClose={closeFeedback}
            />
            {children}
        </FeedbackContext.Provider>
    );
};

export default FeedbackProvider;
