import React, { useState, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Autocomplete,
    TextField,
    Box,
    Step,
    StepLabel,
    Stepper,
    Card,
    CardContent,
    Typography,
    Container,
    Divider,
    Grid,
    Chip,
    styled,
    StepConnector,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Checkbox,
    CardHeader,
    CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth, useFeedback } from '../../../../hooks';
import { useTheme } from '@emotion/react';
import PaymentIcon from '@mui/icons-material/Payment';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import useThemeColors from '../../../../theme/themes';
import palomita from '../../../../public/assets/palomita.svg';
import {
    fetchCreatePayment,
    fetchExport,
} from '../../../store/income/fetchSlice';
import { Typography as AntTypography, Avatar } from 'antd';
import student from '../../../../assets/images/default-image-niña.svg';
import student2 from '../../../../assets/images/default-image-niño.svg';
import { GavelOutlined, LocalAtm } from '@mui/icons-material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DiscountIcon from '@mui/icons-material/Discount';
import {
    selectConceptsByStudent,
    selectPartialitiesByConcept,
    selectStudentsWithPendingConcepts,
} from '../../../store/income/selectors';
import { CreatePaymnet } from '../../../store/income/operationSlice';
import { useFormik } from 'formik';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import femaleStudent from '../../../assets/img/female_student.png';
import MaleStudent from '../../../assets/img/male_student.png';
import defaultStudent from '../../../assets/img/default_student.png';

const IncomeModal = ({ isModalOpen, onClose }) => {
    const auth = useAuth();
    const schoolId = auth.getUser().school_id;
    const dispatch = useDispatch();
    const theme = useTheme();
    const feedbackApp = useFeedback();
    const colors = useThemeColors();

    const [selectedStudent, setSelectedStudent] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [activeStep, setActiveStep] = useState(0);
    const [selectedConcept, setSelectedConcept] = useState(null);
    const [selectedPartialities, setSelectedPartialities] = useState({});
    const [totalSelectedAmount, setTotalSelectedAmount] = useState(0);
    const [selectAll, setSelectAll] = useState(false);

    const students = useSelector(selectStudentsWithPendingConcepts);
    const concepts = useSelector((state) =>
        selectConceptsByStudent(state, selectedStudent?.student_id)
    );
    const partialities = useSelector((state) =>
        selectPartialitiesByConcept(state, selectedConcept?.concept_id)
    );

    useEffect(() => {
        if (isModalOpen) {
            dispatch(fetchCreatePayment(schoolId));
        }
    }, [dispatch, schoolId, isModalOpen]);

    const paymentMethods = [
        {
            value: 1,
            label: 'Efectivo',
            bgColor: '#E8F5E9',
            iconColor: '#4CAF50',
            icon: <PaymentIcon />,
        },
        {
            value: 2,
            label: 'Transferencia',
            bgColor: '#E3F2FD',
            iconColor: '#2196F3',
            icon: <AccountBalanceIcon />,
        },
        {
            value: 3,
            label: 'Tarjeta de crédito o débito',
            bgColor: '#FFF3E0',
            iconColor: '#FF9800',
            icon: <CreditCardIcon />,
        },
        {
            value: 4,
            label: 'Tarjeta pre paga',
            bgColor: '#F3E5F5',
            iconColor: '#9C27B0',
            icon: <CardGiftcardIcon />,
        },
        {
            value: 5,
            label: 'Domiciliación bancaria',
            bgColor: '#FFEBEE',
            iconColor: '#F44336',
            icon: <AccountBalanceWalletIcon />,
        },
    ];

    const handleCheckboxChange = (partialityIndex, termIndex) => {
        setSelectedPartialities((prevState) => {
            const updatedState = { ...prevState };
            const key = `${partialityIndex}-${termIndex}`;

            // Si está seleccionado, lo deseleccionamos, de lo contrario, lo seleccionamos
            if (updatedState[key]) {
                delete updatedState[key];

                // Si se deselecciona, desmarcar todas las parcialidades posteriores
                for (
                    let tIndex = termIndex + 1;
                    tIndex < partialities[partialityIndex].remaining_terms;
                    tIndex++
                ) {
                    delete updatedState[`${partialityIndex}-${tIndex}`];
                }
            } else {
                updatedState[key] = true;
            }

            // Actualizar el valor de 'number_terms' en Formik
            const selectedCount = Object.keys(updatedState).length;
            formik.setFieldValue('number_terms', selectedCount);

            // Cálculo del total basado en las parcialidades seleccionadas
            const newTotal = partialities.reduce((acc, partiality, pIndex) => {
                const paidTerms =
                    partiality.total_terms - partiality.remaining_terms;
                const remainingPartialities = partiality.remaining_terms;

                // Calcular el monto redondeado de las parcialidades restantes
                const roundedAmounts = Array.from({
                    length: remainingPartialities - 1,
                }).map(() =>
                    Math.ceil(
                        selectedConcept.net_total / partiality.total_terms
                    )
                );

                // Calcular el total de las parcialidades redondeadas
                const totalRounded = roundedAmounts.reduce(
                    (sum, amount) => sum + amount,
                    0
                );

                // Calcular la última parcialidad ajustada
                const lastPartiality =
                    selectedConcept.net_total -
                    (paidTerms *
                        Math.ceil(
                            selectedConcept.net_total / partiality.total_terms
                        ) +
                        totalRounded);

                // Sumar los montos seleccionados
                const selectedTerms = Array.from({
                    length: remainingPartialities,
                })
                    .map((_, tIndex) => {
                        const amount =
                            tIndex < remainingPartialities - 1
                                ? roundedAmounts[tIndex]
                                : lastPartiality;
                        return updatedState[`${pIndex}-${tIndex}`] ? amount : 0;
                    })
                    .reduce((sum, amount) => sum + amount, 0);

                return acc + selectedTerms;
            }, 0);

            setTotalSelectedAmount(newTotal);

            // Gestión del estado del checkbox "Select All"
            if (!updatedState[key]) {
                setSelectAll(false);
            }

            const totalTerms = partialities.reduce(
                (acc, partiality) => acc + partiality.remaining_terms,
                0
            );
            if (selectedCount === totalTerms) {
                setSelectAll(true);
            }

            return updatedState;
        });
    };

    const handleSelectAllChange = (event) => {
        const isChecked = event.target.checked;
        setSelectAll(isChecked);

        if (isChecked) {
            const newSelections = {};
            let totalAmount = 0;

            partialities.forEach((partiality, partialityIndex) => {
                const remainingPartialities = partiality.remaining_terms;
                const roundedAmounts = Array.from({
                    length: remainingPartialities - 1,
                }).map(() =>
                    Math.ceil(
                        selectedConcept.net_total / partiality.total_terms
                    )
                );
                const totalRounded = roundedAmounts.reduce(
                    (sum, amount) => sum + amount,
                    0
                );
                const lastPartiality =
                    selectedConcept.net_total -
                    ((partiality.total_terms - remainingPartialities) *
                        Math.ceil(
                            selectedConcept.net_total / partiality.total_terms
                        ) +
                        totalRounded);

                Array.from({ length: remainingPartialities }).forEach(
                    (_, termIndex) => {
                        newSelections[`${partialityIndex}-${termIndex}`] = true;
                        const amount =
                            termIndex < remainingPartialities - 1
                                ? roundedAmounts[termIndex]
                                : lastPartiality;
                        totalAmount += amount;
                    }
                );
            });

            setSelectedPartialities(newSelections);
            const selectedCount = Object.keys(newSelections).length;
            formik.setFieldValue('number_terms', selectedCount);
            setTotalSelectedAmount(totalAmount);
        } else {
            setSelectedPartialities({});
            formik.setFieldValue('number_terms', 0);
            setTotalSelectedAmount(0);
        }
    };

    /////////////////////FORMIK////////////////////
    const formik = useFormik({
        initialValues: {
            user_id: null,
            concept_id: null,
            payment_method: null,
            number_terms: 0,
        },

        onSubmit: async (values) => {
            const data = {
                payment_method: values.payment_method,
                number_terms: values.number_terms,
            };

            if (values.number_terms === 0) {
                delete data.number_terms;
            }

            await dispatch(
                CreatePaymnet({
                    school_id: schoolId,
                    concept_id: values.concept_id,
                    user_id: values.user_id,
                    data: data,
                })
            )
                .unwrap()
                .then((result) => {
                    feedbackApp.showFeedback({
                        title: 'Pago registrado con éxito',
                        severity: 'success',
                    });

                    const paymentId = result.payment[0]?.payment_id;
                    const terms =
                        values.number_terms > 0 ? values.number_terms : 1;

                    dispatch(
                        fetchExport({
                            schoolId: schoolId,
                            paymentId: paymentId,
                            terms: terms,
                        })
                    )
                        .unwrap()
                        .then(() => {
                            setTimeout(() => {
                                feedbackApp.showFeedback({
                                    title: 'Pago exportado con éxito',
                                    severity: 'success',
                                });
                            }, 3000);
                        })
                        .catch((error) => {
                            setTimeout(() => {
                                feedbackApp.showFeedback({
                                    title: 'Error al exportar el pago',
                                    severity: 'error',
                                });
                            }, 3000);
                        });
                    formik.resetForm();
                    setActiveStep(0);
                    onClose();
                })
                .catch((error) => {
                    feedbackApp.showFeedback({
                        title: error.feedback.title,
                        severity: 'error',
                    });
                });
        },
    });

    useEffect(() => {
        setSelectedPartialities({});
    }, [selectedStudent, selectedConcept]);

    const steps = [
        {
            label: 'Seleccionar Estudiante y Método de pago',
            content: (
                <Container>
                    <Autocomplete
                        options={students}
                        noOptionsText="No hay opciones disponibles"
                        getOptionLabel={(option) =>
                            `${option.name} ${option.last_name} ${option.second_last_name}`
                        }
                        value={
                            students.find(
                                (student) =>
                                    student.student_id === formik.values.user_id
                            ) || null
                        }
                        onChange={(event, newValue) => {
                            setSelectedStudent(newValue);
                            formik.setFieldValue(
                                'user_id',
                                newValue?.student_id
                            );
                        }}
                        renderOption={(props, option) => {
                            const avatarSrc =
                                option.url_photo_profile ||
                                (option.gender === 'M'
                                    ? femaleStudent
                                    : option.gender === 'H'
                                    ? MaleStudent
                                    : defaultStudent);

                            return (
                                <Box
                                    component="li"
                                    {...props}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        mb: 1,
                                        p: 1,
                                        borderRadius: 1,
                                        '&:hover': {
                                            backgroundColor:
                                                theme.palette.action.hover,
                                        },
                                    }}>
                                    <Avatar
                                        sx={{
                                            width: 56,
                                            height: 56,
                                            mr: 2,
                                        }}
                                        src={avatarSrc}
                                        alt={option.name}
                                    />
                                    <Box>
                                        <Typography variant="body1">
                                            {option.name} {option.last_name}{' '}
                                            {option.second_last_name}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary">
                                            ID: {option.student_id}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary">
                                            Teléfono: {option.cellphone}
                                        </Typography>
                                    </Box>
                                </Box>
                            );
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Seleccionar estudiante"
                                variant="outlined"
                            />
                        )}
                        sx={{
                            borderRadius: 10,
                            width: '100%',
                            height: '100%',
                            mt: 2,
                            mb: 2,
                        }}
                    />

                    <Autocomplete
                        noOptionsText="No hay opciones disponibles"
                        options={paymentMethods}
                        getOptionLabel={(option) => option.label}
                        value={
                            paymentMethods.find(
                                (method) =>
                                    method.value ===
                                    formik.values.payment_method
                            ) || null
                        }
                        onChange={(event, newValue) => {
                            if (newValue) {
                                setPaymentMethod(newValue);
                                formik.setFieldValue(
                                    'payment_method',
                                    newValue.value
                                );
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Seleccionar método de pago"
                                variant="outlined"
                                fullWidth
                            />
                        )}
                        renderOption={(props, option) => (
                            <Box
                                component="li"
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    p: 1,
                                }}
                                {...props}>
                                <Box
                                    sx={{
                                        backgroundColor: option.bgColor,
                                        color: option.iconColor,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: 32,
                                        height: 32,
                                    }}>
                                    {option.icon}
                                </Box>
                                <Typography sx={{ ml: 2 }}>
                                    {option.label}
                                </Typography>
                            </Box>
                        )}
                        sx={{
                            borderRadius: 10,
                            width: '100%',
                            height: '100%',
                            mt: 2,
                            mb: 2,
                        }}
                    />
                </Container>
            ),
        },
        {
            label: 'Seleccionar Concepto del estudiante',
            content: (
                <Container>
                    <Box sx={{ flexWrap: 'wrap', gap: 2 }}>
                        {concepts.map((concept) => (
                            <Grid item key={concept.concept_id} xs={12} sm={6}>
                                <Card
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: '100%',
                                        boxShadow: 3,
                                        borderRadius: 2,
                                    }}>
                                    <CardContent
                                        sx={{
                                            flexGrow: 1,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            textAlign: 'center',
                                            padding: 3,
                                        }}>
                                        <Typography
                                            variant="h5"
                                            sx={{
                                                fontWeight: 'bold',
                                                mb: 1,
                                            }}>
                                            {concept.catalogConcept.concept}
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            color="textSecondary">
                                            {concept.description}
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'flex-start',
                                            }}>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    width: '100%',
                                                }}>
                                                <Box
                                                    sx={{
                                                        borderRadius: '100%',
                                                        height: 25,
                                                        width: 25,
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                        backgroundColor:
                                                            colors.red[200],
                                                    }}>
                                                    <AttachMoneyIcon
                                                        fontSize="small"
                                                        sx={{
                                                            color: colors
                                                                .red[600],
                                                        }}
                                                    />
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        width: '100%',
                                                        justifyContent:
                                                            'space-between',
                                                    }}>
                                                    <Typography
                                                        sx={{
                                                            ml: 1,
                                                        }}
                                                        variant="body2">
                                                        Recargo:
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        sx={{
                                                            color: colors
                                                                .red[500],
                                                        }}>
                                                        + $
                                                        {
                                                            concept.surcharge_total
                                                        }
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    width: '100%',
                                                    paddingTop: 1,
                                                }}>
                                                <Box
                                                    sx={{
                                                        borderRadius: '100%',
                                                        height: 25,
                                                        width: 25,
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                        backgroundColor:
                                                            colors.green[200],
                                                    }}>
                                                    <DiscountIcon
                                                        fontSize="small"
                                                        sx={{
                                                            color: colors
                                                                .green[600],
                                                        }}
                                                    />
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        width: '100%',
                                                        justifyContent:
                                                            'space-between',
                                                    }}>
                                                    <Typography
                                                        sx={{
                                                            ml: 1,
                                                        }}
                                                        variant="body2">
                                                        Descuento:
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        sx={{
                                                            color: colors
                                                                .green[500],
                                                        }}>
                                                        - $
                                                        {concept.discount_total}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Divider
                                                sx={{
                                                    width: '100%',
                                                }}
                                                flexItem>
                                                <Chip
                                                    label="Totales"
                                                    size="small"
                                                />
                                            </Divider>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    width: '100%',
                                                    paddingTop: 1,
                                                }}>
                                                <Box
                                                    sx={{
                                                        borderRadius: '100%',
                                                        height: 25,
                                                        width: 25,
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                        backgroundColor:
                                                            colors.orange[200],
                                                    }}>
                                                    <LocalAtm
                                                        fontSize="small"
                                                        sx={{
                                                            color: colors
                                                                .orange[600],
                                                        }}
                                                    />
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        width: '100%',
                                                        justifyContent:
                                                            'space-between',
                                                    }}>
                                                    <Typography
                                                        sx={{
                                                            ml: 1,
                                                        }}
                                                        variant="body2">
                                                        Subtotal:
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        ${concept.subtotal}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    width: '100%',
                                                    paddingTop: 1,
                                                }}>
                                                <Box
                                                    sx={{
                                                        borderRadius: '100%',
                                                        height: 25,
                                                        width: 25,
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                        backgroundColor:
                                                            colors.teal[200],
                                                    }}>
                                                    <LocalAtm
                                                        fontSize="small"
                                                        sx={{
                                                            color: colors
                                                                .teal[600],
                                                        }}
                                                    />
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        width: '100%',
                                                        justifyContent:
                                                            'space-between',
                                                    }}>
                                                    <Typography
                                                        sx={{
                                                            ml: 1,
                                                        }}
                                                        variant="body2">
                                                        Total:
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        ${concept.net_total}
                                                    </Typography>
                                                </Box>
                                            </Box>

                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    width: '100%',
                                                    paddingTop: 1,
                                                }}>
                                                <Box
                                                    sx={{
                                                        borderRadius: '100%',
                                                        height: 25,
                                                        width: 25,
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                        backgroundColor:
                                                            colors
                                                                .sunsetBlush[200],
                                                    }}>
                                                    <LocalAtm
                                                        fontSize="small"
                                                        sx={{
                                                            color: colors
                                                                .sunsetBlush[600],
                                                        }}
                                                    />
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        width: '100%',
                                                        justifyContent:
                                                            'space-between',
                                                    }}>
                                                    <Typography
                                                        sx={{
                                                            ml: 1,
                                                        }}
                                                        variant="body2">
                                                        Restante:
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        ${concept.remaining}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </CardContent>
                                    {concept.in_agreement === true ? (
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            endIcon={
                                                <NavigateNextOutlinedIcon />
                                            }
                                            onClick={() => {
                                                setSelectedConcept(concept);
                                                formik.setFieldValue(
                                                    'concept_id',
                                                    concept.concept_id
                                                );
                                                setActiveStep(2);
                                            }}
                                            sx={{
                                                borderTopLeftRadius: 0,
                                                borderTopRightRadius: 0,
                                                mt: 'auto',
                                            }}>
                                            Ver parcialidades
                                        </Button>
                                    ) : (
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            endIcon={
                                                <NavigateNextOutlinedIcon />
                                            }
                                            onClick={() => {
                                                setSelectedConcept(concept);
                                                formik.setFieldValue(
                                                    'concept_id',
                                                    concept.concept_id
                                                );
                                                setActiveStep(3);
                                            }}
                                            sx={{
                                                borderTopLeftRadius: 0,
                                                borderTopRightRadius: 0,
                                                mt: 'auto',
                                            }}>
                                            Pagar Concepto
                                        </Button>
                                    )}
                                </Card>
                            </Grid>
                        ))}
                    </Box>
                </Container>
            ),
        },
        {
            label: 'Seleccionar Parcialidades',
            content: (
                <Container
                    sx={{
                        width: '100%',
                        height: '100%',
                    }}>
                    <Box sx={{ mt: 3 }}>
                        <Accordion expanded={true}>
                            <AccordionSummary
                                expandIcon={false}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexGrow: 1,
                                    }}>
                                    <GavelOutlined />
                                    <Typography
                                        sx={{
                                            ml: 1,
                                        }}>
                                        {
                                            selectedConcept?.catalogConcept
                                                .concept
                                        }
                                    </Typography>
                                </Box>
                                <Chip
                                    Filled
                                    color="primary"
                                    sx={{
                                        borderRadius: 10,
                                        color: 'white',
                                    }}
                                    label={partialities.reduce(
                                        (acc, partiality) =>
                                            acc + partiality.remaining_terms,
                                        0
                                    )}
                                />
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box sx={{ mb: 2 }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}>
                                        <Checkbox
                                            checked={selectAll}
                                            onChange={handleSelectAllChange}
                                            sx={{
                                                color: 'red',
                                                '&.Mui-checked': {
                                                    color: 'red',
                                                },
                                            }}
                                        />
                                        <Typography>
                                            Pagar todas las parcialidades
                                        </Typography>
                                    </Box>
                                </Box>

                                {partialities.map(
                                    (partiality, partialityIndex) => {
                                        const paidTerms =
                                            partiality.total_terms -
                                            partiality.remaining_terms;
                                        const termAmount =
                                            selectedConcept.net_total /
                                            partiality.total_terms;
                                        const remainingPartialities =
                                            partiality.remaining_terms;

                                        const roundedAmounts = Array.from({
                                            length: remainingPartialities - 1,
                                        }).map(() => Math.ceil(termAmount));

                                        const totalRounded =
                                            roundedAmounts.reduce(
                                                (acc, amount) => acc + amount,
                                                0
                                            );

                                        const lastPartiality =
                                            selectedConcept.net_total -
                                            (paidTerms * Math.ceil(termAmount) +
                                                totalRounded);

                                        return (
                                            <Box
                                                key={partialityIndex}
                                                sx={{
                                                    justifyContent:
                                                        'space-between',
                                                    paddingLeft: 2,
                                                }}>
                                                {Array.from({
                                                    length: remainingPartialities,
                                                }).map((_, termIndex) => {
                                                    const currentTerm =
                                                        paidTerms +
                                                        termIndex +
                                                        1;

                                                    const amount =
                                                        termIndex <
                                                        remainingPartialities -
                                                            1
                                                            ? roundedAmounts[
                                                                  termIndex
                                                              ]
                                                            : lastPartiality;

                                                    const isPreviousSelected =
                                                        termIndex === 0 ||
                                                        selectedPartialities[
                                                            `${partialityIndex}-${
                                                                termIndex - 1
                                                            }`
                                                        ];

                                                    return (
                                                        <Box
                                                            key={termIndex}
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                            }}>
                                                            <Checkbox
                                                                checked={
                                                                    selectedPartialities[
                                                                        `${partialityIndex}-${termIndex}`
                                                                    ] || false
                                                                }
                                                                onChange={() =>
                                                                    handleCheckboxChange(
                                                                        partialityIndex,
                                                                        termIndex
                                                                    )
                                                                }
                                                                disabled={
                                                                    !isPreviousSelected
                                                                }
                                                            />
                                                            <Box
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                    justifyContent:
                                                                        'space-between',
                                                                    alignItems:
                                                                        'center',
                                                                    width: '100%',
                                                                }}>
                                                                <Typography>
                                                                    Parcialidad{' '}
                                                                    {
                                                                        currentTerm
                                                                    }
                                                                </Typography>
                                                                <Typography
                                                                    variant="body2"
                                                                    sx={{
                                                                        fontWeight:
                                                                            'bold',
                                                                    }}>
                                                                    $
                                                                    {amount.toFixed(
                                                                        2
                                                                    )}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    );
                                                })}
                                            </Box>
                                        );
                                    }
                                )}
                                <Divider
                                    sx={{
                                        mt: 2,
                                        mb: 2,
                                    }}
                                />
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}>
                                    <Typography variant="h6">
                                        Total a pagar:
                                    </Typography>
                                    <Chip
                                        label={`$${totalSelectedAmount.toFixed(
                                            2
                                        )}`}
                                        color="primary"
                                        sx={{
                                            color: 'white',
                                            fontSize: '1rem',
                                        }}
                                    />
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                </Container>
            ),
        },
        {
            label: 'Confirmar Pago',
            content: (
                <Container>
                    <Card
                        sx={{
                            p: 0,
                            boxShadow: 3,
                        }}>
                        <CardHeader
                            title="Resumen del Pago"
                            sx={{
                                textAlign: 'center',
                                backgroundColor: '#f5f5f5',
                                borderRadius: '4px',
                            }}
                        />
                        <CardContent>
                            <Grid container>
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}>
                                    <Avatar
                                        alt={selectedStudent?.name}
                                        src={
                                            selectedStudent?.url_photo_profile ||
                                            (selectedStudent?.gender === 'M'
                                                ? student
                                                : student2)
                                        }
                                        sx={{
                                            width: 56,
                                            height: 56,
                                            mr: 2,
                                        }}
                                    />
                                    <Typography variant="h6" component="div">
                                        {selectedStudent?.name}{' '}
                                        {selectedStudent?.last_name}{' '}
                                        {selectedStudent?.second_last_name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider
                                        sx={{
                                            my: 2,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box>
                                        <Typography
                                            variant="body2"
                                            component="div">
                                            Concepto:{' '}
                                            <Typography
                                                variant="h6"
                                                sx={{
                                                    fontWeight: 'bold',

                                                    pl: 2,
                                                }}>
                                                {
                                                    selectedConcept
                                                        ?.catalogConcept.concept
                                                }
                                            </Typography>
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box>
                                        <Typography
                                            variant="body2"
                                            component="div"
                                            sx={{}}>
                                            Descripción:
                                            <Typography
                                                variant="h6"
                                                sx={{
                                                    fontWeight: 'bold',

                                                    pl: 2,
                                                }}>
                                                {selectedConcept?.description}
                                            </Typography>
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider
                                        sx={{
                                            my: 2,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}>
                                        <Box
                                            sx={{
                                                borderRadius: '100%',
                                                height: 25,
                                                width: 25,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                backgroundColor:
                                                    colors.red[200],
                                                mr: 1,
                                            }}>
                                            <AttachMoneyIcon
                                                fontSize="small"
                                                sx={{
                                                    color: colors.red[600],
                                                }}
                                            />
                                        </Box>
                                        <Typography
                                            variant="h6"
                                            component="div"
                                            sx={{
                                                flexGrow: 1,
                                            }}>
                                            Recargo:
                                        </Typography>
                                        <Typography
                                            variant="h5"
                                            color="textSecondary">
                                            <Box
                                                component="span"
                                                sx={{
                                                    fontWeight: 'bold',

                                                    color: colors.red[600],
                                                }}>
                                                +{' '}
                                            </Box>
                                            ${selectedConcept?.surcharge_total}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}>
                                        <Box
                                            sx={{
                                                borderRadius: '100%',
                                                height: 25,
                                                width: 25,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                backgroundColor:
                                                    colors.green[200],
                                                mr: 1,
                                            }}>
                                            <DiscountIcon
                                                fontSize="small"
                                                sx={{
                                                    color: colors.green[600],
                                                }}
                                            />
                                        </Box>
                                        <Typography
                                            variant="h6"
                                            component="div"
                                            sx={{
                                                flexGrow: 1,
                                            }}>
                                            Descuento:
                                        </Typography>
                                        <Typography
                                            variant="h5"
                                            color="textSecondary"
                                            sx={{
                                                alignItems: 'center',
                                            }}>
                                            <Box
                                                component="span"
                                                sx={{
                                                    fontWeight: 'bold',
                                                    color: colors.green[600],
                                                }}>
                                                -{' '}
                                            </Box>
                                            ${selectedConcept?.discount_total}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    {selectedPartialities &&
                                    Object.keys(selectedPartialities).length >
                                        0 ? (
                                        <>
                                            <Divider sx={{ my: 2 }} />
                                            <Typography variant="body2">
                                                Parcialidades a pagar:
                                                <ul>
                                                    {Object.entries(
                                                        selectedPartialities
                                                    )
                                                        .filter(
                                                            ([key, value]) =>
                                                                value === true
                                                        )
                                                        .map(
                                                            (
                                                                [key, value],
                                                                index
                                                            ) => (
                                                                <li key={key}>
                                                                    Parcialidad{' '}
                                                                    {index + 1}
                                                                </li>
                                                            )
                                                        )}
                                                </ul>
                                            </Typography>
                                            <Divider sx={{ my: 2 }} />
                                            <Grid
                                                item
                                                xs={12}
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}>
                                                <Box
                                                    sx={{
                                                        borderRadius: '100%',
                                                        height: 25,
                                                        width: 25,
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                        backgroundColor:
                                                            colors.teal[200],
                                                    }}>
                                                    <LocalAtm
                                                        fontSize="small"
                                                        sx={{
                                                            color: colors
                                                                .teal[600],
                                                        }}
                                                    />
                                                </Box>
                                                <Typography
                                                    variant="h6"
                                                    component="div"
                                                    sx={{
                                                        flexGrow: 1,
                                                    }}>
                                                    Total:
                                                </Typography>
                                                <Typography
                                                    variant="h4"
                                                    color="textSecondary"
                                                    sx={{
                                                        fontWeight: 'bold',
                                                    }}>
                                                    $
                                                    {totalSelectedAmount.toFixed(
                                                        2
                                                    )}
                                                </Typography>
                                            </Grid>
                                        </>
                                    ) : (
                                        <>
                                            <Divider sx={{ my: 2 }} />
                                            <Grid
                                                item
                                                xs={12}
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}>
                                                <Box
                                                    sx={{
                                                        borderRadius: '100%',
                                                        height: 25,
                                                        width: 25,
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                        backgroundColor:
                                                            colors.teal[200],
                                                    }}>
                                                    <LocalAtm
                                                        fontSize="small"
                                                        sx={{
                                                            color: colors
                                                                .teal[600],
                                                        }}
                                                    />
                                                </Box>
                                                <Typography
                                                    variant="h6"
                                                    component="div"
                                                    sx={{
                                                        flexGrow: 1,
                                                    }}>
                                                    Total:
                                                </Typography>
                                                <Typography
                                                    variant="h4"
                                                    color="textSecondary"
                                                    sx={{
                                                        fontWeight: 'bold',
                                                    }}>
                                                    $
                                                    {selectedConcept?.net_total}
                                                </Typography>
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Container>
            ),
        },
    ];

    const handleNext = () => {
        if (activeStep === steps.length - 1) {
            formik.handleSubmit();
        } else if (
            activeStep === 1 &&
            selectedConcept?.in_agreement === false
        ) {
            setActiveStep(3);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        if (activeStep === 3 && selectedConcept?.in_agreement === false) {
            setActiveStep(1);
        } else if (activeStep === 2) {
            setActiveStep(1); // Volver a seleccionar concepto
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const isNextButtonDisabled = () => {
        if (activeStep === 0) {
            return !selectedStudent || !paymentMethod;
        } else if (activeStep === 1) {
            return !selectedConcept || selectedConcept;
        } else if (activeStep === 2) {
            return Object.keys(selectedPartialities).length === 0;
        }
        return false;
    };
    const ActiveStepIcon = styled(RadioButtonCheckedIcon)(({ theme }) => ({
        color: 'black',
    }));

    const NonActiveStepIcon = styled(CircleOutlinedIcon)(({ theme }) => ({
        color: 'grey',
    }));

    const CompletedStepIcon = styled(CheckCircleIcon)(({ theme }) => ({
        color: '#000000',
    }));

    const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
        '& .MuiStepConnector-line': {
            minHeight: '50px',
        },
        '& .MuiStepConnector-icon': {
            marginLeft: '-12px',
        },
    }));

    return (
        <Dialog
            open={isModalOpen}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    onClose();
                }
            }}
            disableEscapeKeyDown
            sx={{
                '& .MuiDialog-paper': {
                    borderRadius: 5,
                    width: '90%',
                    maxWidth: 900,
                    height: '90%',
                    maxHeight: 700,
                },
            }}>
            <DialogTitle
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: 1,
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                    }}>
                    <Box
                        component="img"
                        src={palomita}
                        alt="Descripción de la imagen"
                        sx={{
                            width: '15%',
                            height: 'auto',
                        }}
                    />
                    <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        sx={{
                            display: 'flex',
                            ml: 2,
                            mr: 2,
                        }}
                    />
                    <AntTypography.Title level={5}>
                        Registrar pago
                    </AntTypography.Title>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                    }}>
                    <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        sx={{
                            display: 'flex',
                            ml: 2,
                            mr: 2,
                        }}
                    />
                    <IconButton
                        onClick={() => {
                            formik.resetForm();
                            setActiveStep(0);
                            onClose();
                        }}>
                        <CloseIcon
                            sx={{
                                color: '#000000ff',
                                width: 20,
                            }}
                        />
                    </IconButton>
                </Box>
            </DialogTitle>
            <Divider
                sx={{
                    width: '95%',
                }}
                flexItem
                variant="middle"
            />
            <DialogContent
                sx={{
                    padding: '10px',
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        mt: 2,
                    }}>
                    <Stepper
                        activeStep={activeStep}
                        orientation="vertical"
                        connector={<CustomStepConnector />}
                        sx={{
                            height: '50px',
                            '& .MuiStep-root': {
                                '& .MuiStepLabel-root': {
                                    padding: 0,
                                },
                            },
                            '& .MuiStepConnector-root': {
                                marginLeft: '11px',
                            },
                        }}>
                        {steps.map((step, index) => {
                            const stepProps = {
                                completed:
                                    activeStep > index ||
                                    steps[index].completed,
                            };
                            const labelProps = {
                                StepIconComponent:
                                    activeStep === index
                                        ? ActiveStepIcon
                                        : steps[index].completed ||
                                          activeStep > index
                                        ? CompletedStepIcon
                                        : NonActiveStepIcon,
                            };
                            if (index === 0) {
                                labelProps.optional = (
                                    <Typography variant="caption">
                                        {step.description}
                                    </Typography>
                                );
                            }
                            return (
                                <Step key={step.label} {...stepProps}>
                                    <StepLabel {...labelProps}>
                                        {step.label}
                                    </StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    <Divider
                        orientation="vertical"
                        flexItem
                        sx={{ mx: 2, height: '527px' }}
                    />
                    <Box sx={{ flexGrow: 1 }}>{steps[activeStep].content}</Box>
                </Box>
            </DialogContent>
            <Divider
                sx={{
                    width: '95%',
                }}
                flexItem
                variant="middle"
            />
            <DialogActions
                sx={{
                    justifyContent: 'space-between',
                }}>
                <Box sx={{ mb: 2 }}>
                    <Button
                        sx={{ mt: 1, mr: 1 }}
                        onClick={() => {
                            formik.resetForm();
                            setActiveStep(0);
                            onClose();
                        }}>
                        Cancelar
                    </Button>
                </Box>
                <Box sx={{ mb: 2 }}>
                    <Button disabled={activeStep === 0} onClick={handleBack}>
                        Atrás
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        disabled={
                            isNextButtonDisabled() || formik.isSubmitting
                        }>
                        {formik.isSubmitting ? (
                            <CircularProgress size={24} />
                        ) : activeStep === steps.length - 1 ? (
                            'Enviar'
                        ) : (
                            'Siguiente'
                        )}
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default IncomeModal;
