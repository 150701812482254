import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, CardContent, Grid, Paper, Typography } from '@mui/material';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectBestScoreSubject,
} from '../../../store/home/fetchSlice';
import { getBestScoreSubject } from '../../../store/home/thunks';
import EmojiEvents from '@mui/icons-material/School';
import { selectComponentHomeBestScoreSubjectStatusServer } from '../../../store/home/componentStatusSelectors';
import { SkeletonCard } from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';
import { ErrorBoundary } from 'react-error-boundary';

const BestScoreSubjectCard = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentHomeBestScoreSubjectStatusServer
    );
    const bestScoreSubject = useFetchResource(
        () =>
            getBestScoreSubject({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectBestScoreSubject,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getBestScoreSubject({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
            selectBestScoreSubject,
            [cycleSelected, schoolId]
        );
    };

    const value = bestScoreSubject?.value;

    return (
        <ErrorBoundary>
            <Box>
                {fetchingStatus === 'pending' && <SkeletonCard items={9} />}

                {fetchingStatus === 'rejected' && (
                    <Error
                        onRetry={reload}
                        message={'Estamos teniendo problemas'}
                    />
                )}

                {fetchingStatus === 'fulfilled' && (
                    <Card
                        sx={{
                            background: '#f45c03',
                            borderRadius: '10px',
                            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                            padding: '15px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            maxWidth: '900px',
                            height: '270px',
                            margin: 'auto',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: '10px',
                            }}
                        >
                            <EmojiEvents
                                style={{
                                    fontSize: 35,
                                    color: 'rgb(232, 247, 255)',
                                    marginRight: '10px',
                                    marginBottom: '10px',
                                }}
                            />
                            <Typography
                                variant="h6"
                                sx={{
                                    fontWeight: 700,
                                    color: '#fff',
                                    marginBottom: '10px',
                                    textAlign: 'center',
                                }}
                            >
                                {bestScoreSubject?.title ||
                                    'Mejor Promedio de Nivel'}
                            </Typography>
                        </Box>
                        <Paper
                            elevation={3}
                            sx={{
                                margin: '16px 0',
                                padding: '16px',
                                width: '100%',
                                backgroundColor: '#f1f1f1',
                                borderRadius: '10px',
                            }}
                        >
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={3}>
                                    <Typography
                                        variant="subtitle2"
                                        sx={{
                                            color: '#808080',
                                            textAlign: 'center',
                                        }}
                                    >
                                        Profesor
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography
                                        variant="subtitle2"
                                        sx={{
                                            color: '#808080',
                                            textAlign: 'center',
                                        }}
                                    >
                                        Materia
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography
                                        variant="subtitle2"
                                        sx={{
                                            color: '#808080',
                                            textAlign: 'center',
                                        }}
                                    >
                                        Grupo
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography
                                        variant="subtitle2"
                                        sx={{
                                            color: '#808080',
                                            textAlign: 'center',
                                        }}
                                    >
                                        Promedio
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            color: '#f45c03',
                                            fontWeight: 600,
                                            textAlign: 'center',
                                        }}
                                    >
                                        {value?.data[2]}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            color: '#f45c03',
                                            fontWeight: 600,
                                            textAlign: 'center',
                                        }}
                                    >
                                        {value?.data[0]}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            color: '#f45c03',
                                            fontWeight: 600,
                                            textAlign: 'center',
                                        }}
                                    >
                                        {value?.data[1]}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={4}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            backgroundColor: '#f45c03',
                                            borderRadius: '10px',
                                            width: '80px',
                                            padding: '10px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'flex-end',
                                            color: '#fff',
                                            fontWeight: 700,
                                            fontSize: '2rem',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {value?.data[3]}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Card>
                )}
            </Box>
        </ErrorBoundary>
    );
};

export default BestScoreSubjectCard;
