import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Services from '../../../service/Connection';
import { STUDENTSUI_EXPIRE_TIME } from '../../../service/const';
import Feedback from '../../../service/Feedback';
import * as _ from 'lodash';
import Authentication from '../../../service/Login';
import { addManyUsers, addOneUser, upsertManyUsers } from '../entities/users';
import {
    addOneStudent,
    upsertManyStudents,
    upsertOneStudent,
} from '../entities/students';
import { addOneItem, setAllItems } from './ItemsSlice';
import {
    selectGroupsById,
    upsertManyGroup,
    upsertOneGroup,
} from '../entities/groups';
import {
    addManyGroupsStudents,
    addOneGroupsStudents,
} from '../entities/groups_students';
import { letterSpacing } from '@mui/system';
import { getExpireIn } from '../../../libs/utils';

const emptyState = {
    expireIn: null,
    ferchingAt: null,
    statusServer: 'idle',
    statusOperation: 'idle',
    didInvalidate: true,
    feedback: {
        title: null,
        message: null,
        payload: null,
    },
};

/**
 * Slice para el settings UI
 */
export const studentsUIServerSlice = createSlice({
    name: 'studentsUI/server',
    initialState: emptyState,
    reducers: {
        /**
         * Invalidar datos de la UI
         */
        invalidate: (state, action) => {
            state.didInvalidate = true;
        },
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return emptyState;
        });

        const pendingServerStatus = (state, action) => {
            state.statusServer = 'pending';
        };

        /**
         * Termina la carga de informacion
         */
        builder.addCase(loadUI.fulfilled, (state, action) => {
            state.expireIn = getExpireIn('STUDENTSUI_EXPIRE_TIME');
            state.ferchingAt = Date.now();
            state.didInvalidate = false;

            state.statusServer = 'fulfilled';
        });
        builder.addCase(loadUI.pending, pendingServerStatus);
        builder.addCase(loadUI.rejected, (state, action) => {
            state.statusServer = 'rejected';
            state.feedback = action.payload.feedback;
        });

        /////////////////////// ACTUALIZAR ALUMNO /////////////////////////

        builder.addCase(updateStudent.rejected, (state, action) => {
            state.statusOperation = 'rejected';
            state.feedback = action.payload.feedback;
        });
        builder.addCase(updateStudent.fulfilled, (state, action) => {
            state.statusOperation = 'fulfilled';
        });
        builder.addCase(updateStudent.pending, (state, action) => {
            state.statusOperation = 'pending';
        });

        /////////////////////// CREAR ALUMNO /////////////////////////

        builder.addCase(createStudent.rejected, (state, action) => {
            state.statusOperation = 'rejected';
            state.feedback = action.payload.feedback;
        });
        builder.addCase(createStudent.fulfilled, (state, action) => {
            state.statusOperation = 'fulfilled';
        });
        builder.addCase(createStudent.pending, (state, action) => {
            state.statusOperation = 'pending';
        });

        /////////////////////// CREAR ALUMNO /////////////////////////

        builder.addCase(findGroups.rejected, (state, action) => {
            state.statusOperation = 'rejected';
            state.feedback = action.payload.feedback;
        });
        builder.addCase(findGroups.fulfilled, (state, action) => {
            state.statusOperation = 'fulfilled';
        });
        builder.addCase(findGroups.pending, (state, action) => {
            state.statusOperation = 'pending';
        });
    },
});

export const { invalidate } = studentsUIServerSlice.actions;

export default studentsUIServerSlice.reducer;

//////////////////// SELECTORES //////////////////
/**
 * Recuperamos las configuraciones de la escuela
 *
 * @param {*} state
 * @returns
 */

export const selectStudentsData = (state) => state.studentsUI.items;

export const selectStudentsServer = (state) => state.studentsUI.server;

export const selectStatusServer = (state) =>
    state.studentsUI.server.statusServer;

export const selectStatusOperation = (state) =>
    state.studentsUI.server.statusOperation;

//////////////// TRUNCKS /////////////////

export const getEmptyItem = (studentId) => {
    return {
        data: {},
        servers: {
            groups: {
                expireIn: null,
                ferchingAt: null,
                statusServer: 'idle',
                statusOperation: 'idle',
                didInvalidate: true,
                feedback: {
                    title: null,
                    message: null,
                    payload: null,
                },
            },
            subjects: {
                expireIn: null,
                ferchingAt: null,
                statusServer: 'idle',
                statusOperation: 'idle',
                didInvalidate: true,
                feedback: {
                    title: null,
                    message: null,
                    payload: null,
                },
            },
            parents: {
                expireIn: null,
                ferchingAt: null,
                statusServer: 'idle',
                statusOperation: 'idle',
                didInvalidate: true,
                feedback: {
                    title: null,
                    message: null,
                    payload: null,
                },
            },
        },
        ui: {
            student_id: studentId,
        },
    };
};

/**
 * Cargar informacion de la UI de alumnos
 */
export const loadUI = createAsyncThunk(
    'studentsUI/server/fetch/data',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback();

        let Auth = new Authentication();

        try {
            let lastPage = 0;
            let currentPage = 0;
            let students = [];

            let lastPageGroups = 0;
            let currentPageGroups = 0;
            let groups = [];

            let professors = await Services.professorsBySchool(schoolId).then(
                (res) => res.data.data
            );

            do {
                currentPage++;

                let studentsResponse = await Services.StudentsBySchool(
                    schoolId,
                    {
                        per_page: 100,
                        page: currentPage,
                        //filters: {status:1}
                    }
                ).then((res) => res.data);
                let studentsPaginated = studentsResponse.data;
                let meta = studentsResponse.meta;
                lastPage = meta.last_page;
                students = students.concat(studentsPaginated);
            } while (currentPage < lastPage);

            do {
                currentPageGroups++;
                let groupsResponse = await Services.groupsBySchool(schoolId, {
                    per_page: 100,
                    page: currentPageGroups,
                }).then((res) => res.data);
                let groupsPaginated = groupsResponse.data;
                let metaGroups = groupsResponse.meta;
                lastPageGroups = metaGroups.last_page;
                groups = groups.concat(groupsPaginated);
            } while (currentPageGroups < lastPageGroups);

            thunkAPI.dispatch(upsertManyStudents(students));
            thunkAPI.dispatch(upsertManyUsers(professors));
            thunkAPI.dispatch(upsertManyGroup(groups));

            const items = students.reduce((preveState, curr) => {
                preveState[curr.student_id] = getEmptyItem(curr.student_id);
                return preveState;
            }, {});

            thunkAPI.dispatch(setAllItems(items));

            return {
                students,
                groups,
            };
        } catch (err) {
            console.log(err);
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } = getState().studentsUI.server;

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

/**
 * Actualizar alumno
 */
export const updateStudent = createAsyncThunk(
    'studentsUI/server/student/update',
    async ({ student }, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            let studentUpdated = await Services.updateStudent(
                student.student_id,
                student
            ).then((res) => res.data.data);

            thunkAPI.dispatch(upsertOneStudent(studentUpdated));

            return {
                studentUpdated,
            };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Crear alumno
 */
export const createStudent = createAsyncThunk(
    'studentsUI/server/student/create',
    async ({ student, primaryGroupId, specialgroups, schoolId }, thunkAPI) => {
        let FeedbackService = new Feedback();
        let Auth = new Authentication();

        try {
            let studentCreated = null;
            let primaryGroup = null;

            if (primaryGroupId == '') {
                studentCreated = await Services.storeStudentInSchool(
                    schoolId,
                    student
                ).then((res) => res.data.data);
            } else {
                // CREAR Y ASIGNAR ALUMNO A UN GRUPO PRINCIPAL

                studentCreated = await Services.createAndSetStudentToGroup(
                    schoolId,
                    primaryGroupId,
                    student
                ).then((res) => res.data.data);

                // ACTUALIZAR DATOS DEL GRUPO

                const store = thunkAPI.getState();
                primaryGroup = selectGroupsById(primaryGroupId)(store);

                if (primaryGroup) {
                    primaryGroup = {
                        ...primaryGroup,
                        students: primaryGroup.students + 1,
                    };
                } else {
                    primaryGroup = await Services.getGroupById(
                        primaryGroupId
                    ).then((i) => i.data.data);

                    // Agregar al alumno al grupo en la store

                    thunkAPI.dispatch(
                        addOneGroupsStudents({
                            student_id: studentCreated.student_id,
                            group_id: primaryGroupId,
                        })
                    );
                }

                thunkAPI.dispatch(upsertOneGroup(primaryGroup));
            }

            let specialGroupsAssigned = [];

            try {
                for (const groupId of specialgroups) {
                    let groupUpdated = await Services.setStudentsToGroup(
                        studentCreated.student_id,
                        groupId
                    ).then((i) => i.data.data);

                    specialGroupsAssigned.push(groupUpdated);
                }
            } catch (err) {
                // NO LANZAR LA EXCEPCION
            }

            if (specialGroupsAssigned.length > 0) {
                let specialGroupsCount = _.sumBy(
                    specialGroupsAssigned,
                    'subjects'
                );
                let groupsCount = specialGroupsAssigned.length;

                if (primaryGroup) {
                    specialGroupsCount += primaryGroup.subjects;
                    groupsCount += 1;
                }

                studentCreated.has_special_groups = true;
                studentCreated.groups = groupsCount;
                studentCreated.amount_subjects = specialGroupsCount;

                thunkAPI.dispatch(upsertManyGroup(specialGroupsAssigned));

                // Agregar al alumno al grupo en la store

                let grousStudentsItems = specialGroupsAssigned.map((i) => ({
                    student_id: studentCreated.student_id,
                    group_id: i.group_id,
                }));

                thunkAPI.dispatch(addManyGroupsStudents(grousStudentsItems));
            }

            thunkAPI.dispatch(addOneStudent(studentCreated));
            thunkAPI.dispatch(
                addOneItem(getEmptyItem(studentCreated.student_id))
            );

            return {
                studentCreated,
                specialGroupsAssigned,
                primaryGroup,
            };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Cargar informacion de la UI de alumnos
 */
export const findGroups = createAsyncThunk(
    'studentsUI/server/find/groups',
    async ({ schoolId, level }, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            const groups = await Services.groupsBySchool(schoolId, {
                filters: {
                    level,
                },
                'not-calculate-properties': true,
                fields: {
                    only: [
                        'group_id',
                        'school_id',
                        'assessor_id',
                        'grade',
                        'group',
                        'turn',
                        'level',
                        'special',
                        'group_type',
                    ],
                },
            }).then((r) => r.data.data);

            return {
                groups,
            };
        } catch (err) {
            console.log(err);
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);
