// components/CustomPagination.js
import React from 'react';
import Pagination from '@mui/material/Pagination';
import { useGridApiContext, useGridSelector } from '@mui/x-data-grid';
import { gridPageSelector, gridPageCountSelector } from '@mui/x-data-grid';
import { Box, Typography } from '@mui/material';

const CustomPagination = ({ items = [] }) => {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: 'auto',
            }}>
            <Box
                sx={{
                    display: 'flex',
                    gap: '10px',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                {items.map((item, index) => (
                    <Box
                        key={index}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '5px',
                        }}>
                        <Box
                            sx={{
                                width: '10px',
                                height: '10px',
                                borderRadius: '50%',
                                backgroundColor: item.color,
                            }}
                        />
                        <Typography variant="body2">{item.text}</Typography>
                    </Box>
                ))}
            </Box>
            <Box>
                <Pagination
                    color="primary"
                    count={pageCount}
                    page={page + 1}
                    onChange={(event, value) =>
                        apiRef.current.setPage(value - 1)
                    }
                />
            </Box>
        </Box>
    );
};

export default CustomPagination;
