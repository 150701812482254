import React from 'react';
import {
    Box,
    Card,
    IconButton,
    Avatar,
    Typography,
    Tooltip,
} from '@mui/material';
import { DataGrid, esES } from '@mui/x-data-grid';
import { Space } from 'antd';
import { PopConfirm } from '../../../components/Tooltips/PopConfirm';
import { ReactComponent as WatchIcon } from '../../../assets/icons/delete-trash-svgrepo-com.svg';
import { ReactComponent as PdfIcon } from '../../../assets/icons/pdf-svgrepo-com.svg';
import PaymentsIcon from '@mui/icons-material/Payments';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import IncomeModal from './ModalIncome';
import NewIncomeModal from './NewModalIncome';
import CustomPagination from '../../../components/utilities/CustomPagination';
import { GridToolBar } from '../../../components/utilities/GridToolBar';
import femaleStudent from '../../../assets/img/female_student.png';
import MaleStudent from '../../../assets/img/male_student.png';
import defaultStudent from '../../../assets/img/default_student.png';
import useThemeColors from '../../../../theme/themes';
import useIncomeMethods from '../../../hooks/useIncomeMethods';
import { DateTime } from 'luxon';
import NoDataOverlay from '../../../components/utilities/NoDataOverlay';
import DeleteSweepTwoToneIcon from '@mui/icons-material/DeleteSweepTwoTone';

const IncomeTable = () => {
    const {
        isModalOpen,
        featureFlagPartialities,
        handleClose,
        handleDeleteConfirm,
        handleDownload,
        handleAddButtonClick,
        filteredRows,
    } = useIncomeMethods();

    const colors = useThemeColors();

    const columns = [
        {
            field: 'payment_id',
            headerName: 'Folio',
            flex: 0.5,
            minWidth: 100,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                return (
                    <Typography color="primary" sx={{ fontWeight: 'bold' }}>
                        {params.value}
                    </Typography>
                );
            },
        },
        {
            field: 'user',
            headerName: 'Alumno',
            flex: 2,
            minWidth: 360,
            renderCell: (params) => {
                const { user } = params.row;

                const avatarSrc =
                    user.url_photo_profile ||
                    (user.gender === 'M'
                        ? femaleStudent
                        : user.gender === 'H'
                            ? MaleStudent
                            : defaultStudent);

                return (
                    <Box display="flex" alignItems="center">
                        <Avatar src={avatarSrc} sx={{ marginRight: 2 }} />
                        <Box>
                            <Typography
                                variant="body1"
                                sx={{
                                    fontWeight: 'bold',
                                    fontSize: '1rem',
                                    width: '100%',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                                title={`${user.name} ${user.last_name} ${user.second_last_name}`}>
                                {`${user.name} ${user.last_name} ${user.second_last_name}`}
                            </Typography>

                            <Typography
                                variant="body2"
                                sx={{
                                    fontSize: '0.875rem',
                                    color: 'textSecondary',
                                    width: '100%',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                                title={`ID: ${user.student_id}`}>
                                ID: {user.student_id}
                            </Typography>

                            <Typography
                                variant="body2"
                                sx={{
                                    fontSize: '0.875rem',
                                    color: 'textSecondary',
                                    width: '100%',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                                title={`Teléfono: ${user.cellphone}`}>
                                Teléfono: {user.cellphone}
                            </Typography>
                        </Box>
                    </Box>
                );
            },
            valueGetter: (params) => {
                const { user } = params.row;
                return `${user.name} ${user.last_name} ${user.second_last_name}`;
            },
        },
        {
            field: 'created_at',
            headerName: 'Fecha de Pago',
            flex: 1.5,
            minWidth: 250,
            valueFormatter: (params) => {
                const date = DateTime.fromSQL(params.value);
                return date.setLocale('es').toLocaleString(DateTime.DATE_FULL);
            },
        },
        {
            field: 'concept',
            headerName: 'Concepto',
            flex: 2,
            minWidth: 300,
            headerAlign: 'center',
            renderCell: (params) => {
                const {
                    value: title,
                    row: { description },
                } = params;

                return (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                        }}>
                        <Typography
                            variant="body1"
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '1rem',
                                color: colors.deepOcean[500],
                                textAlign: 'left',
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                            title={title}>
                            {title}
                        </Typography>

                        <Typography
                            variant="body2"
                            sx={{
                                fontSize: '0.875rem',
                                color: colors.grey[600],
                                textAlign: 'left',
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                            title={description}>
                            {description}
                        </Typography>
                    </Box>
                );
            },
        },

        {
            field: 'payment_method',
            headerName: 'Método de Pago',
            flex: 0.5,
            headerAlign: 'center',
            align: 'center',
            minWidth: 300,

            valueGetter: (params) => {
                const paymentMethodMapping = {
                    1: 'Efectivo',
                    2: 'Transferencia',
                    3: 'Tarjeta de crédito/débito',
                    4: 'Tarjeta prepaga',
                    5: 'Domiciliación bancaria',
                };
                return paymentMethodMapping[params.value] || 'Desconocido';
            },

            renderCell: (params) => {
                const paymentMethodIcons = {
                    Efectivo: {
                        label: 'Efectivo',
                        icon: <PaymentsIcon fontSize="small" />,
                        color: '#4CAF50',
                        background: '#E8F5E9',
                    },
                    Transferencia: {
                        label: 'Transferencia',
                        icon: <AccountBalanceIcon fontSize="small" />,
                        color: '#2196F3',
                        background: '#E3F2FD',
                    },
                    'Tarjeta de crédito/débito': {
                        label: 'Tarjeta de crédito/débito',
                        icon: <CreditCardIcon fontSize="small" />,
                        color: '#FF9800',
                        background: '#FFF3E0',
                    },
                    'Tarjeta prepaga': {
                        label: 'Tarjeta prepaga',
                        icon: <CardGiftcardIcon fontSize="small" />,
                        color: '#9C27B0',
                        background: '#F3E5F5',
                    },
                    'Domiciliación bancaria': {
                        label: 'Domiciliación bancaria',
                        icon: <AccountBalanceWalletIcon fontSize="small" />,
                        color: '#F44336',
                        background: '#FFEBEE',
                    },
                };

                const paymentMethod = paymentMethodIcons[params.value] || {};

                return (
                    <Box
                        px={2}
                        py={0.5}
                        justifyContent="center"
                        alignItems="center"
                        fontWeight="bold"
                        width="100%"
                        height="100%"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor:
                                paymentMethod.background || 'grey.200',
                            color: paymentMethod.color || 'grey.700',
                            borderRadius: 1,
                            px: 2,
                            py: 0.5,
                            fontWeight: 'bold',
                            minWidth: 200,
                        }}>
                        {paymentMethod.icon}
                        <Typography sx={{ marginLeft: 1, fontWeight: 'bold' }}>
                            {paymentMethod.label || 'Desconocido'}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: 'amount',
            headerName: 'Importe',
            flex: 1,
            headerAlign: 'right',
            align: 'right',
            minWidth: 100,

            renderCell: (params) => {
                const formattedAmount = new Intl.NumberFormat('es-MX', {
                    style: 'currency',
                    currency: 'MXN',
                }).format(params.value);

                return (
                    <Typography
                        variant="body2"
                        sx={{
                            fontWeight: 'bold',
                            fontSize: '1rem',
                            color: 'text.primary',
                        }}
                        title={formattedAmount}
                        noWrap>
                        {formattedAmount}
                    </Typography>
                );
            },
        },
        {
            field: 'actions',
            headerName: 'Acciones',
            flex: 1,
            align: 'center',
            minWidth: 200,
            headerAlign: 'center',
            renderCell: (params) => {
                return (
                    <>
                        <Tooltip title={'Descargar recibo de pago'} arrow>
                            <IconButton
                                onClick={() =>
                                    handleDownload(
                                        params.row.payment_id,
                                        params.row.current_term
                                    )
                                }
                                sx={{ color: colors.lavender[500] }}>
                                <PdfIcon
                                    style={{
                                        width: 30,
                                        height: 30,
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                        <Space>
                            <PopConfirm
                                title="¿Estás seguro de que deseas continuar?"
                                message="Confirma tu decisión y sigamos adelante. Recuerda No podrás recuperar este registro después de eliminarlo."
                                icon={WatchIcon}
                                IconButton={DeleteSweepTwoToneIcon}
                                textButtonConfirm="Confirmar"
                                textButtonCancel="Cancelar"
                                messageButton={'Eliminar'}
                                color="#e24141"
                                iconSize={80}
                                placement='right'
                                topIcon='-17%'
                                onConfirm={() =>
                                    handleDeleteConfirm(params.row.payment_id)
                                }
                            />
                        </Space>
                    </>
                );
            },
        },
    ];

    return (
        <>
            <Card
                style={{
                    color: 'white',
                    borderRadius: '15px',
                    width: '100%',
                    height: '100%',
                    paddingTop: '10px',
                }}>
                <DataGrid
                    rowHeight={80}
                    rows={filteredRows}
                    columns={columns}
                    autoHeight
                    pageSize={15}
                    rowsPerPageOptions={[10, 25, 50]}
                    localeText={{
                        ...esES.components.MuiDataGrid.defaultProps.localeText,
                        toolbarColumns: '',
                        toolbarFilters: '',
                        toolbarDensity: '',
                        toolbarExport: '',
                    }}
                    components={{
                        Toolbar: GridToolBar,
                        Pagination: CustomPagination,
                        NoRowsOverlay: NoDataOverlay,
                        NoResultsOverlay: NoDataOverlay,
                    }}
                    componentsProps={{
                        toolbar: {
                            onAddButtonClick: handleAddButtonClick,
                            ButtonText: 'Pago',
                        },
                        noResultsOverlay: {
                            message:
                                'No se encontraron resultados para la búsqueda',
                        },
                        noRowsOverlay: {
                            message: 'No hay pagos registrados',
                        },
                    }}
                />
            </Card>
            {featureFlagPartialities ? (
                <NewIncomeModal open={isModalOpen} onClose={handleClose} />
            ) : (
                <>
                    <IncomeModal
                        isModalOpen={isModalOpen}
                        onClose={handleClose}
                    />
                </>
            )}
        </>
    );
};

export default IncomeTable;
