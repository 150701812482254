import React, { useEffect, useState } from 'react';
import {
    Card,
    CardContent,
    Typography,
    Box,
    CircularProgress,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { selectTotalGains } from '../../../store/home/kpis';
import { ReactComponent as MoneyBagIcon } from '../../../assets/icons/money-tgrant-success-line.svg';
import MonthlyIncome from './MonthlyIncome';
import ErrorPage from '../../../components/Error/ErrorPage';
import { Statistic } from 'antd';
import CountUp from 'react-countup';
import formatter from '../../../components/utilities/animations';
import {
    loadUIMonthlyPaymentsCount,
    selectTableStatus_monthly_payments_count,
} from '../../../store/home/tables';
import { useAuth, useFeedback } from '../../../../hooks';

const GainsCard = () => {
    ////////////////////////// SHARE STATE //////////////////////////
    const totalGains = useSelector(selectTotalGains).value;
    const Auth = useAuth();
    const dispatch = useDispatch();
    const feedbackApp = useFeedback();

    const StatusServer_monthly_payments_count = useSelector(
        selectTableStatus_monthly_payments_count
    );

    ////////////////////////// ACTIONS //////////////////////////
    const reloadloadUIMonthlyPaymentsCount = () => {
        dispatch(loadUIMonthlyPaymentsCount(Auth.getUser().school_id))
            .unwrap()
            .then((response) => {})
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    return (
        <Card
            style={{
                backgroundColor: '#4169e2',
                color: 'white',
                borderRadius: '15px',
                width: '100%',
                height: '50em',
                padding: '10px',
            }}>
            <CardContent style={{ position: 'relative' }}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        paddingBottom: '10px',
                    }}></div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>
                    <Box
                        style={{
                            backgroundColor: '#6383e6',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '10px',
                            paddingBottom: '10px',
                            borderRadius: '100px',
                        }}>
                        <MoneyBagIcon style={{ width: 50, height: 50 }} />
                    </Box>
                </div>
                <Statistic
                    title={
                        <Typography
                            variant="h5"
                            component="div"
                            style={{
                                marginTop: '10px',
                                color: '#ffffff',
                                fontWeight: '100',
                            }}>
                            Ganancias
                        </Typography>
                    }
                    value={totalGains}
                    precision={1}
                    valueStyle={{
                        fontWeight: 'bold',
                        fontSize: '50px',
                        color: '#ffffff',
                    }}
                    prefix={'$'}
                    formatter={formatter}
                />

                <div
                    style={{
                        height: '500px',
                        marginTop: '20px',
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    {StatusServer_monthly_payments_count === 'fulfilled' && (
                        <MonthlyIncome />
                    )}
                    {StatusServer_monthly_payments_count === 'pending' && (
                        <CircularProgress
                            sx={{
                                color: '#ffffff',
                            }}
                        />
                    )}

                    {StatusServer_monthly_payments_count === 'rejected' && (
                        <ErrorPage
                            onRetry={reloadloadUIMonthlyPaymentsCount}
                            maxHeight={'100%'}
                            fontHeight={'20px'}
                            message={
                                'Ocurrió un error ¿Podrías intentarlo una vez más? ¡Por favor! 🤞'
                            }
                        />
                    )}
                </div>
            </CardContent>
        </Card>
    );
};

export default GainsCard;
