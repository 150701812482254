import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Card,
    CardContent,
    Grid,
    Paper,
    Tooltip,
    Typography,
} from '@mui/material';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectLastAttendanceProfessor,
} from '../../../store/home/fetchSlice';
import { getLastAttendanceProfessor } from '../../../store/home/thunks';
import { SkeletonCard, SkeletonLongCard } from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';
import { selectComponentHomeLastAttendanceProfessorStatusServer } from '../../../store/home/componentStatusSelectors';
import SchoolIcon from '@mui/icons-material/School';
import { DoNotDisturbOn } from '@mui/icons-material';
import { ErrorBoundary } from 'react-error-boundary';

const LastAttendanceProfessorCard = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentHomeLastAttendanceProfessorStatusServer
    );

    const lastAttendanceProfessor = useFetchResource(
        () =>
            getLastAttendanceProfessor({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectLastAttendanceProfessor,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getLastAttendanceProfessor({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
            selectLastAttendanceProfessor,
            [cycleSelected, schoolId]
        );
    };

    return (
        <ErrorBoundary>
            <Box>
                {fetchingStatus === 'pending' && <SkeletonCard items={9} />}

                {fetchingStatus === 'rejected' && (
                    <Error
                        onRetry={reload}
                        message={'Estamos teniendo problemas'}
                    />
                )}
                {fetchingStatus === 'fulfilled' && (
                    <Card
                        sx={{
                            background: '#a903f4',
                            borderRadius: '10px',
                            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                            padding: '15px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            maxWidth: '900px',
                            height: '270px',
                            margin: 'auto',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: '10px',
                            }}
                        >
                            <DoNotDisturbOn
                                style={{
                                    fontSize: 35,
                                    color: 'rgb(232, 247, 255)',
                                    marginRight: '10px',
                                    marginBottom: '10px',
                                }}
                            />
                            <Typography
                                variant="h6"
                                sx={{
                                    fontWeight: 700,
                                    color: '#fff',
                                    marginBottom: '10px',
                                    textAlign: 'center',
                                }}
                            >
                                {lastAttendanceProfessor?.title ||
                                    'Profesor con menos asistencias'}
                            </Typography>
                        </Box>
                        <Paper
                            elevation={3}
                            sx={{
                                margin: '16px 0',
                                padding: '16px',
                                width: '100%',
                                backgroundColor: '#f1f1f1',
                                borderRadius: '10px',
                            }}
                        >
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={6}>
                                    <Typography
                                        variant="subtitle2"
                                        sx={{
                                            color: '#808080',
                                            textAlign: 'center',
                                        }}
                                    >
                                        Profesor
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography
                                        variant="subtitle2"
                                        sx={{
                                            color: '#808080',
                                            textAlign: 'center',
                                        }}
                                    >
                                        Promedio
                                    </Typography>
                                </Grid>

                                <Grid item xs={6}>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            color: '#a903f4',
                                            fontWeight: 600,
                                            textAlign: 'center',
                                        }}
                                    >
                                        {lastAttendanceProfessor.value
                                            ?.data[0] ||
                                            'Sin datos disponibles'}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            backgroundColor: '#a903f4',
                                            borderRadius: '10px',
                                            width: '200px',
                                            padding: '10px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'flex-start',
                                            color: '#fff',
                                            fontWeight: 700,
                                            fontSize: '2rem',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {lastAttendanceProfessor.value
                                            ?.data[1] !== undefined
                                            ? lastAttendanceProfessor.value
                                                  .data[1] === 0
                                                ? '0'
                                                : lastAttendanceProfessor.value
                                                      .data[1]
                                            : 'Sin datos disponibles'}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Card>
                )}
            </Box>
        </ErrorBoundary>
    );
};

export default LastAttendanceProfessorCard;
