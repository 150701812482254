import { LoadingButton } from '@mui/lab';
import {
    Alert,
    AlertTitle,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    Snackbar,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import Services from '../../../service/Connection';
import { selectSettingBySchoolId } from '../../../store/slices/entities/settings';
import { useAuth } from '../../../hooks';
import { useDispatch, useSelector } from 'react-redux';
import LockIcon from '@mui/icons-material/Lock';
import { levelsNames } from '../../../service/const';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faLayerGroup,
    faBoxArchive,
    faBell,
} from '@fortawesome/free-solid-svg-icons';
import Feedback from '../../../service/Feedback';
import useFeedback from '../../../hooks/useFeedback';
import {
    CreateSnaphot,
    selectStatusSnaphot,
} from '../../../store/slices/settingsUI';

/**
 * Componente para iniciar el proceso de corte de ciclo
 *
 * @param {*} param0
 *
 * @returns
 */
const MakeSnaphotModal = ({ open, setOpen }) => {
    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id;
    let FeedbackService = new Feedback();
    const feedbackApp = useFeedback();
    const dispatch = useDispatch();

    const statusServer = useSelector(selectStatusSnaphot);

    ////////////////// SHARED STATE ///////////////
    const config = useSelector(selectSettingBySchoolId(schoolId));

    let titleBarBackground = config.find(
        (res) => res.key === 'theme-color-title-bar'
    );
    let fontColor = config.find((res) => res.key === 'theme-color-font');

    ////////////////// LOCAL STATE ////////////////
    const [cycle, setCycle] = useState({
        password: '',
        password_confirmation: '',
        cycle: '',
        level: -1,
    });

    /////////////////// ACTIONS //////////////////////

    useEffect(() => {
        if (!open) {
            setCycle({
                password: '',
                password_confirmation: '',
                cycle: '',
                level: -1,
            });
        }
    }, [open]);

    /**
     * Escucha eventos del teclado en input de contraseña
     *
     * @param {*} event
     */
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onCreateSnaphot();
        }
    };

    /**
     * Actualizacion de los inputs de formulario de nombre de usuario
     *
     * @param {*} event
     */
    const onInputChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;

        setCycle({ ...cycle, [name]: value });
    };

    /**
     * Solicitu cancelada por el usuario
     */
    const handleClose = () => {
        setOpen(false);
    };

    /**
     * Crear un ciclo escolar
     */

    const onCreateSnaphot = () => {
        dispatch(CreateSnaphot({ schoolId, cycles: cycle }))
            .unwrap()
            .then(() => {
                feedbackApp.showFeedback({
                    title: 'El proceso iniciará en breve',
                });
                setOpen(false);
            })
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    //////////////////////// VALIDATIONS ////////////////////

    const levelValid = () => {
        return {
            invalid: cycle.level == -1,
            message: 'Campo requerido',
        };
    };

    const cycleValid = () => {
        return {
            invalid: !/^[0-9]{4}_[0-9]{4}$/.test(cycle.cycle),
            message: 'Campo requerido o formato incorrecto',
        };
    };

    const passwordValidation = () => {
        return {
            invalid: cycle.password_confirmation == '',
            message: 'Campo requerido',
        };
    };

    const passwordConfirmValidation = () => {
        return {
            invalid: !(
                cycle.password != '' &&
                cycle.password == cycle.password_confirmation
            ),
            message: 'Los valores no coinciden',
        };
    };

    const formPasswordinValid = () => {
        return (
            levelValid().invalid ||
            cycleValid().invalid ||
            passwordValidation().invalid ||
            passwordConfirmValidation().invalid
        );
    };

    return (
        <Dialog
            open={open}
            onClose={(event, reason) => {
                if (reason == 'escapeKeyDown') {
                    handleClose();
                }
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={'md'}
            disableEscapeKeyDown={statusServer == 'pending'}>
            <DialogTitle
                id="alert-dialog-title"
                style={{
                    backgroundColor: titleBarBackground.value,
                    color: fontColor.value,
                }}>
                <Typography variant="h6" component="div">
                    Corte de ciclo escolar
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container sx={{ mt: 3 }}>
                    <Grid item xs={12} md={6}>
                        <DialogContentText
                            id="alert-dialog-description"
                            sx={{ pr: 2 }}>
                            <Typography variant="h5" component="div">
                                El proceso consiste en:
                            </Typography>

                            <List dense={true}>
                                <ListItem>
                                    <ListItemIcon>
                                        <FontAwesomeIcon
                                            icon={faBoxArchive}
                                            size="2x"
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Respaldar información"
                                        secondary={
                                            'Toda la informacion del ciclo actual será transferida a un respaldo'
                                        }
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <FontAwesomeIcon
                                            icon={faLayerGroup}
                                            size="2x"
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Organizar todos los grupos."
                                        secondary={
                                            'Ordenar grupo de manera automática'
                                        }
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <FontAwesomeIcon
                                            icon={faBell}
                                            size="2x"
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Te mantendremos al tanto."
                                        secondary={
                                            'Te enviaremos notificaciones en cada parte del proceso'
                                        }
                                    />
                                </ListItem>
                            </List>

                            <Alert severity="info">
                                <AlertTitle>
                                    Si tienes más de un nivel escolar
                                </AlertTitle>
                                Inicia el corte de ciclo del nivel más{' '}
                                <strong>alto</strong> al más{' '}
                                <strong>bajo</strong>
                            </Alert>
                        </DialogContentText>
                    </Grid>
                    <Grid xs={12} md={6}>
                        <Stack spacing={2}>
                            <TextField
                                id="cycle"
                                name="cycle"
                                label="Ciclo escolar (xxxx_xxxx)"
                                variant="outlined"
                                size="small"
                                fullWidth
                                value={cycle.cycle}
                                onChange={onInputChange}
                                error={cycleValid().invalid}
                                helperText={
                                    cycleValid().invalid
                                        ? cycleValid().message
                                        : false
                                }
                                onKeyDown={handleKeyDown}
                            />

                            <FormControl
                                variant="standard"
                                sx={{ width: '100%' }}
                                error={levelValid().invalid}>
                                <InputLabel id="lbl-nivel">
                                    Nivel escolar
                                </InputLabel>
                                <Select
                                    labelId="lbl-nivel"
                                    id="level"
                                    value={cycle.level}
                                    onChange={onInputChange}
                                    label="level escolar"
                                    name="level"
                                    fullWidth>
                                    <MenuItem value={-1} selected disabled>
                                        Selecciona una opción
                                    </MenuItem>
                                    {levelsNames.map((i) => {
                                        return (
                                            <MenuItem key={i.key} value={i.key}>
                                                {i.title}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                {levelValid().invalid && (
                                    <FormHelperText>
                                        {levelValid().message}
                                    </FormHelperText>
                                )}
                            </FormControl>

                            <Divider>Por seguridad</Divider>

                            <TextField
                                id="new-password"
                                name="password"
                                type="password"
                                size="small"
                                fullWidth
                                onChange={onInputChange}
                                value={cycle.password}
                                error={passwordValidation().invalid}
                                helperText={
                                    passwordValidation().invalid
                                        ? passwordValidation().message
                                        : false
                                }
                                label="Contraseña"
                                variant="outlined"
                                onKeyDown={handleKeyDown}
                            />

                            <TextField
                                id="new-password"
                                name="password_confirmation"
                                type="password"
                                size="small"
                                fullWidth
                                onChange={onInputChange}
                                value={cycle.password_confirmation}
                                error={passwordConfirmValidation().invalid}
                                helperText={
                                    passwordConfirmValidation().invalid
                                        ? passwordConfirmValidation().message
                                        : false
                                }
                                label="Repetir contraseña"
                                variant="outlined"
                                onKeyDown={handleKeyDown}
                            />
                        </Stack>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    size="small"
                    color="primary"
                    onClick={onCreateSnaphot}
                    loading={statusServer == 'pending'}
                    loadingPosition="start"
                    startIcon={<LockIcon />}
                    variant="contained"
                    disabled={formPasswordinValid()}>
                    Iniciar
                </LoadingButton>
                <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={handleClose}
                    disabled={statusServer == 'pending'}>
                    Cancelar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default MakeSnaphotModal;
