import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFeedback } from '../../../../../hooks';
import { Avatar, Box, Card, Stack, Switch, Typography } from '@mui/material';
import { DataGrid, esES } from '@mui/x-data-grid';
import DescriptionIcon from '@mui/icons-material/Description';
import SendIcon from '@mui/icons-material/Send';
import { ReportCreateModal } from './ReportSubjectModal';
import {
    newStoreAssitsList,
    selectAtendenceForSubject,
    selectClassroomViewmodelAssistOperationtatusById,
} from '../../../../store/meGroups/classrooms';
import { selectGroupsById } from '../../../../../store/slices/entities/groups';
import { DateTime } from 'luxon';
import NoDataOverlay from '../../../../../components/utilities/NoDataOverlay';
import { GridToolBar } from '../../../../../components/utilities/GridToolBar';
import CustomPagination from '../../../../../components/utilities/CustomPagination';
import Header from '../../../../../components/utilities/Header';
import ConfirmLeaveModal from '../../../../../components/Modal/ConfirmLeaveModal';
import * as _ from 'lodash';

const SubjectAssistanceTable = ({ classroom }) => {
    const currentDate = DateTime.local().toFormat('yyyy-MM-dd');
    const [allChecked, setAllChecked] = useState(true);

    /////////////////// HOOKS //////////

    const dispatch = useDispatch();
    const feedbackApp = useFeedback();

    ///////////// SHARED STATE /////////////////

    const assists = useSelector(
        selectAtendenceForSubject(classroom, currentDate)
    );
    const saveAssistsStatus = useSelector(
        selectClassroomViewmodelAssistOperationtatusById(classroom)
    );
    const { students, subject, assistancePerformed, assistsToday } = assists;

    const group = useSelector(selectGroupsById(subject.group_id));

    ///////////////////////// LOCAL STATE /////////////////////////
    const [checkedStudent, setCheckedStudent] = useState([]);
    const [selectAllAttendance, setSelectAllAttendance] = useState(true);
    const [attendanceList, setAttendanceList] = useState([]);

    const [openModal, setOpenModal] = useState(false);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

    //////////////////////// COLUMNS ////////////////////
    const studentColumns = [
        {
            field: 'url_photo_profile',
            headerName: 'Alumno',
            flex: 0.8,
            minWidth: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            filterable: false,
            renderCell: (props) => (
                <Avatar
                    alt="Estudiante"
                    src={props.value}
                    sx={{ display: { xs: 'none', sm: 'flex' } }}
                />
            ),
        },
        {
            field: 'Nombre',
            headerName: 'Nombre',
            flex: 1,
            minWidth: 100,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            valueGetter: (params) => {
                return `${params.row.last_name} ${params.row.second_last_name} ${params.row.name}`;
            },
        },
        {
            field: 'assist',
            headerName: 'Asistencia',
            flex: 1,
            minWidth: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            filterable: false,
            renderHeader: (params) => (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="caption" sx={{ mb: 0, mt: 1 }}>
                        {allChecked
                            ? 'Falta a todos los alumnos'
                            : 'Asistencia de todos los alumnos'}
                    </Typography>
                    <Switch
                        edge="end"
                        checked={allChecked}
                        onChange={handlerSelectAllAttendance}
                        disabled={assistancePerformed}
                        sx={{
                            '& .MuiSwitch-switchBase.Mui-checked': {
                                color: '#05D590',
                            },
                            '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track':
                                {
                                    backgroundColor: '#05D590',
                                },
                        }}
                    />
                </Box>
            ),
            renderCell: (props) => (
                <Switch
                    edge="end"
                    onChange={(event) =>
                        handlerChangeAssitsList(props.id, event)
                    }
                    checked={props.value}
                    disabled={assistancePerformed}
                    sx={{
                        '& .MuiSwitch-switchBase.Mui-checked': {
                            color: '#05D590',
                        },
                        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track':
                            { backgroundColor: '#05D590' },
                    }}
                />
            ),
        },
    ];

    //////////////////////// EFFECTS ////////////////////
    useEffect(() => {
        let studentsAssitsList = students.map((i) => {
            let todayAssist = assistsToday.find(
                (assist) => assist.student_id == i.student_id
            );
            return {
                ...i,
                assist: todayAssist ? todayAssist.assistance : true,
            };
        });

        studentsAssitsList = _.orderBy(
            studentsAssitsList,
            ['last_name', 'second_last_name', 'name'],
            ['asc', 'asc']
        );
        setAttendanceList(studentsAssitsList);
    }, []);

    //////////////////////// HANDLERS ////////////////////

    const handleSelectionChange = (selectionModel) => {
        const selectedStudents = selectionModel.map((studentId) => {
            return students.find((student) => student.student_id === studentId);
        });
        setCheckedStudent(selectedStudents);
    };

    const handlerSaveAssitsList = () => {
        let assitsRequest = attendanceList.map((item) => ({
            assistance: item.assist === true ? 1 : 0,
            date: DateTime.local().toFormat('yyyy-MM-dd'),
            student_id: item.student_id,
            subject_id: subject.subject_id,
        }));

        dispatch(
            newStoreAssitsList({
                postData: assitsRequest,
                groupId: subject.group_id,
                subjectId: subject.subject_id,
                uuid: classroom,
            })
        )
            .unwrap()
            .then(() => {
                feedbackApp.showFeedback({
                    title: 'Guardado correctamente',
                });
                setHasUnsavedChanges(false);
            })
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    const handlerChangeAssitsList = (studentID, event) => {
        const updatedList = attendanceList.map((item) => ({
            ...item,
            assist:
                item.student_id === studentID
                    ? event.target.checked
                    : item.assist,
        }));
        setAttendanceList(updatedList);

        const areAllChecked = updatedList.every((item) => item.assist === true);
        setHasUnsavedChanges(true);
        setAllChecked(areAllChecked);
    };

    const handlerSelectAllAttendance = () => {
        const newSelectAllState = !selectAllAttendance;
        setSelectAllAttendance(newSelectAllState);

        const updatedStudents = attendanceList.map((student) => ({
            ...student,
            assist: newSelectAllState,
        }));
        setAttendanceList(updatedStudents);
        setHasUnsavedChanges(true);
        setAllChecked(newSelectAllState);
    };

    const openReportModal = () => {
        if (
            group.assessor_id === null ||
            group.assessor_id === 0 ||
            group.assessor_id === undefined
        ) {
            feedbackApp.showFeedback({
                title: 'El grupo no cuenta con un orientador',
            });
        } else {
            setOpenModal(true);
        }
    };

    const getCurrentDate = () => {
        const fecha = DateTime.local();
        const formattedDate = fecha
            .setLocale('es')
            .toFormat("dd 'de' LLLL yyyy");
        return formattedDate;
    };

    //////////////////////// RENDER ////////////////////
    const anotherButtons = [
        {
            text: 'Guardar',
            icon: <SendIcon />,
            onClick: handlerSaveAssitsList,
            loading: saveAssistsStatus === 'pending',
            loadingPosition: 'end',
            disabled: assistancePerformed,
        },
        {
            text: 'Reportar Alumno(s)',
            icon: <DescriptionIcon />,
            onClick: openReportModal,
            disabled: checkedStudent.length === 0,
        },
    ];

    const paginationItems = [{ color: '#1c8d00', text: 'En repetición' }];

    return (
        <>
            <Header
                title="Asistencias"
                subtitle={`Lista de asistencias del dia ${getCurrentDate()}`}
            />
            <Stack
                direction="row"
                sx={{ width: '100%', flexWrap: 'wrap', gap: '10px' }}
            ></Stack>
            <Card
                sx={{
                    color: 'white',
                    borderRadius: '10px ',
                    width: '100%',
                    height: '100%',
                }}
            >
                <DataGrid
                    getRowId={(row) => row.student_id}
                    localeText={{
                        ...esES.components.MuiDataGrid.defaultProps.localeText,
                        toolbarColumns: '',
                        toolbarFilters: '',
                        toolbarDensity: '',
                        toolbarExport: '',
                    }}
                    rows={attendanceList}
                    checkboxSelection
                    disableRowSelectionOnClick
                    columns={studentColumns}
                    components={{
                        NoRowsOverlay: NoDataOverlay,
                        NoResultsOverlay: NoDataOverlay,
                        Toolbar: GridToolBar,
                        Pagination: CustomPagination,
                    }}
                    componentsProps={{
                        panel: {
                            placement: 'bottom-end',
                        },
                        toolbar: {
                            AnotherButtons: anotherButtons,
                        },
                        noResultsOverlay: {
                            message:
                                'No se encontraron resultados para la búsqueda',
                        },
                        noRowsOverlay: {
                            message: 'No hay Asistencias para mostrar',
                        },
                        pagination: {
                            items: paginationItems,
                        },
                    }}
                    getCellClassName={(props) => {
                        if (props.field === '__check__') {
                            return props.row?.in_repetition
                                ? 'in_repetition_bordered'
                                : '';
                        }
                    }}
                    pageSize={100}
                    rowsPerPageOptions={[100]}
                    disableSelectionOnClick
                    disableColumnMenu
                    autoHeight
                    disableDensitySelector
                    onSelectionModelChange={handleSelectionChange}
                />
            </Card>
            {openModal && (
                <ReportCreateModal
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    students={checkedStudent}
                    group={group}
                />
            )}
            <ConfirmLeaveModal hasUnsavedChanges={hasUnsavedChanges} />
        </>
    );
};

export default SubjectAssistanceTable;
