import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Card, Grid, CardContent, Paper } from '@mui/material';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectWorstScoreSubject,
} from '../../../store/subjects/fetchSlice';
import { getWorstScoreSubject } from '../../../store/subjects/thunks';
import { selectComponentHomeWorstScoreSubjectStatusServer } from '../../../store/subjects/componentsStatusSelectors';
import Error from '../../../../components/Feedback/Error';
import { SkeletonSubjectsCards } from '../../../components/Skeleton';
import { Report } from '@mui/icons-material';

const WorstScoreSubject = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentHomeWorstScoreSubjectStatusServer
    );

    const worstScoreSubject = useFetchResource(
        () =>
            getWorstScoreSubject({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectWorstScoreSubject,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getWorstScoreSubject({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
            selectWorstScoreSubject,
            [cycleSelected, schoolId]
        );
    };

    const value = worstScoreSubject?.value;

    return (
        <Box>
            {fetchingStatus === 'pending' && (
                <SkeletonSubjectsCards items={9} />
            )}

            {fetchingStatus === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {fetchingStatus === 'fulfilled' && (
                <Card
                    sx={{
                        background: '#f44336',
                        borderRadius: '10px',
                        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                        padding: '15px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        maxWidth: '900px',
                        margin: 'auto',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '10px',
                        }}
                    >
                        <Report
                            style={{
                                fontSize: 35,
                                color: 'rgb(232, 247, 255)',
                                marginRight: '10px',
                                marginBottom: '10px',
                            }}
                        />
                        <Typography
                            variant="h6"
                            sx={{
                                fontWeight: 700,
                                color: '#fff',
                                marginBottom: '10px',
                                textAlign: 'center',
                            }}
                        >
                            {worstScoreSubject?.title ||
                                'Peor Promedio de Nivel'}
                        </Typography>
                    </Box>

                    <Paper
                        elevation={3}
                        sx={{
                            margin: '16px 0',
                            padding: '16px',
                            width: '100%',
                            backgroundColor: '#f1f1f1',
                            borderRadius: '10px',
                        }}
                    >
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={3}>
                                <Typography
                                    variant="subtitle2"
                                    sx={{
                                        color: '#808080',
                                        textAlign: 'center',
                                    }}
                                >
                                    Profesor
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography
                                    variant="subtitle2"
                                    sx={{
                                        color: '#808080',
                                        textAlign: 'center',
                                    }}
                                >
                                    Materia
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography
                                    variant="subtitle2"
                                    sx={{
                                        color: '#808080',
                                        textAlign: 'center',
                                    }}
                                >
                                    Grupo
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography
                                    variant="subtitle2"
                                    sx={{
                                        color: '#808080',
                                        textAlign: 'center',
                                    }}
                                >
                                    Promedio
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: '#f44336',
                                        fontWeight: 600,
                                        textAlign: 'center',
                                    }}
                                >
                                    {value?.data[2]}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: '#f44336',
                                        fontWeight: 600,
                                        textAlign: 'center',
                                    }}
                                >
                                    {value?.data[0]}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: '#f44336',
                                        fontWeight: 600,
                                        textAlign: 'center',
                                    }}
                                >
                                    {value?.data[3]}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={4}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Box
                                    sx={{
                                        backgroundColor: '#f44336',
                                        borderRadius: '10px',
                                        width: '200px',
                                        padding: '10px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        color: '#fff',
                                        fontWeight: 700,
                                        fontSize: '2rem',
                                        textAlign: 'center',
                                    }}
                                >
                                    {value?.data[1]}
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Card>
            )}
        </Box>
    );
};

export default WorstScoreSubject;
