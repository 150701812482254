import React from 'react';
import { Stack, Typography } from '@mui/material';
import { ReactComponent as NoDataIcon } from '../../assets/icons/avatar-thinking-6-svgrepo-com.svg';

const NoDataOverlay = ({ message }) => (
    <Stack
        height="100%"
        alignItems="center"
        justifyContent="center"
        spacing={2}
        sx={{ textAlign: 'center' }}>
        <NoDataIcon style={{ width: '80px', height: '80px' }} />
        <Typography variant="subtitle2" sx={{ maxWidth: '200px' }}>
            {message}
        </Typography>
    </Stack>
);

export default NoDataOverlay;
