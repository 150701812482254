import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../../hooks';
import useFeedback from '../../../hooks/useFeedback';

import {
    selectStatusOperation,
    updateImageSchool,
} from '../../../store/slices/settingsUI';
import { selectSettingBySchoolId } from '../../../store/slices/entities/settings';

export const useImage = (entityKey) => {
    ////////// HOOKS /////
    const Auth = useAuth();
    const dispatch = useDispatch();
    const feedbackApp = useFeedback();

    const schoolId = Auth.getUser().school_id;
    const config = useSelector(selectSettingBySchoolId(schoolId));
    const statusOperation = useSelector(selectStatusOperation);

    const [loading, setLoading] = useState('idle');
    const [imageSrc, setImageSrc] = useState(null);

    const entitySetting = config?.find((res) => res.key === entityKey);

    useEffect(() => {
        if (entitySetting) {
            setImageSrc(entitySetting.value);
        } else {
            setImageSrc('U');
        }
    }, [entitySetting]);

    useEffect(() => {
        if (
            (statusOperation === 'rejected' ||
                statusOperation === 'fulfilled') &&
            loading === 'pending'
        ) {
            setLoading('idle');
        }
    }, [statusOperation]);

    const changePhotoEvent = (event) => {
        if (!/\.(jpeg|jpg|png|gif|webp)$/i.test(event.target.files[0]?.name)) {
            feedbackApp.showFeedback({
                title: 'El archivo debe ser una imagen de tipo: jpeg, png, jpg, webp o gif',
            });
            event.target.value = '';
            return;
        }

        if (!entitySetting) {
            feedbackApp.showFeedback({
                title: 'Error: No se pudo actualizar la imagen porque no se encontró la configuración',
            });
            return;
        }

        setLoading('pending');
        const settingId = entitySetting.configuration_id;
        const imgRemplazar = event.target.files[0];

        const formData = new FormData();
        formData.append('value', imgRemplazar);
        formData.append('_method', 'PATCH');

        dispatch(updateImageSchool({ settingId, formData }))
            .unwrap()
            .then(() => {
                event.target.value = '';
                feedbackApp.showFeedback({
                    title: 'Actualizado correctamente',
                });
            })
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({ title: feedback.title });
            });
    };

    return {
        schoolId,
        imageSrc,
        changePhotoEvent,
        isLoading: loading === 'pending',
    };
};
