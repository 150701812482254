import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFeedback } from '../../../../hooks';
import { Avatar, Box, Button, Card, CardContent, Tooltip } from '@mui/material';
import ListIcon from '@mui/icons-material/List';
import { DataGrid, esES, GridToolbar } from '@mui/x-data-grid';
import GridCell from './GridCellExpand';
import {
    noticeReprobationSelected,
    selectReprobationNoticesFilter,
    updateReprobationFilters,
} from '../../../store/reprobation/uiSlice';
import {
    fetchReprobationNoticesData,
    selectFetchingStatus,
} from '../../../store/reprobation/fetchSlice';
import { selectAllReprobationNoticesItems } from '../../../store/reprobation/itemsSlice';
import { Error } from '../../../../components/Feedback';
import SkeletonPage from '../../../components/SkeletonPage';
import UsersReprobationModal from './UsersReprobationModal';
import NoDataOverlay from '../../../../components/utilities/NoDataOverlay';
import { GridToolBar } from '../../../../components/utilities/GridToolBar';
import CustomPagination from '../../../../components/utilities/CustomPagination';

const ReprobationTable = ({ schoolId, type }) => {
    ////////// HOOKS  ///////////
    const dispatch = useDispatch();
    const feedbackApp = useFeedback();

    /////////// SHARED STATE /////////////

    const reprobationNotices = useSelector(selectAllReprobationNoticesItems);
    const fetchingStatus = useSelector(selectFetchingStatus);
    const reprobationFilters = useSelector(selectReprobationNoticesFilter);

    ///////// LOCAL STATE //////////////
    const [openUsersModal, setOpenUsersModal] = useState(false);
    const [currentViewModel, setCurrentViewModel] = useState({});
    const [modalType, setModalType] = useState('');

    const columns = [
        {
            field: 'emisor',
            headerName: 'Emisor',
            flex: 1,
            minWidth: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: ({ row }) => {
                let emmiterName = 'Usuario no encontrado';

                if (row.emitter) {
                    let emitter = row.emitter;
                    emmiterName = `${emitter.last_name} ${emitter.second_last_name} ${emitter.name}`;
                }

                return emmiterName;
            },
            renderCell: (params) => {
                let emitter = params.row.emitter;

                if (!emitter) {
                    const Fullemitter = params.value;
                    return (
                        <div
                            style={{
                                whiteSpace: 'pre-line',
                                textAlign: 'center',
                            }}
                        >
                            {Fullemitter}
                        </div>
                    );
                }

                return (
                    <>
                        <Avatar
                            sx={{ width: 24, height: 24, mr: 1 }}
                            src={emitter.url_photo_profile}
                        />
                        <div
                            style={{
                                whiteSpace: 'pre-line',
                                textAlign: 'center',
                            }}
                        >
                            {params.value}
                        </div>
                    </>
                );
            },
        },
        {
            field: 'title',
            headerName: 'Título',
            flex: 1,
            minWidth: 100,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const FullTitle = `${params.row.title}`;
                return (
                    <div
                        style={{ whiteSpace: 'pre-line', textAlign: 'center' }}
                    >
                        {FullTitle}
                    </div>
                );
            },
        },
        {
            field: 'subtitle',
            headerName: 'Subtítulo',
            flex: 1,
            minWidth: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: function renderCellExpand(params) {
                return (
                    <GridCell
                        value={params.value || ''}
                        width={params.colDef.computedWidth}
                    />
                );
            },
        },
        {
            field: 'description',
            headerName: 'Descripción',
            flex: 1,
            minWidth: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: function renderCellExpand(params) {
                return (
                    <GridCell
                        value={params.value || ''}
                        width={params.colDef.computedWidth}
                    />
                );
            },
        },
        {
            field: 'created_at',
            headerName: 'Creación',
            type: 'date',
            flex: 0.8,
            minWidth: 100,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: ({ value }) => value,
            valueFormatter: ({ value }) => {
                return value;
            },
        },
        {
            field: 'student',
            headerName: 'Alumnos',
            flex: 1,
            minWidth: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: ({ row }) => {
                let studentName = 'Alumno sin padres registrados';
                console.log(row, 'row');

                if (row.student) {
                    let student = row.student;
                    studentName = `${student.last_name} ${student.second_last_name} ${student.name}`;
                }

                return studentName;
            },
            renderCell: (params) => {
                let student = params.row.student;

                if (!student) {
                    const Fullemitter = params.value;
                    return (
                        <div
                            style={{
                                whiteSpace: 'pre-line',
                                textAlign: 'center',
                            }}
                        >
                            {Fullemitter}
                        </div>
                    );
                }

                return (
                    <>
                        <Avatar
                            sx={{ width: 24, height: 24, mr: 1 }}
                            src={student.url_photo_profile}
                        />
                        <div
                            style={{
                                whiteSpace: 'pre-line',
                                textAlign: 'center',
                            }}
                        >
                            {params.value}
                        </div>
                    </>
                );
            },
        },
        {
            field: 'sentNotifications',
            headerName: 'Enviado',
            flex: 1,
            maxWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: ({ value }) => {
                return value.length;
            },
            renderCell: (params) => (
                <>
                    <Tooltip title="Mostrar notificaciones enviadas">
                        <Button
                            size="small"
                            variant="outlined"
                            startIcon={<ListIcon />}
                            onClick={() => {
                                dispatch(
                                    noticeReprobationSelected(
                                        params.row.viewModel.id
                                    )
                                );
                                openModal(params.row.viewModel, 'sent');
                            }}
                        >
                            {params.value}
                        </Button>
                    </Tooltip>
                </>
            ),
        },
        {
            field: 'noticedNotifications',
            headerName: 'Enterado',
            flex: 1,
            maxWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: ({ value }) => {
                return value.length;
            },
            renderCell: (params) => (
                <>
                    <Tooltip title="Mostrar notificaciones enviadas">
                        <Button
                            size="small"
                            variant="outlined"
                            startIcon={<ListIcon />}
                            onClick={() => {
                                dispatch(
                                    noticeReprobationSelected(
                                        params.row.viewModel.id
                                    )
                                );
                                openModal(params.row.viewModel, 'noticed');
                            }}
                        >
                            {params.value}
                        </Button>
                    </Tooltip>
                </>
            ),
        },
        {
            field: 'readNotifications',
            headerName: 'Leído',
            flex: 1,
            maxWidth: 90,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: ({ value }) => {
                return value.length;
            },
            renderCell: (params) => (
                <>
                    <Tooltip title="Mostrar notificaciones enviadas">
                        <Button
                            size="small"
                            variant="outlined"
                            startIcon={<ListIcon />}
                            onClick={() => {
                                dispatch(
                                    noticeReprobationSelected(
                                        params.row.viewModel.id
                                    )
                                );
                                openModal(params.row.viewModel, 'read');
                            }}
                        >
                            {params.value}
                        </Button>
                    </Tooltip>
                </>
            ),
        },
    ];

    ///////////// HANDLERS ///////////

    useEffect(() => {
        dispatch(fetchReprobationNoticesData({ schoolId, type }));
    }, []);

    /**
     * Funcion para recargar los avisos
     */
    const reload = () => {
        dispatch(
            fetchReprobationNoticesData({
                schoolId,
                type,
            })
        )
            .unwrap()
            .then((response) => {})
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    const openModal = (vm, type) => {
        setCurrentViewModel(vm);
        setModalType(type);
        setOpenUsersModal(true);
    };

    const handleFilterChange = (event, value) => {
        let newFilterValues = {};

        event.items.forEach((filter) => {
            const { columnField, operatorValue, value } = filter;

            newFilterValues = {
                columnField,
                operatorValue,
                value,
            };
        });
        dispatch(updateReprobationFilters({ filter: newFilterValues }));
    };

    return (
        <>
            <Box>
                {fetchingStatus == 'pending' && <SkeletonPage items={9} />}

                {fetchingStatus == 'rejected' && (
                    <Error
                        onRetry={reload}
                        message={'Estamos teniendo problemas'}
                    />
                )}
                {fetchingStatus === 'fulfilled' && (
                    <>
                        <Card
                            sx={{
                                color: 'white',
                                borderRadius: '15px',
                                width: '100%',
                                height: '100%',
                            }}
                        >
                            <DataGrid
                                localeText={{
                                    ...esES.components.MuiDataGrid.defaultProps
                                        .localeText,
                                    toolbarColumns: '',
                                    toolbarFilters: '',
                                    toolbarDensity: '',
                                    toolbarExport: '',
                                }}
                                rows={reprobationNotices}
                                getRowId={(row) => row.notice_id}
                                columns={columns}
                                pageSize={10}
                                disableSelectionOnClick
                                autoHeight
                                components={{
                                    NoRowsOverlay: NoDataOverlay,
                                    NoResultsOverlay: NoDataOverlay,
                                    Toolbar: GridToolBar,
                                    Pagination: CustomPagination,
                                }}
                                componentsProps={{
                                    panel: {
                                        placement: 'bottom-end',
                                    },
                                    noResultsOverlay: {
                                        message:
                                            'No se encontraron resultados para la búsqueda',
                                    },
                                    noRowsOverlay: {
                                        message: 'No hay datos disponibles',
                                    },
                                }}
                                onFilterModelChange={(event) =>
                                    handleFilterChange(event)
                                }
                                initialState={{
                                    filter: {
                                        filterModel: {
                                            items: reprobationFilters
                                                ? [
                                                      {
                                                          columnField:
                                                              reprobationFilters.columnField ||
                                                              '',
                                                          operatorValue:
                                                              reprobationFilters.operatorValue ||
                                                              '',
                                                          value:
                                                              reprobationFilters.value ||
                                                              '',
                                                      },
                                                  ]
                                                : [],
                                        },
                                    },
                                }}
                                disableDensitySelector
                            />
                        </Card>
                    </>
                )}
            </Box>
            {openUsersModal && (
                <UsersReprobationModal
                    open={openUsersModal}
                    setOpen={setOpenUsersModal}
                    viewModel={currentViewModel}
                    type={modalType}
                />
            )}
        </>
    );
};

export default ReprobationTable;
