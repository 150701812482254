import { DIRECTORS_HOME_EXPIRE_TIME } from '../../service/const';
import { getExpireIn } from '../../libs/utils';
export const createKpiState = () => ({
    fetch: {
        error: null,
        expireIn: null,
        fetchingAt: null,
        statusServer: 'idle',
        statusOperation: 'idle',
        didInvalidate: true,
        feedback: {
            title: null,
            message: null,
            payload: null,
        },
    },
    data: {},
});

export const addAsyncCase = (builder, asyncThunk, kpiName) => {
    builder
        .addCase(asyncThunk.pending, (state, action) => {
            state[kpiName].fetch.statusServer = 'pending';
        })
        .addCase(asyncThunk.fulfilled, (state, action) => {
            state[kpiName].data = action.payload;
            state[kpiName].fetch.expireIn = getExpireIn(
                'DIRECTORS_HOME_EXPIRE_TIME'
            );
            state[kpiName].fetch.fetchingAt = Date.now();
            state[kpiName].fetch.didInvalidate = false;
            state[kpiName].fetch.statusServer = 'fulfilled';
        })
        .addCase(asyncThunk.rejected, (state, action) => {
            state[kpiName].fetch.statusServer = 'rejected';
            state[kpiName].fetch.error = action.payload.error;
        });
};

export const initialStateHome = {
    best_score_students: createKpiState(),
    assistance_index: createKpiState(),
    average_imc_school: createKpiState(),
    best_score_subject: createKpiState(),
    frequent_reports: createKpiState(),
    total_students: createKpiState(),
    total_professors: createKpiState(),
    school_average: createKpiState(),
    total_abscence: createKpiState(),
    total_students_without_group: createKpiState(),
    total_reports: createKpiState(),
    total_scholarship: createKpiState(),
    total_registered_parents: createKpiState(),
    total_incidents: createKpiState(),
    last_attendance_professor: createKpiState(),
    total_male_female_students: createKpiState(),
    students_diversity: createKpiState(),
    total_students_approved_failed: createKpiState(),
    total_groups: createKpiState(),
    total_male_female_students_register: createKpiState(),
    total_male_female_students_withdrawn: createKpiState(),
    students_approved_gender: createKpiState(),
    students_failed_gender: createKpiState(),
    total_male_female_student_register_grade: createKpiState(),
    total_male_female_students_withdrawn_grade: createKpiState(),
    students_approved_failed_gender_grade: createKpiState(),
    total_students_repeated: createKpiState(),
    grade_average: createKpiState(),
    worst_score_student: createKpiState(),
    incidents: createKpiState(),
};

export const initialStateGroups = {
    best_assistance_group: createKpiState(),
    most_abscenses_group: createKpiState(),
    most_reports_group: createKpiState(),
    imc: createKpiState(),
    scores_main: createKpiState(),
    scores_special: createKpiState(),
    scores_extracurricular: createKpiState(),
    assists_absences: createKpiState(),
    reports: createKpiState(),
    total_students_diversity: createKpiState(),
    male_female: createKpiState(),
    best_score_group: createKpiState(),
    worst_score_group: createKpiState(),
    all_scholarship_students: createKpiState(),
};

export const initialStateSubjects = {
    absence_by_subjects: createKpiState(),
    assists_by_subjects: createKpiState(),
    best_score_on_subject: createKpiState(),
    worst_score_subject: createKpiState(),
    students_approved_gender_grade_subject: createKpiState(),
    students_failed_gender_grade_subject: createKpiState(),
    subjects: createKpiState(),
};

export const initialStateProfessors = {
    lag_assists: createKpiState(),
    lag_califications: createKpiState(),
    professors_without_group: createKpiState(),
    professor_most_reports: createKpiState(),
    all_professor_reports: createKpiState(),
    professors: createKpiState(),
    last_incident: createKpiState(),
};

export const initialStateStudents = {
    best_average_level: createKpiState(),
    worst_average_level: createKpiState(),
    best_average_grade: createKpiState(),
    worst_average_grade: createKpiState(),
    more_inassist_level: createKpiState(),
    more_inassists_grade: createKpiState(),
    most_report_level: createKpiState(),
    most_report_grade: createKpiState(),
    students: createKpiState(),
    students_summary: createKpiState(),
    failed_students: createKpiState(),
    students_with_subjects_failed: createKpiState(),
};
