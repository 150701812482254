import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { useAuth, useFeedback } from '../../../../hooks';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { createCatDiscount } from '../../../store/cat_discounts/operations';
import palomita from '../../../../public/assets/palomita.svg';
import { Typography as AntTypography } from 'antd';

const CreateCatalog = ({ open, onclose }) => {
    ///////////// SHERD STATE /////////////

    const auth = useAuth();
    const schoolId = auth.getUser().school_id;
    const dispatch = useDispatch();
    const theme = useTheme();
    const feedbackApp = useFeedback();

    ///////////// LOCAL STATE /////////////

    ///////////// FORM CONFIGURATION /////////////

    const formik = useFormik({
        initialValues: {
            title: '',
            value: '',
            value_modality: '',
        },
        validationSchema: Yup.object({
            title: Yup.string().required('El título es obligatorio'),
            value: Yup.number()
                .required('El valor es obligatorio')
                .positive('El valor debe ser positivo')
                .when('value_modality', {
                    is: 2,
                    then: (schema) =>
                        schema.max(100, 'El valor no puede ser mayor que 99%'),
                    otherwise: (schema) => schema,
                }),
            value_modality: Yup.number()
                .required('La modalidad es obligatoria')
                .oneOf([1, 2], 'Modalidad inválida'),
        }),
        onSubmit: async (values, { resetForm }) => {
            await dispatch(createCatDiscount({ schoolId, data: values }))
                .unwrap()
                .then((response) => {
                    feedbackApp.showFeedback({
                        title: 'Catalogo de descuentos creado',
                    });
                    resetForm();
                    onclose();
                })
                .catch((error) => {
                    feedbackApp.showFeedback({
                        title: error.feedback.title,
                    });
                });
        },
    });

    ///////////// RENDER /////////////

    return (
        <>
            <Dialog
                open={open}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        onclose();
                    }
                }}
                disableEscapeKeyDown
                sx={{
                    '& .MuiDialog-paper': {
                        borderRadius: 5,
                        width: '60%',
                        maxWidth: 700,
                        height: '80%',
                        maxHeight: 450,
                    },
                }}>
                <DialogTitle
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: 1,
                    }}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignContent: 'center',
                            alignItems: 'center',
                        }}>
                        <Box
                            component="img"
                            src={palomita}
                            alt="Descripción de la imagen"
                            sx={{
                                width: '15%',
                                height: 'auto',
                            }}
                        />
                        <Divider
                            orientation="vertical"
                            variant="middle"
                            flexItem
                            sx={{
                                display: 'flex',
                                ml: 2,
                                mr: 2,
                            }}
                        />
                        <Box>
                            <AntTypography.Title level={5}>
                                Agregar Catalogo de Descuento
                            </AntTypography.Title>
                            <Typography
                                variant="body2"
                                sx={{
                                    width: '100%',
                                    mt: -1,
                                }}>
                                Aquí podrás agregar un nuevo catálogo de
                                descuento
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignContent: 'center',
                            alignItems: 'center',
                        }}>
                        <Divider
                            orientation="vertical"
                            variant="middle"
                            flexItem
                            sx={{
                                display: 'flex',
                                ml: 2,
                                mr: 2,
                            }}
                        />
                        <IconButton
                            onClick={() => {
                                formik.resetForm();
                                onclose();
                            }}>
                            <CloseIcon
                                sx={{
                                    color: '#000000ff',
                                    width: 20,
                                }}
                            />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <Divider
                    sx={{
                        width: '95%',
                    }}
                    flexItem
                    variant="middle"
                />

                <FormikProvider value={formik}>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Título del catálogo de Descuento"
                                    variant="outlined"
                                    id="title"
                                    name="title"
                                    value={formik.values.title}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.title &&
                                        Boolean(formik.errors.title)
                                    }
                                    helperText={
                                        formik.touched.title &&
                                        formik.errors.title
                                    }
                                    margin="dense"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl
                                    fullWidth
                                    margin="dense"
                                    error={
                                        formik.touched.value_modality &&
                                        Boolean(formik.errors.value_modality)
                                    }>
                                    <InputLabel id="value_modality-label">
                                        Modalidad de Valor
                                    </InputLabel>
                                    <Select
                                        labelId="value_modality-label"
                                        id="value_modality"
                                        name="value_modality"
                                        value={formik.values.value_modality}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        label="Modalidad de Valor">
                                        <MenuItem value={1}>Monetario</MenuItem>
                                        <MenuItem value={2}>
                                            Porcentaje
                                        </MenuItem>
                                    </Select>
                                    {formik.touched.value_modality &&
                                        formik.errors.value_modality && (
                                            <Typography
                                                variant="body2"
                                                color="error"
                                                sx={{ mt: 1 }}>
                                                {formik.errors.value_modality}
                                            </Typography>
                                        )}
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="value"
                                    name="value"
                                    placeholder="0.00"
                                    type="number"
                                    label="Valor"
                                    variant="outlined"
                                    value={formik.values.value}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.value &&
                                        Boolean(formik.errors.value)
                                    }
                                    helperText={
                                        formik.touched.value &&
                                        formik.errors.value
                                    }
                                    margin="dense"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {formik.values
                                                    .value_modality === 2
                                                    ? '%'
                                                    : '$'}
                                            </InputAdornment>
                                        ),
                                    }}
                                    disabled={!formik.values.value_modality}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                </FormikProvider>
                <Divider
                    sx={{
                        width: '95%',
                    }}
                    flexItem
                    variant="middle"
                />
                <DialogActions
                    sx={{
                        justifyContent: 'space-between',
                    }}>
                    <Box sx={{ mb: 2 }}>
                        <Button
                            sx={{ mt: 1, mr: 1 }}
                            onClick={() => {
                                formik.resetForm();
                                onclose();
                            }}>
                            Cancelar
                        </Button>
                    </Box>
                    <Box sx={{ mb: 2 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            size="small"
                            onClick={formik.handleSubmit}
                            disabled={
                                formik.isSubmitting ||
                                !formik.isValid ||
                                !formik.dirty
                            }>
                            {formik.isSubmitting ? (
                                <CircularProgress size={24} />
                            ) : (
                                'Registrar Catalogo de Descuento'
                            )}
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CreateCatalog;
