import _ from 'lodash';
import GroupsSelector from './GroupsSelector';
import React, { useEffect, useState } from 'react';
import Services from '../../../service/Connection';
import useFeedback from '../../../hooks/useFeedback';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    Step,
    StepContent,
    StepIcon,
    StepLabel,
    Stepper,
    Switch,
    TextField,
    Typography,
    useMediaQuery,
} from '@mui/material';
import {
    createStudent,
    findGroups,
    selectStatusOperation,
} from '../../../store/slices/studentsUI/serverSlice';
import { DateTime } from 'luxon';
import { esES } from '@mui/x-date-pickers/locales';
import { LoadingButton } from '@mui/lab';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { selectSettingBySchoolId } from '../../../store/slices/entities/settings';
import { useAuth } from '../../../hooks';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import generarCurp from '../../../helper/CurpGenerator';
import { DatePicker } from '@mui/x-date-pickers';
import { VALIDATIONS } from '../../../constants';
import Feedback from '../../../service/Feedback';

////////////////////////////////////// ICONS /////////////////////////////////////////////////

import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from '@mui/icons-material/Search';

const StudentStepperModal = ({ open, setOpen, student = null }) => {
    ////////////////////////////////////// HOOKS /////////////////////////////////////////////////

    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id;
    const dispatch = useDispatch();
    const feedbackApp = useFeedback();
    let FeedbackService = new Feedback();

    ////////////////////////////////////// LOCAL STATE /////////////////////////////////////////////////

    const generateSizesOptions = (maxSize) => {
        const sizes = ['SM', 'MD', 'LG', 'XG'];
        for (let i = 3; i <= maxSize; i++) {
            sizes.push(i.toString().padStart(2, '0'));
        }
        return sizes;
    };
    const optionSize = generateSizesOptions(40);
    const optionGender = ['Hombre', 'Mujer'];
    const optionModality = ['Presencial', 'En linea'];
    const [apcChecked, setApcChecked] = useState(false);

    const steps = [
        {
            label: 'Datos Generales',
            description: 'Información personal del alumno',
        },
        {
            label: 'Domicilio',
            description: 'información detallada de la residencia',
        },
        {
            label: 'Datos Adicionales',
            description: 'Información adicional del alumno',
        },
        {
            label: 'Grupos',
            description: 'Asignacion de grupo, nivel y turno',
        },
        {
            label: 'Finalizar',
            description: 'Resumen informativo del alumno',
        },
    ];

    const [studentItem, setStudentItem] = useState({
        student_id: null,
        group_id: null,
        school_id: null,
        name: '',
        last_name: '',
        second_last_name: '',
        cellphone: '',
        folio: '',
        colony: '',
        inside_number: '',
        outside_number: '',
        street: '',
        zipcode: '',
        city_id: '',
        address_city_id: '',
        gender: '',
        date_birth: null,
        curp: '',
        height: '',
        weight: '',
        observation: '',
        email: '',
        is_indigenous: false,
        is_foreign: false,
        preschool_years: '',
        elementary_years: '',
        middle_years: 0,
        highschool_years: 0,
        has_scholar_grants: false,
        uniform_size: '',
        is_immigrant: false,
        is_stranger: false,
        school_modality: '',
        in_repetition: false,
        in_wrong_degree: false,
        apc: false,
        situation: '',
        city_id: '',
        state_id: '',
        municipality_id: '',
        addressstate_id: '',
        addressmunicipality_id: '',
        created_at: '',
        updated_at: '',
    });

    const [targetKeys, setTargetKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [activeStep, setActiveStep] = useState(0);
    const [isStepZero, setIsStepZero] = useState(false);
    const [level, setLevel] = useState('');
    const [selectedPrimaryGroup, setSelectedPrimaryGroup] = useState('');
    const [cities, setCities] = useState([]);
    const [addresscities, setAddressCities] = useState([]);
    const [getNameofGroups, setNameofGroups] = useState('');
    const [getNamesofSpecialGroups, setNamesofSpecialGroups] = useState([]);
    const [states, setStates] = useState([]);
    const [stateValue, setStateValue] = useState(null);
    const [municipalities, setMunicipalities] = useState([]);
    const [municipalityValue, setMunicipialityValue] = useState(null);
    const [cityValue, setCityValue] = useState(null);
    const [addressstates, setAddressStates] = useState([]);
    const [addressstateValue, setAddressStateValue] = useState(null);
    const [addressmunicipalities, setAddressMunicipalities] = useState([]);
    const [addressmunicipalityValue, setAddressMunicipialityValue] =
        useState(null);
    const [addresscityValue, setAddressCityValue] = useState(null);
    const [stateSelected, setStateSelected] = useState({ code: '' });
    const [isSearchLoading, setIsSearchLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [dateError, setDateError] = useState(false);

    ////////////////// SWITCHES STYLE ///////////////////

    const GreyBlueSwitch = withStyles((theme) => ({
        switchBase: {
            color: theme.palette.grey[500], // Color inicial del Switch (gris)
            '&$checked': {
                color: theme.palette.primary.main, // Color al activar el Switch (azul)
            },
            '&$checked + $track': {
                backgroundColor: theme.palette.primary.main, // Color del fondo al activar el Switch
            },
        },
        checked: {},
        track: {},
    }))(Switch);

    //////////// SHARED STATE /////////////

    const config = useSelector(selectSettingBySchoolId(schoolId));
    const statusOperation = useSelector(selectStatusOperation);

    let titleBarBackground = config.find(
        (res) => res.key === 'theme-color-title-bar'
    );
    let fontColor = config.find((res) => res.key === 'theme-color-font');

    ///////////////////// ACTIONS ///////////////////////////

    let [groups, setGroups] = useState([]);

    const changeLevel = (level) => {
        dispatch(
            findGroups({
                level,
                schoolId,
            })
        )
            .unwrap()
            .then(({ groups }) => {
                setGroups(groups);
                setLevel(level);
            })
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    /**
     * Actualizacion de los inputs de formulario
     *
     * @param {*} event
     */
    const onInputChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;
        let altervalues = handleInputs(name, value);
        setStudentItem(altervalues);
    };

    const handleApcChange = (event) => {
        setApcChecked(event.target.checked);

        onInputChange({ target: { name: 'apc', value: event.target.checked } });
    };

    const handleInputs = (name, value) => {
        let updatedStudentItem = { ...studentItem, [name]: value };

        switch (name) {
            case 'curp':
                updatedStudentItem[name] = value.toUpperCase();
                break;
            case 'elementary_years':
                if (value === '0') {
                    updatedStudentItem.middle_years = 0;
                    updatedStudentItem.highschool_years = 0;
                }
                break;
            case 'middle_years':
                if (value === '0') {
                    updatedStudentItem.highschool_years = 0;
                }
                break;
            case 'cellphone':
            case 'zipcode':
            case 'weight':
            case 'height':
                const numericValue = value.replace(/\D/g, '');
                updatedStudentItem[name] = numericValue;
                break;
            default:
                break;
        }

        return updatedStudentItem;
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        if (activeStep === 0) {
            handleClose(); // Cerrar el modal
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };

    /* No cerrar click afuera del modal */
    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        setOpen(false);
    };

    const handleGroupsChange = (groups) => {
        if (groups.primaryGroup) {
            setSelectedPrimaryGroup(groups.primaryGroup);
        }

        if (groups.selectedkeys) {
            setSelectedKeys(groups.selectedkeys);
        }

        if (groups.targetkeys) {
            setTargetKeys(groups.targetkeys);
        }
    };

    useEffect(() => {
        setIsStepZero(activeStep === 0);
    }, [activeStep]);

    /**
     * Efecto al abrir el modal
     */
    useEffect(() => {
        if (!open) {
            setStudentItem({
                student_id: null,
                group_id: null,
                school_id: null,
                name: '',
                last_name: '',
                second_last_name: '',
                cellphone: '',
                folio: '',
                colony: '',
                inside_number: '',
                outside_number: '',
                street: '',
                zipcode: '',
                city_id: '',
                address_city_id: '',
                gender: '',
                date_birth: null,
                curp: '',
                height: '',
                weight: '',
                observation: '',
                email: '',
                is_indigenous: false,
                is_foreign: false,
                preschool_years: '',
                elementary_years: '',
                middle_years: 0,
                highschool_years: 0,
                has_scholar_grants: false,
                uniform_size: '',
                is_immigrant: false,
                is_stranger: false,
                school_modality: '',
                in_repetition: false,
                in_wrong_degree: false,
                apc: null,
                situation: '',
                city_id: '',
                state_id: '',
                municipality_id: '',
                addressstate_id: '',
                addressmunicipality_id: '',
                created_at: '',
                updated_at: '',
            });
            setActiveStep(0);
            setLevel(-1);
            setTargetKeys([]);
            setSelectedKeys([]);
            setSelectedPrimaryGroup('');
            getMunicipalities(null);
            getAddressMunicipalities(null);
        }
    }, [open]);

    useEffect(() => {
        const getAllStates = async () => {
            let states = await Services.getStates();
            setStates(states.data.data);
        };
        getAllStates();
    }, []);

    const getMunicipalities = async (value) => {
        setStateValue(value);
        setStateSelected(value);
        setMunicipialityValue(null);
        setCityValue(null);
        setMunicipalities([]);
        setCities([]);
        if (value !== null) {
            let municipality = await Services.getMunicipalitysByStateId(
                value.state_id
            );
            setMunicipalities(municipality.data.data);
        }
    };

    const getCities = async (value) => {
        setMunicipialityValue(value);
        setCityValue(null);
        setCities([]);
        if (value !== null) {
            let city = await Services.getCityByMunicipalityId(
                value.municipality_id
            );
            setCities(city.data.data);
        }
    };

    const saveCity = (value) => {
        if (value !== null) {
            setCityValue(value);
            studentItem.city_id = value.city_id;
        }
    };

    useEffect(() => {
        const getAllAddressStates = async () => {
            let addressstate = await Services.getStates();
            setAddressStates(addressstate.data.data);
        };
        getAllAddressStates();
    }, []);

    const getAddressMunicipalities = async (value) => {
        setAddressMunicipialityValue(null);
        setAddressCityValue(null);
        setAddressStateValue(value);
        setAddressMunicipalities([]);
        setAddressCities([]);
        if (value !== null) {
            let addressmunicipality = await Services.getMunicipalitysByStateId(
                value.state_id
            );
            setAddressMunicipalities(addressmunicipality.data.data);
        }
    };

    const getAddressCities = async (value) => {
        setAddressMunicipialityValue(value);
        setAddressCityValue(null);
        setAddressCities([]);
        if (value !== null) {
            let addresscity = await Services.getCityByMunicipalityId(
                value.municipality_id
            );
            setAddressCities(addresscity.data.data);
        }
    };

    const saveAddressCity = (value) => {
        if (value !== null) {
            setAddressCityValue(value);
            setStudentItem({ ...studentItem, address_city_id: value.city_id });
        }
    };
    /**
     * Guardar o actualziar alumno
     */
    const saveStudent = () => {
        dispatch(
            createStudent({
                student: studentItem,
                primaryGroupId: selectedPrimaryGroup,
                specialgroups: targetKeys,
                schoolId,
            })
        )
            .unwrap()
            .then((data) => {
                feedbackApp.showFeedback({
                    title: 'Alumno agregado',
                });
                setTimeout(() => {
                    feedbackApp.closeFeedback();
                }, 5000); // 5000 milisegundos (5 segundos)
                setOpen(false);
            })
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
                setTimeout(() => {
                    feedbackApp.closeFeedback();
                }, 5000); // 5000 milisegundos (5 segundos)
            });
    };

    /**
     * Buscar el alumno
     */
    const onSearchStudent = () => {
        if (!studentItem.curp) {
            return;
        }

        setIsSearchLoading(true);

        searchStudent(studentItem.curp)
            .then((data) => {
                if (data) {
                    feedbackApp.showFeedback({
                        title: `Alumno registrado: ${data.student.name} ${data.student.last_name} con id: ${data.student.student_id} en la escuela ${data.school.name}`,
                    });
                } else {
                    feedbackApp.showFeedback({
                        title: 'Alumno aún no registrado',
                    });
                }
            })
            .catch((err) => {
                let feedbackError = FeedbackService.getMessage(err);

                feedbackApp.showFeedback({
                    title: feedbackError.title,
                });
            })
            .then(() => {
                setIsSearchLoading(false);
            });
    };

    /**
     * Buscar alumno usando la curp
     *
     * @param {*} curp
     */
    const searchStudent = async (curp) => {
        let students = await Services.searchStudentByCURP(curp).then(
            (i) => i.data.data
        );

        if (students.length == 0) {
            return null;
        }

        let student = students[0];

        let school = await Services.getSchoolById(student.school_id).then(
            (i) => i.data.data
        );

        return {
            student,
            school,
        };
    };

    ////////////////////////// VALIDATIONS /////////////////////

    const nameValid = () => {
        return {
            invalid:
                studentItem.name == '' ||
                !VALIDATIONS.ALPHA_NUMERIC_SPACES.test(studentItem.name),
            message: 'Campo requerido, Máximo 50 caracteres',
        };
    };

    const lastNameValid = () => {
        return {
            invalid:
                studentItem.last_name == '' ||
                !VALIDATIONS.ALPHA_NUMERIC_SPACES.test(studentItem.last_name),
            message: 'Campo requerido o incorrecto',
        };
    };

    const secondLastNameValid = () => {
        return {
            invalid:
                studentItem.second_last_name != '' &&
                !VALIDATIONS.ALPHA_NUMERIC_SPACES.test(
                    studentItem.second_last_name
                ),
            message: 'Campo requerido o incorrecto',
        };
    };

    const folioValid = () => {
        const folio = studentItem.folio ? studentItem.folio.trim() : '';

        return {
            invalid: folio === '' || !VALIDATIONS.FOLIO.test(folio),
            message: 'Campo requerido o incorrecto',
        };
    };

    /**
     * Validacion para el numero de telefono
     *
     * @returns
     */
    const cellphoneValid = () => {
        const isPhone = /^[0-9]{10}$/.test(studentItem.cellphone);

        return {
            invalid: !isPhone,
            message: 'Campo requerido o incorrecto',
        };
    };

    const streetValid = () => {
        return {
            invalid:
                studentItem.street == '' ||
                !VALIDATIONS.ALPHA_NUMERIC_SPACES.test(studentItem.street),
            message: 'Campo requerido o incorrecto',
        };
    };

    const colonyValid = () => {
        return {
            invalid:
                studentItem.colony == '' ||
                !VALIDATIONS.ALPHA_NUMERIC_SPACES.test(studentItem.colony),
            message: 'Campo requerido o incorrecto',
        };
    };

    const outsideNumberValid = () => {
        return {
            invalid: !VALIDATIONS.ALPHA_NUMERIC_SPACES.test(
                studentItem.outside_number
            ),
            message: 'Campo requerido o incorrecto',
        };
    };

    const insideNumberValid = () => {
        if (studentItem.inside_number == '') {
            return {
                invalid: false,
                message: 'Campo incorrecto',
            };
        }

        return {
            invalid: !VALIDATIONS.ALPHA_NUMERIC_SPACES.test(
                studentItem.inside_number
            ),
            message: 'Campo incorrecto',
        };
    };

    const observationValid = () => {
        if (studentItem.observation == '') {
            return {
                invalid: false,
                message: 'Campo incorrecto',
            };
        }

        return {
            invalid: !VALIDATIONS.ALPHA_NUMERIC_SPACES.test(
                studentItem.observation
            ),
            message: 'Campo incorrecto',
        };
    };

    const zipcodeValid = () => {
        return {
            invalid:
                studentItem.zipcode == '' ||
                !VALIDATIONS.INTEGER.test(studentItem.zipcode),
            message: 'Campo requerido o incorrecto',
        };
    };

    const groupsValid = () => {
        return {
            invalid: false,
            message: 'Al menos se tiene que seleccionar un grupo',
        };
    };
    const genderValid = () => {
        return {
            invalid: studentItem.gender === '' || studentItem.gender === null,
            message: 'Campo requerido o incorrecto',
        };
    };

    const preescolaryearsValid = () => {
        return {
            invalid:
                studentItem.preschool_years === '' ||
                studentItem.preschool_years === null,
            message: 'Valores validos de 0 a 3',
        };
    };

    const elementaryyearsValid = () => {
        return {
            invalid: studentItem.elementary_years === '',
            message: 'Valores validos 0 o 6',
        };
    };

    const middleyearsValid = () => {
        return {
            invalid: studentItem.middle_years === '',
            message: 'Valores validos 0, 3 o 4',
        };
    };

    const highschoolyearValid = () => {
        return {
            invalid: studentItem.highschool_years === '',
            message: 'Valores validos 0, 3 o 4',
        };
    };

    const heightValid = () => {
        const heightValue = studentItem.height;

        if (!heightValue || heightValue === '') {
            return {
                invalid: false,
                message: 'Campo requerido',
            };
        }

        const regex = /^\d{0,1}(\.\d{0,2})?$/;
        const isValidFormat = regex.test(heightValue);

        if (!isValidFormat) {
            return {
                invalid: true,
                message: 'El valor mínimo es 1.00',
            };
        }

        const heightNumber = parseFloat(heightValue);

        if (heightNumber < 1.0 || heightNumber > 2.2) {
            return {
                invalid: true,
                message: 'El valor debe estar en el rango de 1.00 a 2.20',
            };
        }

        return {
            invalid: false,
            message: '',
        };
    };

    const weightValid = () => {
        const weightValue = studentItem.weight;

        if (!weightValue || weightValue === '') {
            return {
                invalid: false,
                message: 'Campo requerido',
            };
        }
        const regex = /^\d{0,3}(\.\d{0,2})?$/;
        const isValidFormat = regex.test(weightValue);

        if (!isValidFormat) {
            return {
                invalid: true,
                message: 'Máximo 2 decimales permitidos',
            };
        }
        const weightNumber = parseFloat(weightValue);

        if (weightNumber < 8 || weightNumber > 150.99) {
            return {
                invalid: true,
                message: 'El valor debe estar en el rango de 8 a 150.99',
            };
        }

        return {
            invalid: false,
            message: '',
        };
    };

    const cityValid = () => {
        return {
            invalid: !cityValue || cityValue === '',
            message: 'Campo requerido o incorrecto',
        };
    };

    const statesValid = () => {
        return {
            invalid: !stateValue || stateValue === '',
            message: 'Campo requerido o incorrecto',
        };
    };

    const municipalityValid = () => {
        return {
            invalid: !municipalityValue || municipalityValue === '',
            message: 'Campo requerido o incorrecto',
        };
    };

    const modalityValid = () => {
        return {
            invalid:
                studentItem.school_modality === '' ||
                studentItem.school_modality === null,
            message: 'Campo requerido o incorrecto',
        };
    };

    //////////////////////// VALIDACION DE ADDRESS

    const addresscityValid = () => {
        return {
            invalid: !addresscityValue || addresscityValue === '',
            message: 'Campo requerido o incorrecto',
        };
    };

    const addressstatesValid = () => {
        return {
            invalid: !addressstateValue || addressstateValue === '',
            message: 'Campo requerido o incorrecto',
        };
    };

    const addressmunicipalityValid = () => {
        return {
            invalid:
                !addressmunicipalityValue || addressmunicipalityValue === '',
            message: 'Campo requerido o incorrecto',
        };
    };

    //////////////////////////////////////////////////

    const curpValid = () => {
        const isAnySwitchActive =
            studentItem.is_indigenous ||
            studentItem.is_immigrant ||
            studentItem.is_stranger;

        if (isAnySwitchActive) {
            const requiredFieldsFilled =
                studentItem.name &&
                studentItem.last_name &&
                studentItem.second_last_name &&
                studentItem.date_birth &&
                studentItem.gender &&
                stateSelected &&
                stateSelected.code;

            if (requiredFieldsFilled && studentItem.curp !== '') {
                const curpGenerada = generarCurp(
                    studentItem.name || '',
                    studentItem.last_name || '',
                    studentItem.second_last_name || '',
                    studentItem.date_birth || '',
                    studentItem.gender || '',
                    stateSelected.code || ''
                );

                const isCurpValid = curpGenerada === studentItem.curp;

                return {
                    invalid: !isCurpValid,
                    message: 'La CURP proporcionada no es correcta',
                };
            } else {
                return {
                    invalid: false,
                    message: 'Campo requerido o incorrecto',
                };
            }
        } else {
            if (
                studentItem.name === '' ||
                studentItem.last_name === '' ||
                studentItem.second_last_name === '' ||
                studentItem.date_birth === null ||
                studentItem.gender === '' ||
                !stateSelected ||
                !stateSelected.code
            ) {
                return {
                    invalid: true,
                    message: 'La CURP proporcionada no es correcta',
                };
            }

            const curpGenerada = generarCurp(
                studentItem.name,
                studentItem.last_name,
                studentItem.second_last_name,
                studentItem.date_birth,
                studentItem.gender,
                stateSelected.code,
                studentItem.curp.substring(16, 17)
            );

            const isCurpValid = curpGenerada === studentItem.curp;

            return {
                invalid: !isCurpValid,
                message: isCurpValid
                    ? ''
                    : 'La CURP proporcionada no es correcta',
            };
        }
    };

    const birthDateValid = () => {
        return {
            invalid:
                studentItem.date_birth === null ||
                studentItem.date_birth === '' ||
                dateError,
            message: 'Campo requerido o incorrecto',
        };
    };

    /**
     * Validacion para el correo
     *
     * @returns
     */
    const emailValid = () => {
        var mailformat =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        const isEmail = mailformat.test(studentItem.email);

        return {
            invalid:
                !isEmail &&
                studentItem.email != '' &&
                studentItem.email != null,
            message: 'Campo incorrecto',
        };
    };

    const StepOneisInvalid = () => {
        return (
            nameValid().invalid ||
            lastNameValid().invalid ||
            cellphoneValid().invalid ||
            genderValid().invalid ||
            cityValid().invalid ||
            statesValid().invalid ||
            municipalityValid().invalid ||
            curpValid().invalid ||
            birthDateValid().invalid ||
            folioValid().invalid
        );
    };
    const StepTwoisInvalid = () => {
        return (
            streetValid().invalid ||
            colonyValid().invalid ||
            outsideNumberValid().invalid ||
            zipcodeValid().invalid ||
            addresscityValid().invalid ||
            addressstatesValid().invalid ||
            addressmunicipalityValid().invalid
        );
    };
    const StepThreeisInvalid = () => {
        return (
            modalityValid().invalid ||
            preescolaryearsValid().invalid ||
            elementaryyearsValid().invalid ||
            middleyearsValid().invalid ||
            highschoolyearValid().invalid ||
            observationValid().invalid ||
            weightValid().invalid
        );
    };

    const isStepInvalid = (step) => {
        switch (step) {
            case 0:
                return StepOneisInvalid();
            case 1:
                return StepTwoisInvalid();
            case 2:
                return StepThreeisInvalid();
            default:
                return false;
        }
    };

    ////////////////////////////////////// RENDER FORM STEPPER /////////////////////////////////////////////////

    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(min-width:601px) and (max-width:960px)');
    const onDevices = isMobile || isTablet;

    const renderForm = () => {
        switch (activeStep) {
            case 0:
                return (
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="last_name"
                                    name="last_name"
                                    label="Apellido Paterno"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    inputProps={{
                                        maxLength: 50,
                                        autocomplete: 'turnoff',
                                    }}
                                    value={studentItem.last_name}
                                    onChange={onInputChange}
                                    error={lastNameValid().invalid}
                                    helperText={
                                        lastNameValid().invalid
                                            ? lastNameValid().message
                                            : `Caracteres disponibles: ${
                                                  studentItem?.last_name
                                                      ? studentItem.last_name
                                                            .length
                                                      : 0
                                              }/50`
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="second_last_name"
                                    name="second_last_name"
                                    label="Apellido Materno"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    inputProps={{
                                        maxLength: 50,
                                        autocomplete: 'turnoff',
                                    }}
                                    value={studentItem.second_last_name}
                                    onChange={onInputChange}
                                    error={secondLastNameValid().invalid}
                                    helperText={
                                        secondLastNameValid().invalid
                                            ? secondLastNameValid().message
                                            : `Caracteres disponibles: ${
                                                  studentItem?.second_last_name
                                                      ? studentItem
                                                            .second_last_name
                                                            .length
                                                      : 0
                                              }/50`
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="name"
                                    name="name"
                                    label="Nombre"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    inputProps={{
                                        maxLength: 50,
                                        autocomplete: 'turnoff',
                                    }}
                                    value={studentItem.name}
                                    onChange={onInputChange}
                                    error={nameValid().invalid}
                                    helperText={
                                        nameValid().invalid
                                            ? nameValid().message
                                            : `Caracteres disponibles: ${
                                                  studentItem?.name
                                                      ? studentItem.name.length
                                                      : 0
                                              }/50`
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="cellphone"
                                    name="cellphone"
                                    label="Teléfono"
                                    type="tel"
                                    variant="outlined"
                                    fullWidth
                                    inputProps={{
                                        maxLength: 10,
                                        autocomplete: 'turnoff',
                                    }}
                                    value={studentItem.cellphone}
                                    onChange={onInputChange}
                                    error={cellphoneValid().invalid}
                                    helperText={
                                        cellphoneValid().invalid
                                            ? cellphoneValid().message
                                            : `Caracteres disponibles: ${
                                                  studentItem?.cellphone
                                                      ? studentItem.cellphone
                                                            .length
                                                      : 0
                                              }/10`
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Autocomplete
                                    value={stateValue}
                                    isOptionEqualToValue={(option, value) =>
                                        option.state_id === value.state_id
                                    }
                                    getOptionLabel={(option) => option.title}
                                    onChange={(event, newValue) => {
                                        setSelectedOption(newValue);
                                        getMunicipalities(newValue);
                                        // Verificar si la opción seleccionada es "Extranjero" y habilitar o deshabilitar el switch
                                        const isExtranjero =
                                            newValue &&
                                            newValue.title == 'EXTRANJERO';
                                        setStudentItem((prevItem) => ({
                                            ...prevItem,
                                            is_stranger: isExtranjero,
                                        }));
                                    }}
                                    id="controllable-states-demo"
                                    options={states}
                                    renderOption={(props, option) => (
                                        <Box
                                            component="li"
                                            sx={{
                                                '& > img': {
                                                    mr: 2,
                                                    flexShrink: 0,
                                                },
                                            }}
                                            {...props}>
                                            {option.title} - {option.code}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Lugar de nacimiento"
                                            variant="outlined"
                                            error={statesValid().invalid}
                                            helperText={
                                                statesValid().invalid
                                                    ? statesValid().message
                                                    : false
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Autocomplete
                                    value={municipalityValue}
                                    isOptionEqualToValue={(option, value) =>
                                        option.value === value.value
                                    }
                                    getOptionLabel={(option) => option.title}
                                    onChange={(event, newValue) => {
                                        getCities(newValue);
                                    }}
                                    id="controllable-states-demo"
                                    options={municipalities}
                                    disabled={
                                        municipalities.length === 0
                                            ? true
                                            : false
                                    }
                                    renderOption={(props, option) => (
                                        <Box
                                            component="li"
                                            sx={{
                                                '& > img': {
                                                    mr: 2,
                                                    flexShrink: 0,
                                                },
                                            }}
                                            {...props}>
                                            {option.title}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Elige un municipio"
                                            variant="outlined"
                                            error={municipalityValid().invalid}
                                            helperText={
                                                municipalityValid().invalid
                                                    ? municipalityValid()
                                                          .message
                                                    : false
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Autocomplete
                                    value={cityValue}
                                    isOptionEqualToValue={(option, value) =>
                                        option.value === value.value
                                    }
                                    getOptionLabel={(option) => option.title}
                                    onChange={(event, newValue) => {
                                        saveCity(newValue);
                                    }}
                                    id="controllable-states-demo"
                                    options={cities}
                                    disabled={
                                        cities.length === 0 ? true : false
                                    }
                                    renderOption={(props, option) => (
                                        <Box
                                            component="li"
                                            sx={{
                                                '& > img': {
                                                    mr: 2,
                                                    flexShrink: 0,
                                                },
                                            }}
                                            key={option.city_id}
                                            {...props}>
                                            {option.title}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Elige una ciudad"
                                            variant="outlined"
                                            error={cityValid().invalid}
                                            helperText={
                                                cityValid().invalid
                                                    ? cityValid().message
                                                    : false
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl
                                    variant="outlined"
                                    fullWidth
                                    required
                                    error={genderValid().invalid}>
                                    <TextField
                                        labelId="gender-label"
                                        id="gender"
                                        name="gender"
                                        value={studentItem.gender}
                                        onChange={onInputChange}
                                        fullWidth
                                        label="Seleccionar Género"
                                        variant="outlined"
                                        select
                                        InputLabelProps={{
                                            shrink: studentItem.gender,
                                        }}>
                                        <MenuItem value="" />
                                        {optionGender.map((option) => (
                                            <MenuItem
                                                key={option}
                                                value={option.charAt(0)}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    {genderValid().invalid && (
                                        <FormHelperText error>
                                            {genderValid().message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <LocalizationProvider
                                    dateAdapter={AdapterLuxon}
                                    localeText={
                                        esES.components.MuiLocalizationProvider
                                            .defaultProps.localeText
                                    }
                                    adapterLocale="es-MX">
                                    <Stack
                                        direction="column"
                                        justifyContent="flex-start"
                                        alignItems="flex-start">
                                        <DatePicker
                                            views={['year', 'month', 'day']}
                                            label="Fecha de nacimiento"
                                            value={
                                                studentItem.date_birth
                                                    ? DateTime.fromISO(
                                                          studentItem.date_birth
                                                      )
                                                    : null
                                            }
                                            onChange={(date) => {
                                                const formattedDate = date
                                                    ? date.toISODate()
                                                    : null;
                                                setStudentItem({
                                                    ...studentItem,
                                                    date_birth: formattedDate,
                                                });
                                            }}
                                            slotProps={{
                                                textField: {
                                                    fullWidth: true,
                                                },
                                            }}
                                            onError={(error) => {
                                                if (error === null) {
                                                    setDateError(false);
                                                } else {
                                                    setDateError(true);
                                                }
                                            }}
                                            onClose={() => {}}
                                            disableOpenPicker={!onDevices}
                                            shouldDisableDate={(day) => {
                                                const currentDate =
                                                    DateTime.now();
                                                let minDate = currentDate.minus(
                                                    { years: 100 }
                                                );
                                                minDate = DateTime.fromISO(
                                                    minDate.year +
                                                        '-12-30T13:07:04.054'
                                                );
                                                let maxDate = currentDate.minus(
                                                    { years: 3 }
                                                );
                                                maxDate = DateTime.fromISO(
                                                    maxDate.year +
                                                        '-12-30T13:07:04.054'
                                                );
                                                return (
                                                    day < minDate ||
                                                    day > maxDate
                                                );
                                            }}
                                            minDate={DateTime.fromISO(
                                                DateTime.now().minus({
                                                    years: 101,
                                                }).year + '-12-30T11:59:59.054'
                                            )}
                                            maxDate={DateTime.fromISO(
                                                DateTime.now().minus({
                                                    years: 3,
                                                }).year + '-12-30T11:59:59.054'
                                            )}
                                        />
                                        {birthDateValid().invalid && (
                                            <Grid item>
                                                <Typography
                                                    variant="caption"
                                                    color="error">
                                                    {birthDateValid().message}
                                                </Typography>
                                            </Grid>
                                        )}
                                    </Stack>
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} md={6} sx={{ mt: 2 }}>
                                <FormControlLabel
                                    control={
                                        <GreyBlueSwitch
                                            checked={studentItem.is_indigenous}
                                            onChange={(event) => {
                                                const isChecked =
                                                    event.target.checked;
                                                setStudentItem((prevItem) => ({
                                                    ...prevItem,
                                                    is_indigenous: isChecked,
                                                }));
                                            }}
                                        />
                                    }
                                    label="Indígena"
                                />
                                <FormControlLabel
                                    control={
                                        <GreyBlueSwitch
                                            defaultChecked={
                                                studentItem.is_immigrant
                                            }
                                            onChange={(event) => {
                                                const isChecked =
                                                    event.target.checked;
                                                setStudentItem((prevItem) => ({
                                                    ...prevItem,
                                                    is_immigrant: isChecked,
                                                }));
                                            }}
                                        />
                                    }
                                    label="Inmigrante"
                                />
                                <FormControlLabel
                                    control={
                                        <GreyBlueSwitch
                                            defaultChecked={
                                                studentItem.is_stranger
                                            }
                                            onChange={(event) => {
                                                const isChecked =
                                                    event.target.checked;
                                                setStudentItem((prevItem) => ({
                                                    ...prevItem,
                                                    is_stranger: isChecked,
                                                }));
                                            }}
                                        />
                                    }
                                    label="Extranjero"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl
                                    variant="outlined"
                                    fullWidth={true}
                                    error={curpValid().invalid}>
                                    <InputLabel
                                        id="curp"
                                        shrink={studentItem.curp != ''}>
                                        CURP
                                    </InputLabel>
                                    <OutlinedInput
                                        id="curp"
                                        labelId="curp"
                                        value={studentItem.curp}
                                        onChange={onInputChange}
                                        name="curp"
                                        label="CURP"
                                        type="text"
                                        fullWidth={true}
                                        inputProps={{
                                            maxLength: 18,
                                            autocomplete: 'turnoff',
                                        }}
                                        endAdornment={
                                            studentItem?.curp.length == 18 ? (
                                                <InputAdornment position="end">
                                                    <LoadingButton
                                                        color="primary"
                                                        onClick={
                                                            onSearchStudent
                                                        }
                                                        loading={
                                                            isSearchLoading
                                                        }
                                                        loadingPosition="center"
                                                        variant="text">
                                                        <SearchIcon />
                                                    </LoadingButton>
                                                </InputAdornment>
                                            ) : (
                                                <></>
                                            )
                                        }
                                    />
                                    <FormHelperText>
                                        {curpValid().invalid
                                            ? curpValid().message
                                            : false}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="folio"
                                    name="folio"
                                    label="Matrícula"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    inputProps={{
                                        maxLength: 20,
                                        autocomplete: 'turnoff',
                                    }}
                                    value={studentItem.folio}
                                    onChange={onInputChange}
                                    error={folioValid().invalid}
                                    helperText={
                                        folioValid().invalid
                                            ? folioValid().message
                                            : `Caracteres disponibles: ${
                                                  studentItem?.folio
                                                      ? studentItem.folio.length
                                                      : 0
                                              }/20`
                                    }
                                />
                            </Grid>
                        </Grid>
                    </>
                );
            case 1:
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="street"
                                name="street"
                                label="Calle"
                                type="text"
                                variant="outlined"
                                fullWidth
                                inputProps={{
                                    maxLength: 50,
                                    autocomplete: 'turnoff',
                                }}
                                value={studentItem.street}
                                onChange={onInputChange}
                                error={streetValid().invalid}
                                helperText={
                                    streetValid().invalid
                                        ? streetValid().message
                                        : `Caracteres disponibles: ${
                                              studentItem?.street
                                                  ? studentItem.street.length
                                                  : 0
                                          }/50`
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="outside_number"
                                name="outside_number"
                                label="Número Exterior"
                                type="text"
                                variant="outlined"
                                fullWidth
                                inputProps={{
                                    maxLength: 20,
                                    autocomplete: 'turnoff',
                                }}
                                value={studentItem.outside_number}
                                onChange={onInputChange}
                                error={outsideNumberValid().invalid}
                                helperText={
                                    outsideNumberValid().invalid
                                        ? outsideNumberValid().message
                                        : `Caracteres disponibles: ${
                                              studentItem?.outside_number
                                                  ? studentItem.outside_number
                                                        .length
                                                  : 0
                                          }/20`
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="colony"
                                name="colony"
                                label="Colonia"
                                type="text"
                                variant="outlined"
                                fullWidth
                                inputProps={{
                                    maxLength: 50,
                                    autocomplete: 'turnoff',
                                }}
                                value={studentItem.colony}
                                onChange={onInputChange}
                                error={colonyValid().invalid}
                                helperText={
                                    colonyValid().invalid
                                        ? colonyValid().message
                                        : `Caracteres disponibles: ${
                                              studentItem?.colony
                                                  ? studentItem.colony.length
                                                  : 0
                                          }/50`
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="inside_number"
                                name="inside_number"
                                label="Número Interior"
                                type="text"
                                variant="outlined"
                                fullWidth
                                inputProps={{
                                    maxLength: 20,
                                    autocomplete: 'turnoff',
                                }}
                                value={studentItem.inside_number}
                                onChange={onInputChange}
                                error={insideNumberValid().invalid}
                                helperText={
                                    insideNumberValid().invalid
                                        ? insideNumberValid().message
                                        : `Caracteres disponibles: ${
                                              studentItem?.inside_number
                                                  ? studentItem.inside_number
                                                        .length
                                                  : 0
                                          }/20`
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="zipcode"
                                name="zipcode"
                                label="Código Postal"
                                type="text"
                                variant="outlined"
                                fullWidth
                                inputProps={{
                                    maxLength: 5,
                                    autocomplete: 'turnoff',
                                }}
                                value={studentItem.zipcode}
                                onChange={onInputChange}
                                error={zipcodeValid().invalid}
                                helperText={
                                    zipcodeValid().invalid
                                        ? zipcodeValid().message
                                        : `Caracteres disponibles: ${
                                              studentItem?.zipcode
                                                  ? studentItem.zipcode.length
                                                  : 0
                                          }/5`
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                value={addressstateValue}
                                isOptionEqualToValue={(option, value) =>
                                    option.addressstate_id ===
                                    value.addressstate_id
                                }
                                getOptionLabel={(option) => option.title}
                                onChange={(event, newValue) => {
                                    getAddressMunicipalities(newValue);
                                }}
                                id="controllable-states-demo"
                                options={addressstates}
                                renderOption={(props, option) => (
                                    <Box
                                        component="li"
                                        sx={{
                                            '& > img': { mr: 2, flexShrink: 0 },
                                        }}
                                        {...props}>
                                        {option.title} - {option.code}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Elige estado"
                                        variant="outlined"
                                        error={addressstatesValid().invalid}
                                        helperText={
                                            addressstatesValid().invalid
                                                ? addressstatesValid().message
                                                : false
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                value={addressmunicipalityValue}
                                isOptionEqualToValue={(option, value) =>
                                    option.value === value.value
                                }
                                getOptionLabel={(option) => option.title}
                                onChange={(event, newValue) => {
                                    getAddressCities(newValue);
                                }}
                                id="controllable-states-demo"
                                options={addressmunicipalities}
                                disabled={
                                    addressmunicipalities.length === 0
                                        ? true
                                        : false
                                }
                                renderOption={(props, option) => (
                                    <Box
                                        component="li"
                                        sx={{
                                            '& > img': { mr: 2, flexShrink: 0 },
                                        }}
                                        {...props}>
                                        {option.title}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Elige un municipio"
                                        variant="outlined"
                                        error={
                                            addressmunicipalityValid().invalid
                                        }
                                        helperText={
                                            addressmunicipalityValid().invalid
                                                ? addressmunicipalityValid()
                                                      .message
                                                : false
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                value={addresscityValue}
                                isOptionEqualToValue={(option, value) =>
                                    option.value === value.value
                                }
                                getOptionLabel={(option) => option.title}
                                onChange={(event, newValue) => {
                                    saveAddressCity(newValue);
                                }}
                                id="controllable-states-demo"
                                options={addresscities}
                                disabled={
                                    addresscities.length === 0 ? true : false
                                }
                                renderOption={(props, option) => (
                                    <Box
                                        component="li"
                                        sx={{
                                            '& > img': { mr: 2, flexShrink: 0 },
                                        }}
                                        key={option.address_city_id}
                                        {...props}>
                                        {option.title}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Elige una ciudad"
                                        variant="outlined"
                                        error={addresscityValid().invalid}
                                        helperText={
                                            addresscityValid().invalid
                                                ? addresscityValid().message
                                                : false
                                        }
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                );
            case 2:
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <FormControl
                                variant="outlined"
                                fullWidth
                                required
                                error={modalityValid().invalid}>
                                <TextField
                                    labelId="modality-label"
                                    id="school_modality"
                                    name="school_modality"
                                    value={studentItem.school_modality}
                                    onChange={onInputChange}
                                    fullWidth
                                    label="Modalidad de Estudio"
                                    variant="outlined"
                                    select
                                    InputLabelProps={{
                                        shrink: studentItem.school_modality,
                                    }}>
                                    <MenuItem value="" />
                                    {optionModality.map((option, index) => (
                                        <MenuItem key={index} value={index + 1}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                {modalityValid().invalid && (
                                    <FormHelperText error>
                                        {modalityValid().message}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="email"
                                name="email"
                                label="Correo electrónico"
                                type="text"
                                variant="outlined"
                                fullWidth
                                inputProps={{
                                    maxLength: 50,
                                    autocomplete: 'turnoff',
                                }}
                                value={studentItem.email || ''}
                                onChange={onInputChange}
                                InputLabelProps={{ shrink: studentItem.email }}
                                error={emailValid().invalid}
                                helperText={
                                    emailValid().invalid
                                        ? emailValid().message
                                        : `Caracteres disponibles: ${
                                              studentItem?.email
                                                  ? studentItem.email.length
                                                  : 0
                                          }/50`
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                labelId="preschool_years"
                                id="preschool_years"
                                name="preschool_years"
                                value={studentItem.preschool_years}
                                onChange={onInputChange}
                                fullWidth
                                label="Años de Prescolar"
                                variant="outlined"
                                select
                                InputLabelProps={{
                                    shrink: studentItem.preschool_years != null,
                                }}
                                error={preescolaryearsValid().invalid}
                                helperText={preescolaryearsValid().message}>
                                <MenuItem value={0}>0</MenuItem>
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                labelId="elementary_years"
                                id="elementary_years"
                                name="elementary_years"
                                value={studentItem.elementary_years}
                                onChange={onInputChange}
                                fullWidth
                                label="Años de Primaria"
                                variant="outlined"
                                select
                                InputLabelProps={{
                                    shrink:
                                        studentItem.elementary_years != null,
                                }}
                                error={elementaryyearsValid().invalid}
                                helperText={elementaryyearsValid().message}>
                                <MenuItem value={0}>0</MenuItem>
                                <MenuItem value={6}>6</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                labelId="middle_years"
                                id="middle_years"
                                name="middle_years"
                                value={studentItem.middle_years}
                                onChange={onInputChange}
                                fullWidth
                                label="Años de Secundaria"
                                variant="outlined"
                                select
                                InputLabelProps={{
                                    shrink: studentItem.middle_years != null,
                                }}
                                defaultValue={0}
                                disabled={studentItem.elementary_years !== 6}
                                error={middleyearsValid().invalid}
                                helperText={middleyearsValid().message}>
                                <MenuItem value={0}>0</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={4}>4</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                labelId="highschool_years"
                                id="highschool_years"
                                name="highschool_years"
                                value={studentItem.highschool_years}
                                onChange={onInputChange}
                                fullWidth
                                label="Años de Bachillerato"
                                variant="outlined"
                                select
                                InputLabelProps={{
                                    shrink:
                                        studentItem.highschool_years != null,
                                }}
                                defaultValue={0}
                                error={highschoolyearValid().invalid}
                                helperText={highschoolyearValid().message}
                                disabled={
                                    studentItem.middle_years !== 3 &&
                                    studentItem.middle_years !== 4
                                }>
                                <MenuItem value={0}>0</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={4}>4</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                control={
                                    <GreyBlueSwitch
                                        defaultChecked={
                                            studentItem.has_scholar_grants
                                        }
                                        onChange={(event) => {
                                            const isChecked =
                                                event.target.checked;
                                            setStudentItem((prevItem) => ({
                                                ...prevItem,
                                                has_scholar_grants: isChecked,
                                            }));
                                        }}
                                    />
                                }
                                label="Posee una beca"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                control={
                                    <GreyBlueSwitch
                                        defaultChecked={studentItem.is_foreign}
                                        onChange={(event) => {
                                            const isChecked =
                                                event.target.checked;
                                            setStudentItem((prevItem) => ({
                                                ...prevItem,
                                                is_foreign: isChecked,
                                            }));
                                        }}
                                    />
                                }
                                label="Es foráneo"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                control={
                                    <GreyBlueSwitch
                                        defaultChecked={
                                            studentItem.in_repetition
                                        }
                                        onChange={(event) => {
                                            const isChecked =
                                                event.target.checked;
                                            setStudentItem((prevItem) => ({
                                                ...prevItem,
                                                in_repetition: isChecked,
                                            }));
                                        }}
                                    />
                                }
                                label="Está repitiendo año"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                control={
                                    <GreyBlueSwitch
                                        defaultChecked={
                                            studentItem.in_wrong_degree
                                        }
                                        onChange={(event) => {
                                            const isChecked =
                                                event.target.checked;
                                            setStudentItem((prevItem) => ({
                                                ...prevItem,
                                                in_wrong_degree: isChecked,
                                            }));
                                        }}
                                    />
                                }
                                label="Cursa un grado que no le corresponde"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                id="observation"
                                name="observation"
                                label="Observaciones"
                                type="text"
                                variant="outlined"
                                fullWidth
                                inputProps={{
                                    maxLength: 400,
                                    autocomplete: 'turnoff',
                                }}
                                multiline
                                rows={4}
                                value={studentItem.observation}
                                onChange={onInputChange}
                                error={observationValid().invalid}
                                helperText={
                                    observationValid().invalid
                                        ? observationValid().message
                                        : `Caracteres disponibles: ${
                                              studentItem?.observation
                                                  ? studentItem.observation
                                                        .length
                                                  : 0
                                          }/400`
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                id="height"
                                name="height"
                                label="Estatura"
                                type="number"
                                variant="outlined"
                                fullWidth
                                value={studentItem.height}
                                inputProps={{
                                    autocomplete: 'turnoff',
                                    shrink: studentItem.height,
                                    pattern: '[0-9]*',
                                }}
                                onChange={(event) => {
                                    const inputValue = event.target.value;
                                    const regex = /^\d{0,1}(\.\d{0,2})?$/;
                                    const isValidFormat =
                                        regex.test(inputValue);

                                    if (isValidFormat || inputValue === '') {
                                        const heightNumber =
                                            inputValue === ''
                                                ? ''
                                                : parseFloat(inputValue);
                                        if (
                                            heightNumber === '' ||
                                            (heightNumber >= 1.0 &&
                                                heightNumber <= 2.2)
                                        ) {
                                            setStudentItem({
                                                ...studentItem,
                                                height: inputValue,
                                            });
                                        }
                                    }
                                }}
                                onBlur={() => {
                                    const heightValue = studentItem.height;
                                    const heightNumber =
                                        parseFloat(heightValue);
                                    const isValidHeight =
                                        heightNumber >= 1.0 &&
                                        heightNumber <= 2.2;

                                    if (heightValue !== '' && !isValidHeight) {
                                        // Actualizar el valor con el formato correcto
                                        setStudentItem({
                                            ...studentItem,
                                            height: heightNumber.toFixed(2),
                                        });
                                    }
                                }}
                                error={heightValid().invalid}
                                helperText={
                                    heightValid().invalid
                                        ? heightValid().message
                                        : 'Valores válidos del 1.00 al 2.20'
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                id="weight"
                                name="weight"
                                label="Peso"
                                type="number"
                                variant="outlined"
                                fullWidth
                                value={studentItem.weight}
                                inputProps={{
                                    autocomplete: 'turnoff',
                                    shrink: studentItem.weight,
                                    pattern: '[0-9]*',
                                }}
                                onChange={(event) => {
                                    const inputValue = event.target.value;
                                    const regex = /^\d{0,3}(\.\d{0,2})?$/;
                                    const isValid = regex.test(inputValue);

                                    if (isValid) {
                                        setStudentItem({
                                            ...studentItem,
                                            weight: inputValue,
                                        });
                                    }
                                }}
                                error={weightValid().invalid}
                                helperText={
                                    weightValid().invalid
                                        ? weightValid().message
                                        : 'Valores válidos entre 8 y 150.99'
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} mt={-0.4}>
                            <FormControl
                                variant="outlined"
                                sx={{ minWidth: 120 }}>
                                <InputLabel id="talla">Talla</InputLabel>
                                <Select
                                    labelId="talla"
                                    id="uniform_size"
                                    name="uniform_size"
                                    value={studentItem.uniform_size}
                                    onChange={onInputChange}
                                    label="Talla">
                                    <MenuItem value="" />
                                    {optionSize.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={apcChecked}
                                        value={studentItem.apc}
                                        onChange={handleApcChange}
                                        name="apc"
                                        color="primary"
                                    />
                                }
                                label="APC"
                            />
                        </Grid>
                    </Grid>
                );
            case 3:
                return (
                    <>
                        {!student && (
                            <GroupsSelector
                                level={level}
                                groups={groups}
                                selectedPrimaryGroups={selectedPrimaryGroup}
                                getNameofGroups={getNameofGroups}
                                getNamesofSpecialGroups={
                                    getNamesofSpecialGroups
                                }
                                targetKeys={targetKeys}
                                selectedKeys={selectedKeys}
                                onChangeGettingNameGroup={setNameofGroups}
                                onChangeGettingSpecialGroupsNames={
                                    setNamesofSpecialGroups
                                }
                                onChangeLevel={changeLevel}
                                onChange={handleGroupsChange}
                            />
                        )}
                    </>
                );
            case 4:
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Box sx={{ marginBottom: 2 }}>
                                <Divider>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            marginBottom: 1,
                                            fontWeight: 'bold',
                                        }}>
                                        Datos generales
                                    </Typography>
                                </Divider>
                                <Box
                                    sx={{
                                        display: 'grid',
                                        gridTemplateColumns: '1fr 4rem 1fr',
                                        columnGap: '1rem',
                                        alignItems: 'center',
                                    }}>
                                    <div>
                                        <Typography>
                                            Apellido paterno:
                                            <strong>
                                                {studentItem.last_name}
                                            </strong>
                                        </Typography>
                                        <Typography>
                                            Apellido materno:
                                            <strong
                                                style={{
                                                    color: studentItem.second_last_name
                                                        ? 'inherit'
                                                        : 'grey',
                                                }}>
                                                {studentItem.second_last_name ||
                                                    'N/A'}
                                            </strong>
                                        </Typography>
                                        <Typography>
                                            Nombre:
                                            <strong>{studentItem.name}</strong>
                                        </Typography>
                                        <Typography>
                                            Teléfono:{' '}
                                            <strong>
                                                {studentItem.cellphone}
                                            </strong>
                                        </Typography>
                                        <Typography>
                                            Género:
                                            <strong>
                                                {studentItem.gender}
                                            </strong>
                                        </Typography>
                                    </div>
                                    <div
                                        style={{
                                            width: '100%',
                                            textAlign: 'center',
                                        }}></div>
                                    <div>
                                        <Typography>
                                            Estado:
                                            <strong>
                                                {stateValue
                                                    ? stateValue.title
                                                    : ''}
                                            </strong>
                                        </Typography>
                                        <Typography>
                                            Municipio:
                                            <strong>
                                                {municipalityValue
                                                    ? municipalityValue.title
                                                    : ''}
                                            </strong>
                                        </Typography>
                                        <Typography>
                                            Ciudad:
                                            <strong>
                                                {cityValue
                                                    ? cityValue.title
                                                    : ''}
                                            </strong>
                                        </Typography>
                                        <Typography>
                                            Fecha de Nacimiento:
                                            <strong
                                                style={{
                                                    color: studentItem.date_birth
                                                        ? 'inherit'
                                                        : 'grey',
                                                }}>
                                                {studentItem.date_birth
                                                    ? studentItem.date_birth.toLocaleString(
                                                          'es-MX',
                                                          { dateStyle: 'short' }
                                                      )
                                                    : '' || 'N/A'}
                                            </strong>
                                        </Typography>
                                        <Typography>
                                            CURP:
                                            <strong>{studentItem.curp}</strong>
                                        </Typography>
                                    </div>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Box sx={{ marginBottom: 2 }}>
                                <Divider>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            marginBottom: 1,
                                            fontWeight: 'bold',
                                        }}>
                                        Domicilio
                                    </Typography>
                                </Divider>
                                <Box
                                    sx={{
                                        display: 'grid',
                                        gridTemplateColumns: '1fr 4rem 1fr',
                                        columnGap: '1rem',
                                        alignItems: 'center',
                                    }}>
                                    <div>
                                        <Typography>
                                            Calle:
                                            <strong>
                                                {' '}
                                                {studentItem.street}
                                            </strong>
                                        </Typography>
                                        <Typography>
                                            Colonia:
                                            <strong>
                                                {' '}
                                                {studentItem.colony}
                                            </strong>
                                        </Typography>
                                        <Typography>
                                            Número exterior:
                                            <strong>
                                                {' '}
                                                {studentItem.outside_number}
                                            </strong>
                                        </Typography>
                                        <Typography>
                                            Estado del domicilio:
                                            <strong>
                                                {addressstateValue
                                                    ? addressstateValue.title
                                                    : ''}
                                            </strong>
                                        </Typography>
                                        <Typography>
                                            Municipio del domicilio:
                                            <strong>
                                                {addressmunicipalityValue
                                                    ? addressmunicipalityValue.title
                                                    : ''}
                                            </strong>
                                        </Typography>
                                        <Typography>
                                            Ciudad del domicilio:
                                            <strong>
                                                {addresscityValue
                                                    ? addresscityValue.title
                                                    : ''}
                                            </strong>
                                        </Typography>
                                    </div>
                                    <div
                                        style={{
                                            width: '100%',
                                            textAlign: 'center',
                                        }}></div>
                                    <div>
                                        <Typography>
                                            Número interior:
                                            <strong
                                                style={{
                                                    color: studentItem.inside_number
                                                        ? 'inherit'
                                                        : 'grey',
                                                }}>
                                                {studentItem.inside_number ||
                                                    'N/A'}
                                            </strong>
                                        </Typography>
                                        <Typography>
                                            Código postal:
                                            <strong>
                                                {' '}
                                                {studentItem.zipcode}
                                            </strong>
                                        </Typography>
                                    </div>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Box sx={{ marginBottom: 2 }}>
                                <Divider>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            marginBottom: 1,
                                            fontWeight: 'bold',
                                        }}>
                                        Datos Adicionales
                                    </Typography>
                                </Divider>
                                <Box
                                    sx={{
                                        display: 'grid',
                                        gridTemplateColumns: '1fr 4rem 1fr',
                                        columnGap: '1rem',
                                        alignItems: 'center',
                                    }}>
                                    <div>
                                        <Typography>
                                            Correo electrónico:
                                            <strong
                                                style={{
                                                    color: studentItem.email
                                                        ? 'inherit'
                                                        : 'grey',
                                                }}>
                                                {studentItem.email || 'N/A'}
                                            </strong>
                                        </Typography>
                                        <Typography>
                                            Matrícula:
                                            <strong>{studentItem.folio}</strong>
                                        </Typography>
                                        <Typography>
                                            Modalidad:
                                            <strong
                                                style={{
                                                    color: studentItem.school_modality
                                                        ? 'inherit'
                                                        : 'grey',
                                                }}>
                                                {studentItem.school_modality ===
                                                1
                                                    ? 'Presencial'
                                                    : studentItem.school_modality ===
                                                      2
                                                    ? 'En Linea'
                                                    : studentItem.school_modality ||
                                                      'N/A'}
                                            </strong>
                                        </Typography>
                                        <Typography>
                                            Años de prescolar:
                                            <strong>
                                                {studentItem.preschool_years}
                                            </strong>
                                        </Typography>
                                        <Typography>
                                            Años de primaria:
                                            <strong>
                                                {studentItem.elementary_years}
                                            </strong>
                                        </Typography>
                                        <Typography>
                                            Años de secundaria:
                                            <strong>
                                                {studentItem.middle_years}
                                            </strong>
                                        </Typography>
                                        <Typography>
                                            Años de Bachillerato:
                                            <strong>
                                                {studentItem.highschool_years}
                                            </strong>
                                        </Typography>
                                        <Typography>
                                            Es indígena:
                                            <strong>
                                                {studentItem.is_indigenous
                                                    ? 'Si'
                                                    : 'No'}
                                            </strong>
                                        </Typography>
                                        <Typography>
                                            Posee una beca:
                                            <strong>
                                                {studentItem.has_scholar_grants
                                                    ? 'Si'
                                                    : 'No'}
                                            </strong>
                                        </Typography>
                                        <Typography>
                                            Es foráneo:
                                            <strong>
                                                {studentItem.is_foreign
                                                    ? 'Si'
                                                    : 'No'}
                                            </strong>
                                        </Typography>
                                    </div>
                                    <div
                                        style={{
                                            width: '100%',
                                            textAlign: 'center',
                                        }}></div>
                                    <div>
                                        <Typography>
                                            Es inmigrante:
                                            <strong>
                                                {studentItem.is_immigrant
                                                    ? 'Si'
                                                    : 'No'}
                                            </strong>
                                        </Typography>
                                        <Typography>
                                            Está repitiendo año:
                                            <strong>
                                                {studentItem.in_repetition
                                                    ? 'Si'
                                                    : 'No'}
                                            </strong>
                                        </Typography>
                                        <Typography>
                                            Cursa un grado que no le
                                            corresponde:
                                            <strong>
                                                {studentItem.in_wrong_degree
                                                    ? 'Si'
                                                    : 'No'}
                                            </strong>
                                        </Typography>
                                        <Typography>
                                            Observaciones:
                                            <strong
                                                style={{
                                                    color: studentItem.observation
                                                        ? 'inherit'
                                                        : 'grey',
                                                }}>
                                                {studentItem.observation ||
                                                    'N/A'}
                                            </strong>
                                        </Typography>
                                        <Typography>
                                            Altura:
                                            <strong
                                                style={{
                                                    color: studentItem.height
                                                        ? 'inherit'
                                                        : 'grey',
                                                }}>
                                                {studentItem.height || 'N/A'}
                                            </strong>
                                        </Typography>
                                        <Typography>
                                            Peso:
                                            <strong
                                                style={{
                                                    color: studentItem.weight
                                                        ? 'inherit'
                                                        : 'grey',
                                                }}>
                                                {studentItem.weight || 'N/A'}
                                            </strong>
                                        </Typography>
                                        <Typography>
                                            Talla:
                                            <strong
                                                style={{
                                                    color: studentItem.uniform_size
                                                        ? 'inherit'
                                                        : 'grey',
                                                }}>
                                                {studentItem.uniform_size ===
                                                'ch'
                                                    ? 1
                                                    : studentItem.uniform_size ===
                                                      'md'
                                                    ? 2
                                                    : studentItem.uniform_size ===
                                                      'gd'
                                                    ? 3
                                                    : studentItem.uniform_size ||
                                                      'N/A'}
                                            </strong>
                                        </Typography>
                                    </div>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Box sx={{ marginBottom: 2 }}>
                                <Divider>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            marginBottom: 1,
                                            fontWeight: 'bold',
                                        }}>
                                        Grupos
                                    </Typography>
                                </Divider>
                                <Box
                                    sx={{
                                        display: 'grid',
                                        gridTemplateColumns: '1fr 4rem 1fr',
                                        columnGap: '1rem',
                                        alignItems: 'center',
                                    }}>
                                    <div>
                                        <Typography>
                                            Nivel educativo:
                                            <strong
                                                style={{
                                                    color: level
                                                        ? 'inherit'
                                                        : 'grey',
                                                }}>
                                                {level === 1 && 'Preescolar'}
                                                {level === 2 && 'Primaria'}
                                                {level === 3 && 'Secundaria'}
                                                {level === 4 && 'Bachillerato'}
                                                {level === 5 && 'Universidad'}
                                                {level === 6 && 'Maestría'}
                                                {level === 7 && 'Posgrado'}
                                                {level === 8 && 'Doctorado'}
                                                {level === 9 && 'Certificación'}
                                                {!level && 'N/A'}
                                            </strong>
                                        </Typography>
                                        <Typography>
                                            Grupo principal:
                                            <strong
                                                style={{
                                                    color: getNameofGroups
                                                        ? 'inherit'
                                                        : 'grey',
                                                }}>
                                                {getNameofGroups || 'N/A'}
                                            </strong>
                                        </Typography>
                                    </div>
                                    <div
                                        style={{
                                            width: '100%',
                                            textAlign: 'center',
                                        }}></div>
                                    <div>
                                        <Typography
                                            variant="body1"
                                            component="div"
                                            style={{ marginTop: '20px' }}>
                                            Grupos Especiales Seleccionados:
                                            <strong
                                                style={{
                                                    color:
                                                        getNamesofSpecialGroups.length >
                                                        0
                                                            ? 'inherit'
                                                            : 'grey',
                                                }}>
                                                {getNamesofSpecialGroups.map(
                                                    (name) => (
                                                        <div key={name}>
                                                            {name}{' '}
                                                        </div>
                                                    )
                                                )}
                                            </strong>
                                        </Typography>
                                    </div>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                );
            default:
                return null;
        }
    };

    /**
     * Componente del titulo
     *
     * @param {*} props
     *
     * @returns
     */
    function Title(props) {
        return 'Agregar alumno';
    }

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth={'lg'}
            disableEscapeKeyDown={statusOperation === 'pending'}
            onClose={handleClose}>
            <DialogTitle
                style={{
                    backgroundColor: titleBarBackground.value,
                    color: fontColor.value,
                }}>
                <div style={{ display: 'flex', color: fontColor.value }}>
                    <Typography
                        variant="h6"
                        component="div"
                        style={{ flexGrow: 1 }}>
                        <Title />
                    </Typography>
                </div>
            </DialogTitle>
            <DialogContent>
                <Stack
                    direction="row"
                    divider={
                        <Divider
                            orientation="vertical"
                            flexItem
                            variant="middle"
                        />
                    }
                    spacing={4.7}
                    sx={{ height: 550 }}>
                    <Stack
                        direction="column"
                        spacing={1}
                        sx={{ minWidth: 153, maxWidth: 153 }}>
                        <Stepper activeStep={activeStep} orientation="vertical">
                            {steps.map((label, index) => {
                                let stepValid = true;
                                let stepText = '';

                                if (index === 0) {
                                    stepValid = !StepOneisInvalid();
                                    stepText = StepOneisInvalid()
                                        ? 'Faltan campos por llenar'
                                        : 'Todos los campos están completos';
                                } else if (index === 1) {
                                    stepValid = !StepTwoisInvalid();
                                    stepText = StepTwoisInvalid()
                                        ? 'Faltan campos por llenar'
                                        : 'Todos los campos están completos';
                                } else if (index === 2) {
                                    stepValid = !StepThreeisInvalid();
                                    stepText = StepThreeisInvalid()
                                        ? 'Faltan campos por llenar'
                                        : 'Todos los campos están completos';
                                }

                                return (
                                    <Step
                                        key={index}
                                        completed={index < activeStep}
                                        error={
                                            index === activeStep && !stepValid
                                        }>
                                        <StepLabel
                                            StepIconProps={{
                                                completed: index < activeStep,
                                                error:
                                                    index === activeStep &&
                                                    !stepValid,
                                            }}
                                            StepIconComponent={(props) => {
                                                if (props.error) {
                                                    return (
                                                        <ErrorIcon
                                                            style={{
                                                                color: 'red',
                                                            }}
                                                        />
                                                    );
                                                } else if (props.completed) {
                                                    return (
                                                        <CheckIcon
                                                            style={{
                                                                color: 'green',
                                                            }}
                                                        />
                                                    );
                                                } else {
                                                    return (
                                                        <StepIcon {...props} />
                                                    );
                                                }
                                            }}>
                                            <Typography
                                                variant="h7"
                                                color="primary">
                                                {label.label}
                                            </Typography>
                                            {index === activeStep && (
                                                <Typography
                                                    variant="body2"
                                                    style={{
                                                        color: stepValid
                                                            ? 'green'
                                                            : 'red',
                                                    }}>
                                                    {stepText}
                                                </Typography>
                                            )}
                                        </StepLabel>
                                        <StepContent>
                                            <Typography>
                                                {label.description}
                                            </Typography>
                                        </StepContent>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </Stack>
                    <Box sx={{ pt: 3 }}>{renderForm()}</Box>
                </Stack>
            </DialogContent>
            <DialogActions>
                {activeStep === 0 ? (
                    <Button onClick={handleClose}>Cerrar</Button>
                ) : (
                    <Button disabled={activeStep === 0} onClick={handleBack}>
                        Atrás
                    </Button>
                )}
                {activeStep < steps.length - 1 ? (
                    <Button
                        variant="contained"
                        onClick={handleNext}
                        sx={{ ml: 2 }}
                        disabled={isStepInvalid(activeStep)}>
                        Siguiente
                    </Button>
                ) : (
                    <LoadingButton
                        size="small"
                        color="primary"
                        onClick={() => {
                            saveStudent();
                        }}
                        loading={statusOperation == 'pending'}
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="contained">
                        Finalizar
                    </LoadingButton>
                )}
            </DialogActions>
        </Dialog>
    );
};
export default StudentStepperModal;
