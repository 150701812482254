import React, { useEffect, useState } from 'react';
import {
    Avatar,
    Card,
    CardHeader,
    CardActions,
    CardContent,
    IconButton,
    Stack,
} from '@mui/material';
import { DataGrid, esES, GridToolbar } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { selectScoresImports } from '../../../store/slices/entities/imports';
import { selectAdministers } from '../../../store/slices/entities/users';

import ReplayIcon from '@mui/icons-material/Replay';
import ErrorIcon from '@mui/icons-material/Error';
import DoneIcon from '@mui/icons-material/Done';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CloudSyncIcon from '@mui/icons-material/CloudSync';

import DefaultMaleImage from '../../../assets/images/default-image-niño.svg';
import DefaultFemaleImage from '../../../assets/images/default-image-niña.svg';
import { DateTime } from 'luxon';
import { UpdateImportModal } from '../../Configuraciones/components/UpdateImportModal';
import { selectScoresUI, updateScoreImportFilter } from '../store/uiSlice';
import { GridToolBar } from '../../../components/utilities/GridToolBar';
import CustomPagination from '../../../components/utilities/CustomPagination';
import NoDataOverlay from '../../../components/utilities/NoDataOverlay';
import Header from '../../../components/utilities/Header';

export const ImportsCard = () => {
    const dispatch = useDispatch();

    //Shared Stare
    const importsData = useSelector(selectScoresImports);
    const adminstrators = useSelector(selectAdministers);
    const filters = useSelector(selectScoresUI);
    const filter = filters.filter;

    //Local State

    const [openUpdateModal, setOpenUpdateModal] = useState(false);
    const [imports, setImports] = useState([]);
    const [importObject, setImportObject] = useState(null);

    const ImportsToolbar = (props) => {
        return (
            <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}>
                <CardHeader title="Importaciones" />
                <GridToolbar {...props} />
            </Stack>
        );
    };

    /**
     * Import status definitions
     */
    let allImportStatus = {
        1: {
            color: '#ddc005',
            text: 'En espera',
            icon: <AccessTimeIcon />,
            class: 'left-status-onhold',
        },
        2: {
            color: '#036dba',
            text: 'Procesando',
            icon: <CloudSyncIcon />,
            class: 'left-status-processing',
        },
        3: {
            color: 'green',
            text: 'Importada',
            icon: <DoneIcon />,
            class: 'left-status-imported',
        },
        4: {
            color: 'red',
            text: 'Fallido',
            icon: <ErrorIcon />,
            class: 'left-status-failed',
        },
    };

    /**
     * Import types definitions
     */
    let importTypes = {
        students: {
            title: 'Alumnos',
        },
        assists: {
            title: 'Asistencias',
        },
        'group-scores': {
            title: 'Calificaciones de grupo',
        },
    };

    useEffect(() => {
        const importsWithAdminNames = importsData.map((importObj) => {
            const userId = importObj.user_id;

            return {
                ...importObj,
                administrator: adminstrators.find(
                    (admin) => Number(admin.user_id) === Number(userId)
                ),
            };
        });
        setImports(importsWithAdminNames);
    }, [importsData, adminstrators]);

    /**
     * columns definitions
     */
    const columns = [
        {
            field: 'folio',
            headerName: 'Folio',
            flex: 0.4,
            minWidth: 40,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'status',
            headerName: 'Estado',
            flex: 0.4,
            minWidth: 40,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ value }) => {
                let importStatus = allImportStatus[value];

                return (
                    <Stack
                        direction={'row'}
                        sx={{ color: importStatus.color }}
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}>
                        {importStatus.icon}
                    </Stack>
                );
            },
        },
        {
            field: 'administrator_name',
            headerName: 'Importador',
            type: 'singleSelect',
            flex: 1,
            minWidth: 100,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => {
                if (row.administrator) {
                    const { gender, url_photo_profile } = row.administrator;

                    const generoS =
                        gender === 'H' ? DefaultMaleImage : DefaultFemaleImage;
                    const comparado =
                        url_photo_profile == null ? generoS : url_photo_profile;

                    return (
                        <>
                            <Avatar
                                alt="Imagen de usuario"
                                src={comparado}
                                sx={{ width: 24, height: 24 }}
                            />

                            {`${row.administrator.name} ${row.administrator.last_name}`}
                        </>
                    );
                }

                return (
                    <>
                        <Avatar
                            alt="Imagen de usuario"
                            src={DefaultMaleImage}
                            sx={{ width: 24, height: 24 }}
                        />
                        Usuario no encontrado
                    </>
                );
            },
        },
        {
            field: 'created_at',
            headerName: 'Fecha de importación',
            type: 'date',
            flex: 1,
            minWidth: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: ({ value }) => {
                return DateTime.fromSQL(value)
                    .setLocale('es')
                    .toLocaleString({ locale: 'es', ...DateTime.DATETIME_MED });
            },
        },
        {
            field: 'updated_at',
            type: 'date',
            headerName: 'Fecha de actualización',
            flex: 1,
            minWidth: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: ({ value }) => {
                return DateTime.fromSQL(value)
                    .setLocale('es')
                    .toLocaleString({ locale: 'es', ...DateTime.DATETIME_MED });
            },
        },
        {
            field: 'import_type',
            headerName: 'Tipo',
            flex: 1,
            minWidth: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => {
                return importTypes[params.value].title;
            },
        },
        {
            field: 'acciones',
            headerName: 'Acciones',
            flex: 1,
            maxWidth: 180,
            editable: false,
            sortable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                if (params.row.status !== 4) {
                    return <></>;
                }

                return (
                    <IconButton
                        onClick={() => {
                            updateImport(params.row);
                        }}>
                        <ReplayIcon />
                    </IconButton>
                );
            },
        },
    ];

    const updateImport = (imp) => {
        setImportObject(imp);
        setOpenUpdateModal(true);
    };

    const handleScoreImportFilterChange = (event) => {
        let newFilterValues = {};
        event.items.forEach((filter) => {
            const { columnField, operatorValue, value } = filter;

            newFilterValues = {
                columnField,
                operatorValue,
                value,
            };
        });

        dispatch(updateScoreImportFilter({ filter: newFilterValues }));
    };

    const paginationItems = [
        { color: 'red', text: 'Fallido', icon: <ErrorIcon /> },
        { color: '#ddc005', text: 'En espera', icon: <AccessTimeIcon /> },
        { color: '#036dba', text: 'Procesando', icon: <CloudSyncIcon /> },
        { color: 'green', text: 'Importada', icon: <DoneIcon /> },
    ];
    return (
        <>
            <Header
                title="Importaciones"
                subtitle="Aquí puedes ver el estado de las importaciones realizadas en el sistema"
            />
            <Card
                sx={{
                    color: 'white',
                    borderRadius: '15px',
                    width: '100%',
                    height: '100%',
                }}>
                <DataGrid
                    localeText={{
                        ...esES.components.MuiDataGrid.defaultProps.localeText,
                        toolbarColumns: '',
                        toolbarFilters: '',
                        toolbarDensity: '',
                        toolbarExport: '',
                    }}
                    rows={imports}
                    columns={columns}
                    getRowId={(row) => row.import_id}
                    pageSize={10}
                    disableSelectionOnClick
                    autoHeight
                    components={{
                        Toolbar: GridToolBar,
                        Pagination: CustomPagination,
                        NoRowsOverlay: NoDataOverlay,
                        NoResultsOverlay: NoDataOverlay,
                    }}
                    componentsProps={{
                        noResultsOverlay: {
                            message:
                                'No se encontraron resultados para la búsqueda',
                        },
                        noRowsOverlay: {
                            message: 'No hay datos disponibles',
                        },
                        pagination: {
                            items: paginationItems,
                        },
                    }}
                    disableDensitySelector
                    sx={{
                        ' & .left-status-onhold': {
                            borderLeftColor: '#ddc005',
                            borderLeftStyle: 'solid',
                        },
                        ' & .left-status-processing': {
                            borderLeftColor: '#036dba',
                            borderLeftStyle: 'solid',
                        },
                        ' & .left-status-imported': {
                            borderLeftColor: 'green',
                            borderLeftStyle: 'solid',
                        },
                        ' & .left-status-failed': {
                            borderLeftColor: 'red',
                            borderLeftStyle: 'solid',
                        },
                        ' & .left-status-unknown': {
                            borderLeftColor: 'pink',
                            borderLeftStyle: 'solid',
                        },
                    }}
                    getCellClassName={(params) => {
                        if (params.field === 'folio') {
                            let importStatus =
                                allImportStatus[params.row.status];

                            return importStatus.class;
                        }

                        return '';
                    }}
                    onFilterModelChange={(event) =>
                        handleScoreImportFilterChange(event)
                    }
                    initialState={{
                        filter: {
                            filterModel: {
                                items:
                                    filter.scoreImportCenter.columnField &&
                                    filter.scoreImportCenter.operatorValue &&
                                    filter.scoreImportCenter.value
                                        ? [
                                              {
                                                  columnField:
                                                      filter.scoreImportCenter
                                                          .columnField,
                                                  operatorValue:
                                                      filter.scoreImportCenter
                                                          .operatorValue,
                                                  value: filter
                                                      .scoreImportCenter.value,
                                              },
                                          ]
                                        : [],
                            },
                        },
                    }}
                />
            </Card>
            <UpdateImportModal
                openUpdateModal={openUpdateModal}
                setOpenUpdateModal={setOpenUpdateModal}
                importData={importObject}
            />
        </>
    );
};
