import { Button, Card, CardContent } from '@mui/material';
import { Box } from '@mui/system';
import { DataGrid, esES } from '@mui/x-data-grid';
import _ from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectStudentAssistsView } from '../../../../../views/Scores/store/ItemsSlice';
import AssistsToolBar from './AssistsToolBar';
import AbsencesModal from './AbsencesModal.jsx';
import JustificationModal from './JustificationModal';
import { GridToolBar } from '../../../../../components/utilities/GridToolBar.jsx';
import CustomPagination from '../../../../../components/utilities/CustomPagination.jsx';
import NoDataOverlay from '../../../../../components/utilities/NoDataOverlay.jsx';
import Header from '../../../../../components/utilities/Header.jsx';

/**
 * Componente para mostrar las asistencias de un alumno
 */
const AssistsCard = ({ studentId }) => {
    ////////////////// SHARES STATE //////////////////

    const newData = useSelector(selectStudentAssistsView(parseInt(studentId)));

    ////////////////// LOCAL STATE /////////////////

    const [openAbsenceModal, setOpenAbsenceModal] = useState(false);
    const [openJustificatedModal, setOpenJustificatedModal] = useState(false);
    const [partialAbsences, setParcialAbsences] = useState([]);

    /////////////// ACTIONS /////////////////

    /**
     *
     * Inicia el proceso para dar formato a los datos
     */

    const buildAssitsDataGrid = ({
        partials,
        subjects,
        subjectCatalog,
        assists,
    }) => {
        let currentDate = moment().format('YYYY-MM-DD hh:mm:ss');

        let subjectItems = [];
        let columnsItems = [];
        let columnsUI = [
            {
                column: 'name',
                title: 'MATERIAS',
                sticky: true,
                className: '',
            },
        ];

        for (let subjet of subjects.map((i) => Object.assign({}, i))) {
            let subjectIItem = {};

            let catalog = _.find(subjectCatalog, {
                catalog_subject_id: subjet.catalog_subject_id,
            });
            subjet.detalles = catalog;

            subjectIItem.name = {
                name: true,
                value: catalog.title,
            };

            let partialsUI = {};

            let total = 0;
            let totalAbs = 0;
            let totalJus = 0;

            for (let partial of partials) {
                let assiten = _.filter(assists, {
                    subject_id: subjet.subject_id,
                    partial_id: partial.partial_id,
                });

                ///HERE!-------------
                let assi = assiten.filter((e) => e.assistance === 1);
                let absence = assiten.filter(
                    (e) => e.assistance == 0 && e.is_justified === false
                );

                let justified = assiten.filter(
                    (e) => e.assistance == 0 && e.is_justified === true
                );

                total += assi.length;
                totalAbs += absence.length;
                totalJus += justified.length;

                partialsUI[`Asistencias p-${partial.partial}`] = {
                    partial: true,
                    value: assi.length,
                    partialActive:
                        currentDate >= partial.start_date &&
                        currentDate <= partial.limit_date,
                };
                partialsUI[`Faltas p-${partial.partial}`] = {
                    partial: true,
                    value: absence.length,
                    abs: absence,
                    partialActive:
                        currentDate >= partial.start_date &&
                        currentDate <= partial.limit_date,
                };

                partialsUI[`Justificaciones p-${partial.partial}`] = {
                    partial: true,
                    value: justified.length,
                    abs: justified,
                    partialActive:
                        currentDate >= partial.start_date &&
                        currentDate <= partial.limit_date,
                };
            }

            partialsUI['total'] = {
                total: true,
                value: total,
            };
            ///HERE!----------------
            partialsUI['total faltas'] = {
                total: true,
                value: totalAbs,
            };

            partialsUI['total justificaciones'] = {
                total: true,
                value: totalJus,
            };

            subjectItems.push({ ...subjectIItem, ...partialsUI });
        }

        for (let partial of partials) {
            columnsItems.push({
                column: `Asistencias p-${partial.partial}`,
                title: `Asist P${partial.partial}`,
                sticky: false,
                className: 'parcial-column',
                partialActive:
                    currentDate >= partial.start_date &&
                    currentDate <= partial.limit_date,
            });
            ///HERE!-------------
            columnsItems.push({
                column: `Faltas p-${partial.partial}`,
                title: `Faltas P${partial.partial}`,
                sticky: false,
                className: 'parcial-column',
                partialActive:
                    currentDate >= partial.start_date &&
                    currentDate <= partial.limit_date,
            });

            columnsItems.push({
                column: `Justificaciones p-${partial.partial}`,
                title: `Justif P${partial.partial}`,
                sticky: false,
                className: 'parcial-column',
                partialActive:
                    currentDate >= partial.start_date &&
                    currentDate <= partial.limit_date,
            });
        }

        columnsItems.push(
            {
                column: `total`,
                title: `TOTAL ASIST`,
                sticky: false,
                className: 'parcial-column',
            },
            {
                column: `total faltas`,
                title: `TOTAL FALTAS`,
                sticky: false,
                className: 'parcial-column',
            },
            {
                column: `total justificaciones`,
                title: `TOTAL JUST`,
                sticky: false,
                className: 'parcial-column',
            }
        );

        columnsUI = [...columnsUI, ...columnsItems];

        return {
            columns: columnsUI,
            rows: subjectItems,
        };
    };

    const dataAssits = buildAssitsDataGrid(newData);

    let assistsColumns = dataAssits.columns.map((i) => {
        return {
            field: i.column,
            headerName: i.title,
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                if (params.field === 'name') {
                    return (
                        <div
                            style={{
                                whiteSpace: 'pre-line',
                                textAlign: 'center',
                            }}>
                            {params.value == null ? '' : params.value}
                        </div>
                    );
                }
                const cellValue = params.row[params.field];
                const isFaltaCell = params.field.includes('Faltas');
                const isJustificacionCell =
                    params.field.includes('Justificaciones');
                if (isFaltaCell && cellValue.value > 0) {
                    return (
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                                setParcialAbsences(cellValue.abs);
                                setOpenAbsenceModal(true);
                            }}>
                            {cellValue.value}
                        </Button>
                    );
                } else if (isJustificacionCell && cellValue.value > 0) {
                    return (
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                                setParcialAbsences(cellValue.abs);
                                setOpenJustificatedModal(true);
                            }}>
                            {cellValue.value}
                        </Button>
                    );
                }

                return cellValue.value;
            },
            headerClassName: i.partialActive ? 'partial-active--cell' : '',
            cellClassName: (params) => {
                let row = params.row[params.field]?.partialActive;

                if (row) {
                    return 'partial-active--cell';
                }

                return '';
            },
            valueGetter: (params) => {
                return params.value.value;
            },
        };
    });

    let assistsRows = dataAssits.rows.map((i, index) => ({ id: index, ...i }));

    const sortedAssistancesRows = [...assistsRows].sort((a, b) => {
        const materiaA = a.name.value;
        const materiaB = b.name.value;

        if (materiaA < materiaB) return -1;
        if (materiaA > materiaB) return 1;

        return 0;
    });

    /**
     * Funcion para construir los datos del
     * data grid de asistencias
     */
    return (
        <>
            <AbsencesModal
                title="Faltas"
                openModal={openAbsenceModal}
                setOpenModal={setOpenAbsenceModal}
                absences={partialAbsences}
            />

            <JustificationModal
                title="Justificaciones"
                openModal={openJustificatedModal}
                setOpenModal={setOpenJustificatedModal}
                absences={partialAbsences}
            />

            <Header
                title="Asistencias"
                subtitle="Aquí puedes ver las asistencias de el alumno"
            />
            <Card
                sx={{
                    color: 'white',
                    borderRadius: '15px',
                    width: '100%',
                    height: '100%',
                }}>
                <Box
                    sx={{
                        '& .partial-active--cell': {
                            backgroundColor: 'rgba(0, 0, 0, 0.05)',
                            color: '#1a3e72',
                            fontWeight: '600',
                        },
                    }}>
                    <DataGrid
                        localeText={{
                            ...esES.components.MuiDataGrid.defaultProps
                                .localeText,
                            toolbarColumns: '',
                            toolbarFilters: '',
                            toolbarDensity: '',
                            toolbarExport: '',
                        }}
                        rows={sortedAssistancesRows}
                        components={{
                            Toolbar: GridToolBar,
                            Pagination: CustomPagination,
                            NoRowsOverlay: NoDataOverlay,
                            NoResultsOverlay: NoDataOverlay,
                        }}
                        columns={assistsColumns}
                        pageSize={20}
                        rowsPerPageOptions={[5]}
                        disableSelectionOnClick
                        autoHeight
                        disableDensitySelector
                    />
                </Box>
            </Card>
        </>
    );
};

export default AssistsCard;
