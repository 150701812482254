import React, { useState } from 'react';
import { Box, Stack, Tab, Tabs, Grid, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
} from 'react-router-dom';
import { useAuth } from '../../../hooks';
import Skeleton from '../../components/Skeleton';
import Error from '../../../components/Feedback/Error';
import { CyclesSelectOnDirector } from '../../../components/Director';
import AllStudents from './components/AllStudents';
import BestAverageGrade from './components/BestAverageGrade';
import MostInassistGrade from './components/MostInassistGrade';
import MostReportGrade from './components/MostReportGrade';
import WorstAverageGrade from './components/WorstAverageGrade';
import SyncIcon from '@mui/icons-material/Sync';
import {
    getCurrentTab,
    getCycleSelected,
    getLoading,
    invalidate as invalidateComponents,
} from '../../store/students/fetchSlice';
import {
    fetchAllResources,
    selectEntitiesFetchStatus,
    invalidate as invalidateEntities,
} from '../../store/entities';
import DirectorNewHome from '../Home';
import DirectorGroups from '../Groups';
import DirectorSubjects from '../Subjects';
import DirectorProfessors from '../Professors';
import { setCurrentTab } from '../../store/students/uiSlice';
import { LoadingButton } from '@mui/lab';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorDirectorBoundary from '../../../components/ErrorDirectorBoundary';
import SchoolPerformanceSummary from './components/SchoolPerformanceSummary';
import TotalStudentsFailedTable from './components/TotalStudentsFailedTable';
import StudentsWithSubjectsFailedTable from './components/StudentsWithSubjectsFailedTable';

const DirectorStudents = () => {
    const dispatch = useDispatch();
    let match = useRouteMatch();
    const Auth = useAuth();
    let user = Auth.getUser();

    const schoolCycle = useSelector(getCycleSelected);
    const serverState = useSelector(getLoading);
    const currentTab = useSelector(getCurrentTab);
    const fetchingStatus = useSelector(selectEntitiesFetchStatus);

    const [selectedTurn, setSelectedTurn] = useState('');

    const onChangeTab = (event, newValue) => {
        dispatch(setCurrentTab(newValue));
    };

    const onChangeCycle = (cycle) => {
        dispatch(invalidateEntities());
        dispatch(
            fetchAllResources({
                school_id: user.school_id,
                cycle: schoolCycle,
                turn: selectedTurn,
                //limit: 5,
            })
        );
        dispatch(invalidateComponents());
        fetchAllInformation(cycle);
    };

    const reload = () => {
        dispatch(invalidateComponents());
    };

    const handleTurnChange = (event, cycle) => {
        const selectedValue = event.target.value;
        setSelectedTurn(selectedValue);
        dispatch(invalidateEntities());
        dispatch(
            fetchAllResources({
                school_id: user.school_id,
                cycle: schoolCycle,
                turn: selectedTurn,
                //limit: 5,
            })
        );
        dispatch(invalidateComponents());
        fetchAllInformation(cycle);
    };

    const invalidateUI = () => {
        dispatch(invalidateEntities());
        dispatch(
            fetchAllResources({
                school_id: user.school_id,
                cycle: schoolCycle,
                turn: selectedTurn,
                //limit: 5,
            })
        );
        dispatch(invalidateComponents());
    };

    async function fetchAllInformation(cycle = null) {
        let dataRequest = {
            school_id: user.school_id,
            cycle: cycle ? cycle : schoolCycle.cycle_id,
        };
        dispatch(fetchAllResources(dataRequest))
            .unwrap()
            .then((response) => {})
            .catch((err) => {
                let message = 'feedback.getMessage(err)';
            });
    }

    function LinkTab(props) {
        return <Tab component={Link} {...props} />;
    }

    return (
        <Box
            sx={{
                paddingTop: {
                    xs: 1,
                    sm: 2,
                    md: 2,
                },
                paddingLeft: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingRight: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingBottom: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
            }}
        >
            {fetchingStatus == 'pending' && <Skeleton items={9} />}

            {fetchingStatus == 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {fetchingStatus == 'fulfilled' && (
                <>
                    <Grid container spacing={2} sx={{ mb: 4 }}>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <Tabs
                                value={currentTab}
                                onChange={onChangeTab}
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="tabs"
                                sx={{
                                    [`& .MuiTabs-scrollButtons`]: {
                                        '&.Mui-disabled': { opacity: 0.3 },
                                    },
                                    [`& .MuiTabs-flexContainer`]: {
                                        flexDirection: {
                                            xs: 'column',
                                            sm: 'row',
                                        },
                                    },
                                }}
                            >
                                <Tab
                                    label="Inicio"
                                    value="inicio"
                                    component={Link}
                                    to="/NewDirector/inicio"
                                />
                                <Tab
                                    label="Grupos"
                                    value="groups"
                                    component={Link}
                                    to="/NewDirector/groups"
                                />
                                <Tab
                                    label="Materias"
                                    value="subjects"
                                    component={Link}
                                    to="/NewDirector/subjects"
                                />
                                <Tab
                                    label="Profesores"
                                    value="professors"
                                    component={Link}
                                    to="/NewDirector/professors"
                                />
                                <Tab
                                    label="Alumnos"
                                    value="students"
                                    component={Link}
                                    to="/NewDirector/students"
                                />
                            </Tabs>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <Stack
                                spacing={2}
                                direction={{ xs: 'column', sm: 'row' }}
                                justifyContent="flex-end"
                                alignItems="center"
                            >
                                <CyclesSelectOnDirector
                                    disabled={serverState === 'pending'}
                                    onChange={onChangeCycle}
                                />
                                <Tooltip title="Sincronizar información">
                                    <LoadingButton
                                        onClick={invalidateUI}
                                        startIcon={<SyncIcon />}
                                        loading={serverState == 'pending'}
                                        loadingPosition="start"
                                        variant="contained"
                                        size="small"
                                    >
                                        Sincronizar
                                    </LoadingButton>
                                </Tooltip>
                            </Stack>
                        </Grid>
                    </Grid>

                    <Box>
                        <Switch>
                            <Route path="/NewDirector/inicio">
                                <DirectorNewHome />
                            </Route>
                            <Route path="/NewDirector/groups">
                                <DirectorGroups />
                            </Route>
                            <Route path="/NewDirector/subjects">
                                <DirectorSubjects />
                            </Route>
                            <Route path="/NewDirector/professors">
                                <DirectorProfessors />
                            </Route>
                            <Route path="/NewDirector/students">
                                <Grid sx={{ mt: 5, mb: 5 }}>
                                    <ErrorBoundary
                                        FallbackComponent={
                                            ErrorDirectorBoundary
                                        }
                                    >
                                        <SchoolPerformanceSummary />
                                    </ErrorBoundary>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                            <ErrorBoundary
                                                FallbackComponent={
                                                    ErrorDirectorBoundary
                                                }
                                            >
                                                <BestAverageGrade />
                                            </ErrorBoundary>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                            <ErrorBoundary
                                                FallbackComponent={
                                                    ErrorDirectorBoundary
                                                }
                                            >
                                                <MostReportGrade />
                                            </ErrorBoundary>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                            <ErrorBoundary
                                                FallbackComponent={
                                                    ErrorDirectorBoundary
                                                }
                                            >
                                                <WorstAverageGrade />
                                            </ErrorBoundary>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                            <ErrorBoundary
                                                FallbackComponent={
                                                    ErrorDirectorBoundary
                                                }
                                            >
                                                <MostInassistGrade />
                                            </ErrorBoundary>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={6} mt={7} mb={3}>
                                        <ErrorBoundary
                                            FallbackComponent={
                                                ErrorDirectorBoundary
                                            }
                                        >
                                            <TotalStudentsFailedTable />
                                        </ErrorBoundary>
                                    </Grid>
                                    <Grid item xs={12} md={6} mt={7} mb={3}>
                                        <ErrorBoundary
                                            FallbackComponent={
                                                ErrorDirectorBoundary
                                            }
                                        >
                                            <StudentsWithSubjectsFailedTable />
                                        </ErrorBoundary>
                                    </Grid>
                                    <Grid item xs={12} md={6} mt={7} mb={3}>
                                        <ErrorBoundary
                                            FallbackComponent={
                                                ErrorDirectorBoundary
                                            }
                                        >
                                            <AllStudents />
                                        </ErrorBoundary>
                                    </Grid>
                                </Grid>
                            </Route>
                        </Switch>
                    </Box>
                </>
            )}
        </Box>
    );
};

export default DirectorStudents;
