import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Feedback from '../../../service/Feedback';
import { createSelector } from '@mui/x-data-grid/utils/createSelector';
import { selectPaymentIncome } from './selectors';
import Connection from '../../../service/Connection';
import {
    addManyPayments,
    addOnePayment,
    upsertManyPayments,
    upsertOnePayment,
} from '../../../store/slices/entities/payments/income';
import { upsertManyAgreements } from '../../../store/slices/entities/payments/agreements';
import { upsertManyConcepts } from '../../../store/slices/entities/payments/concepts';

const emptyState = {
    create: {
        expireIn: null,
        ferchingAt: null,
        status: 'idle',
        didInvalidate: true,
    },
    delete: {
        expireIn: null,
        ferchingAt: null,
        status: 'idle',
        didInvalidate: true,
    },
    update: {
        expireIn: null,
        ferchingAt: null,
        status: 'idle',
        didInvalidate: true,
    },
};

export const operationsSlice = createSlice({
    name: 'operations/income',
    initialState: emptyState,
    reducers: {},

    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', () => {
            return emptyState;
        });

        //////////////// Registro de un pago ///////////T///////////

        builder.addCase(CreatePaymnet.rejected, (state, action) => {
            state.create.status = 'rejected';
            state.create.feedback = action.payload.feedback;
        });
        builder.addCase(CreatePaymnet.fulfilled, (state) => {
            state.create.status = 'fulfilled';
        });
        builder.addCase(CreatePaymnet.pending, (state) => {
            state.create.status = 'pending';
        });

        //////////////// Eliminar un pago ///////////T///////////
        builder.addCase(DeletePayment.rejected, (state, action) => {
            state.delete.status = 'rejected';
        });
        builder.addCase(DeletePayment.fulfilled, (state) => {
            state.delete.status = 'fulfilled';
        });
        builder.addCase(DeletePayment.pending, (state) => {
            state.delete.status = 'pending';
        });
        //////////////// Actualizar un pago ///////////T///////////

        builder.addCase(UpdatePayment.pending, (state) => {
            state.update.status = 'pending';
        });

        builder.addCase(UpdatePayment.rejected, (state, action) => {
            state.update.status = 'rejected';
            state.update.feedback = action.payload.feedback;
        });
        builder.addCase(UpdatePayment.fulfilled, (state) => {
            state.update.status = 'fulfilled';
        });
    },
});

//export const {} = operationsSlice.actions;

export default operationsSlice.reducer;

export const selectOperations = createSelector(
    selectPaymentIncome,
    (reports) => reports.operations
);

export const selectCreate = createSelector(
    selectOperations,
    (operations) => operations.create
);

export const selectCreateOperation = createSelector(
    selectCreate,
    (create) => create.status
);

export const selectStatusOperation = (state) =>
    state.newReports.home.operations.create.statusOperation;

/**
 * Agregar un nuevo Pago
 */

export const CreatePaymnet = createAsyncThunk(
    'payment/create',
    async ({ concept_id, user_id, data, school_id }, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            const response = await Connection.addPayment(
                concept_id,
                user_id,
                data
            );

            const payment = response.data.data;

            const fetchAgrements =
                await Connection.getAgreementBySchool(school_id);

            const fetchConcepts =
                await Connection.getConceptsBySchool(school_id);

            const agrements = fetchAgrements.data.data;
            const concepts = fetchConcepts.data.data;

            thunkAPI.dispatch(upsertManyPayments(payment));
            thunkAPI.dispatch(upsertManyAgreements(agrements));
            thunkAPI.dispatch(upsertManyConcepts(concepts));

            return { payment };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

export const DeletePayment = createAsyncThunk(
    'payment/Delete',
    async (payment_id, thunkAPI) => {
        let FeedbackService = new Feedback();
        try {
            const response = await Connection.deletePayment(payment_id);

            const payment = response.data.data;

            thunkAPI.dispatch(upsertOnePayment(payment));

            return { payment };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

export const UpdatePayment = createAsyncThunk(
    'payment/update',
    async ({ payment_id, data }, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            const response = await Connection.updatePayment(payment_id, data);
            const payment = response.data.data;

            thunkAPI.dispatch(upsertOnePayment(payment));
            return { payment };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);
