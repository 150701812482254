import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Card,
    CardContent,
    Tooltip,
    Typography,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Avatar,
} from '@mui/material';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectGradeAverage,
} from '../../../store/home/fetchSlice';
import { getGradeAverage } from '../../../store/home/thunks';
import { SkeletongGrafics } from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';
import { selectComponentHomeGradeAverageStatusServer } from '../../../store/home/componentStatusSelectors';
import { ErrorBoundary } from 'react-error-boundary';

const GradeAverageRanking = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentHomeGradeAverageStatusServer
    );
    const gradeAverageBar = useFetchResource(
        () =>
            getGradeAverage({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectGradeAverage,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getGradeAverage({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
            selectGradeAverage,
            [cycleSelected, schoolId]
        );
    };

    const data = gradeAverageBar?.value?.data
        ?.map((value, index) => ({
            grade: gradeAverageBar.value.labels[index],
            promedio: value,
        }))
        .filter((item) => item.promedio !== null && item.promedio !== '')
        .sort((a, b) => b.promedio - a.promedio)
        .slice(0, 3);

    return (
        <ErrorBoundary>
            <Box>
                {fetchingStatus === 'pending' && <SkeletongGrafics items={9} />}

                {fetchingStatus === 'rejected' && (
                    <Error
                        onRetry={reload}
                        message={'Estamos teniendo problemas'}
                    />
                )}

                {fetchingStatus === 'fulfilled' && (
                    <Card
                        variant="outlined"
                        sx={{
                            borderRadius: '10px',
                            textAlign: 'center',
                            maxWidth: '400px',
                            minHeight: '213px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            boxShadow: 'none',
                            margin: 'auto',
                        }}
                    >
                        <CardContent
                            sx={{
                                width: '100%',
                                padding: '24px',
                            }}
                        >
                            <Tooltip
                                title={
                                    gradeAverageBar?.description ||
                                    'Promedio por grado de la escuela'
                                }
                                followCursor
                            >
                                <Box sx={{ marginBottom: 2 }}>
                                    <Typography variant="h6" gutterBottom>
                                        {gradeAverageBar?.title ||
                                            'Promedio por grado'}
                                    </Typography>
                                </Box>
                            </Tooltip>
                            {data && data.length > 0 ? (
                                <TableContainer
                                    component={Paper}
                                    elevation={3}
                                    sx={{ boxShadow: 'none' }}
                                >
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Posición</TableCell>
                                                <TableCell>Grado</TableCell>
                                                <TableCell>Promedio</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data.map((item, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>
                                                        <Avatar
                                                            sx={{
                                                                width: 40,
                                                                height: 40,
                                                                backgroundColor:
                                                                    index === 0
                                                                        ? '#FFD700'
                                                                        : index ===
                                                                          1
                                                                        ? '#C0C0C0'
                                                                        : '#CD7F32',
                                                                color: '#fff',
                                                                fontWeight:
                                                                    'bold',
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                                justifyContent:
                                                                    'center',
                                                            }}
                                                        >
                                                            {index + 1}
                                                        </Avatar>
                                                    </TableCell>
                                                    <TableCell>
                                                        {item.grade}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item.promedio.toFixed(
                                                            2
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            ) : (
                                <Paper
                                    elevation={3}
                                    sx={{ margin: 2, padding: 2 }}
                                >
                                    <Typography variant="h5" gutterBottom>
                                        Sin datos disponibles
                                    </Typography>
                                </Paper>
                            )}
                        </CardContent>
                    </Card>
                )}
            </Box>
        </ErrorBoundary>
    );
};

export default GradeAverageRanking;
