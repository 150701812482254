import React, { useEffect, useState } from 'react';
import { Typography as AntTypography } from 'antd';
import palomita from '../../../../public/assets/palomita.svg';
import { GridToolBar } from '../../../components/utilities/GridToolBar';
import NoDataOverlay from '../../../components/utilities/NoDataOverlay';
import { Space } from 'antd';
import { ReactComponent as WatchIcon } from '../../../assets/icons/delete-trash-svgrepo-com.svg';
import { PopConfirm } from '../../../components/Tooltips/PopConfirm';
import {
    Box,
    Typography,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    Card,
    Divider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
    DataGrid,
    esES,
} from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCatSurchages } from '../../../store/cat_surcharges/fetchSlice';
import { useAuth, useFeedback } from '../../../../hooks';
import { selectCatSurcharge } from '../../../store/cat_surcharges/selectors';
import CreateCatalog from './CreateCatalog';
import { DeleteCatSurcharges } from '../../../store/cat_surcharges/operations';
import useThemeColors from '../../../../theme/themes';
import CustomPagination from '../../../components/utilities/CustomPagination';
import PercentOutlinedIcon from '@mui/icons-material/PercentOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import DeleteSweepTwoToneIcon from '@mui/icons-material/DeleteSweepTwoTone';



const CatalogModal = ({ open, onclose }) => {
    ///////////////// LOCAL STATE /////////////

    const [openModal, setOpenModal] = useState(false);

    ///////////// SHERD STATE /////////////
    const feedbackApp = useFeedback();
    const colors = useThemeColors();

    const auth = useAuth();
    const schoolId = auth.getUser().school_id;
    const dispatch = useDispatch();

    const catSurcharges = Object.values(useSelector(selectCatSurcharge)); ///////////// USE EFFECT /////////////

    useEffect(() => {
        dispatch(fetchCatSurchages(schoolId));
    }, [dispatch, schoolId]);


    ///////////// HANDLERS /////////////

    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);

    const handleDelete = async (surcharge_catalog_id) => {
        await dispatch(DeleteCatSurcharges(surcharge_catalog_id))
            .unwrap()
            .then(() => {
                feedbackApp.showFeedback({
                    title: 'Recargo Eliminado con exito',
                });
            })
            .catch((error) => {
                feedbackApp.showFeedback({
                    title: error.feedback.title,
                });
            });
    };


    ///////////// FUNCTIONS /////////////

    ///////////// COLUMNS /////////////
    const columns = [
        {
            field: 'surcharge_catalog_id',
            headerName: 'Folio',
            flex: 0.5,
            minWidth: 100,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                return (
                    <>
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                            <Typography
                                color="primary"
                                sx={{
                                    fontWeight: 'bold',
                                }}>
                                {params.value}
                            </Typography>
                        </Box>
                    </>
                );
            },
        },
        {
            field: 'title',
            headerName: 'Catalogo',
            flex: 1,
            minWidth: 250,
            headerAlign: 'center',
        },
        {
            field: 'value_modality',
            headerName: 'Modalidad',
            flex: 1,
            minWidth: 100,
            headerAlign: 'center',
            valueGetter: (params) => {
                return params.row.value_modality === 1
                    ? 'Monetario'
                    : 'Porcentual';
            },
            renderCell: (params) => {
                const getStatusColorCell = (status) => {
                    switch (status) {
                        case 'Monetario':
                            return {
                                color: colors.orange[200],
                                backgroundColor: colors.orange[500],
                                description: 'Monetario',
                                icon: (
                                    <AttachMoneyOutlinedIcon
                                        size="small"
                                        sx={{
                                            color: colors.orange[500],
                                        }}
                                    />
                                ),
                            };
                        case 'Porcentual':
                            return {
                                color: colors.teal[200],
                                backgroundColor: colors.teal[600],
                                description: 'Porcentual',
                                icon: (
                                    <PercentOutlinedIcon
                                        size="small"
                                        sx={{
                                            color: colors.teal[500],
                                        }}
                                    />
                                ),
                            };
                        default:
                            return {
                                color: colors.grey[300],
                                description: 'Desconocido',
                            };
                    }
                };
                const termType = getStatusColorCell(params.value);
                return (
                    <Box
                        sx={{
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: termType.color,
                        }}>
                        {termType.icon} &nbsp;
                        <Typography
                            variant="body1"
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '1rem',
                                color: termType.backgroundColor,
                            }}>
                            {termType.description}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: 'value',
            headerName: 'Importe',
            flex: 1,
            minWidth: 100,
            headerAlign: 'center',
            renderCell: (params) => {
                const isMonetary = params.row.value_modality === 1;
                const formattedValue = isMonetary
                    ? new Intl.NumberFormat('es-MX', {
                          style: 'currency',
                          currency: 'MXN',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                      }).format(params.row.value)
                    : `${params.row.value}%`;

                return (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            gap: 0.5,
                            width: '100%',
                        }}>
                        {isMonetary ? (
                            <>
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        textAlign: 'right',
                                        width: 'auto',
                                        fontSize: '1rem',
                                    }}>
                                    {formattedValue}
                                </Typography>
                            </>
                        ) : (
                            <>
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        textAlign: 'right',
                                        fontSize: '1rem',
                                        width: 'auto',
                                    }}>
                                    {formattedValue}
                                </Typography>
                            </>
                        )}
                    </Box>
                );
            },
        },

        {
            field: 'actions',
            headerName: 'Acciones',
            flex: 0.8,
            renderCell: (params) => (
                
                // <Button
                //     variant="text"
                //     startIcon={<DeleteSweepTwoToneIcon />}
                //     onClick={handleDelete()}
                //     sx={{
                //         color: colors.red[500],
                //         fontSize: 10,
                //         textTransform: 'capitalize',
                //     }}>
                //     Eliminar
                // </Button>
                <Space>
                <PopConfirm
                      title="¿Estás seguro de que deseas continuar?"
                      message="Confirma tu decisión y sigamos adelante. Recuerda No podrás recuperar este registro después de eliminarlo."
                      icon={WatchIcon}
                      IconButton={DeleteSweepTwoToneIcon}
                      textButtonConfirm="Confirmar"
                      textButtonCancel="Cancelar"
                      messageButton={'Eliminar'}
                      color="#e24141"
                      iconSize={80}
                      placement='right'
                      topIcon='-17%'
                  onConfirm={() =>
                      handleDelete(params.row.surcharge_catalog_id)
                      
                  }
              />
          </Space>
            ),
        },
    ];

        const rows = catSurcharges
        .filter((option) => option.active === true)
        .map((surcharge) => ({
            id: surcharge.surcharge_catalog_id,
            ...surcharge,
        }))
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

    return (
        <>
            <Dialog
                open={open}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        onclose();
                    }
                }}
                disableEscapeKeyDown
                sx={{
                    '& .MuiDialog-paper': {
                        borderRadius: 5,
                        width: '80%',
                        maxWidth: 900,
                        height: 'auto',
                        maxHeight: 900,
                    },
                }}>
                <DialogTitle
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: 1,
                    }}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignContent: 'center',
                            alignItems: 'center',
                        }}>
                        <Box
                            component="img"
                            src={palomita}
                            alt="Descripción de la imagen"
                            sx={{
                                width: '15%',
                                height: 'auto',
                            }}
                        />
                        <Divider
                            orientation="vertical"
                            variant="middle"
                            flexItem
                            sx={{
                                display: 'flex',
                                ml: 2,
                                mr: 2,
                            }}
                        />
                        <Box>
                            <AntTypography.Title level={5}>
                                Catalogo de Descuentos
                            </AntTypography.Title>
                            <Typography
                                variant="body2"
                                sx={{
                                    width: '100%',
                                    mt: -1,
                                }}>
                                Aquí podrás observar el catalogo de los
                                descuentos
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignContent: 'center',
                            alignItems: 'center',
                        }}>
                        <Divider
                            orientation="vertical"
                            variant="middle"
                            flexItem
                            sx={{
                                display: 'flex',
                                ml: 2,
                                mr: 2,
                            }}
                        />
                        <IconButton
                            onClick={() => {
                                onclose();
                            }}>
                            <CloseIcon
                                sx={{
                                    color: '#000000ff',
                                    width: 20,
                                }}
                            />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <Divider
                    sx={{
                        width: '95%',
                    }}
                    flexItem
                    variant="middle"
                />
                <DialogContent>
                    <Card
                        style={{
                            color: 'white',
                            borderRadius: '15px',
                            width: '100%',
                            height: '100%',
                            paddingTop: '10px',
                        }}>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            autoHeight
                            pageSize={10}
                            rowsPerPageOptions={[5, 10, 30]}
                            disableColumnReorder
                            localeText={
                                esES.components.MuiDataGrid.defaultProps
                                    .localeText
                            }
                            components={{
                                Toolbar: GridToolBar,
                                Pagination: CustomPagination,
                                NoRowsOverlay: NoDataOverlay,
                                NoResultsOverlay: NoDataOverlay,
                            }}
                            componentsProps={{
                                toolbar: {
                                    onAddButtonClick: handleOpen,
                                    ButtonText: 'Catálogo',
                                },
                                noResultsOverlay: {
                                    message:
                                        'No se encontraron resultados para la búsqueda',
                                },
                                noRowsOverlay: {
                                    message: 'No hay Catálogos registrados',
                                },
                            }}
                        />
                    </Card>
                </DialogContent>
            </Dialog>
            <CreateCatalog open={openModal} onclose={handleClose} />
        </>
    );
};

export default CatalogModal;
