import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Card, Grid, Paper } from '@mui/material';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectProfessorMostReports,
} from '../../../store/professors/fetchSlice';
import { getProfessorMostReports } from '../../../store/professors/thunks';
import { selectComponentProfessorsMostReportsStatusServer } from '../../../store/professors/componentsStatusSelectors';
import Error from '../../../../components/Feedback/Error';
import { SkeletonProfessorsCard } from '../../../components/Skeleton';
import PeopleIcon from '@mui/icons-material/People';

const ProfessorsMostReports = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentProfessorsMostReportsStatusServer
    );

    const professorMostReports = useFetchResource(
        () =>
            getProfessorMostReports({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectProfessorMostReports,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getProfessorMostReports({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
            selectProfessorMostReports,
            [cycleSelected, schoolId]
        );
    };

    const value = professorMostReports?.value;

    return (
        <Box sx={{ alignItems: 'flex-start' }}>
            {fetchingStatus === 'pending' && (
                <SkeletonProfessorsCard items={9} />
            )}

            {fetchingStatus === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {fetchingStatus === 'fulfilled' && value && (
                <Card
                    sx={{
                        background: '#f4a903',
                        borderRadius: '10px',
                        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                        padding: '15px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        maxWidth: '600px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '10px',
                        }}
                    >
                        <PeopleIcon
                            style={{
                                fontSize: 35,
                                color: 'rgb(232, 247, 255)',
                                marginRight: '10px',
                                marginBottom: '10px',
                            }}
                        />
                        <Typography
                            variant="h6"
                            sx={{
                                fontWeight: 700,
                                color: '#fff',
                                marginBottom: '10px',
                                textAlign: 'left',
                            }}
                        >
                            {professorMostReports?.title ||
                                'Profesor que más reporta'}
                        </Typography>
                    </Box>
                    <Paper
                        elevation={3}
                        sx={{
                            margin: '16px 0',
                            padding: '16px',
                            width: '100%',
                            backgroundColor: '#f1f1f1',
                            borderRadius: '10px',
                        }}
                    >
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={6}>
                                <Typography
                                    variant="subtitle2"
                                    sx={{ color: '#808080', textAlign: 'left' }}
                                >
                                    Profesor
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="subtitle2"
                                    sx={{ color: '#808080', textAlign: 'left' }}
                                >
                                    Total de reportes
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: '#f4a903',
                                        fontWeight: 600,
                                        textAlign: 'left',
                                    }}
                                >
                                    {value?.labels}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                }}
                            >
                                {' '}
                                <Box
                                    sx={{
                                        backgroundColor: '#f4a903',
                                        borderRadius: '10px',
                                        width: '200px',
                                        padding: '10px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        color: '#fff',
                                        fontWeight: 700,
                                        fontSize: '2rem',
                                        textAlign: 'center',
                                    }}
                                >
                                    {value?.data}
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Card>
            )}
        </Box>
    );
};

export default ProfessorsMostReports;
