import React from 'react';
import { Box, Button } from '@mui/material';
import { Carousel } from 'antd';

// Assets
import asesor from '../../assets/asesor.png';
import check from '../../assets/check.svg';
import directivo from '../../assets/directivo.png';
import facebook from '../../assets/facebook.svg';
import heart from '../../assets/heart.svg';
import instagram from '../../assets/instagram.svg';
import whatsapp from '../../assets/whatsapp.svg';
import whatsAppFloat from '../../assets/whatsAppFloat.svg';
import logoFooter from '../../assets/logo_footer.svg';
import maestra from '../../assets/maestra.png';
import palomita from '../../assets/palomita.svg';
import phone1 from '../../assets/phone1.png';
import phone2 from '../../assets/phone2.png';
import phone3 from '../../assets/phone3.png';
import phone4 from '../../assets/phone4.png';
import play from '../../assets/play.svg';
import videos from '../../assets/videos.svg';
import youtube from '../../assets/youtube.svg';
import Yt01 from '../../assets/videos/Yt_01.jpg';
import Yt02 from '../../assets/videos/Yt_02.jpg';
import Yt03 from '../../assets/videos/Yt_03.jpg';
import Yt04 from '../../assets/videos/Yt_04.jpg';
import Yt05 from '../../assets/videos/Yt_05.jpg';
import Downloads from '../../Components/Downloads';
import { Redirect, useHistory } from 'react-router-dom';

// Styles
import '../../style.css';
import { NavLink } from 'react-router-dom';
import NavBar from '../../Components/NavBar';

const NewInicio = () => {
    const handleClick = () => {
        window.scrollTo(0, 0);
    };

    const history = useHistory();

    return (
        <Box>
            <NavBar />
            <div className="body">
                <div className="mweb">
                    <section className="sidebar">
                        <div className="custom-container">
                            <div className="grid dos">
                                <div className="info">
                                    <img src={palomita} alt="Palomita" />
                                    <h1>Educación efectiva en tus Manos.</h1>
                                    <p>
                                        Somos una plataforma educativa que está
                                        impulsando la manera en que{' '}
                                        <b>Maestros, Padres de familia</b> y{' '}
                                        <b>Directores</b> gestionan la
                                        educación.
                                    </p>
                                    <div className="buttons">
                                        <a>
                                            <NavLink to="/funcionalidades">
                                                <button className="btn_mw red">
                                                    Funcionalidades
                                                </button>
                                            </NavLink>
                                        </a>
                                        <a href="#videos">
                                            <button className="btn_mw trans">
                                                {' '}
                                                <img
                                                    src={play}
                                                    alt="Play"
                                                />{' '}
                                                Ver Videos
                                            </button>
                                        </a>
                                    </div>
                                    <p className="chico">
                                        Automatizamos el registro de
                                        calificaciones y asistencias, brindando
                                        a los <b>Maestros</b> más tiempo para
                                        enfocarse en lo que realmente importa:{' '}
                                        <b>educar.</b>
                                    </p>
                                </div>
                                <div className="maestra">
                                    <img src={maestra} alt="Maestra" />
                                    <div className="botonix">
                                        <div></div>
                                        <div className="delay"></div>
                                        <div className="moredelay"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="beneficios">
                        <div className="custom-container">
                            <h1>
                                <img src={check} />
                                Beneficios
                            </h1>
                            <p>
                                Olvídate del tedioso papeleo y las horas
                                invertidas en tareas administrativas. Cura{' '}
                                <b>
                                    automatiza el registro de calificaciones y
                                    asistencias
                                </b>
                                , brindando a los maestros más tiempo para
                                enfocarse en lo que realmente importa:{' '}
                                <b>educar.</b> Los padres de familia disfrutarán
                                de una conexión más cercana con el{' '}
                                <b>progreso académico de sus hijos</b>,
                                recibiendo notificaciones importantes y acceso a
                                información en tiempo real.
                            </p>
                            <div className="grid dos grid_beneficios">
                                <div className="beneficio">
                                    <img src={phone1} />
                                    <div>
                                        <h2 className="maestros">Maestros</h2>
                                        <ul>
                                            <li>
                                                Registra calificaciones y
                                                asistencias de forma automática.
                                            </li>
                                            <li>
                                                Ahorra horas de papeleo con
                                                nuestro sistema de promedio
                                                instantáneo.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="beneficio">
                                    <img src={phone2} />
                                    <div>
                                        <h2 className="padres">Padres</h2>
                                        <ul>
                                            <li>
                                                Accede instantáneamente a las
                                                calificaciones y asistencias de
                                                tus hijos.
                                            </li>
                                            <li>
                                                Recibe notificaciones
                                                importantes de la escuela y
                                                maestros.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="beneficio">
                                    <img src={phone3} />
                                    <div>
                                        <h2 className="orientadores">
                                            Orientadores
                                        </h2>
                                        <ul>
                                            <li>
                                                Recibe reportes de maestros
                                                sobre el comportamiento y
                                                desarrollo de los alumnos.
                                            </li>
                                            <li>
                                                Detecta y aborda problemas de
                                                comportamiento y bullying de
                                                manera proactiva.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="beneficio">
                                    <img src={phone4} />
                                    <div>
                                        <h2 className="directivos">
                                            Directivos
                                        </h2>
                                        <ul>
                                            <li>
                                                Acceso panorámico al rendimiento
                                                escolar de todos los
                                                estudiantes.
                                            </li>
                                            <li>
                                                Análisis de datos en tiempo real
                                                para tomar decisiones
                                                informadas.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="funcionalidades">
                        <div className="custom-container">
                            <div className="content">
                                <img
                                    className="asesor"
                                    src={asesor}
                                    alt="asesor"
                                />
                                <div>
                                    <h1>
                                        <img src={heart} alt="heart" />{' '}
                                        Funcionalidades
                                    </h1>
                                    <p>
                                        Diseñadas para{' '}
                                        <b>agilizar la gestión educativa</b>,
                                        mejorar la comunicación y transparencia
                                        y ofrecer una{' '}
                                        <b>experiencia educativa</b> más{' '}
                                        <b>efectiva y conectada</b> para todos.
                                        Cada funcionalidad está adaptada a las{' '}
                                        <b>
                                            necesidades y requerimientos
                                            específicos
                                        </b>{' '}
                                        de maestros, padres de familia,
                                        directores y orientadores educativos.
                                    </p>
                                    <NavLink
                                        to="/funcionalidades"
                                        onClick={handleClick}>
                                        <button>
                                            Conoce todas las funcionalidades
                                        </button>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="videos" className="videos">
                        <div className="custom-container">
                            <h1>
                                <img src={videos} />
                                Videos
                            </h1>
                            <span>
                                ¡Descubre nuestro emocionante rincón de videos!
                            </span>
                            <div className="textos">
                                <p>
                                    Aquí conocerás la manera más sencilla de{' '}
                                    <b>descargar la aplicación "Cura"</b> y una
                                    breve introducción de cómo funciona,
                                    adaptada para cada uno de nuestros usuarios:
                                    Maestros, Padres de familia y Orientadores
                                    escolares.{' '}
                                </p>
                                <p>
                                    Sumérgete en una experiencia visual que te
                                    guiará <b>paso a paso en el proceso</b> de
                                    aprovechar al máximo todas las ventajas que
                                    Cura tiene para ofrecerte.
                                </p>
                            </div>
                            <div id="antd-demo" className="antd-carousel">
                                <Carousel
                                    slidesToShow={3}
                                    slidesToScroll={1}
                                    autoplay
                                    autoplaySpeed={3000}>
                                    <a
                                        href="https://www.youtube.com/watch?v=P32u1HMRyPI&ab_channel=CURAAPP"
                                        target="_blank">
                                        <img src={Yt01} alt="Imagen 1" />
                                    </a>
                                    <a
                                        href="https://www.youtube.com/watch?v=3DxRG9vlOnU&ab_channel=CURAAPP"
                                        target="_blank">
                                        <img src={Yt02} alt="Imagen 2" />
                                    </a>
                                    <a
                                        href="https://www.youtube.com/watch?v=YjlHvAl3BzM&ab_channel=CURAAPP"
                                        target="_blank">
                                        <img src={Yt03} alt="Imagen 3" />
                                    </a>
                                    <a
                                        href="https://www.youtube.com/watch?v=ZL-oh5R6LM4&ab_channel=CURAAPP"
                                        target="_blank">
                                        <img src={Yt04} alt="Imagen 4" />
                                    </a>
                                    <a
                                        href="https://www.youtube.com/watch?v=prSrVFlBtUY&ab_channel=CURAAPP"
                                        target="_blank">
                                        <img src={Yt05} alt="Imagen 5" />
                                    </a>
                                </Carousel>
                            </div>
                            <a
                                href="https://www.youtube.com/@cura-educacionefectiva-oficial"
                                target="_blank">
                                <img src={videos} />
                                ¡Suscríbete a nuestro canal y déjate sorprender
                                por el poder transformador de la educación en la
                                palma de tu mano!
                            </a>
                        </div>
                    </section>
                    <section className="descargas">
                        <Downloads />
                    </section>
                    <footer>
                        <img className="palomita" src={palomita} />
                        <div className="custom-container">
                            <div className="infoot">
                                <div>
                                    <img src={logoFooter} />
                                    <p>
                                        Transformando la manera en que Maestros,
                                        Padres de familia y Directores gestionan
                                        la educación.
                                    </p>
                                </div>

                                <div className="profe">
                                    <img
                                        src={directivo}
                                        style={{ width: '230px' }}
                                    />
                                </div>
                                <div className="FBeneficios">
                                    <h4>Visita también:</h4>
                                    <ul>
                                        <li>
                                            <NavLink
                                                to="/beneficios"
                                                onClick={handleClick}>
                                                <a>Beneficios</a>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                to="/funcionalidades"
                                                onClick={handleClick}>
                                                <a>Funcionaliddes</a>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <ul className="redes">
                                        <li>
                                            <a
                                                href="https://www.facebook.com/curaeducacion"
                                                target="_blank">
                                                <img src={facebook} />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.instagram.com/curaeducacion/"
                                                target="_blank">
                                                <img src={instagram} />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.youtube.com/@cura-educacionefectiva-oficial"
                                                target="_blank">
                                                <img src={youtube} />
                                            </a>
                                        </li>
                                        {/* <li>
                                            <a href="https://wa.me/5216181448945" target="_blank">
                                                <img src={whatsapp} />
                                            </a>
                                        </li> */}
                                    </ul>
                                    <a
                                        href="https://wa.me/5216181448945"
                                        className="float-wa"
                                        target="_blank">
                                        <img
                                            src={whatsAppFloat}
                                            className="wa-icon"
                                        />
                                    </a>
                                    <a
                                        href="https://wa.me/5216181448945"
                                        target="_blank">
                                        <h2>
                                            <img src={whatsapp} /> 618 144 8945
                                        </h2>
                                    </a>
                                    <h2>Email: curati2023@gmail.com</h2>
                                    <h2>Dirección</h2>
                                    <p className="dir">
                                        Blvd. Domingo Arrieta 1700, Gral Domingo
                                        Arrieta, CP 34180, Durango, Dgo., Mexico
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="bottom">
                            <Button
                                size="small"
                                sx={{
                                    color: '#ffffffbe',
                                    textDecoration: 'underline',
                                    fontWeight: 'bold',
                                    padding: '0',
                                }}
                                onClick={() => {
                                    history.push({
                                        pathname: '/TerminosCondiciones',
                                    });
                                }}>
                                Términos y condiciones
                            </Button>
                            <div className="custom-container">
                                <span>
                                    Cura App ® Todos los Derechos Reservados •
                                    Durango, Dgo. México • 2023
                                </span>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </Box>
    );
};

export default NewInicio;
