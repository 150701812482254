import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    useHistory,
    useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import { useAuth } from '../../hooks';
import useFeedback from '../../hooks/useFeedback';

import {
    invalidate,
    loadReportsStudentsUI,
    selectAllStudentsItems,
    selectStatusServer,
    studentSelected,
} from '../store/group';

import { Box, Card, CardContent, Button, Stack, Tooltip } from '@mui/material';
import { DataGrid, esES } from '@mui/x-data-grid';

import SyncIcon from '@mui/icons-material/Sync';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

import SkeletonPage from '../components/SkeletonPage';
import { Error } from '../../components/Feedback';
import { selectSelectedGroup } from '../store/home/uiSlice';
import { ReportsToolBar } from '../components/ReportsToolBar';
import NoDataOverlay from '../../components/utilities/NoDataOverlay';
import { GridToolBar } from '../../components/utilities/GridToolBar';
import CustomPagination from '../../components/utilities/CustomPagination';

const ReportsGroupsDetails = () => {
    //////// HOOKS //////////

    const { id } = useParams();
    const Auth = useAuth();
    const history = useHistory();
    const feedbackApp = useFeedback();
    const dispatch = useDispatch();

    //////// SHARED STATE //////////
    const schoolId = Auth.getUser().school_id;
    const students = useSelector(selectAllStudentsItems(id));
    const statusServer = useSelector(selectStatusServer);
    const selectedGroup = useSelector(selectSelectedGroup);

    //////// LOCAL STATE //////////

    const columns = [
        {
            field: 'student_id',
            headerName: 'ID',
            flex: 0.5,
            minWidth: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'folio',
            headerName: 'Matrícula',
            flex: 1,
            minWidth: 100,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'name',
            headerName: 'Nombre del Alumno',
            flex: 1,
            minWidth: 130,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => {
                return `${params.row.last_name || ''} ${
                    params.row.second_last_name || ''
                } ${params.row.name || ''}`.replace('  ', ' ');
            },
            renderCell: (params) => {
                const FullName = `${params.row.last_name || ''} ${
                    params.row.second_last_name || ''
                } ${params.row.name || ''}`;
                return (
                    <div
                        style={{
                            whiteSpace: 'pre-line',
                            textAlign: 'center',
                        }}>
                        {FullName}
                    </div>
                );
            },
        },
        {
            field: 'total_files',
            headerName: 'Cantidad de Expedientes',
            flex: 1,
            minWidth: 130,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'total_reports',
            headerName: 'Cantidad de reportes',
            flex: 1,
            minWidth: 130,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'acciones',
            headerName: 'Acciones',
            sortable: false,
            filterable: false,
            flex: 1,
            minWidth: 100,
            headerAlign: 'center',
            align: 'center',
            disableExport: true,
            renderCell: (params) => (
                <strong>
                    <Button
                        size="small"
                        variant="outlined"
                        startIcon={<FontAwesomeIcon icon={faEye} size="lg" />}
                        onClick={() => onSelectStudent(params.row)}>
                        VER
                    </Button>
                </strong>
            ),
        },
    ];

    ////// HANDLERS //////////

    /**
     * Seleccionamos el estudiante para redireccionar
     * y guardamos en la store el id del alumno
     * para carga diferida
     */

    const onSelectStudent = (student) => {
        dispatch(
            studentSelected({
                studentUUID: student.viewModel.id,
                groupUUID: selectedGroup,
            })
        );
        history.push({
            pathname: `/reportes/${id}/alumno/${student.student_id}`,
        });
    };

    /**
     * Si el usuario a entrado a la aplicacion
     * iniciamos el proceso de recuperacion de datos
     *
     */
    useEffect(() => {
        dispatch(loadReportsStudentsUI({ groupId: id, schoolId }));
    }, []);

    /**
     * Invalida la UI
     */
    const invalidateUI = () => {
        dispatch(invalidate(selectedGroup));
        reload();
    };

    const reload = () => {
        dispatch(loadReportsStudentsUI({ groupId: id, schoolId }))
            .unwrap()
            .then(() => {})
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    return (
        <Box
            sx={{
                flexGrow: 1,
                paddingTop: {
                    xs: 1,
                    sm: 2,
                    md: 2,
                },
                paddingLeft: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingRight: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingBottom: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
            }}>
            {statusServer === 'pending' && <SkeletonPage />}

            {statusServer === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}
            {statusServer === 'fulfilled' && (
                <>
                    <Stack
                        spacing={1}
                        direction="row"
                        alignItems={'flex-end'}
                        justifyContent={'flex-end'}>
                        <Tooltip title="Sincronizar información">
                            <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                onClick={invalidateUI}
                                startIcon={<SyncIcon />}>
                                Sincronizar
                            </Button>
                        </Tooltip>
                    </Stack>

                    <Card sx={{ mt: 5 }}>
                        <CardContent
                            sx={{
                                pt: 0,
                                pl: 0,
                                pr: 0,
                                pb: '0 !important',
                                ' & .MuiDataGrid-root': {
                                    border: 'none',
                                },
                                ' & .MuiDataGrid-toolbarContainer': {
                                    justifyContent: 'flex-end',
                                },
                            }}>
                            <DataGrid
                                localeText={{
                                    ...esES.components.MuiDataGrid.defaultProps
                                        .localeText,
                                    toolbarColumns: '',
                                    toolbarFilters: '',
                                    toolbarDensity: '',
                                    toolbarExport: '',
                                }}
                                components={{
                                    NoRowsOverlay: NoDataOverlay,
                                    NoResultsOverlay: NoDataOverlay,
                                    Toolbar: GridToolBar,
                                    Pagination: CustomPagination,
                                }}
                                rows={students}
                                getRowId={(row) => row.student_id}
                                columns={columns}
                                pageSize={10}
                                disableSelectionOnClick
                                autoHeight
                                componentsProps={{
                                    panel: {
                                        placement: 'bottom-end',
                                    },

                                    noResultsOverlay: {
                                        message:
                                            'No se encontraron resultados para la búsqueda',
                                    },
                                    noRowsOverlay: {
                                        message:
                                            'No hay datos para mostrar en esta tabla',
                                    },
                                }}
                                disableDensitySelector
                            />
                        </CardContent>
                    </Card>
                </>
            )}
        </Box>
    );
};

export default ReportsGroupsDetails;
