import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

///////////////// VIEWS //////////////////////

import AvisoPrivacidad from './../views/AvisoPrivacidad/AvisoPrivacidad';
import TerminosCondiciones from './../views/TerminosCondiciones';
import RecoverAccount from './../views/RecuperarCuenta/RecoverAccount';
import ResetPassword from './../views/RecuperarCuenta/ResetPassword';

import Login from '../public/pages/Login';
import Funcionalidades from '../public/pages/functionalities';
import Contacto from '../public/pages/Contact';
import Beneficios from '../public/pages/Benefits';
import NewRecoverPassword from '../public/pages/Recovery';
import NewInicio from '../public/pages/Home';

/////////////// COMPONENTS ////////////////////

import { AuthProvider } from './../providers';
import FeedbackProvider from '../providers/FeedbackProvider';
import Working from '../views/Working';
import InnerRoutes from './InnerRoutes';

/**
 * Componente de mantiene todo el arbol del dashboard
 *
 * @returns
 */
function Dashboard() {
    // const mode = useSelector((state) => state.entities.settings.mode);
    // const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

    return (
        <div className="App">
            <Router>
                <AuthProvider>
                    <FeedbackProvider>
                        <Switch>
                            <Route path="/Beneficios">
                                <Beneficios></Beneficios>
                            </Route>
                            <Route path="/Funcionalidades">
                                <Funcionalidades></Funcionalidades>
                            </Route>
                            <Route path="/Contacto">
                                <Contacto></Contacto>
                            </Route>
                            <Route path="/login">
                                <Login></Login>
                            </Route>
                            <Route path="/NewRecoverPassword">
                                <NewRecoverPassword></NewRecoverPassword>
                            </Route>
                            <Route exact path="/">
                                <NewInicio></NewInicio>
                            </Route>
                            <Route path="/aviso-privacidad">
                                <AvisoPrivacidad></AvisoPrivacidad>
                            </Route>
                            <Route path="/TerminosCondiciones">
                                <TerminosCondiciones></TerminosCondiciones>
                            </Route>
                            <Route path="/recuperar_cuenta">
                                <RecoverAccount></RecoverAccount>
                            </Route>
                            <Route path="/restablecer_cuenta">
                                <ResetPassword></ResetPassword>
                            </Route>
                            <Route path="/working">
                                <Working />
                            </Route>
                            <InnerRoutes />
                        </Switch>
                    </FeedbackProvider>
                </AuthProvider>
            </Router>
        </div>
    );
}

export default Dashboard;
