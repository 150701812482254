import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectStudentsApprovedFailedGenderGrade,
} from '../../../store/home/fetchSlice';
import { getStudentsApprovedFailedGenderGrade } from '../../../store/home/thunks';
import { SkeletongGrafics } from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';
import { selectComponentHomeStudentsApprovedFailedGenderGradeStatusServer } from '../../../store/home/componentStatusSelectors';
import { ErrorBoundary } from 'react-error-boundary';

const StudentApproventFailedGenderGradeGrafic = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentHomeStudentsApprovedFailedGenderGradeStatusServer
    );

    const data = useFetchResource(
        () =>
            getStudentsApprovedFailedGenderGrade({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectStudentsApprovedFailedGenderGrade,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getStudentsApprovedFailedGenderGrade({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
            selectStudentsApprovedFailedGenderGrade,
            [cycleSelected, schoolId]
        );
    };

    const [view, setView] = useState('Aprobados');

    const processStudentData = (data) => {
        if (!data || !data.value) {
            return [];
        }

        const structuredData = [];

        const addToStructuredData = (items, gender) => {
            if (!items) return;
            items.forEach((item) => {
                const label = item?.label || 'EMPTY';
                let entry = structuredData.find((d) => d.label === label);

                if (!entry) {
                    entry = {
                        label,
                        Hombres: 0,
                        Mujeres: 0,
                    };
                    structuredData.push(entry);
                }

                entry[gender] = item.data;
            });
        };

        if (view === 'Aprobados') {
            if (data.value.APROBADOS) {
                addToStructuredData(
                    data.value.APROBADOS.hombres || [],
                    'Hombres'
                );
                addToStructuredData(
                    data.value.APROBADOS.mujeres || [],
                    'Mujeres'
                );
            }
        } else {
            if (data.value.REPROBADOS) {
                addToStructuredData(
                    data.value.REPROBADOS.hombres || [],
                    'Hombres'
                );
                addToStructuredData(
                    data.value.REPROBADOS.mujeres || [],
                    'Mujeres'
                );
            }
        }

        return structuredData;
    };

    const getColor = (bar) => {
        if (bar.id === 'Hombres') return '#1E90FF';
        if (bar.id === 'Mujeres') return '#f47edd';
        return '#cccccc';
    };

    const structuredData = data ? processStudentData(data) : [];

    return (
        <ErrorBoundary>
            <Box>
                {fetchingStatus === 'pending' && <SkeletongGrafics items={9} />}

                {fetchingStatus === 'rejected' && (
                    <Error
                        onRetry={reload}
                        message={'Estamos teniendo problemas'}
                    />
                )}

                {fetchingStatus === 'fulfilled' && (
                    <Card
                        variant="outlined"
                        sx={{
                            borderRadius: '10px',
                            textAlign: 'center',
                            maxWidth: '400px',
                            minHeight: '213px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            boxShadow: 'none',
                            margin: 'auto',
                        }}
                    >
                        <CardContent
                            sx={{
                                width: '100%',
                                padding: '16px',
                            }}
                        >
                            <Typography variant="h6" gutterBottom>
                                Alumnos {view.toLowerCase()} por género y grado
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: '10px',
                                    marginBottom: '20px',
                                }}
                            >
                                <Button
                                    variant={
                                        view === 'Aprobados'
                                            ? 'contained'
                                            : 'outlined'
                                    }
                                    size="small"
                                    onClick={() => setView('Aprobados')}
                                >
                                    Aprobados
                                </Button>
                                <Button
                                    variant={
                                        view === 'Reprobados'
                                            ? 'contained'
                                            : 'outlined'
                                    }
                                    size="small"
                                    onClick={() => setView('Reprobados')}
                                >
                                    Reprobados
                                </Button>
                            </Box>
                            <Box
                                sx={{
                                    height: '242px',
                                    minWidth: '200px',
                                }}
                            >
                                <ResponsiveBar
                                    data={structuredData}
                                    keys={['Hombres', 'Mujeres']}
                                    indexBy="label"
                                    margin={{
                                        top: 10,
                                        right: 20,
                                        bottom: 50,
                                        left: 30,
                                    }}
                                    padding={0.2}
                                    colors={getColor}
                                    borderRadius={5}
                                    groupMode="grouped"
                                    axisTop={null}
                                    axisRight={null}
                                    axisBottom={{
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 45,
                                        legend: '',
                                        legendPosition: 'middle',
                                        legendOffset: 60,
                                    }}
                                    axisLeft={{
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        legend: 'Cantidad de Alumnos',
                                        legendPosition: 'middle',
                                        legendOffset: -50,
                                    }}
                                    labelSkipWidth={0}
                                    labelSkipHeight={0}
                                    labelTextColor="white"
                                    animate={true}
                                    motionStiffness={90}
                                    motionDamping={15}
                                    layers={[
                                        'grid',
                                        'axes',
                                        'bars',
                                        'markers',
                                        'legends',
                                        (props) =>
                                            props.bars.map((bar) => {
                                                const isSmallValue =
                                                    bar.data.value <= 10;

                                                return (
                                                    <g
                                                        transform={`translate(${bar.x}, ${bar.y})`}
                                                        key={bar.key}
                                                    >
                                                        <rect
                                                            width={bar.width}
                                                            height={bar.height}
                                                            fill={bar.color}
                                                        />
                                                        {bar.data.value >
                                                            10 && (
                                                            <text
                                                                x={
                                                                    bar.width /
                                                                    2
                                                                }
                                                                y={
                                                                    bar.height /
                                                                    2
                                                                }
                                                                textAnchor="middle"
                                                                dominantBaseline="central"
                                                                style={{
                                                                    fill: 'white',
                                                                    fontSize: 16,
                                                                    fontWeight:
                                                                        'bold',
                                                                }}
                                                            >
                                                                {bar.data.value}
                                                            </text>
                                                        )}
                                                        {isSmallValue && (
                                                            <text
                                                                x={
                                                                    bar.width /
                                                                    2
                                                                }
                                                                y={-5}
                                                                textAnchor="middle"
                                                                style={{
                                                                    fill: 'black',
                                                                    fontSize: 16,
                                                                    fontWeight:
                                                                        'bold',
                                                                }}
                                                            >
                                                                {bar.data.value}
                                                            </text>
                                                        )}
                                                    </g>
                                                );
                                            }),
                                    ]}
                                    theme={{
                                        axis: {
                                            ticks: {
                                                text: { fontSize: 12 },
                                            },
                                        },
                                        tooltip: {
                                            container: {
                                                background: 'white',
                                                color: 'inherit',
                                                fontSize: 16,
                                                borderRadius: '2px',
                                                boxShadow:
                                                    '0 1px 2px rgba(0, 0, 0, 0.25)',
                                            },
                                        },
                                    }}
                                />
                            </Box>
                        </CardContent>
                    </Card>
                )}
            </Box>
        </ErrorBoundary>
    );
};

export default StudentApproventFailedGenderGradeGrafic;
