import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { message } from 'antd';
import FeatureFlags from '../../service/FeatureFlags';
import { useAuth, useFeedback } from '../../hooks';
import { DeletePayment } from '../store/income/operationSlice';
import { fetchExport } from '../store/income/fetchSlice';
import { selectTableData } from '../store/income/selectors';

const useIncomeMethods = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const dispatch = useDispatch();
    const auth = useAuth();
    const schoolId = auth.getUser().school_id;
    const feedbackApp = useFeedback();
    const incomeData = useSelector(selectTableData);

    const featureFlagPartialities = FeatureFlags.isFeatureFlagActive(
        'NEW_PARTIALITIES_PAYMENTS_MODULE'
    );

    const handleClose = () => setIsModalOpen(false);
    const handleAddButtonClick = () => {
        setIsModalOpen(true);
    };

    const handleDeleteConfirm = async (id) => {
        dispatch(DeletePayment(id))
            .unwrap()
            .then(() => {
                feedbackApp.showFeedback({
                    title: 'Registro de pago eliminado correctamente',
                    severity: 'success',
                });
            })
            .catch((error) => {
                feedbackApp.showFeedback({
                    title: error.feedback.title,
                    severity: 'error',
                });
            });
    };

    const handleDownload = async (paymentId, currentTerm) => {
        await dispatch(
            fetchExport({
                schoolId,
                paymentId,
                terms: currentTerm,
            })
        )
            .unwrap()
            .then((result) => {
                feedbackApp.showFeedback({
                    title: 'Archivo descargado correctamente',
                    severity: 'info',
                });
            })
            .catch((error) => {
                feedbackApp.showFeedback({
                    title: 'Error al descargar el archivo',
                    severity: 'error',
                });
            });
    };

    const filteredRows = incomeData
        .map((row) => ({
            id: row.payment_id,
            payment_id: row.payment_id,
            user: row.user,
            payment_method: row.payment_method,
            concept: row.catalogConcept.concept,
            description: row.concept.description,
            amount: row.amount,
            date: row.created_at,
            current_term: row.agreement?.current_term ?? 1,
            total_terms: row.agreement?.term ?? 1,
            created_at: row.created_at,
        }))
        .sort((a, b) => new Date(b.date) - new Date(a.date));


    return {
        isModalOpen,
        setIsModalOpen,
        featureFlagPartialities,
        handleClose,
        handleDeleteConfirm,
        handleDownload,
        handleAddButtonClick,
        filteredRows,
    };
};

export default useIncomeMethods;
