import React, { useState } from 'react';
import { Card, CardContent, Typography, Box, Divider } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import { selectConceptsWithoutPayment } from '../../../store/home/kpis';
import { ReactComponent as PendingMoney } from '../../../assets/icons/service-error-solid-svgrepo-com.svg';
import { ResponsiveLine } from '@nivo/line';
import { NewFeatures } from '../../../components/features/NewFeatures';
import { Statistic } from 'antd';
import formatter from '../../../components/utilities/animations';

const ToPayCard = () => {
    const theme = useTheme();
    const conceptsWithoutPaymentData = useSelector(
        selectConceptsWithoutPayment
    ).value;

    // Datos de ejemplo con color por defecto
    const lineData = [
        {
            id: 'Serie 1',
            data: [
                { x: 'Enero', y: 4000, color: '#ffffff' },
                { x: 'Febrero', y: 3000, color: '#ffffff' },
                { x: 'Marzo', y: 2000, color: '#ffffff' },
                { x: 'Abril', y: 2780, color: '#ffffff' },
                { x: 'Mayo', y: 1890, color: '#ffffff' },
            ],
        },
    ];

    const maxValue = Math.max(...lineData[0].data.map((item) => item.y));

    const [activeBar, setActiveBar] = useState(null);

    return (
        <Card
            style={{
                backgroundColor: '#e24141',
                borderRadius: '15px',
                width: '100%',
                height: '50em',
                padding: '10px',
            }}>
            <CardContent style={{ position: 'relative' }}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>
                    <Box
                        style={{
                            backgroundColor: '#e66363',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '10px',
                            borderRadius: '100px',
                        }}>
                        <PendingMoney style={{ width: 50, height: 50 }} />
                    </Box>
                </div>

                <Statistic
                    title={
                        <Typography
                            variant="h5"
                            component="div"
                            style={{
                                marginTop: '10px',
                                color: '#ffffff',
                                fontWeight: '100',
                            }}>
                            Por pagar
                        </Typography>
                    }
                    value={conceptsWithoutPaymentData}
                    precision={1}
                    valueStyle={{
                        fontWeight: 'bold',
                        fontSize: '50px',
                        color: '#ffffff',
                    }}
                    prefix={'$'}
                    formatter={formatter}
                />

                <div
                    style={{
                        height: '500px',
                        marginTop: '20px',
                        position: 'relative',
                    }}>
                    <NewFeatures />

                    <div style={{ height: '100%', pointerEvents: 'none' }}>
                        <ResponsiveLine
                            data={lineData}
                            margin={{ top: 50, bottom: 0, left: 0 }}
                            xScale={{ type: 'point' }}
                            yScale={{
                                type: 'linear',
                                min: 'auto',
                                max: 'auto',
                                stacked: false,
                                reverse: false,
                            }}
                            lineWidth={3}
                            colors={
                                ({ point }) =>
                                    point && point.data
                                        ? activeBar === point.data.x
                                            ? '#ffcc00'
                                            : point.data.y === maxValue
                                            ? '#ffffff'
                                            : point.data.color
                                        : '#ffffff' // Color por defecto si el point es undefined
                            }
                            curve="monotoneX"
                            enablePoints={true}
                            pointSize={10}
                            pointColor={({ point }) =>
                                point && point.data
                                    ? activeBar === point.data.x
                                        ? '#ffcc00'
                                        : '#ffffff'
                                    : '#ffffff'
                            }
                            pointBorderWidth={2}
                            pointBorderColor={({ point }) =>
                                point && point.data
                                    ? point.data.color
                                    : '#ffffff'
                            }
                            enableGridX={false}
                            enableGridY={false}
                            enableArea={false}
                            useMesh={true}
                            tooltip={({ point }) =>
                                point && point.data ? (
                                    <div
                                        style={{
                                            padding: '10px',
                                            background: 'white',
                                            border: `1px solid ${point.color}`,
                                            borderRadius: '5px',
                                            color: 'black',
                                            boxShadow:
                                                '0px 0px 6px rgba(0, 0, 0, 0.1)',
                                        }}>
                                        <strong>{point.data.x}</strong>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}>
                                            <span
                                                style={{
                                                    width: '10px',
                                                    height: '10px',
                                                    borderRadius: '50%',
                                                    backgroundColor:
                                                        point.color,
                                                    marginRight: '5px',
                                                }}
                                            />
                                            <span>{`$${point.data.y}`}</span>
                                        </div>
                                    </div>
                                ) : null
                            }
                            axisTop={null}
                            axisRight={null}
                            axisBottom={null}
                            axisLeft={null}
                            theme={{
                                grid: {
                                    line: {
                                        stroke: '#ffffff00',
                                    },
                                },
                            }}
                            onMouseEnter={(point) => setActiveBar(point.data.x)} // Cambia el color al pasar el ratón
                            onMouseLeave={() => setActiveBar(null)} // Restaura el estado cuando se sale
                        />
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};

export default ToPayCard;
