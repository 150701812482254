import { Button, Popconfirm, Typography } from 'antd';
import React, { useState } from 'react';
import { Button as MuiButton } from '@mui/material';

export const PopConfirm = (props) => {
    const {
        message,
        title,
        icon: IconComponent,
        IconButton: IconButtonComponent,
        textButtonConfirm,
        textButtonCancel,
        color,
        iconSize,
        onConfirm,
        isDisabled, messageButton,  muiButtonStyles, placement,topIcon, 
    } = props;

    const [visible, setVisible] = useState(false);

    const handleCancel = () => {
        setVisible(false);
    };

    const handleConfirm = () => {
        onConfirm();
        setVisible(false);
    };

    return (
        <>

            <Popconfirm
                placement={placement}
                overlayStyle={{ zIndex: 3000 }}

                title={

                    <div
                        style={{
                            textAlign: 'center',
                            width: '300px',
                            borderRadius: '20px',
                            overflow: 'hidden',
                            marginLeft: '20px', // Ajustar este valor para desplazar a la derecha

                        }}>
                             
                        <div
                            style={{
                                position: 'absolute',
                                top: topIcon,
                                left: '50%',
                                transform: 'translateX(-50%)',
                                borderRadius: '50%',

                            }}>
                            <IconComponent
                                style={{
                                    width: `${iconSize}px`,
                                    height: `${iconSize}px`,
                                    color: 'white',
                                }}
                            />
                        </div>

                        <div
                            style={{
                                padding: '15px',
                                backgroundColor: '#fff',
                                textAlign: 'center',
                            }}>
                            <Typography.Title
                                level={3}
                                style={{ marginBottom: 10 }}>
                                {title}
                            </Typography.Title>
                            <Typography.Text type="secondary">
                                {message}
                            </Typography.Text>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-around',
                                padding: '15px',
                                backgroundColor: '#fff',
                            }}>
                            <Button
                                onClick={handleConfirm}
                                style={{
                                    backgroundColor: color,
                                    borderColor: color,
                                    color: 'white',
                                    borderRadius: '10px',
                                    width: '45%',
                                }}>
                                {textButtonConfirm}
                            </Button>
                            <Button
                                onClick={handleCancel}
                                style={{
                                    color: '#171717',
                                    borderRadius: '10px',
                                    width: '45%',
                                }}>
                                {textButtonCancel}
                            </Button>
                        </div>
                    </div>
                    
                }
                open={visible} // Update from visible to open
                onConfirm={handleConfirm}
                okButtonProps={{ style: { display: 'none' } }}
                icon={null}
                cancelButtonProps={{ style: { display: 'none' } }}
                >
                    

            </Popconfirm>

            <MuiButton
                size="small"
                variant="text"
                disableElevation
                disabled={isDisabled}
                onClick={() => setVisible(true)}
                sx={{
                  
                    fontWeight: 'bold',
                    color: color,
                    ...muiButtonStyles,
         
                }}
            
                startIcon={<IconButtonComponent sx={{ color: color }} />}
            >
                {messageButton}
            </MuiButton >
        </>

    );
};
