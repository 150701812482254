import {
    createAsyncThunk,
    createSelector,
    createSlice,
} from '@reduxjs/toolkit';
import Connection from '../../../service/Connection';
import Feedback from '../../../service/Feedback';
import { selectPaymentSurcharges } from './selectors';
import {
    setAllSurcharges,
    upsertManySurcharges,
} from '../../../store/slices/entities/payments/surcharges';
import { upsertManyCatalogSurcharges } from '../../../store/slices/entities/payments/cat_surcharges';
import { upsertManyCataloConcepts } from '../../../store/slices/entities/payments/cat_concepts';
import { upsertManyConcepts } from '../../../store/slices/entities/payments/concepts';
import { upsertManyStudents } from '../../../store/slices/entities/students';
import { PAYMENTS_HOME_EXPIRE_TIME } from '../../../service/const';
import { upsertManyAgreements } from '../../../store/slices/entities/payments/agreements';
import { emptyState } from '../emptyState';

// Slice para los conceptos de pago
const SurchargesSlice = createSlice({
    name: 'SurchargesUI/Server',
    initialState: emptyState,
    reducers: {
        /**
         * Invalidar datos de la UI
         */
        invalidate: (state, action) => {
            state.didInvalidate = true;
        },
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return emptyState;
        });

        const pendingServerStatus = (state, action) => {
            state.statusServer = 'pending';
        };

        /**
         * Termina la carga de informacion
         */

        builder.addCase(loadUI.fulfilled, (state, action) => {
            state.expireIn = new Date().setMinutes(
                new Date().getMinutes() + PAYMENTS_HOME_EXPIRE_TIME
            );
            state.ferchingAt = Date.now();
            state.didInvalidate = false;

            state.statusServer = 'fulfilled';
        });
        builder.addCase(loadUI.pending, pendingServerStatus);
        builder.addCase(loadUI.rejected, (state, action) => {
            state.statusServer = 'rejected';
            state.feedback = action.payload.feedback;
        });
    },
});

export const { invalidate } = SurchargesSlice.actions;

export default SurchargesSlice.reducer;

////////////////////////////////////// THUNKS // ////////////////////////////////////

/**
 * Cargar informacion de la UI de Pagos
 */

export const loadUI = createAsyncThunk(
    'SurchargesUI/server/fetch/data',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            const students = await Connection.StudentsBySchool(schoolId).then(
                (res) => res.data.data
            );

            const catalogSurcharges =
                await Connection.getSchoolsBySurchargesCatalog(schoolId).then(
                    (res) => res.data.data
                );

            const surcharges = await Connection.getSurchargesBySchool(
                schoolId
            ).then((res) => res.data.data);

            const concepts = await Connection.getConceptsBySchool(
                schoolId
            ).then((res) => res.data.data);

            const catConcepts = await Connection.getSchoolsByConceptsCatalog(
                schoolId
            ).then((res) => res.data.data);

            thunkAPI.dispatch(upsertManyStudents(students));
            thunkAPI.dispatch(upsertManyCataloConcepts(catConcepts));
            thunkAPI.dispatch(upsertManyConcepts(concepts));

            thunkAPI.dispatch(upsertManySurcharges(surcharges));
            thunkAPI.dispatch(upsertManyCatalogSurcharges(catalogSurcharges));

            return {
                concepts,
                catConcepts,
                students,
                surcharges,
                catalogSurcharges,
            };
        } catch (err) {
            console.log(err);
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    },
    {
        // Condición para ejecutar el thunk solo si es necesario
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } = selectFetchSurcharges(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const fetchCreateSurcharge = createAsyncThunk(
    'Surcharges/create/fetch',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            const [
                responseConcepts,
                responseCatalogSurcharges,
                responseCatalogConcepts,
                responseAgreements,
            ] = await Promise.all([
                Connection.getConceptsBySchool(schoolId),
                Connection.getSchoolsBySurchargesCatalog(schoolId),
                Connection.getSchoolsByConceptsCatalog(schoolId),
                Connection.getAgreementBySchool(schoolId),
            ]);

            const concepts = responseConcepts.data.data;
            const catSurcharges = responseCatalogSurcharges.data.data;
            const catConcepts = responseCatalogConcepts.data.data;
            const agreements = responseAgreements.data.data;

            thunkAPI.dispatch(upsertManyCataloConcepts(catConcepts));
            thunkAPI.dispatch(upsertManyConcepts(concepts));

            thunkAPI.dispatch(upsertManyCatalogSurcharges(catSurcharges));
            thunkAPI.dispatch(upsertManyAgreements(agreements));

            return {
                concepts,
                catConcepts,
                catSurcharges,
                agreements,
            };
        } catch (err) {
            console.log(err);
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

//////////////////////////////////// SELECTORES ////////////////////////////////////
/**
 * Selector para recuperar el estado de la petición de recargos de pago
 */
export const selectFetchSurcharges = createSelector(
    selectPaymentSurcharges,
    (state) => state.fetch
);

/**
 * Selector para recuperar el estado del servidor
 */

export const selectStatusServer = createSelector(
    selectPaymentSurcharges,
    (state) => state.fetch.statusServer
);
