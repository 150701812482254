import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Card,
    CardContent,
    Typography,
    CircularProgress,
} from '@mui/material';
import { DataGrid, esES } from '@mui/x-data-grid';
import useFetchResource from '../../../components/HelperHook';
import { useAuth } from '../../../../hooks';
import {
    getCycleSelected,
    selectScoresMain,
} from '../../../store/groups/fetchSlice';
import { getScoresMain } from '../../../store/groups/thunks';
import { selectComponentGroupsScoresMainStatusServer } from '../../../store/groups/componentStatusSelectors';
import { SkeletonGroupsTables } from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';
import { Tag } from 'antd';
import NoDataOverlay from '../../../../components/utilities/NoDataOverlay';
import { GridToolBar } from '../../../../components/utilities/GridToolBar';
import CustomPagination from '../../../../components/utilities/CustomPagination';

const ScoresMainCard = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentGroupsScoresMainStatusServer
    );

    const scoresMain = useFetchResource(
        () =>
            getScoresMain({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectScoresMain,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getScoresMain({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            })
        );
    };

    const headers = scoresMain?.value ? Object.keys(scoresMain?.value) : [];
    const data = scoresMain?.value
        ? scoresMain?.value.Grado.map((_, index) => {
              const row = { id: index };
              headers.forEach((header) => {
                  row[header] = scoresMain?.value[header]?.[index]?.value;
              });
              return row;
          }).sort((a, b) => b.promedio - a.promedio)
        : [];

    const columns = headers.map((header) => ({
        field: header,
        headerName: header.charAt(0).toUpperCase() + header.slice(1),
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
            if (header === 'Promedio') {
                return (
                    <Tag
                        color="success"
                        style={{
                            width: '100px',
                            textAlign: 'center',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'normal',
                            wordBreak: 'break-word',
                        }}>
                        {params.value}
                    </Tag>
                );
            }
            return params.value;
        },
    }));

    return (
        <Box sx={{ width: '100%', overflowX: 'hidden' }}>
            {fetchingStatus === 'pending' && <SkeletonGroupsTables items={9} />}

            {fetchingStatus === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {fetchingStatus === 'fulfilled' && (
                <Card
                    sx={{
                        background: '#ffffff',
                        borderRadius: '10px',
                        boxShadow: 'none',
                        maxWidth: '100%',
                        margin: '20px auto',
                        padding: '16px',
                        overflow: 'visible',
                        position: 'relative',
                        mt: -4,
                    }}>
                    <CardContent sx={{ padding: '2px' }}>
                        {scoresMain?.loading ? (
                            <CircularProgress
                                sx={{
                                    margin: '20px',
                                    display: 'block',
                                    mx: 'auto',
                                }}
                            />
                        ) : scoresMain?.error ? (
                            <Typography
                                color="error"
                                sx={{ textAlign: 'center' }}>
                                Error: {scoresMain?.error.message}
                            </Typography>
                        ) : (
                            <Box sx={{ minHeight: 575, width: '100%' }}>
                                <DataGrid
                                    localeText={{
                                        ...esES.components.MuiDataGrid
                                            .defaultProps.localeText,
                                        toolbarColumns: '',
                                        toolbarFilters: '',
                                        toolbarDensity: '',
                                        toolbarExport: '',
                                    }}
                                    rows={data}
                                    columns={columns}
                                    pageSize={10}
                                    rowsPerPageOptions={[]}
                                    disableSelectionOnClick
                                    disableDensitySelector
                                    components={{
                                        NoRowsOverlay: NoDataOverlay,
                                        NoResultsOverlay: NoDataOverlay,
                                        Toolbar: GridToolBar,
                                        Pagination: CustomPagination,
                                    }}
                                    componentsProps={{
                                        panel: {
                                            placement: 'bottom-end',
                                        },
                                        noResultsOverlay: {
                                            message:
                                                'No se encontraron resultados para la búsqueda',
                                        },
                                        noRowsOverlay: {
                                            message: 'No hay datos disponibles',
                                        },
                                    }}
                                    getRowHeight={() => 'auto'}
                                    sx={{
                                        '& .MuiDataGrid-cell': {
                                            whiteSpace: 'normal',
                                            wordWrap: 'break-word',
                                            overflow: 'visible',
                                            lineHeight: '3',
                                        },
                                        '&.MuiDataGrid-root': {
                                            border: 'none',
                                        },
                                    }}
                                    autoHeight
                                />
                            </Box>
                        )}
                    </CardContent>
                </Card>
            )}
        </Box>
    );
};

export default ScoresMainCard;
