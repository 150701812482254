import { useSelector } from 'react-redux';
import { selectSettingByKey } from '../../store/slices/entities/settings';
import { Box, Card, Divider } from '@mui/material';
import { getTurnLevel } from '../../libs/utils';
import {
    ErrorOutline,
    PeopleOutline,
    SchoolOutlined,
} from '@mui/icons-material';
import Header from '../../components/utilities/Header';
import { Col, Row, Statistic } from 'antd';

const ClassroomGroupPreviewCard = ({ classroom }) => {
    const scoreMin = useSelector(selectSettingByKey('calification-minimum'));

    const cardData = [
        {
            title: 'Alumnos',
            value: classroom.group.students,
            sx: {
                fontWeight: 'bold',
                backgroundColor: '#05D590',
                padding: '10px',
            },
            prefix: (
                <PeopleOutline style={{ color: '#ffffff', fontSize: '3rem' }} />
            ),
        },
        {
            title: 'Faltas',
            value: classroom.group.total_absence,
            sx: {
                backgroundColor: '#FFBF43', // Orange
                fontWeight: 'bold',
                padding: '10px',
            },
            prefix: (
                <ErrorOutline style={{ color: '#ffffff', fontSize: '3rem' }} />
            ),
        },
        {
            title: 'Promedio',
            value: classroom.group.average_rating,
            sx: {
                backgroundColor:
                    classroom.group.average_rating >= scoreMin?.value
                        ? '#05D590'
                        : '#FF785A',
                fontWeight: 'bold',
                padding: '10px',
            },
            prefix: (
                <SchoolOutlined
                    style={{ color: '#ffffff', fontSize: '3rem' }}
                />
            ),
        },
    ];
    return (
        <>
            <Divider>
                <Header
                    title={classroom.school.name}
                    subtitle={` ${classroom.group.grade}°${
                        classroom.group.group
                    } ${getTurnLevel(classroom.group.turn)}`}
                />
            </Divider>
            <Box>
                <Row gutter={[32, 24]}>
                    {cardData.map((card, index) => (
                        <Col xs={8} xl={8} key={index}>
                            <Card bordered={false} sx={card.sx}>
                                <Statistic
                                    title={
                                        <span style={{ color: '#ffffff' }}>
                                            {card.title}
                                        </span>
                                    }
                                    value={card.value}
                                    valueStyle={{
                                        color: '#ffffff',
                                        fontSize: '3rem',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                    prefix={card.prefix}
                                />
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Box>
        </>
    );
};

export default ClassroomGroupPreviewCard;
