import {
    createAsyncThunk,
    createSelector,
    createSlice,
} from '@reduxjs/toolkit';
import Connection from '../../../service/Connection';
import Feedback from '../../../service/Feedback';

import {
    setAllDiscounts,
    upsertManyDiscounts,
} from '../../../store/slices/entities/payments/discounts';
import { upsertManyStudents } from '../../../store/slices/entities/students';
import { upsertManyCatalogDiscounts } from '../../../store/slices/entities/payments/cat_discounts';
import { upsertManyConcepts } from '../../../store/slices/entities/payments/concepts';
import { upsertManyCataloConcepts } from '../../../store/slices/entities/payments/cat_concepts';
import { selectPaymentDiscounts } from './selectors';
import { PAYMENTS_HOME_EXPIRE_TIME } from '../../../service/const';
import { upsertManyAgreements } from '../../../store/slices/entities/payments/agreements';
import { emptyState } from '../emptyState';

////////////////////////////////////// SLICE //////////////////////////////////////
const DiscountsSlice = createSlice({
    name: 'Discounts',
    initialState: emptyState,
    reducers: {
        /**
         * Invalidar datos de la UI
         */
        invalidate: (state, action) => {
            state.didInvalidate = true;
        },
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return emptyState;
        });

        const pendingServerStatus = (state, action) => {
            state.statusServer = 'pending';
        };

        /**
         * Termina la carga de informacion
         */
        builder.addCase(loadUI.fulfilled, (state, action) => {
            state.expireIn = new Date().setMinutes(
                new Date().getMinutes() + PAYMENTS_HOME_EXPIRE_TIME
            );
            state.ferchingAt = Date.now();
            state.didInvalidate = false;

            state.statusServer = 'fulfilled';
        });
        builder.addCase(loadUI.pending, pendingServerStatus);
        builder.addCase(loadUI.rejected, (state, action) => {
            state.statusServer = 'rejected';
            state.feedback = action.payload.feedback;
        });
    },
});

export const { invalidate } = DiscountsSlice.actions;

export default DiscountsSlice.reducer;

////////////////////////////////////// THUNKS //////////////////////////////////////

/**
 * Cargar informacion de la UI de Pagos
 */

export const loadUI = createAsyncThunk(
    'Discounts/fetch',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback();
        try {
            const students = await Connection.StudentsBySchool(schoolId).then(
                (res) => res.data.data
            );

            const catalogDiscounts =
                await Connection.getSchoolsByDiscountsCatalog(schoolId).then(
                    (res) => res.data.data
                );

            const concepts = await Connection.getConceptsBySchool(
                schoolId
            ).then((res) => res.data.data);

            const discounts = await Connection.getDiscountsBySchool(
                schoolId
            ).then((res) => res.data.data);

            const CatConcepts = await Connection.getSchoolsByConceptsCatalog(
                schoolId
            ).then((res) => res.data.data);

            thunkAPI.dispatch(upsertManyDiscounts(discounts));
            thunkAPI.dispatch(upsertManyStudents(students));
            thunkAPI.dispatch(upsertManyCatalogDiscounts(catalogDiscounts));
            thunkAPI.dispatch(upsertManyConcepts(concepts));
            thunkAPI.dispatch(upsertManyCataloConcepts(CatConcepts));

            return {
                discounts,
                concepts,
                students,
                catalogDiscounts,
                CatConcepts,
            };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    },
    {
        // Condición para ejecutar el thunk solo si es necesario
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } = selectDiscountsFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

// Thunk para obtener los conceptos asignados a un alumno específico
export const fetchConceptsStudents = createAsyncThunk(
    'Discounts/fetch/ConceptsStudents',
    async (studentId, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            const responseConceptsStudent =
                await Connection.getAssignedConceptsToStudent(studentId);

            const concepts = responseConceptsStudent.data.data;

            thunkAPI.dispatch(upsertManyConcepts(concepts));

            return { concepts };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

// Thunk para realizar la petición asincrónica de descuentos de pago
export const fetchCreateDiscount = createAsyncThunk(
    'Discount/create/fetch',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            const [
                responseConcepts,
                responseCatalogDiscounts,
                responseCatalogConcepts,
                responseAgreements,
            ] = await Promise.all([
                Connection.getConceptsBySchool(schoolId),
                Connection.getSchoolsByDiscountsCatalog(schoolId),
                Connection.getSchoolsByConceptsCatalog(schoolId),
                Connection.getAgreementBySchool(schoolId),
            ]);

            const concepts = responseConcepts.data.data;
            const catDiscounts = responseCatalogDiscounts.data.data;
            const catConcepts = responseCatalogConcepts.data.data;
            const agreements = responseAgreements.data.data;

            thunkAPI.dispatch(upsertManyCataloConcepts(catConcepts));
            thunkAPI.dispatch(upsertManyConcepts(concepts));
            thunkAPI.dispatch(upsertManyCatalogDiscounts(catDiscounts));
            thunkAPI.dispatch(upsertManyAgreements(agreements));

            return {
                concepts,
                catConcepts,
                catDiscounts,
                agreements,
            };
        } catch (err) {
            console.log(err);
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

// //////////////////////////////////// SELECTORES // ////////////////////////////////////

/**
 * Selector para recuperar el estado de la petición de los pagos
 */
export const selectDiscountsFetch = createSelector(
    selectPaymentDiscounts,
    (state) => state.fetch
);

/**
 * Selector para recuperar el estado del servidor
 */

export const selectStatusServer = createSelector(
    selectPaymentDiscounts,
    (state) => state.fetch.statusServer
);
