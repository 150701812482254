import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Card, CardContent } from '@mui/material';
import {
    DataGrid,
    esES,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarExport,
    GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectAllScholarshipStudents,
} from '../../../store/groups/fetchSlice';
import { getAllScholarshipStudents } from '../../../store/groups/thunks';
import { SkeletonGroupsSmallCards } from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';
import { selectComponentGroupsAllScholarshipStudentsStatusServer } from '../../../store/groups/componentStatusSelectors';
import { Tag } from 'antd';
import { ErrorBoundary } from 'react-error-boundary';
import NoDataOverlay from '../../../../components/utilities/NoDataOverlay';
import { GridToolBar } from '../../../../components/utilities/GridToolBar';
import CustomPagination from '../../../../components/utilities/CustomPagination';

const TotalScholarshipTable = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentGroupsAllScholarshipStudentsStatusServer
    );

    const allScholarshipStudents = useFetchResource(
        () =>
            getAllScholarshipStudents({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectAllScholarshipStudents,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getAllScholarshipStudents({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            })
        );
    };

    const headers = allScholarshipStudents.value
        ? Object.keys(allScholarshipStudents.value)
        : [];
    const data = allScholarshipStudents.value || {};

    const rows = headers.length
        ? data[headers[0]]?.map((_, index) => {
              const row = { id: index };
              headers.forEach((header) => {
                  row[header] = data[header][index]?.value;
              });
              return row;
          }) || []
        : [];

    const columns = headers.map((header) => ({
        field: header,
        headerName: header,
        flex: header === 'Becados' ? 2 : 1, // Ajustamos la flexibilidad de las columnas si es necesario
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
            if (header === 'Becados') {
                return (
                    <Tag
                        color="blue"
                        style={{
                            width: '100px',
                            textAlign: 'center',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'normal',
                            wordBreak: 'break-word',
                        }}>
                        {params.value}
                    </Tag>
                );
            }
            return params.value;
        },
    }));

    // Si hay necesidad de ordenar, puedes hacerlo aquí, por ejemplo:
    const sortRowsByGroup = (rows) => {
        return rows.sort((a, b) => {
            if (a.group > b.group) return 1;
            if (a.group < b.group) return -1;
            return 0;
        });
    };

    const sortedRows = sortRowsByGroup(rows);

    const CustomToolbar = (props) => (
        <GridToolbarContainer sx={{ justifyContent: 'flex-end' }}>
            <GridToolbarColumnsButton {...props} />
            <GridToolbarFilterButton {...props} />
            <GridToolbarExport
                csvOptions={{
                    utf8WithBom: true,
                    delimiter: ',',
                }}
            />
        </GridToolbarContainer>
    );

    return (
        <ErrorBoundary>
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                {fetchingStatus === 'pending' && (
                    <SkeletonGroupsSmallCards items={9} />
                )}

                {fetchingStatus === 'rejected' && (
                    <Error
                        onRetry={reload}
                        message={'Estamos teniendo problemas'}
                    />
                )}

                {fetchingStatus === 'fulfilled' && (
                    <Card
                        sx={{
                            background: '#ffffff',
                            borderRadius: '10px',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            maxWidth: '100%',
                            margin: '20px auto',
                            padding: '16px',
                            overflow: 'visible',
                            position: 'relative',
                        }}>
                        <CardContent>
                            <Typography
                                variant="h6"
                                sx={{ color: '#333', mb: 2 }}>
                                {allScholarshipStudents?.title ||
                                    'Total de alumnos becados por grupo'}
                            </Typography>
                            {sortedRows.length === 0 ? (
                                <Typography
                                    variant="h5"
                                    sx={{ textAlign: 'center' }}>
                                    Información no disponible
                                </Typography>
                            ) : (
                                <Box sx={{ width: '100%' }}>
                                    <DataGrid
                                        localeText={{
                                            ...esES.components.MuiDataGrid
                                                .defaultProps.localeText,
                                            toolbarColumns: '',
                                            toolbarFilters: '',
                                            toolbarDensity: '',
                                            toolbarExport: '',
                                        }}
                                        rows={rows}
                                        columns={columns}
                                        pageSize={10}
                                        rowsPerPageOptions={[]}
                                        disableSelectionOnClick
                                        disableDensitySelector
                                        components={{
                                            NoRowsOverlay: NoDataOverlay,
                                            NoResultsOverlay: NoDataOverlay,
                                            Toolbar: GridToolBar,
                                            Pagination: CustomPagination,
                                        }}
                                        componentsProps={{
                                            panel: {
                                                placement: 'bottom-end',
                                            },
                                            noResultsOverlay: {
                                                message:
                                                    'No se encontraron resultados para la búsqueda',
                                            },
                                            noRowsOverlay: {
                                                message:
                                                    'No hay datos disponibles',
                                            },
                                        }}
                                        getRowHeight={() => 'auto'}
                                        sx={{
                                            '& .MuiDataGrid-cell': {
                                                whiteSpace: 'normal',
                                                wordWrap: 'break-word',
                                                overflow: 'visible',
                                                lineHeight: '3',
                                            },
                                            '&.MuiDataGrid-root': {
                                                border: 'none',
                                            },
                                        }}
                                        autoHeight
                                    />
                                </Box>
                            )}
                        </CardContent>
                    </Card>
                )}
            </Box>
        </ErrorBoundary>
    );
};

export default TotalScholarshipTable;
