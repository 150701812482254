import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useAuth } from '../../../../hooks';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';

import {
    fetchClassroomSubjectData,
    invalidate,
    selectClassroomSubjectDetails,
    selectClassroomViewmodelFetchStatusById,
    selectJustifyFaults,
} from '../../../store/meGroups/classrooms';
import { Error } from '../../../../components/Feedback';
import SkeletonPage from './../../../components/SkeletonPage';
import ClassroomSubjectPreviewCard from './../../../components/ClassroomPreviewCard';
import SubjectJustifyTable from './components/SubjectJustifyTable';
import AbsencesModal from './components/AbsencesModal';
import JustificationModal from './components/JustificationModal';
import { DateTime } from 'luxon';
import * as _ from 'lodash';

const ClassroomSubjectJustify = () => {
    ////////// HOOKS //////////
    let { id } = useParams();
    const Auth = useAuth();
    const dispatch = useDispatch();

    ///////// SHARED STATE ////////

    const classroomDetails = useSelector(
        selectClassroomSubjectDetails(id, Auth.user.school_id)
    );

    const statusServer = useSelector(
        selectClassroomViewmodelFetchStatusById(id)
    );

    const justtifyFaults = useSelector(
        selectJustifyFaults(
            classroomDetails.subject.subject_id,
            classroomDetails.subject.group_id
        )
    );

    const currentDate = DateTime.local().toFormat('yyyy-MM-dd');

    ///////// LOCAL STATE ////////
    const [openAbsenceModal, setOpenAbsenceModal] = useState(false);
    const [openJustificatedModal, setOpenJustificatedModal] = useState(false);
    const [partialAbsences, setParcialAbsences] = useState([]);

    const buildAssitsDataGrid = ({ partials, students, assists }) => {
        let studentItems = [];
        let columnsItems = [];
        let columnsUI = [
            {
                column: 'name',
                title: 'ALUMNO(S)',
                sticky: true,
                className: '',
            },
        ];

        for (let student of students.map((i) => Object.assign({}, i))) {
            let studentItem = {};

            studentItem.name = {
                name: true,
                value: `${student.last_name} ${student.second_last_name} ${student.name}`,
            };

            let partialsUI = {};

            let total = 0;
            let totalAbs = 0;
            let totalJus = 0;

            for (let partial of partials) {
                let assiten = _.filter(assists, {
                    student_id: student.student_id,
                    partial_id: partial.partial_id,
                });

                ///HERE!-------------
                let assist = assiten.filter((e) => e.assistance === 1);
                let absence = assiten.filter(
                    (e) => e.assistance == 0 && e.is_justified === false
                );

                let justified = assiten.filter(
                    (e) => e.assistance == 0 && e.is_justified === true
                );

                total += assist.length;
                totalAbs += absence.length;
                totalJus += justified.length;

                partialsUI[`Asistencias p-${partial.partial}`] = {
                    partial: true,
                    value: assist.length,
                    partialActive:
                        currentDate >= partial.start_date &&
                        currentDate <= partial.limit_date,
                };
                partialsUI[`Faltas p-${partial.partial}`] = {
                    partial: true,
                    value: absence.length,
                    abs: absence,
                    partialActive:
                        currentDate >= partial.start_date &&
                        currentDate <= partial.limit_date,
                };

                partialsUI[`Justificaciones p-${partial.partial}`] = {
                    partial: true,
                    value: justified.length,
                    abs: justified,
                    partialActive:
                        currentDate >= partial.start_date &&
                        currentDate <= partial.limit_date,
                };
            }

            partialsUI['total'] = {
                total: true,
                value: total,
            };
            ///HERE!----------------
            partialsUI['total faltas'] = {
                total: true,
                value: totalAbs,
            };

            partialsUI['total justificaciones'] = {
                total: true,
                value: totalJus,
            };

            studentItems.push({ ...studentItem, ...partialsUI });
        }

        for (let partial of partials) {
            columnsItems.push({
                column: `Asistencias p-${partial.partial}`,
                title: `Asist P${partial.partial}`,
                sticky: false,
                className: 'parcial-column',
                partialActive:
                    currentDate >= partial.start_date &&
                    currentDate <= partial.limit_date,
            });
            ///HERE!-------------
            columnsItems.push({
                column: `Faltas p-${partial.partial}`,
                title: `Faltas P${partial.partial}`,
                sticky: false,
                className: 'parcial-column',
                partialActive:
                    currentDate >= partial.start_date &&
                    currentDate <= partial.limit_date,
            });

            columnsItems.push({
                column: `Justificaciones p-${partial.partial}`,
                title: `Justif P${partial.partial}`,
                sticky: false,
                className: 'parcial-column',
                partialActive:
                    currentDate >= partial.start_date &&
                    currentDate <= partial.limit_date,
            });
        }

        columnsItems.push(
            {
                column: `total`,
                title: `TOTAL ASIST`,
                sticky: false,
                className: 'parcial-column',
            },
            {
                column: `total faltas`,
                title: `TOTAL FALTAS`,
                sticky: false,
                className: 'parcial-column',
            },
            {
                column: `total justificaciones`,
                title: `TOTAL JUST`,
                sticky: false,
                className: 'parcial-column',
            }
        );

        columnsUI = [...columnsUI, ...columnsItems];

        return {
            columns: columnsUI,
            rows: studentItems,
        };
    };

    const dataAssists = buildAssitsDataGrid(justtifyFaults);

    let assistsColumns = dataAssists.columns.map((i) => {
        return {
            field: i.column,
            headerName: i.title,
            flex: 0.4,
            minWidth: i.column === 'name' ? 150 : 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            headerClassName: i.partialActive ? 'partial-active--cell' : '',
            renderCell: (params) => {
                if (params.field === 'name') {
                    return (
                        <div
                            style={{
                                whiteSpace: 'pre-line',
                                textAlign: 'center',
                            }}>
                            {params.value == null ? '' : params.value}
                        </div>
                    );
                }
                const cellValue = params.row[params.field];
                const isFaltaCell = params.field.includes('Faltas');
                const isJustificacionCell =
                    params.field.includes('Justificaciones');
                if (isFaltaCell && cellValue.value > 0) {
                    return (
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                                setParcialAbsences(cellValue.abs);
                                setOpenAbsenceModal(true);
                            }}>
                            {cellValue.value}
                        </Button>
                    );
                } else if (isJustificacionCell && cellValue.value > 0) {
                    return (
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                                setParcialAbsences(cellValue.abs);
                                setOpenJustificatedModal(true);
                            }}>
                            {cellValue.value}
                        </Button>
                    );
                }

                return cellValue.value;
            },
            cellClassName: (params) => {
                let row = params.row[params.field]?.partialActive;

                if (row) {
                    return 'partial-active--cell';
                }

                return '';
            },
            valueGetter: (params) => {
                return params.value.value;
            },
        };
    });

    let assistsRows = dataAssists.rows
        .map((i, index) => ({ id: index, ...i }))
        .sort((a, b) => {
            const lastNameA = a.name.value;
            const lastNameB = b.name.value;
            return lastNameA.localeCompare(lastNameB);
        });

    const reload = () => {
        dispatch(
            fetchClassroomSubjectData({
                groupId: classroomDetails.group.group_id,
                subjectId: classroomDetails.subject.subject_id,
                curretnDate: DateTime.local().toFormat('yyyy-MM-dd'),
                schoolId: Auth.user.school_id,
                uuid: classroomDetails.viewmodel.uuid,
            })
        );
    };

    /**
     * Invalida la UI
     */
    const invalidateUI = () => {
        dispatch(invalidate(id));
        reload();
    };

    return (
        <Box
            sx={{
                flexGrow: 1,
                paddingTop: {
                    xs: 1,
                    sm: 2,
                    md: 2,
                },
                paddingLeft: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingRight: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingBottom: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
            }}>
            {statusServer === 'pending' && <SkeletonPage />}

            {statusServer === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}
            {statusServer === 'fulfilled' && (
                <>
                    <AbsencesModal
                        title="Faltas"
                        openModal={openAbsenceModal}
                        setOpenModal={setOpenAbsenceModal}
                        absences={partialAbsences}
                    />
                    <JustificationModal
                        title="Justificaciones"
                        openModal={openJustificatedModal}
                        setOpenModal={setOpenJustificatedModal}
                        absences={partialAbsences}
                    />

                    <Box
                        sx={{
                            marginBottom: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                        }}>
                        <Tooltip title="Sincronizar información">
                            <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                onClick={invalidateUI}
                                startIcon={<SyncIcon />}>
                                Sincronizar
                            </Button>
                        </Tooltip>
                    </Box>
                    <ClassroomSubjectPreviewCard classroom={classroomDetails} />
                    <SubjectJustifyTable
                        rows={assistsRows}
                        columns={assistsColumns}
                    />
                </>
            )}
        </Box>
    );
};

export default ClassroomSubjectJustify;
