import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { DataGrid, esES } from '@mui/x-data-grid';
import { Avatar, Box, Card, Chip, Typography } from '@mui/material';
import useThemeColors from '../../../../theme/themes';
import AgreementsModal from './AgreementsModal';
import { selectTableData } from '../../../store/agreements/selectors';
import CustomPagination from '../../../components/utilities/CustomPagination';
import { GridToolBar } from '../../../components/utilities/GridToolBar';
import femaleStudent from '../../../assets/img/female_student.png';
import MaleStudent from '../../../assets/img/male_student.png';
import defaultStudent from '../../../assets/img/default_student.png';
import { DateTime } from 'luxon';
import NoDataOverlay from '../../../components/utilities/NoDataOverlay';

const AgreementsTable = () => {
    ///////////// SHARED STATE /////////////
    const colors = useThemeColors();

    ///////////// SELECTORS /////////////
    const datatable = useSelector(selectTableData);

    ///////////// LOCAL STATE /////////////

    const [open, setOpen] = useState(false);

    ///////////// HANDLERS /////////////
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    ///////////// FUNCTIONS /////////////
    const getTermType = (value) => {
        const termTypeMapping = {
            1: {
                label: 'Semanal',
                color: colors.velvetNight[500],
                background: colors.velvetNight[100],
            }, // Blue
            2: {
                label: 'Mensual',
                color: colors.deepOcean[500],
                background: colors.deepOcean[100],
            }, // Green
            3: {
                label: 'Diario',
                color: colors.twilight[500],
                background: colors.twilight[100],
            },
        };

        return (
            termTypeMapping[value] || {
                label: 'Desconocido',
                color: 'grey.700',
                background: 'grey.200',
            }
        );
    };

    /////////// COLUMN CONFIG /////////////

    const columns = [
        {
            field: 'agreement_id',
            headerName: 'Folio',
            flex: 0.5,
            minWidth: 100,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                return (
                    <Typography color="primary" sx={{ fontWeight: 'bold' }}>
                        {params.value}
                    </Typography>
                );
            },
        },
        {
            field: 'user',
            headerName: 'Alumno',
            flex: 2,
            minWidth: 360,
            renderCell: (params) => {
                const { user } = params.row;

                const avatarSrc =
                    user.url_photo_profile ||
                    (user.gender === 'M'
                        ? femaleStudent
                        : user.gender === 'H'
                        ? MaleStudent
                        : defaultStudent);

                return (
                    <Box display="flex" alignItems="center">
                        <Avatar src={avatarSrc} sx={{ marginRight: 2 }} />
                        <Box>
                            <Typography
                                variant="body1"
                                sx={{
                                    fontWeight: 'bold',
                                    fontSize: '1rem',
                                    width: '100%',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                                title={`${user.name} ${user.last_name} ${user.second_last_name}`}>
                                {`${user.name} ${user.last_name} ${user.second_last_name}`}
                            </Typography>

                            <Typography
                                variant="body2"
                                sx={{
                                    fontSize: '0.875rem',
                                    color: 'textSecondary',
                                    width: '100%',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                                title={`ID: ${user.student_id}`}>
                                ID: {user.student_id}
                            </Typography>

                            <Typography
                                variant="body2"
                                sx={{
                                    fontSize: '0.875rem',
                                    color: 'textSecondary',
                                    width: '100%',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                                title={`Teléfono: ${user.cellphone}`}>
                                Teléfono: {user.cellphone}
                            </Typography>
                        </Box>
                    </Box>
                );
            },
            valueGetter: (params) => {
                const { user } = params.row;
                return `${user.name} ${user.last_name} ${user.second_last_name}`;
            },
        },
        {
            field: 'term_type',
            headerName: 'Tipo de plazo',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            minWidth: 200,
            valueGetter: (params) => getTermType(params.value).label,
            renderCell: (params) => {
                const getTermTypeCell = (value) => {
                    const termTypeMapping = {
                        Semanal: {
                            label: 'Semanal',
                            color: '#1E88E5',
                            background: '#BBDEFB',
                        },
                        Mensual: {
                            label: 'Mensual',
                            color: '#43A047',
                            background: '#C8E6C9',
                        },
                        Diario: {
                            label: 'Diario',
                            color: '#F4511E',
                            background: '#FFCCBC',
                        },
                    };

                    return (
                        termTypeMapping[value] || {
                            label: 'Desconocido',
                            color: 'grey.700',
                            background: 'grey.200',
                        }
                    );
                };
                const termType = getTermTypeCell(params.value);

                return (
                    <Box
                        px={2}
                        py={0.5}
                        borderRadius={1}
                        bgcolor={termType.background}
                        color={termType.color}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        fontWeight="bold"
                        width="100%"
                        height="100%">
                        <Typography
                            variant="body2"
                            sx={{
                                fontWeight: 'bold',
                            }}>
                            {termType.label}
                        </Typography>
                    </Box>
                );
            },
        },

        {
            field: 'created_at',
            headerName: 'Fecha de registro',
            type: 'date',
            flex: 1.5,
            minWidth: 250,
            valueFormatter: (params) => {
                const date = DateTime.fromSQL(params.value);
                return date.setLocale('es').toLocaleString(DateTime.DATE_FULL);
            },
        },
        {
            field: 'next_payment_date',
            headerName: 'Fecha del Próximo Pago',
            type: 'date',
            flex: 1.5,
            minWidth: 250,
            valueFormatter: (params) => {
                if (!params.value) {
                    return '';
                }
                const date = DateTime.fromSQL(params.value);
                return date.setLocale('es').toLocaleString(DateTime.DATE_FULL);
            },
        },

        {
            field: 'current_term',
            headerName: 'Número de Plazo',
            flex: 2,
            headerAlign: 'center',
            align: 'center',
            minWidth: 200,
            renderCell: (params) => {
                return (
                    <Chip
                        label={
                            <Box display="flex" alignItems="center">
                                <Typography
                                    variant="body2"
                                    sx={{
                                        color: 'white',
                                        fontWeight: 'bold',
                                        marginRight: 0.5,
                                    }}>
                                    {params.value}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        color: 'white',
                                    }}>
                                    /{params.row.remaining_terms}
                                </Typography>
                            </Box>
                        }
                        sx={{
                            padding: '0 8px',
                            borderRadius: '16px',
                            backgroundColor: colors.calmWaters[500],
                        }}
                    />
                );
            },
        },
        {
            field: 'importe',
            headerName: 'Importe por Plazo',
            flex: 0.5,
            minWidth: 150,
            renderCell: (params) => {
                return (
                    <Box display="flex" alignItems="center" width="100%">
                        <Typography
                            variant="h6"
                            sx={{
                                color: colors.green[500],
                                fontWeight: 'bold',
                                fontSize: '1rem',
                            }}>
                            {`$${params.value}`}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: 'remaining',
            headerName: 'Restante',
            flex: 0.5,
            minWidth: 100,
            headerAlign: 'center',
            renderCell: (params) => {
                return (
                    <Typography
                        variant="h6"
                        sx={{
                            color: colors.red[500], // Cambiado a un color rojo
                            width: '100%',
                            fontWeight: 'bold',
                            fontSize: '1rem',
                        }}>
                        {`$${params.value}`}
                    </Typography>
                );
            },
        },
        {
            field: 'net_total',
            headerName: 'Total',
            flex: 0.5,
            headerAlign: 'center',
            minWidth: 100,
            renderCell: (params) => {
                return (
                    <Typography
                        variant="h6"
                        sx={{
                            textAlign: 'right',
                            width: '100%',
                            fontWeight: 'bold',
                            fontSize: '1rem',
                        }}>{`$${params.value}`}</Typography>
                );
            },
        },
    ];

    const filteredRows = datatable
        .filter((row) => row.status !== false)
        .map((row) => ({
            id: row.agreement_id,
            agreement_id: row.agreement_id,
            created_at: row.created_at,
            user: row.user,
            term_type: row.term_type,
            current_term: row.current_term,
            remaining_terms: row.term,
            net_total: row.concept?.net_total.toFixed(2),
            importe: (row.concept.net_total / row.term).toFixed(2),
            remaining: row.concept.remaining,
            next_payment_date: row.next_payment_date,
            date: row.created_at,
        }))
        .sort((a, b) => new Date(b.date) - new Date(a.date));

    return (
        <>
            <Card
                style={{
                    color: 'white',
                    borderRadius: '15px',
                    width: '100%',
                    height: '100%',
                    paddingTop: '10px',
                }}>
                <DataGrid
                    rows={filteredRows}
                    columns={columns}
                    rowHeight={80}
                    autoHeight
                    pageSize={15}
                    rowsPerPageOptions={[10, 25, 50]}
                    components={{
                        Toolbar: GridToolBar,
                        Pagination: CustomPagination,
                        NoRowsOverlay: NoDataOverlay,
                        NoResultsOverlay: NoDataOverlay,
                    }}
                    componentsProps={{
                        toolbar: {
                            onAddButtonClick: handleOpen,
                            ButtonText: 'Convenio',
                        },
                        noResultsOverlay: {
                            message:
                                'No se encontraron resultados para la búsqueda',
                        },
                        noRowsOverlay: {
                            message: 'No hay Convenios registrados',
                        },
                    }}
                    localeText={{
                        ...esES.components.MuiDataGrid.defaultProps.localeText,
                        toolbarColumns: '',
                        toolbarFilters: '',
                        toolbarDensity: '',
                        toolbarExport: '',
                    }}
                />
            </Card>

            <AgreementsModal open={open} onclose={handleClose} />
        </>
    );
};

export default AgreementsTable;
