import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, CardContent, Typography } from '@mui/material';
import {
    DataGrid,
    GridToolbar,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton,
    esES,
} from '@mui/x-data-grid';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectBestAverageGrade,
} from '../../../store/students/fetchSlice';
import { getBestAverageGrade } from '../../../store/students/thunks';
import { selectComponentStudentsBestAverageGradeStatusServer } from '../../../store/students/componentsStatusSelectors';
import Error from '../../../../components/Feedback/Error';
import { SkeletonStudentsSmallTables } from '../../../components/Skeleton';
import { Tag } from 'antd';
import _ from 'lodash';
import NoDataOverlay from '../../../../components/utilities/NoDataOverlay';
import { GridToolBar } from '../../../../components/utilities/GridToolBar';
import CustomPagination from '../../../../components/utilities/CustomPagination';

const BestAverageGrade = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentStudentsBestAverageGradeStatusServer
    );

    const bestAverageGrade = useFetchResource(
        () =>
            getBestAverageGrade({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectBestAverageGrade,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getBestAverageGrade({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            })
        );
    };

    const processData = (data) => {
        return _.orderBy(
            Object.entries(data).map(([key, value], index) => ({
                id: index + 1,
                grado: key,
                alumno: value.data[0],
                grupo: value.data[2],
                promedio: value.data[1],
            })),
            ['grado'],
            ['asc']
        );
    };

    const rows = processData(bestAverageGrade?.value || {});

    const columns = [
        {
            field: 'grado',
            headerName: 'Grado',
            flex: 0.3,
            renderCell: (params) => (
                <Box
                    sx={{
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                        lineHeight: '1.2',
                    }}
                >
                    {params.row.grado}
                </Box>
            ),
            headerClassName: 'bold-header',
        },
        {
            field: 'grupo',
            headerName: 'Grupo',
            flex: 0.6,
            renderCell: (params) => (
                <Box
                    sx={{
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                        lineHeight: '1.2',
                    }}
                >
                    {params.row.grupo}
                </Box>
            ),
        },
        {
            field: 'alumno',
            headerName: 'Alumnos',
            flex: 1,
            renderCell: (params) => (
                <Box
                    sx={{
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                        lineHeight: '1.2',
                    }}
                >
                    {params.row.alumno}
                </Box>
            ),
        },
        {
            field: 'promedio',
            headerName: 'Promedio',
            flex: 0.5,
            renderCell: (params) => (
                <Tag
                    color="success"
                    style={{
                        width: '100px',
                        textAlign: 'center',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                    }}
                >
                    {params.row.promedio}
                </Tag>
            ),
        },
    ];

    const CustomToolbar = (props) => {
        return (
            <GridToolbarContainer sx={{ justifyContent: 'flex-end' }}>
                <GridToolbarColumnsButton {...props} />
                <GridToolbarFilterButton {...props} />
                <GridToolbarExport
                    csvOptions={{
                        utf8WithBom: true,
                        delimiter: ',',
                    }}
                />
            </GridToolbarContainer>
        );
    };

    return (
        <Box>
            {fetchingStatus === 'pending' && (
                <SkeletonStudentsSmallTables items={9} />
            )}
            {fetchingStatus === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}
            {fetchingStatus === 'fulfilled' && (
                <Card
                    sx={{
                        mt: 5,
                        color: 'white',
                        borderRadius: '15px',
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <Typography
                        sx={{ ml: 2, mt: 1 }}
                        variant="h6"
                        color="textPrimary"
                        gutterBottom
                    >
                        {bestAverageGrade?.title ||
                            'Mejores Promedios por Grado'}
                    </Typography>
                    <DataGrid
                        rowHeight={70}
                        localeText={{
                            ...esES.components.MuiDataGrid.defaultProps
                                .localeText,
                            toolbarColumns: '',
                            toolbarFilters: '',
                            toolbarDensity: '',
                            toolbarExport: '',
                        }}
                        rows={rows}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[5]}
                        disableSelectionOnClick
                        autoHeight
                        components={{
                            NoRowsOverlay: NoDataOverlay,
                            NoResultsOverlay: NoDataOverlay,
                            Toolbar: GridToolBar,
                            Pagination: CustomPagination,
                        }}
                        disableDensitySelector
                        getRowClassName={(GridRowParams) => {
                            if (GridRowParams.row.status === 0) {
                                return `super-app-theme--disabled`;
                            }
                        }}
                        componentsProps={{
                            noResultsOverlay: {
                                message:
                                    'No se encontraron resultados para la búsqueda',
                            },
                            noRowsOverlay: {
                                message: 'No hay Alumnos registrados',
                            },
                        }}
                    />
                </Card>
            )}
        </Box>
    );
};

export default BestAverageGrade;
