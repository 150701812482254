import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

const CyclesAdapter = createEntityAdapter({
    selectId: (cycle) => cycle.cycle_id,
    sortComparer: (a, b) => a.cycle_id - b.cycle_id,
});

/**
 * Slice para los parciales
 */
export const CyclesSlice = createSlice({
    name: 'cycles',
    initialState: CyclesAdapter.getInitialState(),
    extraReducers: (builder) => {
        builder.addCase('app/clear', (state, action) => {
            return CyclesAdapter.getInitialState();
        });
    },
    reducers: {
        setAllCycles: CyclesAdapter.setAll,
        addManyCycles: CyclesAdapter.addMany,
        addOneCycles: CyclesAdapter.addOne,
        removeOneCycles: CyclesAdapter.removeOne,
        upsertOneCycles: CyclesAdapter.upsertOne,
        upsertManyCycles: CyclesAdapter.upsertMany,
        updateManyCycles: CyclesAdapter.updateMany,
    },
});

/**
 * Acciones generadas por la librerias
 */
export const {
    addManyCycles,
    removeOneCycles,
    upsertOneCycles,
    addOneCycles,
    upsertManyCycles,
    setAllCycles,
    updateManyCycles,
} = CyclesSlice.actions;

const globalizedSelectors = CyclesAdapter.getSelectors(
    (state) => state.entities.cycle
);

export const selectAllCycles = (state) => globalizedSelectors.selectAll(state);

export const selectCyclesById = (id) => (state) =>
    globalizedSelectors.selectById(state, id);

export const selectCyclesAsArray = () => (state) => selectAllCycles(state);

export default CyclesSlice.reducer;

//////////////////// SELECTORES //////////////////

export const selectCyclesBySchool = (school_id) => (state) => {
    let cycles = selectAllCycles(state);
    return cycles.filter((cycle) => cycle.school_id === school_id);
};

export const selectCyclesData = (state) => {
    let cycles = selectAllCycles(state);
    return cycles.map((cycle) => {
        return {
            ...cycle,
        };
    });
};
