import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { DataGrid, esES } from '@mui/x-data-grid';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import { getCycleSelected } from '../../../store/home/fetchSlice';
import { getReports } from '../../../store/groups/thunks';
import { selectReports } from '../../../store/groups/fetchSlice';
import { selectComponentGroupsReportsStatusServer } from '../../../store/groups/componentStatusSelectors';
import { SkeletonGroupsTables } from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';
import NoDataOverlay from '../../../../components/utilities/NoDataOverlay';
import { GridToolBar } from '../../../../components/utilities/GridToolBar';
import CustomPagination from '../../../../components/utilities/CustomPagination';

const ReportsTable = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentGroupsReportsStatusServer
    );
    const Reports = useFetchResource(
        () =>
            getReports({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectReports,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getReports({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
            selectReports,
            [cycleSelected, schoolId]
        );
    };

    const renderDataGrid = () => {
        const { value } = Reports || {};
        if (!value || typeof value !== 'object') return null;

        const headers = Object.keys(value);
        const data = value;

        const rows = headers.length
            ? data['Grupo']?.map((_, index) => {
                  const row = { id: index };
                  headers.forEach((header) => {
                      row[header] = data[header][index]?.label;
                  });
                  return row;
              }) || []
            : [];

        const columns = headers.map((header) => ({
            field: header,
            headerName: header,
            flex: 1,
        }));

        return (
            <Box sx={{ minHeight: 690, width: '100%', mt: 2 }}>
                <DataGrid
                    localeText={{
                        ...esES.components.MuiDataGrid.defaultProps.localeText,
                        toolbarColumns: '',
                        toolbarFilters: '',
                        toolbarDensity: '',
                        toolbarExport: '',
                    }}
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[]}
                    disableSelectionOnClick
                    disableDensitySelector
                    components={{
                        NoRowsOverlay: NoDataOverlay,
                        NoResultsOverlay: NoDataOverlay,
                        Toolbar: GridToolBar,
                        Pagination: CustomPagination,
                    }}
                    componentsProps={{
                        panel: {
                            placement: 'bottom-end',
                        },
                        noResultsOverlay: {
                            message:
                                'No se encontraron resultados para la búsqueda',
                        },
                        noRowsOverlay: {
                            message: 'No hay datos disponibles',
                        },
                    }}
                    getRowHeight={() => 'auto'}
                    sx={{
                        '& .MuiDataGrid-cell': {
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                            overflow: 'visible',
                            lineHeight: '2',
                        },
                        '&.MuiDataGrid-root': {
                            border: 'none',
                        },
                    }}
                    autoHeight
                />
            </Box>
        );
    };

    return (
        <Box sx={{ width: '100%', overflowX: 'hidden' }}>
            {fetchingStatus === 'pending' && <SkeletonGroupsTables items={9} />}

            {fetchingStatus === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {fetchingStatus === 'fulfilled' && (
                <Card
                    sx={{
                        background: '#ffffff',
                        borderRadius: '10px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        maxWidth: '100%',
                        margin: '20px auto',
                        padding: '16px',
                        overflow: 'visible',
                        position: 'relative',
                    }}>
                    <CardContent>
                        <Typography
                            variant="h6"
                            component="div"
                            gutterBottom
                            sx={{ color: '#333' }}>
                            {Reports?.title ||
                                'Reporte más frecuente por grupo'}
                        </Typography>
                        {renderDataGrid()}
                    </CardContent>
                </Card>
            )}
        </Box>
    );
};

export default ReportsTable;
