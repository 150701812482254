import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Card, CardContent, Tooltip } from '@mui/material';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectTotalStudentsApprovedFailed,
} from '../../../store/home/fetchSlice';
import { getTotalStudentsApprovedFailed } from '../../../store/home/thunks';
import { ResponsiveBar } from '@nivo/bar';
import {
    SkeletongGrafics,
    SkeletonLongCard,
} from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';
import { selectComponentHomeTotalStudentsApprovedFailedStatusServer } from '../../../store/home/componentStatusSelectors';
import { ErrorBoundary } from 'react-error-boundary';

const TotalStudentApprovedAndFailedGrafic = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentHomeTotalStudentsApprovedFailedStatusServer
    );

    const totalStudentApprovedFailed = useFetchResource(
        () =>
            getTotalStudentsApprovedFailed({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectTotalStudentsApprovedFailed,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getTotalStudentsApprovedFailed({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
            selectTotalStudentsApprovedFailed,
            [cycleSelected, schoolId]
        );
    };

    const data = totalStudentApprovedFailed?.value?.data
        ?.map((value, index) => ({
            groupType: totalStudentApprovedFailed.value.labels[index],
            total: value,
        }))
        .filter((item) => item.total !== null && item.total !== '');

    return (
        <ErrorBoundary>
            <Box>
                {fetchingStatus === 'pending' && <SkeletonLongCard items={9} />}

                {fetchingStatus === 'rejected' && (
                    <Error
                        onRetry={reload}
                        message={'Estamos teniendo problemas'}
                    />
                )}

                {fetchingStatus === 'fulfilled' && (
                    <Card
                        variant="outlined"
                        sx={{
                            borderRadius: '10px',
                            textAlign: 'center',
                            maxWidth: '400px',
                            minHeight: '213px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            boxShadow: 'none',
                            margin: 'auto',
                        }}
                    >
                        <CardContent
                            sx={{
                                width: '100%',
                                padding: '16px',
                            }}
                        >
                            <Typography variant="h6" gutterBottom>
                                {totalStudentApprovedFailed?.title ||
                                    'Número total de alumnos aprobados y reprobados en la escuela'}
                            </Typography>
                            {data && data.length > 0 ? (
                                <Box height={300}>
                                    <ResponsiveBar
                                        data={data}
                                        keys={['total']}
                                        indexBy="groupType"
                                        margin={{
                                            top: 20,
                                            right: 20,
                                            bottom: 60,
                                            left: 40,
                                        }}
                                        padding={0.5}
                                        layout="vertical"
                                        valueScale={{ type: 'linear' }}
                                        indexScale={{
                                            type: 'band',
                                            round: true,
                                        }}
                                        colors="#1E90FF"
                                        borderRadius={3}
                                        borderColor={{
                                            from: 'color',
                                            modifiers: [['darker', 1.6]],
                                        }}
                                        axisTop={null}
                                        axisRight={null}
                                        axisBottom={{
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 45,
                                            legend: '',
                                            legendPosition: 'middle',
                                            legendOffset: 32,
                                        }}
                                        axisLeft={{
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0,
                                            legendPosition: 'middle',
                                            legendOffset: -40,
                                        }}
                                        enableGridX={false}
                                        enableGridY={true}
                                        labelSkipWidth={12}
                                        labelSkipHeight={12}
                                        labelTextColor="white"
                                        layers={[
                                            'grid',
                                            'axes',
                                            'bars',
                                            'markers',
                                            'legends',
                                            (props) =>
                                                props.bars.map((bar) => (
                                                    <g
                                                        key={bar.key}
                                                        transform={`translate(${bar.x}, ${bar.y})`}
                                                    >
                                                        <rect
                                                            width={bar.width}
                                                            height={bar.height}
                                                            fill={bar.color}
                                                        />
                                                        {bar.data.value > 0 && (
                                                            <text
                                                                x={
                                                                    bar.width /
                                                                    2
                                                                }
                                                                y={-5}
                                                                textAnchor="middle"
                                                                style={{
                                                                    fill: 'black',
                                                                    fontSize: 12,
                                                                    fontWeight:
                                                                        'bold',
                                                                }}
                                                            >
                                                                {bar.data.value}
                                                            </text>
                                                        )}
                                                    </g>
                                                )),
                                        ]}
                                        animate={true}
                                        motionStiffness={90}
                                        motionDamping={15}
                                        theme={{
                                            axis: {
                                                ticks: {
                                                    text: { fontSize: 12 },
                                                },
                                            },
                                            labels: {
                                                text: {
                                                    fontSize: 16,
                                                    fill: 'white',
                                                },
                                            },
                                            tooltip: {
                                                container: {
                                                    background: 'white',
                                                    color: 'inherit',
                                                    fontSize: 12,
                                                    borderRadius: '2px',
                                                    boxShadow:
                                                        '0 1px 2px rgba(0, 0, 0, 0.25)',
                                                },
                                            },
                                        }}
                                    />
                                </Box>
                            ) : (
                                <Box sx={{ margin: 2, padding: 2 }}>
                                    <Typography variant="body2" gutterBottom>
                                        Sin datos disponibles
                                    </Typography>
                                </Box>
                            )}
                        </CardContent>
                    </Card>
                )}
            </Box>
        </ErrorBoundary>
    );
};

export default TotalStudentApprovedAndFailedGrafic;
