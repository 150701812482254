import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, CardContent, Typography } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectAssistanceIndex,
} from '../../../store/home/fetchSlice';
import { getAssistanceIndex } from '../../../store/home/thunks';
import { SkeletonCard } from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';
import { selectComponentHomeAssistanceIndexStatusServer } from '../../../store/home/componentStatusSelectors';
import { ErrorBoundary } from 'react-error-boundary';

const AssistanceIndexCard = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentHomeAssistanceIndexStatusServer
    );

    const assistanceIndex = useFetchResource(
        () =>
            getAssistanceIndex({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectAssistanceIndex,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getAssistanceIndex({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
            selectAssistanceIndex,
            [cycleSelected, schoolId]
        );
    };

    const attendanceValue = assistanceIndex?.value?.data?.[0] || 0;
    const absencesValue = assistanceIndex?.value?.data?.[1] || 0;

    return (
        <ErrorBoundary>
            <Box>
                {fetchingStatus === 'pending' && <SkeletonCard items={9} />}

                {fetchingStatus === 'rejected' && (
                    <Error
                        onRetry={reload}
                        message={'Estamos teniendo problemas'}
                    />
                )}

                {fetchingStatus === 'fulfilled' && (
                    <Card
                        style={{
                            backgroundColor: '#ff5722',
                            borderRadius: '10px',
                            textAlign: 'center',
                            maxWidth: '400px',
                            minHeight: '213px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            boxShadow: 'none',
                            margin: 'auto',
                        }}
                    >
                        <CardContent
                            style={{
                                padding: '16px',
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    mb: 2,
                                }}
                            >
                                <Box sx={{ textAlign: 'center', mr: 8 }}>
                                    <ArrowUpwardIcon
                                        sx={{
                                            fontSize: 40,
                                            color: 'rgb(230, 255, 250)',
                                        }}
                                    />
                                </Box>
                                <Box sx={{ textAlign: 'center' }}>
                                    <ArrowDownwardIcon
                                        sx={{
                                            fontSize: 40,
                                            color: 'rgb(253, 237, 232)',
                                        }}
                                    />
                                </Box>
                            </Box>
                            <Typography
                                variant="h6"
                                align="center"
                                style={{
                                    color: 'rgb(242, 242, 242)',
                                    marginBottom: '10px',
                                    whiteSpace: 'pre-wrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                {assistanceIndex?.title ||
                                    'Índice de asistencia'}
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    width: '100%',
                                }}
                            >
                                <Box sx={{ textAlign: 'center', flex: 1 }}>
                                    <Typography
                                        variant="h4"
                                        sx={{ color: 'rgb(230, 255, 250)' }}
                                    >
                                        {attendanceValue}%
                                    </Typography>
                                </Box>
                                <Box sx={{ textAlign: 'center', flex: 1 }}>
                                    <Typography
                                        variant="h4"
                                        sx={{ color: 'rgb(253, 237, 232)' }}
                                    >
                                        {absencesValue}%
                                    </Typography>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                )}
            </Box>
        </ErrorBoundary>
    );
};

export default AssistanceIndexCard;
