import React from 'react';
import { Box, Button } from '@mui/material';
import { Carousel } from 'antd';
import { NavLink } from 'react-router-dom';

// Assets
import check from '../../assets/check.svg';
import directivo from '../../assets/directivo.png';
import facebook from '../../assets/facebook.svg';
import instagram from '../../assets/instagram.svg';
import whatsapp from '../../assets/whatsapp.svg';
import whatsAppFloat from '../../assets/whatsAppFloat.svg';
import logoFooter from '../../assets/logo_footer.svg';
import palomita from '../../assets/palomita.svg';
import videos from '../../assets/videos.svg';
import youtube from '../../assets/youtube.svg';
import Yt01 from '../../assets/videos/Yt_01.jpg';
import Yt02 from '../../assets/videos/Yt_02.jpg';
import Yt03 from '../../assets/videos/Yt_03.jpg';
import Yt04 from '../../assets/videos/Yt_04.jpg';
import Yt05 from '../../assets/videos/Yt_05.jpg';
import maestrosapp from '../../assets/maestros_app.png';
import beneficiosmaestro from '../../assets/beneficios_maestro.png';
import beneficiospadres from '../../assets/beneficios_padres.png';
import padresapp from '../../assets/padres_app.png';
import orientadoresapp from '../../assets/orientadores_app.png';
import beneficiosorientador from '../../assets/beneficios_orientador.png';
import beneficiosdirector from '../../assets/beneficios_directivos.png';
import directivoapp from '../../assets/directivos_app.png';
import NavBar from '../../Components/NavBar';
import Downloads from '../../Components/Downloads';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

// Styles
// import '../style.scss';

const Beneficios = () => {
    const history = useHistory();

    const handleClick = () => {
        window.scrollTo(0, 0);
    };

    return (
        <Box>
            <NavBar />
            <div className="body">
                <div className="mweb beneficios">
                    <section className="sidebar">
                        <div className="container center">
                            <h1>
                                <img src={check} />
                                Beneficios
                            </h1>
                            <p>
                                Olvídate del tedioso papeleo y las horas
                                invertidas en tareas administrativas. Cura{' '}
                                <b>
                                    automatiza el registro de calificaciones y
                                    asistencias
                                </b>
                                , brindando a los maestros más tiempo para
                                enfocarse en lo que realmente importa:{' '}
                                <b>educar.</b> Los padres de familia disfrutarán
                                de una conexión más cercana con el{' '}
                                <b>progreso académico de sus hijos</b>,
                                recibiendo notificaciones importantes y acceso a
                                información en tiempo real.
                            </p>
                        </div>
                    </section>
                    <section>
                        <div id="maestro" className="maestro">
                            <div className="container">
                                <div className="des">
                                    <div className="inf">
                                        <h1>
                                            <img src={check} />
                                            Maestro
                                        </h1>
                                        <h2>Simplifica tus procesos</h2>
                                        <ul>
                                            <li>
                                                Registra calificaciones y
                                                asistencias de forma automática.
                                            </li>
                                            <li>
                                                Ahorra horas de papeleo con
                                                nuestro sistema de promedio
                                                instantáneo.
                                            </li>
                                            <li>
                                                Comparte información con colegas
                                                y directivos fácilmente.
                                            </li>
                                            <li>
                                                Concéntrate en enseñar, nosotros
                                                te ayudamos con el resto.
                                            </li>
                                        </ul>
                                    </div>
                                    <img className="app" src={maestrosapp} />
                                </div>
                            </div>
                            <div className="avatar">
                                <img src={beneficiosmaestro} />
                            </div>
                        </div>
                        <div id="padres" className="padres">
                            <div className="avatar">
                                <img src={beneficiospadres} />
                            </div>
                            <div className="container">
                                <div className="des">
                                    <div className="inf">
                                        <h1>
                                            <img src={check} />
                                            Padres
                                        </h1>
                                        <h2>
                                            Mantén el control de la educación de
                                            tus hijos
                                        </h2>
                                        <ul>
                                            <li>
                                                Accede instantáneamente a
                                                calificaciones y asistencias.
                                            </li>
                                            <li>
                                                Recibe notificaciones
                                                importantes de la escuela y
                                                maestros.
                                            </li>
                                            <li>
                                                Utiliza los datos y análisis
                                                para tomar decisiones sobre la
                                                educación y desarrollo de tus
                                                hijos.
                                            </li>
                                            <li>
                                                Participa en el aprendizaje de
                                                tus hijos al comprender sus
                                                áreas de interés y desafíos.
                                            </li>
                                        </ul>
                                    </div>
                                    <img className="app" src={padresapp} />
                                </div>
                            </div>
                        </div>
                        <div id="orientador" className="maestro orientador">
                            <div className="container">
                                <div className="des">
                                    <div className="inf">
                                        <h1>
                                            <img src={check} />
                                            Orientadores
                                        </h1>
                                        <h2>
                                            Sé el catalizador del cambio
                                            positivo en la educación
                                        </h2>
                                        <ul>
                                            <li>
                                                Recibe reportes de maestros
                                                sobre el comportamiento y
                                                desarrollo de los alumnos.
                                            </li>
                                            <li>
                                                Detecta y aborda problemas de
                                                comportamiento y bullying de
                                                manera proactiva.
                                            </li>
                                            <li>
                                                Facilita la comunicación con
                                                maestros y padres para
                                                intervenir a tiempo.{' '}
                                            </li>
                                            <li>
                                                Registra y rastrea el progreso
                                                emocional y social de los
                                                estudiantes.
                                            </li>
                                        </ul>
                                    </div>
                                    <img
                                        className="app"
                                        src={orientadoresapp}
                                    />
                                </div>
                            </div>
                            <div className="avatar">
                                <img src={beneficiosorientador} />
                            </div>
                        </div>
                        <div id="directivo" className="padres directivo">
                            <div className="avatar">
                                <img src={beneficiosdirector} />
                            </div>
                            <div className="container">
                                <div className="des">
                                    <div className="inf">
                                        <h1>
                                            <img src={check} />
                                            Directivos
                                        </h1>
                                        <h2>
                                            Mejora el control
                                            <br />
                                            de tu institución
                                        </h2>
                                        <ul>
                                            <li>
                                                Acceso panorámico al rendimiento
                                                escolar de todos los
                                                estudiantes.{' '}
                                            </li>
                                            <li>
                                                Análisis de datos en tiempo real
                                                para tomar decisiones
                                                informadas.{' '}
                                            </li>
                                            <li>
                                                Visualiza reportes y registros
                                                de toda la institución desde
                                                cualquier lugar.
                                            </li>
                                            <li>
                                                Facilita la colaboración
                                                efectiva entre el personal
                                                docente y directivo.
                                            </li>
                                        </ul>
                                    </div>
                                    <img className="app" src={directivoapp} />
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="videos" className="videos">
                        <div className="custom-container">
                            <h1>
                                <img src={videos} />
                                Videos
                            </h1>
                            <span>
                                ¡Descubre nuestro emocionante rincón de videos!
                            </span>
                            <div className="textos">
                                <p>
                                    Aquí conocerás la manera más sencilla de{' '}
                                    <b>descargar la aplicación "Cura"</b> y una
                                    breve introducción de cómo funciona,
                                    adaptada para cada uno de nuestros usuarios:
                                    Maestros, Padres de familia y Orientadores
                                    escolares.{' '}
                                </p>
                                <p>
                                    Sumérgete en una experiencia visual que te
                                    guiará <b>paso a paso en el proceso</b> de
                                    aprovechar al máximo todas las ventajas que
                                    Cura tiene para ofrecerte.
                                </p>
                            </div>
                            <div id="antd-demo" className="antd-carousel">
                                <Carousel
                                    slidesToShow={3}
                                    slidesToScroll={1}
                                    autoplay
                                    autoplaySpeed={3000}>
                                    <a
                                        href="https://www.youtube.com/watch?v=P32u1HMRyPI&ab_channel=CURAAPP"
                                        target="_blank">
                                        <img src={Yt01} alt="Imagen 1" />
                                    </a>
                                    <a
                                        href="https://www.youtube.com/watch?v=3DxRG9vlOnU&ab_channel=CURAAPP"
                                        target="_blank">
                                        <img src={Yt02} alt="Imagen 2" />
                                    </a>
                                    <a
                                        href="https://www.youtube.com/watch?v=YjlHvAl3BzM&ab_channel=CURAAPP"
                                        target="_blank">
                                        <img src={Yt03} alt="Imagen 3" />
                                    </a>
                                    <a
                                        href="https://www.youtube.com/watch?v=ZL-oh5R6LM4&ab_channel=CURAAPP"
                                        target="_blank">
                                        <img src={Yt04} alt="Imagen 4" />
                                    </a>
                                    <a
                                        href="https://www.youtube.com/watch?v=prSrVFlBtUY&ab_channel=CURAAPP"
                                        target="_blank">
                                        <img src={Yt05} alt="Imagen 5" />
                                    </a>
                                </Carousel>
                            </div>
                            <a
                                href="https://www.youtube.com/@cura-educacionefectiva-oficial"
                                target="_blank">
                                <img src={videos} />
                                ¡Suscríbete a nuestro canal y déjate sorprender
                                por el poder transformador de la educación en la
                                palma de tu mano!
                            </a>
                        </div>
                    </section>
                    <section className="descargas">
                        <Downloads />
                    </section>
                    <footer>
                        <img className="palomita" src={palomita} />
                        <div className="container">
                            <div className="grid cinco">
                                <div>
                                    <img src={logoFooter} />
                                    <p>
                                        Transformando la manera en que Maestros,
                                        Padres de familia y Directores gestionan
                                        la educación.
                                    </p>
                                </div>
                                <div>
                                    <img
                                        src={directivo}
                                        style={{ width: '230px' }}
                                    />
                                </div>
                                <div className="FBeneficios">
                                    <h4>Beneficios</h4>
                                    <ul>
                                        <li>
                                            <a href="#maestro">Maestros</a>
                                        </li>
                                        <li>
                                            <a href="#padres">Padres</a>
                                        </li>
                                        <li>
                                            <a href="#orientador">
                                                Orientadores
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#directivo">Directivos</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="FFuncionalidades">
                                    <h4>Funcionalidades</h4>
                                    <ul>
                                        <li>
                                            <NavLink
                                                to="/funcionalidades"
                                                onClick={handleClick}>
                                                <a>
                                                    Calificaciones y asistencias
                                                </a>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                to="/funcionalidades"
                                                onClick={handleClick}>
                                                <a>Promedio automático</a>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                to="/funcionalidades"
                                                onClick={handleClick}>
                                                <a>
                                                    Notificaciones para padres
                                                </a>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                to="/funcionalidades"
                                                onClick={handleClick}>
                                                <a>
                                                    Reportes de comportamiento
                                                </a>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <ul className="redes">
                                        <li>
                                            <a
                                                href="https://www.facebook.com/curaeducacion"
                                                target="_blank">
                                                <img src={facebook} />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.instagram.com/curaeducacion/"
                                                target="_blank">
                                                <img src={instagram} />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.youtube.com/@cura-educacionefectiva-oficial"
                                                target="_blank">
                                                <img src={youtube} />
                                            </a>
                                        </li>
                                        {/* <li>
                                            <a href="https://wa.me/5216181448945" target="_blank">
                                                <img src={whatsapp} />
                                            </a>
                                        </li> */}
                                    </ul>
                                    <a
                                        href="https://wa.me/5216181448945"
                                        target="_blank">
                                        <h2>
                                            <img src={whatsapp} /> 618 144 8945
                                        </h2>
                                    </a>
                                    <a
                                        href="https://wa.me/5216181448945"
                                        className="float-wa"
                                        target="_blank">
                                        <img
                                            src={whatsAppFloat}
                                            className="wa-icon"
                                        />
                                    </a>
                                    <h2>Email: curati2023@gmail.com</h2>
                                    <h2>Dirección</h2>
                                    <p className="dir">
                                        Blvd. Domingo Arrieta 1700, Gral Domingo
                                        Arrieta, CP 34180, Durango, Dgo., Mexico
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="bottom">
                            <Button
                                size="small"
                                sx={{
                                    color: '#ffffffbe',
                                    textDecoration: 'underline',
                                    fontWeight: 'bold',
                                    padding: '0',
                                }}
                                onClick={() => {
                                    history.push({
                                        pathname: '/TerminosCondiciones',
                                    });
                                }}>
                                Términos y condiciones
                            </Button>
                            <div className="container">
                                <span>
                                    Cura App ® Todos los Derechos Reservados •
                                    Durango, Dgo. México • 2023
                                </span>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </Box>
    );
};
export default Beneficios;
