import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Input, Avatar, Typography, Space, Button, Tooltip } from 'antd';
import { selectTopDebtors } from '../../../store/home/tops';
import femaleAvatar from '../../../assets/img/female_student.png';
import maleAvatar from '../../../assets/img/male_student.png';
import defaultStudent from '../../../assets/img/default_student.png';
import { Card } from '@mui/material';
import { ReactComponent as ExportIcon } from '../../../assets/icons/export-svgrepo-com.svg';

const { Search } = Input;

const DebtorTable = () => {
    const [searchText, setSearchText] = useState('');

    const topDebtorsData = useSelector(selectTopDebtors).value;

    const getAvatarSrc = (row) => {
        if (row.foto) {
            return row.foto;
        }
        return row.genders === 'H'
            ? maleAvatar
            : row.genders === 'M'
            ? femaleAvatar
            : defaultStudent;
    };

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('es-MX', {
            style: 'currency',
            currency: 'MXN',
        }).format(value);
    };

    const exportToCSV = () => {
        const header = [
            'Nombre',
            'Teléfono',
            'Importe',
            'Descuento',
            'Recargo',
            'Total a Pagar',
            'Pagado',
            'Adeudo',
        ];

        const rows = topDebtorsData.map((debtor) => [
            debtor.label,
            debtor.cellphone,
            formatCurrency(debtor.importe),
            formatCurrency(debtor.descuento),
            formatCurrency(debtor.recargo),
            formatCurrency(debtor.total_a_pagar),
            formatCurrency(debtor.pagado),
            formatCurrency(debtor.adeudo),
        ]);

        let csvContent =
            'data:text/csv;charset=utf-8,' +
            [header, ...rows].map((e) => e.join(',')).join('\n');

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'deudores.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const columns = [
        {
            title: 'Foto',
            dataIndex: 'foto',
            render: (text, record) => (
                <Avatar src={getAvatarSrc(record)} alt={record.label} />
            ),
            width: '10%',
        },
        {
            title: 'Nombre',
            dataIndex: 'label',
            width: '25%',
        },
        {
            title: 'Teléfono',
            dataIndex: 'cellphone',
            width: '15%',
        },
        {
            title: 'Importe',
            dataIndex: 'importe',
            render: (text) => formatCurrency(text),
            width: '15%',
        },
        {
            title: 'Descuento',
            dataIndex: 'descuento',
            render: (text) => formatCurrency(text),
            width: '15%',
        },
        {
            title: 'Recargo',
            dataIndex: 'recargo',
            render: (text) => formatCurrency(text),
            width: '15%',
        },
        {
            title: 'Total a Pagar',
            dataIndex: 'total_a_pagar',
            render: (text) => formatCurrency(text),
            width: '15%',
        },
        {
            title: 'Pagado',
            dataIndex: 'pagado',
            render: (text) => formatCurrency(text),
            width: '15%',
        },
        {
            title: 'Adeudo',
            dataIndex: 'adeudo',
            render: (text) => (
                <span style={{ color: text > 0 ? 'red' : 'inherit' }}>
                    {formatCurrency(text)}
                </span>
            ),
            width: '15%',
        },
    ];

    const rows = topDebtorsData.map((debtor, index) => ({
        key: index,
        ...debtor,
    }));

    const filteredData = rows.filter((debtor) =>
        debtor.label.toLowerCase().includes(searchText.toLowerCase())
    );

    return (
        <Card sx={{ width: '100%', height: '43em' }}>
            <Space
                style={{
                    marginBottom: 16,
                    width: '100%',
                    justifyContent: 'space-between',
                    padding: '16px',
                }}>
                <div>
                    <Typography.Title
                        level={4}
                        style={{
                            fontWeight: '800',
                            color: '#464646',
                            letterSpacing: '1px',
                        }}>
                        Últimos Deudores
                    </Typography.Title>
                </div>
                <div>
                    <Search
                        placeholder="Buscar deudores"
                        onChange={(e) => setSearchText(e.target.value)}
                        value={searchText}
                        style={{ width: 300 }}
                    />
                    <Tooltip title="Exportar a CSV">
                        <Button type="text" onClick={exportToCSV}>
                            <ExportIcon style={{ width: 25, height: 25 }} />
                        </Button>
                    </Tooltip>
                </div>
            </Space>

            <Table
                columns={columns}
                dataSource={filteredData}
                pagination={{ pageSize: 5 }}
                rowClassName="editable-row"
                scroll={{ x: 'max-content' }}
                onRow={(record, rowIndex) => {
                    return {
                        onMouseEnter: (event) => {
                            event.currentTarget.style.backgroundColor =
                                '#f0f5ff';
                        },
                        onMouseLeave: (event) => {
                            event.currentTarget.style.backgroundColor = '';
                        },
                    };
                }}
                summary={() => (
                    <Table.Summary>
                        <Table.Summary.Row className="total-row">
                            <Table.Summary.Cell colSpan={3}>
                                Total:
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                                {formatCurrency(
                                    filteredData.reduce(
                                        (sum, row) => sum + row.importe,
                                        0
                                    )
                                )}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                                {formatCurrency(
                                    filteredData.reduce(
                                        (sum, row) => sum + row.descuento,
                                        0
                                    )
                                )}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                                {formatCurrency(
                                    filteredData.reduce(
                                        (sum, row) => sum + row.recargo,
                                        0
                                    )
                                )}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                                {formatCurrency(
                                    filteredData.reduce(
                                        (sum, row) => sum + row.total_a_pagar,
                                        0
                                    )
                                )}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                                {formatCurrency(
                                    filteredData.reduce(
                                        (sum, row) => sum + row.pagado,
                                        0
                                    )
                                )}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                                {formatCurrency(
                                    filteredData.reduce(
                                        (sum, row) => sum + row.adeudo,
                                        0
                                    )
                                )}
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    </Table.Summary>
                )}
            />
        </Card>
    );
};

export default DebtorTable;
