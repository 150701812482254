import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Box, Card, CardContent, Tooltip } from '@mui/material';
import {
    DataGrid,
    esES,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { Tag } from 'antd';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectTotalReports,
} from '../../../store/professors/fetchSlice';
import { getTotalReports } from '../../../store/professors/thunks';
import { selectComponentProfessorsTotalReportsStatusServer } from '../../../store/professors/componentsStatusSelectors';
import { SkeletonProfessorsSmallTables } from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';
import _ from 'lodash';
import NoDataOverlay from '../../../../components/utilities/NoDataOverlay';
import { GridToolBar } from '../../../../components/utilities/GridToolBar';
import CustomPagination from '../../../../components/utilities/CustomPagination';

const TotalReports = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentProfessorsTotalReportsStatusServer
    );

    const totalReports = useFetchResource(
        () =>
            getTotalReports({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectTotalReports,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getTotalReports({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            })
        );
    };

    const [pageSize, setPageSize] = useState(10);

    const headers = totalReports.value ? Object.keys(totalReports.value) : [];
    const rows = totalReports.value
        ? totalReports.value[headers[0]].map((_, index) => {
              const row = { id: index + 1 };
              headers.forEach((header) => {
                  row[header] = totalReports.value[header][index]?.value;
              });
              return row;
          })
        : [];

    const columns = headers.map((header) => ({
        field: header,
        headerName: header,
        flex: 1,
        renderCell: (params) => {
            const value = params.value;

            if (header === 'Total de reportes') {
                return (
                    <Tag
                        color="error"
                        style={{
                            width: '100px',
                            textAlign: 'center',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}>
                        {value}
                    </Tag>
                );
            }
            return (
                <Box sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                    {value}
                </Box>
            );
        },
    }));

    const CustomToolbar = (props) => {
        return (
            <GridToolbarContainer sx={{ justifyContent: 'flex-end' }}>
                <GridToolbarColumnsButton {...props} />
                <GridToolbarFilterButton {...props} />
                <GridToolbarExport
                    csvOptions={{
                        utf8WithBom: true,
                        delimiter: ',',
                    }}
                />
            </GridToolbarContainer>
        );
    };

    return (
        <Box sx={{ width: '100%', overflowX: 'hidden' }}>
            {fetchingStatus === 'pending' && (
                <SkeletonProfessorsSmallTables items={9} />
            )}

            {fetchingStatus === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {fetchingStatus === 'fulfilled' && (
                <Card
                    sx={{
                        background: '#ffffff',
                        borderRadius: '10px',
                        boxShadow: 'none',
                        maxWidth: '100%',
                        margin: '20px auto',
                        padding: '16px',
                        overflow: 'visible',
                        position: 'relative',
                    }}>
                    <CardContent
                        sx={{
                            width: '100%',
                            padding: '24px',
                        }}>
                        <Tooltip
                            title={
                                totalReports?.description ||
                                'Total de reportes por profesor en la escuela'
                            }
                            followCursor>
                            <Typography
                                variant="h6"
                                color="textPrimary"
                                gutterBottom>
                                {totalReports?.title ||
                                    'Profesor con más reportes'}
                            </Typography>
                        </Tooltip>
                        <Box sx={{ height: 820, width: '100%' }}>
                            <DataGrid
                                localeText={{
                                    ...esES.components.MuiDataGrid.defaultProps
                                        .localeText,
                                    toolbarColumns: '',
                                    toolbarFilters: '',
                                    toolbarDensity: '',
                                    toolbarExport: '',
                                }}
                                rows={rows}
                                columns={columns}
                                pageSize={pageSize}
                                rowsPerPageOptions={[pageSize]}
                                disableDensitySelector
                                disableSelectionOnClick
                                components={{
                                    NoRowsOverlay: NoDataOverlay,
                                    NoResultsOverlay: NoDataOverlay,
                                    Toolbar: GridToolBar,
                                    Pagination: CustomPagination,
                                }}
                                componentsProps={{
                                    panel: {
                                        placement: 'bottom-end',
                                    },
                                    noResultsOverlay: {
                                        message:
                                            'No se encontraron resultados para la búsqueda',
                                    },
                                    noRowsOverlay: {
                                        message: 'No hay datos disponibles',
                                    },
                                }}
                                sx={{
                                    '&.MuiDataGrid-root': {
                                        border: 'none',
                                    },
                                    '& .MuiDataGrid-cell': {
                                        whiteSpace: 'normal',
                                        wordWrap: 'break-word',
                                    },
                                }}
                            />
                        </Box>
                    </CardContent>
                </Card>
            )}
        </Box>
    );
};

export default TotalReports;
