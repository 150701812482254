import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Card,
    Typography,
    CircularProgress,
    CardContent,
} from '@mui/material';
import { DataGrid, esES } from '@mui/x-data-grid';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectScoresExtracurricular,
} from '../../../store/groups/fetchSlice';
import { getScoresExtracurricular } from '../../../store/groups/thunks';
import { SkeletonGroupsTables } from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';
import { selectComponentGroupsScoresExtracurricularStatusServer } from '../../../store/groups/componentStatusSelectors';
import { Tag } from 'antd';
import NoDataOverlay from '../../../../components/utilities/NoDataOverlay';
import { GridToolBar } from '../../../../components/utilities/GridToolBar';
import CustomPagination from '../../../../components/utilities/CustomPagination';

const ScoresExtracurricular = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentGroupsScoresExtracurricularStatusServer
    );

    const scoresExtracurricular = useFetchResource(
        () =>
            getScoresExtracurricular({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectScoresExtracurricular,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getScoresExtracurricular({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            })
        );
    };

    // Generación dinámica de los datos
    const headers = scoresExtracurricular?.value
        ? Object.keys(scoresExtracurricular?.value)
        : [];
    const data = scoresExtracurricular?.value
        ? scoresExtracurricular?.value.Grado.map((_, index) => {
              const row = { id: index };
              headers.forEach((header) => {
                  row[header] =
                      scoresExtracurricular?.value[header]?.[index]?.value;
              });
              return row;
          }).sort((a, b) => b.promedio - a.promedio) // Ordenar por "promedio"
        : [];

    // Generación dinámica de las columnas
    const columns = headers.map((header) => ({
        field: header,
        headerName: header.charAt(0).toUpperCase() + header.slice(1),
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
            if (header === 'Promedio') {
                return (
                    <Tag
                        color="success"
                        style={{
                            width: '100px',
                            textAlign: 'center',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'normal',
                            wordBreak: 'break-word',
                        }}>
                        {params.value}
                    </Tag>
                );
            }
            return params.value;
        },
    }));

    return (
        <Box sx={{ width: '100%', overflowX: 'hidden' }}>
            {fetchingStatus === 'pending' && <SkeletonGroupsTables items={9} />}

            {fetchingStatus === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {fetchingStatus === 'fulfilled' && (
                <Card
                    sx={{
                        background: '#ffffff',
                        borderRadius: '10px',
                        boxShadow: 'none',
                        maxWidth: '100%',
                        margin: '20px auto',
                        padding: '16px',
                        overflow: 'visible',
                        position: 'relative',
                        mt: -4,
                    }}>
                    <CardContent sx={{ padding: '2px' }}>
                        {scoresExtracurricular?.loading ? (
                            <CircularProgress
                                sx={{
                                    margin: '20px',
                                    display: 'block',
                                    mx: 'auto',
                                }}
                            />
                        ) : scoresExtracurricular?.error ? (
                            <Typography
                                color="error"
                                sx={{ textAlign: 'center' }}>
                                Error: {scoresExtracurricular?.error.message}
                            </Typography>
                        ) : (
                            <Box sx={{ minHeight: 575, width: '100%' }}>
                                <DataGrid
                                    localeText={{
                                        ...esES.components.MuiDataGrid
                                            .defaultProps.localeText,
                                        toolbarColumns: '',
                                        toolbarFilters: '',
                                        toolbarDensity: '',
                                        toolbarExport: '',
                                    }}
                                    rows={data}
                                    columns={columns}
                                    pageSize={10}
                                    rowsPerPageOptions={[]}
                                    disableSelectionOnClick
                                    disableDensitySelector
                                    components={{
                                        NoRowsOverlay: NoDataOverlay,
                                        NoResultsOverlay: NoDataOverlay,
                                        Toolbar: GridToolBar,
                                        Pagination: CustomPagination,
                                    }}
                                    componentsProps={{
                                        panel: {
                                            placement: 'bottom-end',
                                        },
                                        noResultsOverlay: {
                                            message:
                                                'No se encontraron resultados para la búsqueda',
                                        },
                                        noRowsOverlay: {
                                            message: 'No hay datos disponibles',
                                        },
                                    }}
                                    getRowHeight={() => 'auto'}
                                    sx={{
                                        '& .MuiDataGrid-cell': {
                                            whiteSpace: 'normal',
                                            wordWrap: 'break-word',
                                            overflow: 'visible',
                                            lineHeight: '3',
                                        },
                                        '&.MuiDataGrid-root': {
                                            border: 'none',
                                        },
                                    }}
                                    autoHeight
                                />
                            </Box>
                        )}
                    </CardContent>
                </Card>
            )}
        </Box>
    );
};

export default ScoresExtracurricular;
