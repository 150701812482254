import React from 'react';
import Pagination from '@mui/material/Pagination';
import {
    GridPagination,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid';
import { gridPageSelector, gridPageCountSelector } from '@mui/x-data-grid';
import { Box, Typography } from '@mui/material';

const CustomPagination = ({ items = [], props }) => {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    function MuiPagination({ page, onPageChange, className }) {
        const apiRef = useGridApiContext();
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);

        return (
            <Pagination
                color="primary"
                className={className}
                count={pageCount}
                page={page + 1}
                onChange={(event, newPage) => {
                    onPageChange(event, newPage - 1);
                }}
                size="small"
                showFirstButton
                showLastButton
                boundaryCount={1}
                siblingCount={0}
            />
        );
    }

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                flexWrap: 'wrap',
                padding: '10px',
                gap: '10px',
                '@media (max-width: 758px)': {
                    flexDirection: 'column',
                    alignItems: 'center',
                },
            }}>
            <Box
                sx={{
                    display: 'flex',
                    gap: '10px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    '@media (max-width: 758px)': {
                        justifyContent: 'center',
                    },
                }}>
                {items.map((item, index) => (
                    <Box
                        key={index}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                        {item.icon ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: item.color,
                                }}>
                                {item.icon}
                            </Box>
                        ) : (
                            <Box
                                sx={{
                                    borderRadius: '50%',
                                    backgroundColor: item.color,
                                    width: '13px',
                                    height: '13px',
                                    marginRight: '5px',
                                }}
                            />
                        )}
                        <Typography
                            variant="caption"
                            color={item.color}
                            sx={{
                                fontWeight: 'bold',
                            }}>
                            {item.text}
                        </Typography>
                    </Box>
                ))}
            </Box>
            <Box
                sx={{
                    display: 'flex',

                    '@media (max-width: 600px)': {
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                    },
                }}>
                <GridPagination
                    sx={{
                        '& .css-levciy-MuiTablePagination-displayedRows': {
                            paddingTop: '13px',
                        },
                    }}
                    ActionsComponent={MuiPagination}
                    {...props}
                />
            </Box>
        </Box>
    );
};

export default CustomPagination;
