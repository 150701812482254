import React, { useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    Snackbar,
    Typography,
    Divider,
    Grid,
    Avatar,
    DialogContentText,
    Box,
    List,
    ListItemText,
    ListItem,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { LoadingButton } from '@mui/lab';
import { useAuth } from '../../../hooks';
import { selectSettingBySchoolId } from '../../../store/slices/entities/settings';
import { getLevelName, getTurnLevel } from '../../../libs/utils';
import {
    removeStudentsFromGroup,
    selectRemoveGroupSubjectOperationStatus,
} from '../../../store/slices/groupsUI/operationsSlice';
import { selectGroupsById } from '../../../store/slices/entities/groups';
import ReportIcon from '@mui/icons-material/Report';

export default ({ open, setOpen, onDeleted, groupId, selectedStudents }) => {
    const dispatch = useDispatch();
    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id;

    //////////// LOCAL STATES /////////////

    const [loading, setLoading] = useState(false);

    //////////// SELECTORES /////////////

    const group = useSelector(selectGroupsById(groupId));
    const config = useSelector(selectSettingBySchoolId(schoolId));
    const statusOperation = useSelector(
        selectRemoveGroupSubjectOperationStatus
    );

    let titleBarBackground = config.find(
        (res) => res.key === 'theme-color-title-bar'
    );
    let fontColor = config.find((res) => res.key === 'theme-color-font');

    //////////////////// FUNCIONES /////////////////

    const handleClose = () => {
        setOpen(false);
    };

    const enviarDatos = () => {
        setLoading(true);
        if (!Array.isArray(selectedStudents) || selectedStudents.length === 0) {
            showFeedback('No se han seleccionado estudiantes para eliminar.');
            return;
        }

        dispatch(
            removeStudentsFromGroup({
                groupId,
                students: selectedStudents,
            })
        )
            .unwrap()
            .then((data) => {
                onDeleted(data);
                setLoading(false);
                setOpen(false);
            })
            .catch(({ feedback }) => {
                setLoading(false);
                showFeedback(feedback?.title);
            });
    };

    const showFeedback = (message) => {
        setFeedbackMessage(message);
        setOpenFeedback(true);
    };

    const closeFeedback = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenFeedback(false);
    };

    const [openFeedback, setOpenFeedback] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState('');

    const studentsDetails = selectedStudents
        .map((student) => {
            return student
                ? {
                      name: `${student.name} ${student.last_name} ${student.second_last_name}`,
                      folio: student.folio,
                      curp: student.curp,
                      grade: group?.grade,
                      group: group?.group,
                      level: getLevelName(group?.level),
                      turn: getTurnLevel(group?.turn),
                      photo: student.url_photo_profile,
                  }
                : null;
        })
        .filter((item) => item);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={'xs'}
            fullWidth={true}
            sx={{
                '& .MuiDialog-paper': {
                    borderRadius: 5,
                },
            }}>
            <DialogTitle
                id="alert-dialog-title"
                style={{
                    backgroundColor: '#f44336',
                    textAlign: 'center',
                    fontSize: 20,
                }}
            />

            <DialogContent
                sx={{
                    padding: 0,
                }}>
                <DialogTitle
                    sx={{
                        padding: 2,
                    }}>
                    <Typography
                        variant="h6"
                        sx={{
                            color: '#f44336',
                            fontWeight: 'bold',
                        }}>
                        Estas seguro?
                    </Typography>
                </DialogTitle>
                <DialogContentText
                    sx={{
                        backgroundColor: '#fff3cd',
                        color: '#856404',
                        padding: 2,
                    }}>
                    <Typography variant="body1" sx={{ fontSize: 16 }}>
                        Recuerda que esta acción no se puede deshacer y
                        eliminará los Alumn@s seleccionados del grupo.
                    </Typography>
                </DialogContentText>
                <Box
                    display="flex"
                    alignItems="center"
                    sx={{
                        padding: 2,
                    }}>
                    <DialogContentText
                        id="alert-dialog-description"
                        sx={{ textAlign: 'center' }}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                            <ReportIcon
                                sx={{
                                    color: '#f44336',
                                    fontSize: '3rem',
                                }}
                            />
                            <Typography variant="body1" sx={{ fontSize: 16 }}>
                                Los siguientes Alumn@s serán eliminados del
                                grupo:
                            </Typography>
                        </Box>
                    </DialogContentText>
                </Box>
            </DialogContent>
            <DialogContent>
                <Grid container spacing={2} sx={{ textAlign: 'center' }}>
                    <List
                        sx={{
                            maxHeight: 300,
                            overflowY: 'auto',
                            marginTop: 2,
                            width: '100%',
                        }}>
                        {studentsDetails.length === 0 ? (
                            <Typography variant="body2" color="textSecondary">
                                No hay estudiantes seleccionados
                            </Typography>
                        ) : (
                            studentsDetails.map((student, index) => (
                                <React.Fragment key={index}>
                                    <ListItem>
                                        <Avatar
                                            src={student.photo}
                                            alt={student.name}
                                            sx={{
                                                width: 30,
                                                height: 30,
                                                marginBottom: 2,
                                                marginLeft: 2,
                                                marginRight: 2,
                                            }}
                                        />
                                        <Typography variant="h6"></Typography>
                                        <Divider
                                            sx={{
                                                marginTop: 1,
                                                marginBottom: 1,
                                            }}
                                        />

                                        <ListItemText
                                            primary={
                                                <Typography variant="body2">
                                                    {student.name}
                                                </Typography>
                                            }
                                            secondary={
                                                <>
                                                    <Typography
                                                        variant="body2"
                                                        color="textSecondary">
                                                        Folio: {student.folio}
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        color="textSecondary">
                                                        CURP: {student.curp}
                                                    </Typography>
                                                </>
                                            }
                                        />
                                    </ListItem>
                                    <Divider />
                                </React.Fragment>
                            ))
                        )}
                    </List>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Box sx={{ mb: 2 }}>
                    <LoadingButton
                        variant="contained"
                        fullWidth
                        size="small"
                        color="error"
                        onClick={enviarDatos}
                        loading={loading}
                        loadingPosition="start"
                        startIcon={<DeleteOutlineIcon />}>
                        Eliminar
                    </LoadingButton>
                </Box>
                <Box sx={{ mb: 2 }}>
                    <Button
                        onClick={handleClose}
                        autoFocus
                        size="small"
                        color="error"
                        disabled={loading}>
                        Cancelar
                    </Button>
                </Box>
            </DialogActions>
            <Snackbar
                open={openFeedback}
                autoHideDuration={2500}
                onClose={closeFeedback}
                message={feedbackMessage}
            />
        </Dialog>
    );
};
