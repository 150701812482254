import { createSelector, createSlice } from '@reduxjs/toolkit';
import { selectSubjectByid } from '../subjects';

const emptyState = {
    byId: {},
};

/**
 * Slice para los parciales
 */
export const SubjectcatalogsSlice = createSlice({
    name: 'subjectcatalogs',
    initialState: emptyState,
    reducers: {
        /**
         * Remplazar todos los parciales
         *
         * @param {*} state
         * @param {*} action
         */
        setSubjectcatalogs: (state, action) => {
            state.list = action.payload;
        },
        /**
         * Agrega parciales a la coleccion
         *
         * @param {*} state
         * @param {*} action
         */
        addManySubjectcatalogs: (state, action) => {
            state.byId = { ...state.byId, ...action.payload };
        },
        /**
         * Agrega un parcial a la coleccio
         *
         * @param {*} state
         * @param {*} action
         */
        addOneSubjectCatalog: (state, action) => {
            if (!state.byId[action.payload.catalog_subject_id]) {
                state.byId[action.payload.catalog_subject_id] = action.payload;
            }
        },
        updateSubjectcatalog: (state, action) => {
            const {
                partial_id,
                partial,
                start_date,
                end_date,
                limit_date,
                level,
            } = action.payload;
            // find this todo in the list
            const parcial = state.list.find((t) => t.partial_id === partial_id);
            // update that todo
            parcial.partial = partial;
            parcial.start_date = start_date;
            parcial.end_date = end_date;
            parcial.limit_date = limit_date;
            parcial.level = level;
            // note: .find() might return undefined, so you may want to handle that
        },
        /**
         * Elimina un parcial de la coleccion
         * @param {*} state
         * @param {*} action
         */
        deleteOneSubjectcatalogs: (state, action) => {
            delete state.byId[action.payload.catalog_subject_id];
        },
        /**
         * Si el elemento existe realziara una actualziacion superficial
         * y los campos seran fucionados, si el elemento no esta precente este
         * sera agregado a las entidades
         *
         * @param {*} state
         * @param {*} action
         */
        upsertOneSubjectcatalog: (state, action) => {
            let { catalog_subject_id } = action.payload;

            state.byId[catalog_subject_id] = {
                ...state.byId[catalog_subject_id],
                ...action.payload,
            };
        },
        /**
         * Si el elemento existe realizara una actualziacion superficial
         * y los campos seran fucionados, si el elemento no esta precente este
         * sera agregado a las entidades
         *
         * @param {*} state
         * @param {*} action
         */
        upsertManySubjectcatalog: (state, action) => {
            for (const user of action.payload) {
                let { catalog_subject_id } = user;

                state.byId[catalog_subject_id] = {
                    ...state.byId[catalog_subject_id],
                    ...user,
                };
            }
        },
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return emptyState;
        });
    },
});

/**
 * Acciones generadas por la librerias
 */
export const {
    setSubjectcatalogs,
    addManySubjectcatalogs,
    deleteOneSubjectcatalog,
    updateSubjectcatalogs,
    selectSingleSubjectcatalogs,
    addOneSubjectCatalog,
    upsertOneSubjectcatalog,
    upsertManySubjectcatalog,
} = SubjectcatalogsSlice.actions;

//////////////////// SELECTORES //////////////////

/**
 * Selector para recuperar todas la entidades
 *
 * @param {*} state
 * @returns
 */
export const selectEntitiesSubjectcatalogs = (state) =>
    state.entities.subjectcatalogs.byId;

/**
 * Selector para recuperar todo el catalogo de materias
 *
 * @param {*} state
 * @returns
 */
export const selectAllSubjectcatalogs = (state) =>
    Object.values(selectEntitiesSubjectcatalogs(state));

/**
 * Selector para recuperar parciales de una escuela especifica
 *
 * @param {*} schoolId
 * @returns
 */

export const selectSubjectCatalogByID = (catalogSubjectId) => {
    return (state) => {
        let data = state.entities.subjectcatalogs.byId[catalogSubjectId];

        return data;
    };
};

/**
 * Selector para recuperar parciales de una escuela especifica
 *
 * @param {*} schoolId
 * @returns
 */

export const selectSubjectcatalogsByLevel = (level) => {
    return (state) => {
        let data = Object.values(state.entities.subjectcatalogs.byId).filter(
            (i) => i.level == level
        );

        return data;
    };
};

/**
 * Selector para recuperar catlogo de materias como array
 *
 * @param {*} schoolId
 * @returns
 */

export const selectSubjectsCatalogAsArray = () => {
    return (state) => {
        let data = Object.values(state.entities.subjectcatalogs.byId);

        return data;
    };
};

export const selectSubjectCatalogBySubject = (subjectId) =>
    createSelector(
        selectSubjectByid(subjectId),
        selectEntitiesSubjectcatalogs,
        (subject, subjectCatalogEntities) => {
            return subjectCatalogEntities[subject.catalog_subject_id];
        }
    );

export const selectSubjectCatalogsBySubjects = (subjectIds) =>
    createSelector(
        (state) => subjectIds.map((id) => selectSubjectByid(id)(state)), // Mapeamos para obtener los subjects
        selectEntitiesSubjectcatalogs,
        (subjects, subjectCatalogEntities) => {
            // Devolvemos los subjectCatalogs correspondientes
            return subjects.map(
                (subject) => subjectCatalogEntities[subject.catalog_subject_id]
            );
        }
    );

export default SubjectcatalogsSlice.reducer;
