import { useSelector } from 'react-redux';
import useThemeColors from './themes';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import { useMemo } from 'react';

function reverseTokens(tokens) {
    const reversedTokens = {};
    Object.entries(tokens).forEach(([key, val]) => {
        const keys = Object.keys(val);
        const values = Object.values(val);
        const length = keys.length;
        const reversedObj = {};
        for (let i = 0; i < length; i++) {
            reversedObj[keys[i]] = values[length - i - 1];
        }
        reversedTokens[key] = reversedObj;
    });
    return reversedTokens;
}

const createThemeSettings = (mode, tokensDark, tokensLight) => {
    const applyStyles = (currentMode, styles) => {
        if (currentMode === mode) {
            return styles;
        }
        return {};
    };

    return {
        palette: {
            mode,
            ...(mode === 'dark'
                ? {
                      background: {
                          default: '#28243d',
                          paper: '#28243d',
                      },
                      neutral: {
                          main: tokensDark.neutral[100],
                      },
                  }
                : {
                      background: {
                          default: '#ffffff',
                          paper: '#ffffff',
                      },
                      neutral: {
                          main: tokensLight.neutral[800],
                      },
                  }),
        },

        components: {
            MuiDataGrid: {
                styleOverrides: {
                    root: {
                        border: 0,
                        WebkitFontSmoothing: 'auto',
                        letterSpacing: 'normal',
                        '& .MuiDataGrid-columnsContainer': {
                            backgroundColor: '#1d1d1d',
                            ...applyStyles('light', {
                                backgroundColor: '#fafafa',
                                color: '#000000ff',
                            }),
                        },
                        '& .MuiDataGrid-columnHeadersInner': {
                            background: '#dedede44',
                        },
                        '& .MuiDataGrid-columnHeaderRow': {
                            backgroundColor: '#1d1d1d',
                        },
                        '& .MuiDataGrid-iconSeparator': {
                            display: 'none',
                        },

                        '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
                            borderBottom: '1px solid #303030',
                            ...applyStyles('light', {
                                borderBottomColor: '#f0f0f0',
                            }),
                        },
                        '& .MuiDataGrid-cell': {
                            color: 'rgba(255,255,255,0.65)',
                            ...applyStyles('light', {
                                color: 'rgba(0,0,0,.85)',
                            }),
                        },
                    },
                },
            },
            MuiCheckbox: {
                styleOverrides: {
                    root: {},
                },
            },
        },
    };
};

const ThemeProviderWrapper = ({ children }) => {
    const mode = useSelector((state) => state.theme.mode);
    const tokensDark = useThemeColors();
    const tokensLight = reverseTokens(tokensDark);

    const theme = useMemo(
        () => createTheme(createThemeSettings(mode, tokensDark, tokensLight)),
        [mode, tokensDark, tokensLight]
    );

    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default ThemeProviderWrapper;
