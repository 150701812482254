import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Card, CardContent, Divider } from '@mui/material';
import {
    DataGrid,
    esES,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarExport,
    GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectAllIncidents,
} from '../../../store/home/fetchSlice';
import { getAllIncidents } from '../../../store/home/thunks';
import { SkeletongGrafics } from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';
import { selectComponentHomeAllIncidentsStatusServer } from '../../../store/home/componentStatusSelectors';
import { ErrorBoundary } from 'react-error-boundary';
import NoDataOverlay from '../../../../components/utilities/NoDataOverlay';
import { GridToolBar } from '../../../../components/utilities/GridToolBar';
import CustomPagination from '../../../../components/utilities/CustomPagination';
import Header from '../../../../components/utilities/Header';

const AllIncidents = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentHomeAllIncidentsStatusServer
    );

    const allIncidents = useFetchResource(
        () =>
            getAllIncidents({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectAllIncidents,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getAllIncidents({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            })
        );
    };

    const headers = allIncidents.value ? Object.keys(allIncidents.value) : [];
    const data = allIncidents.value || {};

    const rows = headers.length
        ? data[headers[0]]?.map((_, index) => {
              const row = { id: index };
              headers.forEach((header) => {
                  row[header] = data[header][index]?.value;
              });
              return row;
          }) || []
        : [];

    const columns = headers.map((header) => ({
        field: header,
        headerName: header,
        flex: header === 'Descripción' ? 2 : 1,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
            if (header === 'Estatus') {
                return (
                    <span
                        style={{
                            color:
                                params.value === 'ATENDIDO' ? 'green' : 'red',
                        }}
                    >
                        {params.value}
                    </span>
                );
            }
            if (header === 'Fecha de reporte') {
                const date = new Date(params.value);
                return date.toLocaleDateString('es-ES');
            }
            return params.value;
        },
    }));

    const sortRowsByDate = (rows) => {
        return rows.sort((a, b) => {
            const dateA = new Date(a['Fecha de reporte']);
            const dateB = new Date(b['Fecha de reporte']);
            return dateB - dateA;
        });
    };

    const sortedRows = sortRowsByDate(rows);

    const CustomToolbar = (props) => (
        <GridToolbarContainer sx={{ justifyContent: 'flex-end' }}>
            <GridToolbarColumnsButton {...props} />
            <GridToolbarFilterButton {...props} />
            <GridToolbarExport
                csvOptions={{
                    utf8WithBom: true,
                    delimiter: ',',
                }}
            />
        </GridToolbarContainer>
    );

    return (
        <ErrorBoundary>
            <Box>
                {fetchingStatus === 'pending' && <SkeletongGrafics items={9} />}

                {fetchingStatus === 'rejected' && (
                    <Error
                        onRetry={reload}
                        message={'Estamos teniendo problemas'}
                    />
                )}

                {fetchingStatus === 'fulfilled' && (
                    <Card
                        variant="outlined"
                        sx={{
                            borderRadius: 4,
                            boxShadow: 2,
                            width: '100%',
                            overflow: 'hidden',
                        }}
                    >
                        <CardContent sx={{ padding: 3 }}>
                            <Typography variant="h6" sx={{ marginBottom: 2 }}>
                                {allIncidents?.title || 'Incidentes'}
                            </Typography>
                            {sortedRows.length === 0 ? (
                                <Typography
                                    variant="h5"
                                    sx={{ textAlign: 'center' }}
                                >
                                    Información no disponible
                                </Typography>
                            ) : (
                                <Box sx={{ width: '100%' }}>
                                    <DataGrid
                                        localeText={
                                            esES.components.MuiDataGrid
                                                .defaultProps.localeText
                                        }
                                        rows={rows}
                                        columns={columns}
                                        pageSize={10}
                                        rowsPerPageOptions={[]}
                                        disableSelectionOnClick
                                        disableDensitySelector
                                        components={{
                                            NoRowsOverlay: NoDataOverlay,
                                            NoResultsOverlay: NoDataOverlay,
                                            Toolbar: GridToolBar,
                                            Pagination: CustomPagination,
                                        }}
                                        componentsProps={{
                                            panel: {
                                                placement: 'bottom-end',
                                            },
                                            noResultsOverlay: {
                                                message:
                                                    'No se encontraron resultados para la búsqueda',
                                            },
                                            noRowsOverlay: {
                                                message:
                                                    'No hay datos disponibles',
                                            },
                                        }}
                                        getRowHeight={() => 'auto'}
                                        sx={{
                                            '& .MuiDataGrid-cell': {
                                                whiteSpace: 'normal',
                                                wordWrap: 'break-word',
                                                overflow: 'visible',
                                                lineHeight: '1.5',
                                            },
                                            '&.MuiDataGrid-root': {
                                                border: 'none',
                                            },
                                        }}
                                        autoHeight
                                    />
                                </Box>
                            )}
                        </CardContent>
                    </Card>
                )}
            </Box>
        </ErrorBoundary>
    );
};

export default AllIncidents;
