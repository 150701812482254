import {
    createAsyncThunk,
    createSelector,
    createSlice,
} from '@reduxjs/toolkit';
import Connection from '../../../service/Connection';
import Feedback from '../../../service/Feedback';
import { selectPaymentAgreements } from './selectors';
import {
    setAllAgreements,
    upsertManyAgreements,
} from '../../../store/slices/entities/payments/agreements';
import { upsertManyPayments } from '../../../store/slices/entities/payments/income';
import { upsertManyCataloConcepts } from '../../../store/slices/entities/payments/cat_concepts';
import { upsertManyUsers } from '../../../store/slices/entities/users';
import { upsertManyConcepts } from '../../../store/slices/entities/payments/concepts';
import { upsertManyStudents } from '../../../store/slices/entities/students';
import { upsertManyPartialities } from '../../../store/slices/entities/payments/partialities';
import { PAYMENTS_HOME_EXPIRE_TIME } from '../../../service/const';
import Services from '../../../service/Connection';
import { emptyState } from '../emptyState';

////////////////////////////////////// SLICE //////////////////////////////////////
const AgreementsSlice = createSlice({
    name: 'agreementsUI/Server',
    initialState: emptyState,
    reducers: {
        // Acción para invalidar los datos actuales
        invalidate: (state) => {
            state.didInvalidate = true;
        },
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return emptyState;
        });

        const pendingServerStatus = (state, action) => {
            state.statusServer = 'pending';
        };

        /**
         * Termina la carga de informacion
         */

        builder.addCase(loadUI.fulfilled, (state, action) => {
            state.expireIn = new Date().setMinutes(
                new Date().getMinutes() + PAYMENTS_HOME_EXPIRE_TIME
            );
            state.ferchingAt = Date.now();
            state.didInvalidate = false;

            state.statusServer = 'fulfilled';
        });
        builder.addCase(loadUI.pending, pendingServerStatus);
        builder.addCase(loadUI.rejected, (state, action) => {
            state.statusServer = 'rejected';
            state.feedback = action.payload.feedback;
        });
    },
});

export const { invalidate } = AgreementsSlice.actions;

export default AgreementsSlice.reducer;

////////////////////////////////////// THUNKS // ////////////////////////////////////

/**
 * Cargar informacion de la UI de Pagos
 */

export const loadUI = createAsyncThunk(
    'AgreementsUI/server/fetch/data',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback();
        try {
            const payments = await Services.getPaymentsBySchool(schoolId).then(
                (res) => res.data.data
            );

            const agreements = await Services.getAgreementBySchool(
                schoolId
            ).then((res) => res.data.data);

            const concepts = await Services.getConceptsBySchool(schoolId).then(
                (res) => res.data.data
            );

            const catConcepts = await Services.getSchoolsByConceptsCatalog(
                schoolId
            ).then((res) => res.data.data);

            const students = await Services.StudentsBySchool(schoolId).then(
                (res) => res.data.data
            );

            thunkAPI.dispatch(upsertManyAgreements(agreements));
            thunkAPI.dispatch(upsertManyPayments(payments));
            thunkAPI.dispatch(upsertManyCataloConcepts(catConcepts));
            thunkAPI.dispatch(upsertManyConcepts(concepts));
            thunkAPI.dispatch(upsertManyStudents(students));

            return {
                agreements,
                payments,
                concepts,
                catConcepts,
                students,
            };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    },
    {
        // Condición para ejecutar el thunk solo si es necesario
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } = selectAgreementsFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const FetchCreate = createAsyncThunk(
    'agreements/fetch/create',

    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            const [fetchConcepts, fetchStudents] = await Promise.all([
                Connection.getConceptsBySchool(schoolId),
                Connection.StudentsBySchool(schoolId),
            ]);

            const concepts = fetchConcepts.data.data;
            const students = fetchStudents.data.data;

            thunkAPI.dispatch(upsertManyStudents(students));
            thunkAPI.dispatch(upsertManyConcepts(concepts));

            return { concepts, students };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

//////////////////////////////////// SELECTORES ////////////////////////////////////
/**
 * Selector para recuperar el estado de la petición de convenios de pago
 */
export const selectAgreementsFetch = createSelector(
    selectPaymentAgreements,
    (state) => state.fetch
);
/**
 * Selector para recuperar el estado del servidor
 */
export const selectStatusServer = createSelector(
    selectPaymentAgreements,
    (state) => state.fetch.statusServer
);
