import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Feedback from '../../../service/Feedback';
import { createSelector } from '@mui/x-data-grid/utils/createSelector';
import { selectPaymentConcepts, selectPaymentDiscounts } from './selectors';
import Connection from '../../../service/Connection';
import {
    upsertManyCatalogDiscounts,
    upsertOneCatalogDiscount,
} from '../../../store/slices/entities/payments/cat_discounts';
import { upsertManyCataloConcepts } from '../../../store/slices/entities/payments/cat_concepts';
import { upsertManyStudents } from '../../../store/slices/entities/students';
import {
    removeManyConcepts,
    upsertManyConcepts,
} from '../../../store/slices/entities/payments/concepts';
import { upsertManyGroup } from '../../../store/slices/entities/groups';
import {
    removeManyDiscounts,
    upsertManyDiscounts,
} from '../../../store/slices/entities/payments/discounts';

const emptyState = {
    create: {
        expireIn: null,
        ferchingAt: null,
        status: 'idle',
        didInvalidate: true,
    },
    delete: {
        expireIn: null,
        ferchingAt: null,
        status: 'idle',
        didInvalidate: true,
    },
    update: {
        expireIn: null,
        ferchingAt: null,
        status: 'idle',
        didInvalidate: true,
    },
};

export const operationsSlice = createSlice({
    name: 'operations/discounts',
    initialState: emptyState,
    reducers: {},

    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', () => {
            return emptyState;
        });

        // Handle CreateConcept actions
        builder.addCase(createDiscount.rejected, (state, action) => {
            state.create.status = 'rejected';
            state.create.feedback = action.payload.feedback;
        });
        builder.addCase(createDiscount.fulfilled, (state) => {
            state.create.status = 'fulfilled';
        });
        builder.addCase(createDiscount.pending, (state) => {
            state.create.status = 'pending';
        });

        //handle DeleteConcept actions
        builder.addCase(DeleteDiscounts.rejected, (state, action) => {
            state.delete.status = 'rejected';
            state.delete.feedback = action.payload.feedback;
        });
        builder.addCase(DeleteDiscounts.fulfilled, (state) => {
            state.delete.status = 'fulfilled';
        });
        builder.addCase(DeleteDiscounts.pending, (state) => {
            state.delete.status = 'pending';
        });

        // Handle UpdateConcept actions
        builder.addCase(UpdateDiscounts.rejected, (state, action) => {
            state.update.status = 'rejected';
            state.update.feedback = action.payload.feedback;
        });
        builder.addCase(UpdateDiscounts.fulfilled, (state) => {
            state.update.status = 'fulfilled';
        });
        builder.addCase(UpdateDiscounts.pending, (state) => {
            state.update.status = 'pending';
        });
    },
});

//export const {} = operationsSlice.actions;

export default operationsSlice.reducer;

export const selectOperations = createSelector(
    selectPaymentDiscounts,
    (reports) => reports.operations
);

export const selectCreate = createSelector(
    selectOperations,
    (operations) => operations.create
);

export const selectCreateOperation = createSelector(
    selectCreate,
    (create) => create.status
);

export const selectStatusOperation = (state) =>
    state.createConcepts.operations.create.statusOperation;

/**
 * Agregar un concepto
 */

export const createDiscount = createAsyncThunk(
    'Concepts/createDiscount',
    async ({ data, schoolId }, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            const response = await Connection.addDiscount({ data });

            const Discounts = response.data.data;

            const responseConcepts =
                await Connection.getConceptsBySchool(schoolId);

            const Concepts = responseConcepts.data.data;

            thunkAPI.dispatch(upsertManyConcepts(Concepts));

            thunkAPI.dispatch(upsertManyDiscounts(Discounts));

            return { Discounts };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Eliminar  conceptos
 */

export const DeleteDiscounts = createAsyncThunk(
    'Concepts/DeleteDiscounts',
    async (data, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            const response = await Connection.deleteDiscount({ data });

            const deletedDiscountsIds = response.data.data;

            thunkAPI.dispatch(upsertManyDiscounts(deletedDiscountsIds));

            return { deletedDiscountsIds };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);

/**
 * Actualizar conceptos
 */

export const UpdateDiscounts = createAsyncThunk(
    'Concepts/UpdateDiscounts',
    async (data, thunkAPI) => {
        let FeedbackService = new Feedback();
        console.log(data);

        try {
            const Discounts = await Connection.updateDiscount({ data }).then(
                (res) => res.data.data
            );
            console.log(Discounts);

            thunkAPI.dispatch(upsertManyConcepts(Discounts));

            return { Discounts };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    }
);
