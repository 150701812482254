import { createAsyncThunk } from '@reduxjs/toolkit';
import Connection from '../../../service/Connection';
import {
    selectComponentHomeAbsenceBySubjectsFetch,
    selectComponentHomeAssistsBySubjectsFetch,
    selectComponentHomeBestScoreSubjectFetch,
    selectComponentHomeWorstScoreSubjectFetch,
    selectComponentHomeStudentsApprovedGenderGradeSubjectFetch,
    selectComponentHomeStudentsFailedGenderGradeSubjectFetch,
    selectComponentHomeAllSubjectsFetch,
} from './componentsStatusSelectors';

export const getAbsenceBySubjects = createAsyncThunk(
    'fetch/absence_by_subjects',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'subjects',
                    type: 'table',
                    idKey: 'absence_by_subjects',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching AbsenceBySubjects:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentHomeAbsenceBySubjectsFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const getAssistsBySubjects = createAsyncThunk(
    'fetch/assists_by_subjects',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'subjects',
                    type: 'table',
                    idKey: 'assists_by_subjects',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching AssistsBySubjects:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentHomeAssistsBySubjectsFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const getBestScoreSubject = createAsyncThunk(
    'fetch/best_score_on_subject',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'subjects',
                    type: 'top',
                    idKey: 'best_score_on_subject',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching BestScoreSubject:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentHomeBestScoreSubjectFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const getWorstScoreSubject = createAsyncThunk(
    'fetch/worst_score_subject',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'subjects',
                    type: 'top',
                    idKey: 'worst_score_subject',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching WorstScoreSubject:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentHomeWorstScoreSubjectFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const getStudentsApprovedGenderGradeSubject = createAsyncThunk(
    'fetch/students_approved_gender_grade_subject',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'subjects',
                    type: 'table',
                    idKey: 'students_approved_gender_grade_subject',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error(
                'Error fetching StudentsApprovedGenderGradeSubject:',
                error
            );
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentHomeStudentsApprovedGenderGradeSubjectFetch(
                    getState()
                );

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const getStudentsFailedGenderGradeSubject = createAsyncThunk(
    'fetch/students_failed_gender_grade_subject',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'subjects',
                    type: 'table',
                    idKey: 'students_failed_gender_grade_subject',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error(
                'Error fetching StudentsFailedGenderGradeSubject:',
                error
            );
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentHomeStudentsFailedGenderGradeSubjectFetch(
                    getState()
                );

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

export const getAllSubjects = createAsyncThunk(
    'fetch/subjects',
    async ({ schoolId, cycle_id }, thunkAPI) => {
        try {
            const response = await Connection.getDirectorInformation({
                school_id: schoolId,
                cycle_id: cycle_id,
                data: {
                    page: 'subjects',
                    type: 'table',
                    idKey: 'subjects',
                },
            });
            return response.data.data.components;
        } catch (error) {
            console.error('Error fetching Subjects:', error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectComponentHomeAllSubjectsFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);
