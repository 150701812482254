import { Box, Stack, Typography } from '@mui/material';

function ErrorDirectorBoundary() {
    return (
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={3}
            sx={{ textAlign: 'center', padding: '2rem' }}
        >
            <Box
                component="div"
                sx={{
                    fontSize: '8rem',
                    color: '#ff6f61',
                }}
            >
                😔
            </Box>

            <Typography
                variant="h3"
                component="div"
                color="primary"
                gutterBottom
            >
                Algo salió mal
            </Typography>

            <Typography variant="h6" component="div" color="textSecondary">
                Lo sentimos, ocurrió un error inesperado. Favor de contactar a
                soporte técnico.
            </Typography>
        </Stack>
    );
}

export default ErrorDirectorBoundary;
