import React, { useEffect, useState } from 'react';
import {
    Box,
    Stack,
    Tab,
    Tabs,
    Grid,
    Divider,
    Tooltip,
    Select,
    MenuItem,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
    Link,
    Route,
    Switch,
    useLocation,
    useRouteMatch,
} from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import SyncIcon from '@mui/icons-material/Sync';
import {
    getCurrentTab,
    getCycleSelected,
    getLoading,
    invalidate as invalidateComponents,
} from '../../store/home/fetchSlice';
import {
    invalidate as invalidateEntities,
    fetchAllResources,
    getGroups,
    selectEntitiesFetchStatus,
} from '../../store/entities';

import BestScoreStudentCard from './components/BestScoreStudentCard';
import WorstScoreStudentCard from './components/WorstScoreStudentCard';
import AssistanceIndexCard from './components/AssistanceIndexCard';
import AverageIMCSchoolCard from './components/AverageIMCSchoolCard';
import BestScoreSubjectCard from './components/BestScoreSubjectCard';
import FrequentReportsCard from './components/FrequentReportsCard';
import TotalStudentCard from './components/TotalStudentCard';
import LastAttendanceProfessorCard from './components/LastAttendanceProfessorCard';
import SchoolAverageCard from './components/SchoolAverageCard';
import TotalAbscenceCard from './components/TotalAbscenceCard';
import TotalGroupsCard from './components/TotalGroupsCard';
import TotalInsident from './components/TotalInsident';
import TotalParentsCard from './components/TotalParentsCard';
import TotalProfessorsCard from './components/TotalProfessorsCard';
import TotalReportsCard from './components/TotalReportsCard';
import TotalScholarShipCard from './components/TotalScholarShipCard';
import TotalStudentApproved from './components/TotalStudentApproved';
import TotalStudentFailed from './components/TotalStudentFailed';
import GradeAverageGrafic from './components/GradeAverageGrafic';
import StudentApproventFailedGenderGradeGrafic from './components/StudentApproventFailedGenderGradeGrafic';
import StudentsDiversity from './components/StudentsDiversity';
import TotalGenderStudentWithDrawnGrafic from './components/TotalGenderStudentWithDrawnGrafic';
import TotalMaleFemaleStudentsRegister from './components/TotalMaleFemaleStudentsRegister';
import TotalStudentApprovedAndFailedGrafic from './components/TotalStudentApprovedAndFailedGrafic';
import TotalStudentRegisterGrafic from './components/TotalStudentRegisterGrafic';
import TotalStudentsRepeated from './components/TotalStudentsRepeated';
import TotalStudentsWithDrawnGradeGrafic from './components/TotalStudentsWithDrawnGradeGrafic';
import TotalMaleFemaleStudentsRegisterGrade from './components/TotalMaleFemaleStudentsRegisterGrade';
import TotalStudentWithOutGroup from './components/TotalStudentWithOutGroup';
import TotalMaleFemaleStudents from './components/TotalMaleFemaleStudents';
import TotaApprovedAndFailedByGenderGrafic from './components/TotaApprovedAndFailedByGenderGrafic';
import Skeleton from '../../components/Skeleton';
import Error from '../../../components/Feedback/Error';
import AllIncidents from './components/AllIncidents';
import DirectorGroups from '../Groups';
import DirectorSubjects from '../Subjects';
import DirectorProfessors from '../Professors';
import DirectorStudents from '../Students';
import { CyclesSelectOnDirector } from '../../../components/Director';
import { useAuth } from '../../../hooks';
import { setCurrentTab } from '../../store/home/uiSlice';
import { getTurnLevel } from '../../../libs/utils';
import { ErrorBoundary } from 'react-error-boundary';
import GlobalErrorFallback from '../../../components/GlobalErrorFallback';
import ErrorDirectorBoundary from '../../../components/ErrorDirectorBoundary';

const DirectorNewHome = () => {
    function usePageViews() {
        let location = useLocation();
        let currentTab = location.pathname.replace('/', '').split('/')[1];
        let tabRoute = currentTab == undefined ? 'inicio' : currentTab;

        useEffect(() => {
            dispatch(setCurrentTab(`/${tabRoute}`));
        }, [location]);
    }

    usePageViews();

    const dispatch = useDispatch();
    let match = useRouteMatch();
    const Auth = useAuth();
    let user = Auth.getUser();

    const [selectedTurn, setSelectedTurn] = useState('');

    const schoolCycle = useSelector(getCycleSelected);
    const serverState = useSelector(getLoading);
    const currentTab = useSelector(getCurrentTab);
    const fetchingStatus = useSelector(selectEntitiesFetchStatus);
    // const groups = useSelector(getGroups);

    // const uniqueTurns = new Set(
    //     groups.map((group) => group.turn).filter(Boolean)
    // );
    // const turnsArray = Array.from(uniqueTurns);

    useEffect(() => {
        dispatch(
            fetchAllResources({
                school_id: user.school_id,
                cycle: schoolCycle.cycle_id,
                turn: selectedTurn,
                //limit: 5,
            })
        );
    }, [dispatch, user.school_id, schoolCycle.cycle_id]);

    const invalidateUI = () => {
        dispatch(invalidateEntities());
        dispatch(invalidateComponents());
        dispatch(
            fetchAllResources({
                school_id: user.school_id,
                cycle: schoolCycle,
                turn: selectedTurn,
                //limit: 5,
            })
        );
    };

    const reload = () => {
        dispatch(invalidateEntities());
        dispatch(invalidateComponents());
        dispatch(
            fetchAllResources({
                school_id: user.school_id,
                cycle: schoolCycle,
                turn: selectedTurn,
                //limit: 5,
            })
        );
    };

    const handleTurnChange = (event, cycle) => {
        const selectedValue = event.target.value;
        setSelectedTurn(selectedValue);
        dispatch(invalidateEntities());
        dispatch(
            fetchAllResources({
                school_id: user.school_id,
                cycle: schoolCycle,
                turn: selectedTurn,
                //limit: 5,
            })
        );
        dispatch(invalidateComponents());
        fetchAllInformation(cycle);
    };

    const onChangeTab = (event, newValue) => {
        dispatch(setCurrentTab(newValue));
    };

    const onChangeCycle = (cycle) => {
        dispatch(invalidateEntities());
        dispatch(
            fetchAllResources({
                school_id: user.school_id,
                cycle: schoolCycle,
                turn: selectedTurn,
                //limit: 5,
            })
        );
        dispatch(invalidateComponents());
        fetchAllInformation(cycle);
    };

    async function fetchAllInformation(cycle = null) {
        let dataRequest = {
            school_id: user.school_id,
            cycle: cycle ? cycle : schoolCycle.cycle_id,
            turn: selectedTurn,
            limit: 5,
        };
        dispatch(fetchAllResources(dataRequest))
            .unwrap()
            .then((response) => {})
            .catch((err) => {
                let message = 'feedback.getMessage(err)';
            });
    }

    const Root = styled('div')(({ theme }) => ({
        width: '100%',
        ...theme.typography.body2,
        color: theme.palette.text.secondary,
        '& > :not(style) ~ :not(style)': {
            marginTop: theme.spacing(2),
        },
    }));

    return (
        <Box
            sx={{
                paddingTop: {
                    xs: 1,
                    sm: 2,
                    md: 2,
                },
                paddingLeft: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingRight: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingBottom: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
            }}>
            {fetchingStatus === 'pending' && <Skeleton items={9} />}

            {fetchingStatus === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {fetchingStatus === 'fulfilled' && (
                <>
                    <Grid container spacing={2} sx={{ mb: 4 }}>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                            <Tabs
                                value={currentTab}
                                onChange={onChangeTab}
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="tabs"
                                sx={{
                                    [`& .MuiTabs-scrollButtons`]: {
                                        '&.Mui-disabled': { opacity: 0.3 },
                                    },
                                    [`& .MuiTabs-flexContainer`]: {
                                        flexDirection: {
                                            xs: 'column',
                                            sm: 'row',
                                        },
                                    },
                                }}>
                                <Tab
                                    label="Inicio"
                                    value="inicio"
                                    component={Link}
                                    to="/NewDirector/inicio"
                                />
                                <Tab
                                    label="Grupos"
                                    value="groups"
                                    component={Link}
                                    to="/NewDirector/groups"
                                />
                                <Tab
                                    label="Materias"
                                    value="subjects"
                                    component={Link}
                                    to="/NewDirector/subjects"
                                />
                                <Tab
                                    label="Profesores"
                                    value="professors"
                                    component={Link}
                                    to="/NewDirector/professors"
                                />
                                <Tab
                                    label="Alumnos"
                                    value="students"
                                    component={Link}
                                    to="/NewDirector/students"
                                />
                            </Tabs>
                            <Divider textAlign="left" />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                            <Stack
                                spacing={2}
                                direction={{ xs: 'column', sm: 'row' }}
                                justifyContent="flex-end"
                                alignItems="center">
                                {/* <Select
                                    value={selectedTurn}
                                    onChange={handleTurnChange}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Turn Select' }}
                                    sx={{ minWidth: 120 }}
                                    size="small"
                                >
                                    <MenuItem value="">
                                        Selecciona un turno
                                    </MenuItem>
                                    {turnsArray.map((turn, index) => (
                                        <MenuItem key={index} value={turn}>
                                            {getTurnLevel(turn)}
                                        </MenuItem>
                                    ))}
                                </Select> */}
                                <CyclesSelectOnDirector
                                    disabled={serverState === 'pending'}
                                    onChange={onChangeCycle}
                                />
                                <Tooltip title="Sincronizar información">
                                    <LoadingButton
                                        onClick={invalidateUI}
                                        startIcon={<SyncIcon />}
                                        loading={serverState == 'pending'}
                                        loadingPosition="start"
                                        variant="contained"
                                        size="small">
                                        Sincronizar
                                    </LoadingButton>
                                </Tooltip>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Switch>
                        <Route path="/NewDirector/inicio">
                            <Grid container spacing={2} sx={{ mb: 5, mt: 5 }}>
                                <Grid item xs={12} sm={6} md={4} lg={2}>
                                    <ErrorBoundary
                                        FallbackComponent={
                                            ErrorDirectorBoundary
                                        }>
                                        <TotalStudentCard />
                                    </ErrorBoundary>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={2}>
                                    <ErrorBoundary
                                        FallbackComponent={
                                            ErrorDirectorBoundary
                                        }>
                                        <SchoolAverageCard />
                                    </ErrorBoundary>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={2}>
                                    <ErrorBoundary
                                        FallbackComponent={
                                            ErrorDirectorBoundary
                                        }>
                                        <TotalScholarShipCard />
                                    </ErrorBoundary>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={2}>
                                    <ErrorBoundary
                                        FallbackComponent={
                                            ErrorDirectorBoundary
                                        }>
                                        <AssistanceIndexCard />
                                    </ErrorBoundary>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={2}>
                                    <ErrorBoundary
                                        FallbackComponent={
                                            ErrorDirectorBoundary
                                        }>
                                        <TotalAbscenceCard />
                                    </ErrorBoundary>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={2}>
                                    <ErrorBoundary
                                        FallbackComponent={
                                            ErrorDirectorBoundary
                                        }>
                                        <TotalProfessorsCard />
                                    </ErrorBoundary>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={2}>
                                    <ErrorBoundary
                                        FallbackComponent={
                                            ErrorDirectorBoundary
                                        }>
                                        <TotalParentsCard />
                                    </ErrorBoundary>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={2}>
                                    <ErrorBoundary
                                        FallbackComponent={
                                            ErrorDirectorBoundary
                                        }>
                                        <TotalReportsCard />
                                    </ErrorBoundary>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={2}>
                                    <ErrorBoundary
                                        FallbackComponent={
                                            ErrorDirectorBoundary
                                        }>
                                        <AverageIMCSchoolCard />
                                    </ErrorBoundary>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={2}>
                                    <ErrorBoundary
                                        FallbackComponent={
                                            ErrorDirectorBoundary
                                        }>
                                        <TotalInsident />
                                    </ErrorBoundary>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={2}>
                                    <ErrorBoundary
                                        FallbackComponent={
                                            ErrorDirectorBoundary
                                        }>
                                        <BestScoreStudentCard />
                                    </ErrorBoundary>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={2}>
                                    <ErrorBoundary
                                        FallbackComponent={
                                            ErrorDirectorBoundary
                                        }>
                                        <WorstScoreStudentCard />
                                    </ErrorBoundary>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <ErrorBoundary
                                        FallbackComponent={
                                            ErrorDirectorBoundary
                                        }>
                                        <LastAttendanceProfessorCard />
                                    </ErrorBoundary>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <ErrorBoundary
                                        FallbackComponent={
                                            ErrorDirectorBoundary
                                        }>
                                        <BestScoreSubjectCard />
                                    </ErrorBoundary>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <ErrorBoundary
                                        FallbackComponent={
                                            ErrorDirectorBoundary
                                        }>
                                        <FrequentReportsCard />
                                    </ErrorBoundary>
                                </Grid>
                            </Grid>

                            <Box>
                                <Divider textAlign="left" sx={{ mt: 4, mb: 4 }}>
                                    <Typography variant="h5">
                                        <span>GRADO</span>
                                    </Typography>
                                </Divider>
                            </Box>

                            <Grid
                                container
                                spacing={2}
                                direction="row"
                                justifyContent="center"
                                alignItems="flex-start"
                                item
                                xs={12}>
                                <Grid
                                    container
                                    item
                                    spacing={2}
                                    justifyContent="center">
                                    <Grid item xs={12} sm={6} md={6} lg={3}>
                                        <ErrorBoundary
                                            FallbackComponent={
                                                ErrorDirectorBoundary
                                            }>
                                            <GradeAverageGrafic />
                                        </ErrorBoundary>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={3}>
                                        <ErrorBoundary
                                            FallbackComponent={
                                                ErrorDirectorBoundary
                                            }>
                                            <StudentApproventFailedGenderGradeGrafic />
                                        </ErrorBoundary>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={3}>
                                        <ErrorBoundary
                                            FallbackComponent={
                                                ErrorDirectorBoundary
                                            }>
                                            <TotalMaleFemaleStudentsRegisterGrade />
                                        </ErrorBoundary>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={3}>
                                        <ErrorBoundary
                                            FallbackComponent={
                                                ErrorDirectorBoundary
                                            }>
                                            <TotalStudentsWithDrawnGradeGrafic />
                                        </ErrorBoundary>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Box>
                                <Divider textAlign="left" sx={{ mt: 4, mb: 4 }}>
                                    <Typography variant="h5">
                                        <span>GRUPOS</span>
                                    </Typography>
                                </Divider>
                            </Box>

                            <Grid
                                container
                                spacing={2}
                                direction="row"
                                justifyContent="center"
                                alignItems="flex-start"
                                item
                                xs={12}>
                                <Grid
                                    container
                                    item
                                    spacing={2}
                                    justifyContent="center">
                                    <Grid item xs={12} sm={4} md={4} lg={4}>
                                        <ErrorBoundary
                                            FallbackComponent={
                                                ErrorDirectorBoundary
                                            }>
                                            <TotalGroupsCard />
                                        </ErrorBoundary>
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={4} lg={4}>
                                        <ErrorBoundary
                                            FallbackComponent={
                                                ErrorDirectorBoundary
                                            }>
                                            <TotalStudentWithOutGroup />
                                        </ErrorBoundary>
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={4} lg={4}>
                                        <ErrorBoundary
                                            FallbackComponent={
                                                ErrorDirectorBoundary
                                            }>
                                            <TotalStudentsRepeated />
                                        </ErrorBoundary>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Box>
                                <Divider textAlign="left" sx={{ mt: 4, mb: 4 }}>
                                    <Typography variant="h5">
                                        <span>ALUMNOS</span>
                                    </Typography>
                                </Divider>
                            </Box>

                            <Grid
                                container
                                item
                                spacing={2}
                                justifyContent="center">
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <ErrorBoundary
                                        FallbackComponent={
                                            ErrorDirectorBoundary
                                        }>
                                        <TotalMaleFemaleStudents />
                                    </ErrorBoundary>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <ErrorBoundary
                                        FallbackComponent={
                                            ErrorDirectorBoundary
                                        }>
                                        <TotalStudentApprovedAndFailedGrafic />
                                    </ErrorBoundary>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <ErrorBoundary
                                        FallbackComponent={
                                            ErrorDirectorBoundary
                                        }>
                                        <TotalMaleFemaleStudentsRegister />
                                    </ErrorBoundary>
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                item
                                spacing={2}
                                justifyContent="center"
                                sx={{ mt: 1 }}>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <ErrorBoundary
                                        FallbackComponent={
                                            ErrorDirectorBoundary
                                        }>
                                        <StudentsDiversity />
                                    </ErrorBoundary>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <ErrorBoundary
                                        FallbackComponent={
                                            ErrorDirectorBoundary
                                        }>
                                        <TotaApprovedAndFailedByGenderGrafic />
                                    </ErrorBoundary>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <ErrorBoundary
                                        FallbackComponent={
                                            ErrorDirectorBoundary
                                        }>
                                        <TotalGenderStudentWithDrawnGrafic />
                                    </ErrorBoundary>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={3} md={3} lg={12}>
                                <ErrorBoundary
                                    FallbackComponent={ErrorDirectorBoundary}>
                                    <AllIncidents />
                                </ErrorBoundary>
                            </Grid>
                        </Route>
                        <Route path="/NewDirector/groups">
                            <DirectorGroups />
                        </Route>

                        <Route path="/NewDirector/subjects">
                            <DirectorSubjects />
                        </Route>

                        <Route path="/NewDirector/professors">
                            <DirectorProfessors />
                        </Route>

                        <Route path="/NewDirector/students">
                            <DirectorStudents />
                        </Route>
                    </Switch>
                </>
            )}
        </Box>
    );
};

export default DirectorNewHome;
