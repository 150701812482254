import { createSelector, createSlice } from '@reduxjs/toolkit';
import { selectNoticesAssistance } from './selectors';
import { selectNotices } from '../../../store/slices/entities/notices';
import { selectAssistanceNoticeDetails } from '../assistanceDetails/selectors';
import { selectAllUsers } from '../../../store/slices/entities/users';
import { selectAllNotifications } from '../../../store/slices/entities/notifications';
import { selectAllStudents } from '../../../store/slices/entities/students';

let emptyState = [];

export const itemsSlice = createSlice({
    name: 'items',
    initialState: emptyState,
    reducers: {
        setAllAssistanceItems: (state, action) => {
            return action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase('app/clear', () => {
            return emptyState;
        });
    },
});

export const { setAllAssistanceItems } = itemsSlice.actions;

export default itemsSlice.reducer;

export const selectAllNoticesAssistanceItemsEntities = createSelector(
    selectNoticesAssistance,
    (assistance) => {
        return assistance.items;
    }
);

//Traer solo las noticias tipo 1
export const selectAllAssistanceNoticesItems = createSelector(
    selectAllNoticesAssistanceItemsEntities,
    selectNotices,
    selectAssistanceNoticeDetails,
    selectAllUsers,
    selectAllNotifications,
    selectAllStudents,
    (
        noticesDetailsVMEUUIDS,
        noticesEntities,
        viewModels,
        users,
        notifications,
        students
    ) => {
        return noticesDetailsVMEUUIDS
            .map((i) => {
                let currentViewModel = viewModels[i];

                let emitter = users.find(
                    (user) =>
                        user.user_id ===
                        noticesEntities[currentViewModel.ui.notice_id].user_id
                );

                let noticeNotifications = notifications.filter(
                    (notification) =>
                        notification.notice_id ===
                        noticesEntities[currentViewModel.ui.notice_id].notice_id
                );

                let student =
                    noticeNotifications.length > 0
                        ? students.find(
                              (student) =>
                                  student.student_id ===
                                  noticeNotifications[0].student_id
                          )
                        : null;

                let sentNotification = noticeNotifications.filter(
                    (sent) => sent.status === 1
                );
                let noticedtification = noticeNotifications.filter(
                    (sent) => sent.status === 2
                );
                let readNotification = noticeNotifications.filter(
                    (sent) => sent.status === 3
                );
                return {
                    ...noticesEntities[currentViewModel.ui.notice_id],
                    viewModel: currentViewModel,
                    emitter: emitter,
                    sentNotifications: sentNotification,
                    noticedNotifications: noticedtification,
                    readNotifications: readNotification,
                    student,
                };
            })
            .sort((a, b) => {
                return new Date(b.created_at) - new Date(a.created_at);
            });
    }
);
