import {
    createAsyncThunk,
    createSelector,
    createSlice,
} from '@reduxjs/toolkit';
import Connection from '../../../../service/Connection';
import { PAYMENTS_HOME_EXPIRE_TIME } from '../../../../service/const';
import Feedback from '../../../../service/Feedback';
import { emptyState } from '../../emptyState';
import { getExpireIn } from '../../../../libs/utils';

const initialState = {
    Total_Discounts_Lost: {
        fetch: {
            ...emptyState,
        },
        data: [],
    },
    Total_Gains: {
        fetch: {
            ...emptyState,
        },
        data: [],
    },
    Concepts_Without_Payments: {
        fetch: {
            ...emptyState,
        },
        data: [],
    },
};

const kpiSlice = createSlice({
    name: 'kpi',
    initialState,
    reducers: {
        /**
         * Invalidar datos de la UI
         */
        invalidate_Total_Discounts_Lost: (state) => {
            state.Total_Discounts_Lost.fetch.didInvalidate = true;
        },
        invalidate_Total_Gains: (state) => {
            state.Total_Gains.fetch.didInvalidate = true;
        },
        invalidate_Concepts_Without_Payments: (state) => {
            state.Concepts_Without_Payments.fetch.didInvalidate = true;
        },
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return initialState;
        });

        const pendingServerStatusTotal_Discounts_Lost = (state, action) => {
            state.Total_Discounts_Lost.fetch.statusServer = 'pending';
        };
        const pendingServerStatusTotal_Gains = (state, action) => {
            state.Total_Gains.fetch.statusServer = 'pending';
        };
        const pendingServerStatusConcepts_Without_Payments = (
            state,
            action
        ) => {
            state.Concepts_Without_Payments.fetch.statusServer = 'pending';
        };

        /**
         * Termina la carga de informacion de Total_Discounts_Lost
         */
        builder.addCase(loadUITotalDiscountLost.fulfilled, (state, action) => {
            state.Total_Discounts_Lost.fetch = {
                ...state.Total_Discounts_Lost.fetch,
                expireIn: getExpireIn('PAYMENTS_HOME_EXPIRE_TIME'),
                fetchingAt: Date.now(),
                didInvalidate: false,
                statusServer: 'fulfilled',
            };
            state.Total_Discounts_Lost.data = action.payload.data.components;
        });
        builder.addCase(
            loadUITotalDiscountLost.pending,
            pendingServerStatusTotal_Discounts_Lost
        );
        builder.addCase(loadUITotalDiscountLost.rejected, (state, action) => {
            state.Total_Discounts_Lost.fetch.statusServer = 'rejected';
            state.Total_Discounts_Lost.fetch.error = action.error;
        });

        /**
         * Termina la carga de informacion de Concepts_Without_Payments
         */
        builder.addCase(
            loadUIConceptsWithoutPayment.fulfilled,
            (state, action) => {
                state.Concepts_Without_Payments.fetch = {
                    ...state.Concepts_Without_Payments.fetch,
                    expireIn: getExpireIn('PAYMENTS_HOME_EXPIRE_TIME'),
                    fetchingAt: Date.now(),
                    didInvalidate: false,
                    statusServer: 'fulfilled',
                };
                state.Concepts_Without_Payments.data =
                    action.payload.data.components;
            }
        );
        builder.addCase(
            loadUIConceptsWithoutPayment.pending,
            pendingServerStatusConcepts_Without_Payments
        );
        builder.addCase(
            loadUIConceptsWithoutPayment.rejected,
            (state, action) => {
                state.Concepts_Without_Payments.fetch.statusServer = 'rejected';
                state.Concepts_Without_Payments.fetch.error = action.error;
            }
        );

        /**
         * Termina la carga de informacion de Total_Gains
         */
        builder.addCase(loadUITotalGains.fulfilled, (state, action) => {
            state.Total_Gains.fetch = {
                ...state.Total_Gains.fetch,
                expireIn: getExpireIn('PAYMENTS_HOME_EXPIRE_TIME'),
                fetchingAt: Date.now(),
                didInvalidate: false,
                statusServer: 'fulfilled',
            };
            state.Total_Gains.data = action.payload.data.components;
        });

        builder.addCase(
            loadUITotalGains.pending,
            pendingServerStatusTotal_Gains
        );

        builder.addCase(loadUITotalGains.rejected, (state, action) => {
            state.Total_Gains.fetch.statusServer = 'rejected';
            state.Total_Gains.fetch.error = action.error;
        });
    },
});

export const {
    invalidate_Concepts_Without_Payments,
    invalidate_Total_Discounts_Lost,
    invalidate_Total_Gains,
} = kpiSlice.actions;

export default kpiSlice.reducer;

////////////////////////////////////// THUNKS // ////////////////////////////////////

/**
 * Cargar informacion de la UI de el total de descuentos perdidos
 */
export const loadUITotalDiscountLost = createAsyncThunk(
    'homeUI/lastStudentWithDiscount/server/fetch/data',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback();

        try {
            const discountLost = await Connection.getPaymentsInformation({
                school_id: schoolId,
                data: {
                    page: 'home',
                    type: 'kpi',
                    idKey: 'total_discount_loss',
                },
            }).then((res) => res.data);

            return discountLost;
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } = selectKpi_Total_Discounts_Lost(
                getState()
            );
            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

/**
 * Cargar informacion de la UI de los conceptos sin pagos
 */
export const loadUIConceptsWithoutPayment = createAsyncThunk(
    'homeUI/conceptsWithoutPayment/server/fetch/data',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback();
        try {
            const conceptsWithoutPayment =
                await Connection.getPaymentsInformation({
                    school_id: schoolId,
                    data: {
                        page: 'home',
                        type: 'kpi',
                        idKey: 'total_concepts_wiouth_payments',
                    },
                }).then((res) => res.data);

            return conceptsWithoutPayment;
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } =
                selectKpi_Concepts_Without_Payment(getState());
            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

/**
 * Cargar informacion de la UI de el total de ganancias
 */

export const loadUITotalGains = createAsyncThunk(
    'fetch/totalGains',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback();
        try {
            const totalGainsData = await Connection.getPaymentsInformation({
                school_id: schoolId,
                data: {
                    page: 'home',
                    type: 'kpi',
                    idKey: 'total_gains',
                },
            }).then((res) => res.data);

            return totalGainsData;
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } = selectKpi_Total_Gains(getState());
            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);
// //////////////////////////////////// SELECTORES // ////////////////////////////////////

export const selectKpi = (state) => state.paymentsModule.home.kpis;

/**
 * Selector para recuperar el estado de la petición del total de descuentos
 */
export const selectKpi_Total_Discounts_Lost = createSelector(
    [selectKpi],
    (kpis) => kpis.Total_Discounts_Lost.fetch
);

export const selectKpiStatus_Total_Discounts_Lost = createSelector(
    [selectKpi_Total_Discounts_Lost],
    (fetch) => fetch.statusServer
);

export const selectTotalDiscountLoss = createSelector(
    [selectKpi],
    (kpi) => kpi.Total_Discounts_Lost.data
);

/**
 * Selector para recuperar el estado de la petición del total de ganancias
 */
export const selectKpi_Total_Gains = createSelector(
    [selectKpi],
    (kpis) => kpis.Total_Gains.fetch
);
export const selectKpiStatus_Total_Gains = createSelector(
    [selectKpi_Total_Gains],
    (fetch) => fetch.statusServer
);

export const selectTotalGains = createSelector(
    [selectKpi],
    (kpi) => kpi.Total_Gains.data
);

/**
 * Selector para recuperar el estado de la petición de los conceptos sin pagos
 */
export const selectKpi_Concepts_Without_Payment = createSelector(
    [selectKpi],
    (kpis) => kpis.Concepts_Without_Payments.fetch
);
export const selectKpiStatus_Concepts_Without_Payment = createSelector(
    [selectKpi_Concepts_Without_Payment],
    (fetch) => fetch.statusServer
);

export const selectConceptsWithoutPayment = createSelector(
    [selectKpi],
    (kpi) => kpi.Concepts_Without_Payments.data
);
