import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Box, Card, CardContent, Tooltip } from '@mui/material';
import { DataGrid, esES } from '@mui/x-data-grid';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectLagCalifications,
} from '../../../store/professors/fetchSlice';
import { getLagCalifications } from '../../../store/professors/thunks';
import { selectComponentProfessorsLagCalificationsStatusServer } from '../../../store/professors/componentsStatusSelectors';
import { SkeletonProfessorsSmallTables } from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';
import { Tag } from 'antd';
import NoDataOverlay from '../../../../components/utilities/NoDataOverlay';
import { GridToolBar } from '../../../../components/utilities/GridToolBar';
import CustomPagination from '../../../../components/utilities/CustomPagination';

const COLUMN_STYLES = {
    defaultWidth: 200,
    calificationWidth: 150,
    professorAlign: 'left',
    calificationAlign: 'center',
};

const renderersByType = {
    string: (params) => (
        <Tooltip title={params.value}>
            <Typography noWrap variant="body2" style={{ width: '100%' }}>
                {params.value}
            </Typography>
        </Tooltip>
    ),
    number: (params) => (
        <Tag
            color="error"
            style={{
                width: COLUMN_STYLES.calificationWidth,
                textAlign: COLUMN_STYLES.calificationAlign,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'normal',
                wordBreak: 'break-word',
            }}
        >
            {params.value || '0'}
        </Tag>
    ),
};

const LagCalifications = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentProfessorsLagCalificationsStatusServer
    );

    const lagCalifications = useFetchResource(
        () =>
            getLagCalifications({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectLagCalifications,
        [cycleSelected, schoolId]
    );

    const columns = Object.keys(lagCalifications?.value || {}).map((key) => ({
        field: key.toLowerCase(),
        headerName: key,
        flex: 1,
        renderCell: (params) => {
            if (key === 'Rezago') {
                return (
                    <Tag
                        color={params.value > 20 ? 'error' : 'success'}
                        style={{
                            textAlign: 'center',
                            width: '100%',
                        }}
                    >
                        {params.value || 0}
                    </Tag>
                );
            }
            return (
                <Tooltip title={params.value}>
                    <Typography noWrap variant="body2">
                        {params.value}
                    </Typography>
                </Tooltip>
            );
        },
        align: key === 'Rezago' ? 'center' : 'left',
        headerAlign: key === 'Rezago' ? 'center' : 'left',
    }));

    const rows =
        lagCalifications?.value?.Profesor?.map((_, index) => {
            const row = { id: index + 1 };
            Object.keys(lagCalifications?.value || {}).forEach((key) => {
                row[key.toLowerCase()] =
                    lagCalifications.value[key][index]?.value;
            });
            return row;
        }) || [];

    const reload = () => {
        dispatch(
            getLagCalifications({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            })
        );
    };

    return (
        <Box sx={{ width: '100%', overflowX: 'hidden' }}>
            {fetchingStatus === 'pending' && (
                <SkeletonProfessorsSmallTables items={9} />
            )}

            {fetchingStatus === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {fetchingStatus === 'fulfilled' && (
                <Card
                    sx={{
                        borderRadius: '10px',
                        boxShadow: 'none',
                        maxWidth: '100%',
                        margin: '20px auto',
                        padding: '16px',
                        overflow: 'visible',
                        position: 'relative',
                        mt: -4,
                    }}
                >
                    <CardContent sx={{ width: '100%', padding: '24px' }}>
                        <Box sx={{ height: 704, width: '100%' }}>
                            <DataGrid
                                localeText={{
                                    ...esES.components.MuiDataGrid.defaultProps
                                        .localeText,
                                    toolbarColumns: '',
                                    toolbarFilters: '',
                                    toolbarDensity: '',
                                    toolbarExport: '',
                                }}
                                columns={columns}
                                rows={rows}
                                rowsPerPageOptions={[10]}
                                disableDensitySelector
                                components={{
                                    NoRowsOverlay: NoDataOverlay,
                                    NoResultsOverlay: NoDataOverlay,
                                    Toolbar: GridToolBar,
                                    Pagination: CustomPagination,
                                }}
                                componentsProps={{
                                    panel: {
                                        placement: 'bottom-end',
                                    },
                                    noResultsOverlay: {
                                        message:
                                            'No se encontraron resultados para la búsqueda',
                                    },
                                    noRowsOverlay: {
                                        message: 'No hay datos disponibles',
                                    },
                                }}
                                sx={{
                                    '&.MuiDataGrid-root': {
                                        border: 'none',
                                    },
                                    '& .MuiDataGrid-cell': {
                                        whiteSpace: 'normal',
                                        wordWrap: 'break-word',
                                    },
                                }}
                            />
                        </Box>
                    </CardContent>
                </Card>
            )}
        </Box>
    );
};

export default LagCalifications;
