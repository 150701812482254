import React from 'react';
import { Box, Button } from '@mui/material';
import { Carousel } from 'antd';
import heart from '../../assets/heart.svg';
import funcionalidadestop from '../../assets/funcionalidades_top.png';
import funcionalidadesbottom from '../../assets/funcionalidades_bottom.png';
import registro01 from '../../assets/iconos/01_registro.svg';
import analisis05 from '../../assets/iconos/05_analisis.svg';
import sistema02 from '../../assets/iconos/02_sistema.svg';
import seguridad06 from '../../assets/iconos/06_seguridad.svg';
import notificaciones03 from '../../assets/iconos/03_notificaciones.svg';
import sincronizacion07 from '../../assets/iconos/07_sincronizacion.svg';
import reportes04 from '../../assets/iconos/04_reportes.svg';
import soporte08 from '../../assets/iconos/08_soporte.svg';
import videos from '../../assets/videos.svg';
import facebook from '../../assets/facebook.svg';
import instagram from '../../assets/instagram.svg';
import whatsapp from '../../assets/whatsapp.svg';
import whatsAppFloat from '../../assets/whatsAppFloat.svg';
import youtube from '../../assets/youtube.svg';
import directivo from '../../assets/directivo.png';
import logoFooter from '../../assets/logo_footer.svg';
import palomita from '../../assets/palomita.svg';
import Yt01 from '../../assets/videos/Yt_01.jpg';
import Yt02 from '../../assets/videos/Yt_02.jpg';
import Yt03 from '../../assets/videos/Yt_03.jpg';
import Yt04 from '../../assets/videos/Yt_04.jpg';
import Yt05 from '../../assets/videos/Yt_05.jpg';

// import '../style.css';
import { NavLink } from 'react-router-dom';
import NavBar from '../../Components/NavBar';
import Downloads from '../../Components/Downloads';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const Funcionalidad = () => {
    const handleClick = () => {
        window.scrollTo(0, 0);
    };
    const history = useHistory();

    return (
        <Box>
            <NavBar />
            <div className="body">
                <div className="mweb funcionalidades-wrapper">
                    <section className="sidebar">
                        <div className="container center">
                            <h1>
                                <img src={heart} />
                                Funcionalidades
                            </h1>
                            <p>
                                Diseñadas para <b>agilizar la gestión</b>{' '}
                                educativa, mejorar la comunicación y
                                transparencia y ofrecer una{' '}
                                <b>experiencia educativa</b> más{' '}
                                <b>efectiva y conectada</b> para todos. Cada
                                funcionalidad está adaptada a las{' '}
                                <b>necesidades y requerimientos</b> específicos
                                de maestros, padres de familia, directores y
                                orientadores educativos.
                            </p>
                        </div>
                    </section>
                    <section className="funcionalidades_page">
                        <div>
                            <div>
                                <img
                                    src={funcionalidadestop}
                                    className="avatar_top"
                                />
                                <div className="separator" />
                                <div className="grid dos">
                                    <div
                                        id="calificaciones"
                                        className="funcion">
                                        <img src={registro01} />
                                        <h3>
                                            Registro de Calificaciones y
                                            Asistencias
                                        </h3>
                                        <p>
                                            Permite a los maestros ingresar y
                                            actualizar fácilmente las
                                            calificaciones y asistencias de los
                                            estudiantes, eliminando la necesidad
                                            de realizarlo manualmente en papel.
                                        </p>
                                    </div>

                                    <div className="funcion">
                                        <img src={analisis05} />
                                        <h3>Análisis de Datos Educativos</h3>
                                        <p>
                                            Ofrece herramientas para que los
                                            directores puedan analizar los datos
                                            de rendimiento escolar de manera
                                            rápida y efectiva, identificando
                                            áreas de mejora y tomando decisiones
                                            basadas en información concreta.
                                        </p>
                                    </div>

                                    <div id="promedio" className="funcion">
                                        <img src={sistema02} />
                                        <h3>Sistema de Promedio Automático</h3>
                                        <p>
                                            La aplicación calcula
                                            automáticamente los promedios de
                                            calificaciones de los alumnos,
                                            ahorrando tiempo y evitando errores
                                            humanos.
                                        </p>
                                    </div>

                                    <div className="funcion">
                                        <img src={seguridad06} />
                                        <h3>Seguridad y Privacidad</h3>
                                        <p>
                                            Garantizamos altos niveles de
                                            seguridad y privacidad de los datos,
                                            asegurando que solo las personas
                                            autorizadas tengan acceso a la
                                            información pertinente.
                                        </p>
                                    </div>

                                    <div
                                        id="notificaciones"
                                        className="funcion">
                                        <img src={notificaciones03} />
                                        <h3>Notificaciones para Padres</h3>
                                        <p>
                                            Los padres de familia tendrían
                                            acceso donde podrían ver las
                                            calificaciones y asistencias de sus
                                            hijos y recibir notificaciones de
                                            eventos importantes.
                                        </p>
                                    </div>

                                    <div className="funcion">
                                        <img src={sincronizacion07} />
                                        <h3>Sincronización en la Nube</h3>
                                        <p>
                                            Permite la sincronización de datos
                                            en la nube para que todos los
                                            usuarios autorizados puedan acceder
                                            a la información desde diferentes
                                            dispositivos.
                                        </p>
                                    </div>

                                    <div id="reportes" className="funcion">
                                        <img src={reportes04} />
                                        <h3>Reportes de Comportamiento</h3>
                                        <p>
                                            Los maestros podrán registrar y
                                            enviar reportes sobre el
                                            comportamiento de los alumnos a los
                                            orientadores educativos y
                                            directivos, para abordar situaciones
                                            de manera proactiva.
                                        </p>
                                    </div>

                                    <div className="funcion">
                                        <img src={soporte08} />
                                        <h3>Soporte y Asistencia</h3>
                                        <p>
                                            Ofrecer un equipo de soporte para
                                            atender las dudas y consultas de los
                                            usuarios, asegurando una experiencia
                                            fluida y satisfactoria.
                                        </p>
                                    </div>
                                </div>
                                <img
                                    src={funcionalidadesbottom}
                                    className="avatar_bottom"
                                />
                            </div>
                        </div>
                    </section>
                    <section id="videos" className="videos">
                        <div className="custom-container">
                            <h1>
                                <img src={videos} />
                                Videos
                            </h1>
                            <span>
                                ¡Descubre nuestro emocionante rincón de videos!
                            </span>
                            <div className="textos">
                                <p>
                                    Aquí conocerás la manera más sencilla de{' '}
                                    <b>descargar la aplicación "Cura"</b> y una
                                    breve introducción de cómo funciona,
                                    adaptada para cada uno de nuestros usuarios:
                                    Maestros, Padres de familia y Orientadores
                                    escolares.{' '}
                                </p>
                                <p>
                                    Sumérgete en una experiencia visual que te
                                    guiará <b>paso a paso en el proceso</b> de
                                    aprovechar al máximo todas las ventajas que
                                    Cura tiene para ofrecerte.
                                </p>
                            </div>
                            <div id="antd-demo" className="antd-carousel">
                                <Carousel
                                    slidesToShow={3}
                                    slidesToScroll={1}
                                    autoplay
                                    autoplaySpeed={3000}>
                                    <a
                                        href="https://www.youtube.com/watch?v=P32u1HMRyPI&ab_channel=CURAAPP"
                                        target="_blank">
                                        <img src={Yt01} alt="Imagen 1" />
                                    </a>
                                    <a
                                        href="https://www.youtube.com/watch?v=3DxRG9vlOnU&ab_channel=CURAAPP"
                                        target="_blank">
                                        <img src={Yt02} alt="Imagen 2" />
                                    </a>
                                    <a
                                        href="https://www.youtube.com/watch?v=YjlHvAl3BzM&ab_channel=CURAAPP"
                                        target="_blank">
                                        <img src={Yt03} alt="Imagen 3" />
                                    </a>
                                    <a
                                        href="https://www.youtube.com/watch?v=ZL-oh5R6LM4&ab_channel=CURAAPP"
                                        target="_blank">
                                        <img src={Yt04} alt="Imagen 4" />
                                    </a>
                                    <a
                                        href="https://www.youtube.com/watch?v=prSrVFlBtUY&ab_channel=CURAAPP"
                                        target="_blank">
                                        <img src={Yt05} alt="Imagen 5" />
                                    </a>
                                </Carousel>
                            </div>
                            <a
                                href="https://www.youtube.com/@cura-educacionefectiva-oficial"
                                target="_blank">
                                <img src={videos} />
                                ¡Suscríbete a nuestro canal y déjate sorprender
                                por el poder transformador de la educación en la
                                palma de tu mano!
                            </a>
                        </div>
                    </section>
                    <section className="descargas">
                        <Downloads />
                    </section>
                    <footer>
                        <img className="palomita" src={palomita} />
                        <div className="container">
                            <div className="grid cinco">
                                <div>
                                    <img src={logoFooter} />
                                    <p>
                                        Transformando la manera en que Maestros,
                                        Padres de familia y Directores gestionan
                                        la educación.
                                    </p>
                                </div>
                                <div>
                                    <img
                                        src={directivo}
                                        style={{ width: '230px' }}
                                    />
                                </div>
                                <div className="FBeneficios">
                                    <h4>Beneficios</h4>
                                    <ul>
                                        <li>
                                            <NavLink
                                                to="/beneficios"
                                                onClick={handleClick}>
                                                <a>Maestros</a>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                to="/beneficios"
                                                onClick={handleClick}>
                                                <a>Padres</a>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                to="/beneficios"
                                                onClick={handleClick}>
                                                <a>Orientadores</a>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                to="/beneficios"
                                                onClick={handleClick}>
                                                <a>Directivos</a>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                                <div className="FFuncionalidades">
                                    <h4>Funcionalidades</h4>
                                    <ul>
                                        <li>
                                            <a href="#calificaciones">
                                                Calificaciones y asistencias
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#promedio">
                                                Promedio automático
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#promedio">
                                                Notificaciones para padres
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#promedio">
                                                Reportes de comportamiento
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <ul className="redes">
                                        <li>
                                            <a
                                                href="https://www.facebook.com/curaeducacion"
                                                target="_blank">
                                                <img src={facebook} />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.instagram.com/curaeducacion/"
                                                target="_blank">
                                                <img src={instagram} />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.youtube.com/@cura-educacionefectiva-oficial"
                                                target="_blank">
                                                <img src={youtube} />
                                            </a>
                                        </li>
                                        {/* <li>
                                            <a href="https://wa.me/5216181448945">
                                                <img src={whatsapp} />
                                            </a>
                                        </li> */}
                                    </ul>
                                    <a
                                        href="https://wa.me/5216181448945"
                                        className="float-wa"
                                        target="_blank">
                                        <img
                                            src={whatsAppFloat}
                                            className="wa-icon"
                                        />
                                    </a>
                                    <a
                                        href="https://wa.me/5216181448945"
                                        target="_blank">
                                        <h2>
                                            <img src={whatsapp} /> 618 144 8945
                                        </h2>
                                    </a>
                                    <h2>Email: curati2023@gmail.com</h2>
                                    <h2>Dirección</h2>
                                    <p className="dir">
                                        Blvd. Domingo Arrieta 1700, Gral Domingo
                                        Arrieta, CP 34180, Durango, Dgo., Mexico
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="bottom">
                            <Button
                                size="small"
                                sx={{
                                    color: '#ffffffbe',
                                    textDecoration: 'underline',
                                    fontWeight: 'bold',
                                    padding: '0',
                                }}
                                onClick={() => {
                                    history.push({
                                        pathname: '/TerminosCondiciones',
                                    });
                                }}>
                                Términos y condiciones
                            </Button>
                            <div className="container">
                                <span>
                                    Cura App ® Todos los Derechos Reservados •
                                    Durango, Dgo. México • 2023
                                </span>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </Box>
    );
};
export default Funcionalidad;
