import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Card, CardContent } from '@mui/material';
import {
    DataGrid,
    esES,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { Tag } from 'antd';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectStudentsWithSubjectsFailed,
} from '../../../store/students/fetchSlice';
import { getStudentsWithSubjectsFailed } from '../../../store/students/thunks';
import DEFAULT_AVATAR_URL from './../../../../assets/images/Unknown_person.jpeg';
import { selectComponentStudentsWithSubjectsFailedStatusServer } from '../../../store/students/componentsStatusSelectors';
import { SkeletonStudentsTables } from '../../../components/Skeleton';
import Error from '../../../../components/Feedback/Error';
import NoDataOverlay from '../../../../components/utilities/NoDataOverlay';
import { GridToolBar } from '../../../../components/utilities/GridToolBar';
import CustomPagination from '../../../../components/utilities/CustomPagination';

const StudentsWithSubjectsFailedTable = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentStudentsWithSubjectsFailedStatusServer
    );

    const studentsWithSubjectsFailed = useFetchResource(
        () =>
            getStudentsWithSubjectsFailed({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectStudentsWithSubjectsFailed,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getStudentsWithSubjectsFailed({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            })
        );
    };

    const headers = studentsWithSubjectsFailed.value
        ? Object.keys(studentsWithSubjectsFailed.value)
        : [];
    const rows =
        headers.length > 0
            ? studentsWithSubjectsFailed.value[headers[0]].map((_, index) => {
                  const row = { id: index };
                  headers.forEach((header) => {
                      row[header] =
                          header === 'Foto'
                              ? studentsWithSubjectsFailed.value[header][index]
                                    ?.value || DEFAULT_AVATAR_URL
                              : studentsWithSubjectsFailed.value[header][index]
                                    ?.value;
                  });
                  return row;
              })
            : [];

    const columns = headers.map((header) => ({
        field: header,
        headerName: header.charAt(0).toUpperCase() + header.slice(1),
        flex: 1,
        minWidth: 120,
        renderCell: (params) => {
            if (header === 'Foto') {
                return (
                    <img
                        src={params.value || DEFAULT_AVATAR_URL}
                        alt="Foto del estudiante"
                        style={{
                            width: 50,
                            height: 50,
                            borderRadius: '50%',
                            objectFit: 'cover',
                        }}
                    />
                );
            }
            if (
                header === 'Promedio' ||
                header.includes('Materias reprobadas')
            ) {
                const color = header === 'Promedio' ? 'blue' : 'error';
                return (
                    <Tag
                        color={color}
                        style={{
                            width: '100px',
                            textAlign: 'center',
                        }}
                    >
                        {params.value}
                    </Tag>
                );
            }
            return params.value;
        },
    }));

    const CustomToolbar = (props) => (
        <GridToolbarContainer sx={{ justifyContent: 'flex-end' }}>
            <GridToolbarColumnsButton {...props} />
            <GridToolbarFilterButton {...props} />
            <GridToolbarExport
                csvOptions={{
                    utf8WithBom: true,
                    delimiter: ',',
                }}
            />
        </GridToolbarContainer>
    );

    return (
        <Box>
            {fetchingStatus === 'pending' && (
                <SkeletonStudentsTables items={9} />
            )}
            {fetchingStatus === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}
            {fetchingStatus === 'fulfilled' && (
                <Card
                    sx={{
                        mt: 5,
                        borderRadius: '15px',
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <Typography
                        sx={{ ml: 2, mt: 1 }}
                        variant="h6"
                        color="textPrimary"
                        gutterBottom
                    >
                        {studentsWithSubjectsFailed.title ||
                            'Alumnos con materias reprobadas'}
                    </Typography>
                    <CardContent>
                        <DataGrid
                            localeText={{
                                ...esES.components.MuiDataGrid.defaultProps
                                    .localeText,
                                toolbarColumns: '',
                                toolbarFilters: '',
                                toolbarDensity: '',
                                toolbarExport: '',
                            }}
                            rows={rows}
                            columns={columns}
                            pageSize={10}
                            rowsPerPageOptions={[]}
                            disableSelectionOnClick
                            disableDensitySelector
                            components={{
                                NoRowsOverlay: NoDataOverlay,
                                NoResultsOverlay: NoDataOverlay,
                                Toolbar: GridToolBar,
                                Pagination: CustomPagination,
                            }}
                            componentsProps={{
                                panel: {
                                    placement: 'bottom-end',
                                },
                                noResultsOverlay: {
                                    message:
                                        'No se encontraron resultados para la búsqueda',
                                },
                                noRowsOverlay: {
                                    message: 'No hay datos disponibles',
                                },
                            }}
                            getRowHeight={() => 'auto'}
                            sx={{
                                '& .MuiDataGrid-cell': {
                                    whiteSpace: 'normal',
                                    wordWrap: 'break-word',
                                    overflow: 'visible',
                                    lineHeight: '3',
                                },
                                '&.MuiDataGrid-root': {
                                    border: 'none',
                                },
                            }}
                            autoHeight
                        />
                    </CardContent>
                </Card>
            )}
        </Box>
    );
};

export default StudentsWithSubjectsFailedTable;
