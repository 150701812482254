import { combineReducers } from '@reduxjs/toolkit';
import kpis from './kpis';
import tops from './tops';
import charts from './charts';
import tables from './tables';
import cards from './cards';

export default combineReducers({
    cards,
    kpis,
    tables,
    tops,
    charts,
});
