import {
    createAsyncThunk,
    createSelector,
    createSlice,
} from '@reduxjs/toolkit';
import Connection from '../../../../service/Connection';
import { PAYMENTS_HOME_EXPIRE_TIME } from '../../../../service/const';
import Feedback from '../../../../service/Feedback';
import { emptyState } from '../../emptyState';
import { getExpireIn } from '../../../../libs/utils';

const initialState = {
    Concepts_With_Discount: {
        fetch: {
            ...emptyState,
        },
        data: [],
    },
};

////////////////////////////////////// SLICE // ////////////////////////////////////
const chartsSlice = createSlice({
    name: 'homeUI/charts/server',
    initialState,
    reducers: {
        /**
         * Invalidar datos de la UI
         */
        invalidate_Concepts_With_Discount: (state) => {
            state.Concepts_With_Discount.fetch.didInvalidate = true;
        },
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return initialState;
        });

        const pendingServerStatus = (state, action) => {
            state.Concepts_With_Discount.fetch.statusServer = 'pending';
        };

        builder.addCase(
            loadUIConceptsWithDiscount.fulfilled,
            (state, action) => {
                state.Concepts_With_Discount.fetch = {
                    ...state.Concepts_With_Discount.fetch,
                    expireIn: getExpireIn('PAYMENTS_HOME_EXPIRE_TIME'),
                    fetchingAt: Date.now(),
                    didInvalidate: false,
                    statusServer: 'fulfilled',
                };

                state.Concepts_With_Discount.data =
                    action.payload.data.components;
            }
        );
        builder.addCase(
            loadUIConceptsWithDiscount.pending,
            pendingServerStatus
        );
        builder.addCase(
            loadUIConceptsWithDiscount.rejected,
            (state, action) => {
                state.Concepts_With_Discount.fetch.statusServer = 'rejected';
                state.Concepts_With_Discount.fetch.error = action.error;
            }
        );
    },
});

export const { invalidate_Concepts_With_Discount } = chartsSlice.actions;

export default chartsSlice.reducer;

////////////////////////////////////// THUNKS // ////////////////////////////////////

export const loadUIConceptsWithDiscount = createAsyncThunk(
    'homeUI/conceptsWithDiscount/server/fetch/data',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback();
        try {
            const conceptsWithDiscount =
                await Connection.getPaymentsInformation({
                    school_id: schoolId,
                    data: {
                        page: 'home',
                        type: 'chart',
                        idKey: 'concepts_with_discount',
                    },
                }).then((response) => response.data);

            return conceptsWithDiscount;
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    },
    {
        // Condición para ejecutar el thunk solo si es necesario
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn, status } =
                selectChart_Concepts_With_Discount(getState());
            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

// //////////////////////////////////// SELECTORES // ////////////////////////////////////

export const selectChart = (state) => state.paymentsModule.home.charts;

/**
 * Selector para recuperar el estado de la petición de los conceptos con descuento
 */

export const selectChart_Concepts_With_Discount = createSelector(
    selectChart,
    (chart) => chart.Concepts_With_Discount.fetch
);

export const selectChartsStatus_Concepts_With_Discount = createSelector(
    [selectChart_Concepts_With_Discount],
    (fetch) => fetch.statusServer
);

export const selectTotalDiscountLoss = createSelector(
    [selectChart],
    (chart) => chart.Concepts_With_Discount.data
);
