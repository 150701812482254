import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ResponsiveRadialBar } from '@nivo/radial-bar';
import { selectMonthlyPaymentsCount } from '../../../store/home/tables';
import { ChartTooltip } from '../../../components/Tooltips/ChartTooltips';

const MonthlyIncome = () => {
    ////////////////////////// HOOKS //////////////////////////
    const monthlyPaymentsData = useSelector(selectMonthlyPaymentsCount);
    ////////////////////////// LOCAL STATE //////////////////////////
    const [activeBar, setActiveBar] = useState(null);

    const radialData = monthlyPaymentsData.map((item) => ({
        id: item.label.replace(' 2024', ''),
        data: [
            {
                x: item.label,
                y: item.value,
            },
        ],
    }));

    const maxValue = Math.max(...monthlyPaymentsData.map((item) => item.value));

    ////////////////////////// FUNCTIONS //////////////////////////

    const getBarColor = (data) => {
        if (activeBar === data.id) {
            return '#ffffff';
        } else if (data.data.y === maxValue) {
            return '#f5f5f5';
        } else {
            return '#e0e0e0';
        }
    };

    return (
        <ResponsiveRadialBar
            data={radialData}
            cornerRadius={10}
            startAngle={180}
            endAngle={450}
            innerRadius={0.2}
            enableRadialGrid={false}
            enableCircularGrid={false}
            margin={{ top: 30, right: 40, bottom: 30, left: 40 }}
            radialAxisStart={{ tickSize: 5, tickPadding: 5, tickRotation: 0 }}
            circularAxisOuter={{
                tickSize: 10,
                tickPadding: 12,
                tickRotation: 0,
            }}
            colors={(bar) => getBarColor(bar)}
            enableTracks={true}
            onMouseEnter={(data) => setActiveBar(data.id)}
            onMouseLeave={() => setActiveBar(null)}
            tooltip={(data) => (
                <ChartTooltip
                    id={data.bar.data.x}
                    value={`$${data.bar.data.y}`}
                    color={data.color}
                    indexValue={data.bar.data.x}
                />
            )}
            animate={true}
            theme={{
                axis: {
                    ticks: {
                        text: {
                            fill: '#ffffff',
                            letterSpacing: '1px',
                            fontSize: 'xx-small',
                        },
                    },
                },
            }}
        />
    );
};

export default MonthlyIncome;
