import {
    createAsyncThunk,
    createEntityAdapter,
    createSlice,
} from '@reduxjs/toolkit';
import Connection from '../../../service/Connection';
import Feedback from '../../../service/Feedback';
import * as constants from '../../../service/const';
import _ from 'lodash';
import { getExpireIn } from '../../../libs/utils';

//////////////////////////// ADAPTERS ////////////////////////////////////

const cyclesAdapter = createEntityAdapter({
    selectId: (item) => item.cycle_id,
    sortComparer: (a, b) => a.cycle_id - b.cycle_id,
});

let initialState = {
    fetch: {
        expireIn: null,
        fetchingAt: null,
        statusServer: 'idle',
        statusOperation: 'idle',
        didInvalidate: true,
        feedback: {
            title: null,
            message: null,
            payload: null,
        },
    },
    cycles: cyclesAdapter.getInitialState(),
    school: {
        group_id: null,
        school_id: null,
        assessor_id: null,
        grade: '',
        group: '',
        turn: null,
        level: null,
        students: 0,
        special: 0,
        average_rating: 0,
        total_absence: 0,
        total_presence: 0,
        total_reports: 0,
        student_population: 0,
        teaching_population: 0,
        average_absence: 0,
        average_presence: 0,
    },
};

export const fetchSlice = createSlice({
    name: 'fetch',
    initialState: initialState,
    reducers: {
        invalidate: (state, action) => {
            state.fetch.didInvalidate = true;
        },
        setSchool: (state, action) => {
            state.school = action.payload;
        },
        setCycles: (state, action) => {
            cyclesAdapter.setAll(state.cycles, action.payload);
        },
    },
    extraReducers: (builder) => {
        builder.addCase('app/clear', (state, action) => {
            return initialState;
        });
        builder.addCase(fetchAllResources.rejected, (state, action) => {
            state.fetch.statusServer = 'rejected';
            state.fetch.feedback = action.payload.feedback;
        });
        builder.addCase(fetchAllResources.fulfilled, (state, action) => {
            state.fetch.expireIn = getExpireIn('DIRECTORS_HOME_EXPIRE_TIME');
            state.fetch.fetchingAt = Date.now();
            state.fetch.didInvalidate = false;
            state.fetch.statusServer = 'fulfilled';
        });
        builder.addCase(fetchAllResources.pending, (state, action) => {
            state.fetch.statusServer = 'pending';
        });
    },
});

export const { invalidate, setGroups, setCycles, setSchool } =
    fetchSlice.actions;

export default fetchSlice.reducer;

/**
 * Selectores para las entidades
 *
 * @param {*} state
 * @returns
 */

const cyclesGlobalizedSelector = cyclesAdapter.getSelectors(
    (state) => state.moduleDirector.entities.cycles
);
export const getCycles = (state) => cyclesGlobalizedSelector.selectAll(state);

export const selectEntitiesFetch = (state) =>
    state.moduleDirector.entities.fetch;

export const selectEntitiesFetchStatus = (state) =>
    selectEntitiesFetch(state).statusServer;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const fetchAllResources = createAsyncThunk(
    'fetch/getAll',
    async (data, thunkAPI) => {
        const { school_id, cycle } = data;

        let FeedbackService = new Feedback();

        let snaphot = null;

        if (cycle.cycle_id !== -1) {
            snaphot = cycle.snaphot;
        }
        try {
            let cycles = await getResourcesPaginatedFromServer(
                'getCyclesBySchool',
                [school_id]
            );

            let schoolRequest;

            if (snaphot) {
                schoolRequest = Connection.getSchoolByIdDirectorNoneCalculated(
                    school_id,
                    {
                        snaphot,
                    }
                );
            } else {
                schoolRequest =
                    Connection.getSchoolByIdDirectorNoneCalculated(school_id);
            }

            let school = await schoolRequest.then(
                (response) => response.data.data
            );

            thunkAPI.dispatch(setSchool(school));
            thunkAPI.dispatch(setCycles(cycles));
            // thunkAPI.dispatch(setCatalogSubjects(catalogoSubject));
            // thunkAPI.dispatch(setPartials(partials));
            // thunkAPI.dispatch(setProfessors(professors));
            // thunkAPI.dispatch(setAssessors(assessors));
            // thunkAPI.dispatch(
            //     setAllEntities({
            //         subjects,
            //         groups,
            //         students,
            //     })
            // );
            return { school, cycles };
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err),
            });
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } = selectEntitiesFetch(getState());

            const valid = expireIn > Date.now();

            if (!didInvalidate && valid) {
                return false;
            }
        },
    }
);

////////////////////////////////////////////////////// PAGINATED INFO //////////////////////////////////////////////////////////

async function getResourcesPaginatedFromServer(methd, params, snaphot = null) {
    let lastPage = 0;
    let currentPage = 0;

    let students = [];

    let parameter = {
        per_page: 50,
        page: currentPage,
    };

    if (snaphot) {
        parameter = { ...parameter, snaphot };
    }

    do {
        currentPage++;

        parameter.page = currentPage;

        let studentsResponse = await Connection[methd](
            ...params,
            parameter
        ).then((res) => res.data);

        let studentsPaginated = studentsResponse.data;
        let meta = studentsResponse.meta;

        lastPage = meta.last_page;

        students = students.concat(studentsPaginated);
    } while (currentPage < lastPage);

    return students;
}
