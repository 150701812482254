import React, { useEffect, useState } from 'react';
import {
    Button,
    Box,
    Grid,
    Tab,
    Tabs,
    Snackbar,
    Stack,
    Tooltip,
} from '@mui/material';
import { useRouteMatch } from 'react-router-dom';
import { Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';
import UsuariosPadres from '../UsuariosPadres/UsuariosPadres';
import UsuariosMaestros from '../UsuariosMaestros/UsuariosMaestros';
import UsuariosDirector from '../UsuariosDirector/UsuariosDirector';
import UsuariosOrientador from '../UsuariosOrientador/UsuariosOrientador';
import UsuariosAdministrador from '../UsuariosAdministrador/UsuariosAdministrador';
import UsuariosVocero from '../UsuariosVocero/UsuariosVocero';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    invalidate,
    loadUI,
    selectFetchStatusOperation,
} from '../../store/slices/usersUI';
import { useAuth } from '../../hooks';
import { Error, SkeletonGrid } from '../../components/Feedback';
import SkeletonPage from './components/SkeletonPage';
import SyncIcon from '@mui/icons-material/Sync';
import useFeedback from '../../hooks/useFeedback';

/**
 * Pagina de usuarios
 *
 * @returns
 */
const Usuarios = () => {
    let match = useRouteMatch();
    let dispatch = useDispatch();
    const Auth = useAuth();
    const feedbackApp = useFeedback();

    ///////////////// SHARED STATE //////////////

    const statusServer = useSelector(selectFetchStatusOperation);

    ///////////////////// STATE //////////////////

    const [currentTab, setCurrentTab] = useState('/maestros');

    /////////////////// ACTIONS /////////////////

    useEffect(() => {
        dispatch(loadUI(Auth.getUser().school_id));
    }, []);

    /**
     * Invalida la UI
     */
    const invalidateUI = () => {
        dispatch(invalidate());
        reload();
    };

    /**
     * Funcion para recargar los datos del usuario
     */
    const reload = () => {
        dispatch(loadUI(Auth.getUser().school_id))
            .unwrap()
            .then((response) => {})
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    const onChangeTab = (event, newValue) => {
        setCurrentTab(newValue);
    };

    ///////////////////// FEEDBACK ///////////////////////////////////////////

    function LinkTab(props) {
        return <Tab component={Link} {...props} />;
    }

    return (
        <Box
            sx={{
                flexGrow: 1,
                paddingTop: {
                    xs: 2,
                    sm: 2,
                    md: 2,
                },
                paddingLeft: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingRight: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingBottom: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
            }}
        >
            {statusServer == 'pending' && <SkeletonPage />}

            {statusServer == 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {statusServer == 'fulfilled' && (
                <>
                    <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <Box
                                sx={{ borderBottom: 1, borderColor: 'divider' }}
                            >
                                <Tabs
                                    value={currentTab}
                                    onChange={onChangeTab}
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    allowScrollButtonsMobile
                                    sx={{ display: { xs: 'none', sm: 'flex' } }}
                                >
                                    <LinkTab
                                        label="Profesores"
                                        value="/maestros"
                                        to={`${match.path}/maestros`}
                                    />
                                    <LinkTab
                                        label="Administradores"
                                        value="/administradores"
                                        to={`${match.path}/administradores`}
                                    />
                                    <LinkTab
                                        label="Padres"
                                        value="/padres"
                                        to={`${match.path}/padres`}
                                    />
                                    <LinkTab
                                        label="Orientadores"
                                        value="/orientadores"
                                        to={`${match.path}/orientadores`}
                                    />
                                    {/*<LinkTab label="Administrativos" value="/administradores" to={`${match.path}/administradores`}/> */}
                                    <LinkTab
                                        label="Directores"
                                        value="/directores"
                                        to={`${match.path}/directores`}
                                    />
                                </Tabs>
                                <Tabs
                                    value={currentTab}
                                    onChange={onChangeTab}
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    allowScrollButtonsMobile
                                    orientation="vertical"
                                    sx={{ display: { xs: 'flex', sm: 'none' } }}
                                >
                                    <LinkTab
                                        label="Maestros"
                                        value="/maestros"
                                        to={`${match.path}/maestros`}
                                    />
                                    <LinkTab
                                        label="Administradores"
                                        value="/administradores"
                                        to={`${match.path}/administradores`}
                                    />
                                    <LinkTab
                                        label="Padres"
                                        value="/padres"
                                        to={`${match.path}/padres`}
                                    />
                                    <LinkTab
                                        label="Orientadores"
                                        value="/orientadores"
                                        to={`${match.path}/orientadores`}
                                    />
                                    <LinkTab
                                        label="Administrativos"
                                        value="/administradores"
                                        to={`${match.path}/administradores`}
                                        disabled
                                    />
                                    <LinkTab
                                        label="Directores"
                                        value="/directores"
                                        to={`${match.path}/directores`}
                                    />
                                </Tabs>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            <Stack
                                spacing={1}
                                direction={{ xs: 'column', sm: 'row' }}
                                justifyContent="flex-end"
                                alignItems="center"
                            >
                                <Tooltip title="Sincronizar información">
                                    <Button
                                        size="small"
                                        color="primary"
                                        variant="contained"
                                        onClick={invalidateUI}
                                        startIcon={<SyncIcon />}
                                    >
                                        Sincronizar
                                    </Button>
                                </Tooltip>
                            </Stack>
                        </Grid>
                    </Grid>

                    <Switch>
                        <Route path={`${match.path}/padres`}>
                            <UsuariosPadres />
                        </Route>
                        <Route exact path={`${match.path}/maestros`}>
                            <UsuariosMaestros />
                        </Route>
                        <Route path={`${match.path}/directores`}>
                            <UsuariosDirector />
                        </Route>
                        <Route exact path={`${match.path}/orientadores`}>
                            <UsuariosOrientador />
                        </Route>
                        <Route path={`${match.path}/administradores`}>
                            <UsuariosAdministrador />
                        </Route>
                        <Route exact path={`${match.path}/voceros`}>
                            <UsuariosVocero />
                        </Route>
                        <Route path={`${match.path}/`}>
                            <UsuariosMaestros />
                        </Route>
                    </Switch>
                </>
            )}
        </Box>
    );
};

export default Usuarios;
