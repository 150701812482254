import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useAuth } from '../../hooks';
import useFeedback from '../../hooks/useFeedback';
import {
    invalidate,
    loadReportsDetailsUI,
    selectReportsData,
    selectStatusServer,
    selectStudentSelected,
} from '../store/student';
import Exportable from '../../service/Exportable';
import Feedback from '../../service/Feedback';
import { Box } from '@mui/system';
import {
    Avatar,
    Button,
    Card,
    CardContent,
    CardHeader,
    List,
    ListItem,
    ListItemText,
    Grid,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SyncIcon from '@mui/icons-material/Sync';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {
    faFolderOpen,
    faUser,
    faUserFriends,
    faUserGraduate,
    faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SkeletonPage from '../components/SkeletonPage';
import { Error } from '../../components/Feedback';
import { getLevelName, getTurnLevel } from '../../libs/utils';
import { saveFile } from '../../helper';
import { DateTime } from 'luxon';

const ReportsStudentsDetails = () => {
    /////////// HOOKS ///////////
    const { id, groupId } = useParams();
    const dispatch = useDispatch();
    const feedbackApp = useFeedback();

    /////////// SHARED STATE ///////////
    const statusServer = useSelector(selectStatusServer);
    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id;

    const reportsData = useSelector(selectReportsData(id, groupId));
    const selectedStudent = useSelector(selectStudentSelected);

    const {
        studentData,
        group,
        assesorByGroup,
        filesAndReportsByStudent,
        parentsByStudent,
    } = reportsData;

    //////////// LOCAL STATE //////////
    let FeedbackService = new Feedback();

    const [loading, setLoading] = useState(false);

    ///////// HANDLERS ////////////

    /**
     * Carga los datos de reportes, padres del alumno
     */

    useEffect(() => {
        dispatch(loadReportsDetailsUI({ studentId: id, schoolId }));
    }, []);

    /**
     * Invalida la UI
     */
    const invalidateUI = () => {
        dispatch(invalidate(selectedStudent));
        reload();
    };

    const reload = () => {
        dispatch(loadReportsDetailsUI({ studentId: id, schoolId }))
            .unwrap()
            .then(() => {})
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    const formatDate = (date) => {
        return DateTime.fromFormat(date, 'yyyy-MM-dd HH:mm:ss')
            .setLocale('es')
            .toFormat("d 'de' LLLL 'del' yyyy HH:mm a", { capitalize: true });
    };

    /**
     * Funcion para exportar a PIE
     * las calificciones
     */
    const handlerExportStudentReports = (studentId, assesorId) => {
        setLoading(true);
        Exportable.export(
            'assessors_students_files',
            'xlsx',
            { student_id: studentId, assessor_id: assesorId },
            null
        )
            .then((result) => {
                saveFile(result.data, 'reports_by_student.xlsx')
                    .then(() => {
                        setLoading(false);
                        feedbackApp.showFeedback({
                            title: 'Archivo exportado correctamente',
                        });
                    })
                    .catch(() => {
                        setLoading(false);
                        feedbackApp.showFeedback({
                            title: 'Estamos teniendo problemas al almacenar el archivo',
                        });
                    });
            })
            .catch((error) => {
                setLoading(false);
                feedbackApp.showFeedback({
                    title: FeedbackService.getMessage(error).title,
                });
            });
    };

    return (
        <Box
            sx={{
                flexGrow: 1,
                paddingTop: {
                    xs: 1,
                    sm: 2,
                    md: 2,
                },
                paddingLeft: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingRight: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingBottom: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
            }}
        >
            {statusServer === 'pending' && <SkeletonPage />}

            {statusServer === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {statusServer === 'fulfilled' && (
                <>
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-end',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Tooltip title="Sincronizar información">
                            {filesAndReportsByStudent.length > 0 && (
                                <LoadingButton
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    loading={loading == true}
                                    onClick={() =>
                                        handlerExportStudentReports(
                                            studentData.student_id,
                                            assesorByGroup.assessor_id
                                        )
                                    }
                                    startIcon={<FileDownloadIcon />}
                                >
                                    Exportar Reportes
                                </LoadingButton>
                            )}

                            <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                onClick={invalidateUI}
                                startIcon={<SyncIcon />}
                                sx={{ ml: 2 }}
                            >
                                Sincronizar
                            </Button>
                        </Tooltip>
                    </Box>

                    <Grid container sx={{ mt: 4 }} spacing={2}>
                        <Grid item xl={6} sm={12} md={6} xs={12}>
                            <Card
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    minHeight: '100%',
                                }}
                                style={{
                                    backgroundColor: '#313238',
                                }}
                            >
                                <Stack
                                    direction="row"
                                    justifyContent="space-around"
                                    alignItems="center"
                                    spacing={1}
                                >
                                    <Box
                                        sx={{
                                            width: 70,
                                            mt: 2,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {studentData.url_photo_profile ? (
                                            <Avatar
                                                src={
                                                    studentData.url_photo_profile
                                                }
                                            />
                                        ) : (
                                            <Avatar>
                                                <FontAwesomeIcon
                                                    icon={faUserGraduate}
                                                />
                                            </Avatar>
                                        )}
                                    </Box>
                                    <CardContent sx={{ flexGrow: 1 }}>
                                        <Typography
                                            variant="h6"
                                            component="h2"
                                            style={{ color: '#fff' }}
                                        >
                                            {`${studentData.name} ${studentData.last_name} ${studentData.second_last_name}`}
                                        </Typography>
                                        <Typography
                                            variant="subtitle1"
                                            gutterBottom
                                            component="span"
                                            style={{ color: '#fff' }}
                                        >
                                            {'Matrícula: ' + studentData.folio}
                                        </Typography>
                                    </CardContent>
                                </Stack>
                            </Card>
                        </Grid>
                        <Grid item xl={6} sm={12} md={6} xs={12}>
                            <Card
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    minHeight: '100%',
                                }}
                                style={{
                                    backgroundColor: '#db3a2e',
                                }}
                            >
                                <Stack
                                    direction="row"
                                    justifyContent="space-around"
                                    alignItems="center"
                                    spacing={1}
                                >
                                    <Box
                                        sx={{
                                            width: 70,
                                            mt: 2,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Avatar>
                                            <FontAwesomeIcon
                                                icon={faFolderOpen}
                                            />
                                        </Avatar>
                                    </Box>

                                    <CardContent sx={{ flexGrow: 1 }}>
                                        <Box>
                                            <Typography
                                                variant="h6"
                                                component="h2"
                                                style={{ color: '#fff' }}
                                            >
                                                {'Cantidad de Expedientes'}
                                            </Typography>
                                        </Box>

                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Typography
                                                variant="subtitle1"
                                                gutterBottom
                                                component="span"
                                                style={{ color: '#fff' }}
                                            >
                                                {`${filesAndReportsByStudent.length} expedientes`}
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                </Stack>
                            </Card>
                        </Grid>
                        <Grid item xl={4} sm={12} md={4} xs={12}>
                            <Card
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    minHeight: '100%',
                                }}
                                style={{
                                    backgroundColor: '#2c3cc9',
                                }}
                            >
                                <Stack
                                    direction="row"
                                    justifyContent="space-around"
                                    alignItems="center"
                                    spacing={1}
                                >
                                    <Box
                                        sx={{
                                            width: 70,
                                            mt: 2,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Avatar>
                                            <FontAwesomeIcon
                                                icon={faUserFriends}
                                            />
                                        </Avatar>
                                    </Box>

                                    <CardContent sx={{ flexGrow: 1 }}>
                                        <Box>
                                            <Typography
                                                variant="h6"
                                                component="h2"
                                                style={{ color: '#fff' }}
                                            >
                                                {'Padres'}
                                            </Typography>
                                        </Box>
                                        {parentsByStudent.length > 0 ? (
                                            parentsByStudent.map((parent) => {
                                                return (
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                        key={parent.user_id}
                                                    >
                                                        <Typography
                                                            variant="subtitle1"
                                                            gutterBottom
                                                            component="span"
                                                            style={{
                                                                color: '#fff',
                                                            }}
                                                        >
                                                            {`${parent.name} ${
                                                                parent.last_name
                                                            } ${
                                                                parent.second_last_name
                                                            } - ${
                                                                parent
                                                                    .relationship
                                                                    .relationship ===
                                                                1
                                                                    ? 'Padre'
                                                                    : parent
                                                                          .relationship
                                                                          .relationship ===
                                                                      2
                                                                    ? 'Madre'
                                                                    : 'Otro'
                                                            }`}
                                                        </Typography>
                                                    </Box>
                                                );
                                            })
                                        ) : (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Typography
                                                    variant="subtitle1"
                                                    gutterBottom
                                                    component="span"
                                                    style={{ color: '#fff' }}
                                                >
                                                    {`Sin padres registrados`}
                                                </Typography>
                                            </Box>
                                        )}
                                    </CardContent>
                                </Stack>
                            </Card>
                        </Grid>
                        <Grid item xl={4} sm={12} md={4} xs={12}>
                            <Card
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    minHeight: '100%',
                                }}
                                style={{
                                    backgroundColor: '#1f9c23',
                                }}
                            >
                                <Stack
                                    direction="row"
                                    justifyContent="space-around"
                                    alignItems="center"
                                    spacing={1}
                                >
                                    <Box
                                        sx={{
                                            width: 70,
                                            mt: 2,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Avatar>
                                            <FontAwesomeIcon icon={faUsers} />
                                        </Avatar>
                                    </Box>

                                    <CardContent sx={{ flexGrow: 1 }}>
                                        <Box>
                                            <Typography
                                                variant="h6"
                                                component="h2"
                                                style={{ color: '#fff' }}
                                            >
                                                {'Grupo'}
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Typography
                                                variant="subtitle1"
                                                gutterBottom
                                                component="span"
                                                style={{ color: '#fff' }}
                                            >
                                                {group.grade +
                                                    '° ' +
                                                    group.group +
                                                    ' - ' +
                                                    getLevelName(group.level)}
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Typography
                                                variant="subtitle1"
                                                gutterBottom
                                                component="span"
                                                style={{ color: '#fff' }}
                                            >
                                                {'Turno: ' +
                                                    getTurnLevel(group.turn)}
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                </Stack>
                            </Card>
                        </Grid>
                        <Grid item xl={4} sm={12} md={4} xs={12}>
                            <Card
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    minHeight: '100%',
                                }}
                                style={{
                                    backgroundColor: '#b0ad0e',
                                }}
                            >
                                <Stack
                                    direction="row"
                                    justifyContent="space-around"
                                    alignItems="center"
                                    spacing={1}
                                >
                                    <Box
                                        sx={{
                                            width: 70,
                                            mt: 2,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Avatar>
                                            <FontAwesomeIcon icon={faUser} />
                                        </Avatar>
                                    </Box>

                                    <CardContent sx={{ flexGrow: 1 }}>
                                        <Box>
                                            <Typography
                                                variant="h6"
                                                component="h2"
                                                style={{ color: '#fff' }}
                                            >
                                                {'Asesor'}
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Typography
                                                variant="subtitle1"
                                                gutterBottom
                                                component="span"
                                                style={{ color: '#fff' }}
                                            >
                                                {`${assesorByGroup.name} ${assesorByGroup.last_name} ${assesorByGroup.second_last_name}`}
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Typography
                                                variant="subtitle1"
                                                gutterBottom
                                                component="span"
                                                style={{ color: '#fff' }}
                                            >
                                                {`Matricula: ${
                                                    assesorByGroup.enrollment
                                                        ? assesorByGroup.enrollment
                                                        : 'N/A'
                                                }`}
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                </Stack>
                            </Card>
                        </Grid>
                    </Grid>
                    {filesAndReportsByStudent.length > 0 ? (
                        <Grid container sx={{ mt: 4 }} spacing={2}>
                            {filesAndReportsByStudent.map((file) => {
                                return (
                                    <Grid
                                        key={file.file_id}
                                        item
                                        xl={3}
                                        sm={12}
                                        md={4}
                                        xs={12}
                                    >
                                        <Stack
                                            direction="row"
                                            justifyContent="space-around"
                                            alignItems="center"
                                            spacing={1}
                                        >
                                            <Card sx={{ maxWidth: 345 }}>
                                                <CardHeader
                                                    title={`Expediente - #${file.folio}`}
                                                    subheader={formatDate(
                                                        file.created_at
                                                    )}
                                                    sx={{
                                                        bgcolor: '#fc8403',
                                                        color: 'white',
                                                        '& .MuiCardHeader-subheader':
                                                            {
                                                                color: 'white',
                                                            },
                                                    }}
                                                />
                                                <CardContent
                                                    style={{
                                                        maxHeight: 310,
                                                        overflow: 'auto',
                                                    }}
                                                >
                                                    {file.reports.length > 0 ? (
                                                        <List
                                                            sx={{
                                                                width: '100%',
                                                                maxWidth: 360,
                                                                bgcolor:
                                                                    'background.paper',
                                                            }}
                                                        >
                                                            {file.reports.map(
                                                                (report) => {
                                                                    return (
                                                                        <ListItem
                                                                            sx={{
                                                                                m: 0,
                                                                                p: 0,
                                                                            }}
                                                                            key={
                                                                                report.report_id
                                                                            }
                                                                        >
                                                                            <Stack
                                                                                direction="column"
                                                                                alignItems="flex-start"
                                                                                spacing={
                                                                                    0
                                                                                }
                                                                            >
                                                                                <div
                                                                                    style={{
                                                                                        display:
                                                                                            'flex',
                                                                                        alignItems:
                                                                                            'center',
                                                                                    }}
                                                                                >
                                                                                    <div className="dot"></div>
                                                                                    <Typography
                                                                                        variant="body1"
                                                                                        align="left"
                                                                                        sx={{
                                                                                            fontWeight:
                                                                                                'bold',
                                                                                            ml: 1,
                                                                                            maxWidth: 270,
                                                                                        }}
                                                                                    >
                                                                                        {`${report.description}`}
                                                                                    </Typography>
                                                                                </div>
                                                                                <ListItemText
                                                                                    sx={{
                                                                                        ml: 3,
                                                                                    }}
                                                                                    primary={
                                                                                        report.professor
                                                                                            ? `Reportó: ${report.professor?.name} ${report.professor?.last_name} ${report.professor?.second_last_name}`
                                                                                            : 'No se encontró profesor'
                                                                                    }
                                                                                    secondary={formatDate(
                                                                                        report.created_at
                                                                                    )}
                                                                                />
                                                                            </Stack>
                                                                        </ListItem>
                                                                    );
                                                                }
                                                            )}
                                                            {file.notes.length >
                                                            0 ? (
                                                                <Box
                                                                    sx={{
                                                                        bgcolor:
                                                                            'grey',
                                                                        borderRadius: 3,
                                                                        p: 1,
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        variant="h6"
                                                                        sx={{
                                                                            color: 'white',
                                                                            fontWeight:
                                                                                'bold',
                                                                        }}
                                                                    >
                                                                        Histórico
                                                                    </Typography>
                                                                    {file.notes.map(
                                                                        (
                                                                            note
                                                                        ) => {
                                                                            return (
                                                                                <ListItem
                                                                                    sx={{
                                                                                        m: 0,
                                                                                        p: 0,
                                                                                    }}
                                                                                    key={
                                                                                        note.note_id
                                                                                    }
                                                                                >
                                                                                    <Stack
                                                                                        direction="column"
                                                                                        alignItems="flex-start"
                                                                                        spacing={
                                                                                            0
                                                                                        }
                                                                                    >
                                                                                        <ListItemText
                                                                                            sx={{
                                                                                                p: 1,
                                                                                                color: 'white',
                                                                                            }}
                                                                                            primary={formatDate(
                                                                                                note.created_at
                                                                                            )}
                                                                                            secondary={
                                                                                                <span
                                                                                                    style={{
                                                                                                        color: 'white',
                                                                                                    }}
                                                                                                >{`${note.comentary}`}</span>
                                                                                            } // Establecer el color del texto secundario como blanco
                                                                                        />
                                                                                    </Stack>
                                                                                </ListItem>
                                                                            );
                                                                        }
                                                                    )}
                                                                </Box>
                                                            ) : (
                                                                <Typography
                                                                    variant="body1"
                                                                    align="center"
                                                                >
                                                                    Sin notas
                                                                </Typography>
                                                            )}
                                                        </List>
                                                    ) : (
                                                        <Typography
                                                            variant="body1"
                                                            align="center"
                                                        >
                                                            Sin reportes
                                                        </Typography>
                                                    )}
                                                </CardContent>
                                            </Card>
                                        </Stack>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    ) : (
                        <Grid container sx={{ mt: 4 }} spacing={2}>
                            <Grid item xl={12} sm={12} md={12} xs={12}>
                                <Typography align="center" variant="h3">
                                    El Alumno no tiene expedientes
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                </>
            )}
        </Box>
    );
};

export default ReportsStudentsDetails;
