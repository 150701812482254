import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Typography,
    Box,
    Grid,
    Card,
    CardContent,
    Avatar,
} from '@mui/material';
import {
    DataGrid,
    esES,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { useAuth } from '../../../../hooks';
import useFetchResource from '../../../components/HelperHook';
import {
    getCycleSelected,
    selectProfessorsWithoutGroup,
} from '../../../store/professors/fetchSlice';
import { getProfessorsWithoutGroup } from '../../../store/professors/thunks';
import { selectComponentProfessorsWithoutGroupStatusServer } from '../../../store/professors/componentsStatusSelectors';
import Error from '../../../../components/Feedback/Error';
import { SkeletonProfessorsSmallTables } from '../../../components/Skeleton';
import NoDataOverlay from '../../../../components/utilities/NoDataOverlay';
import { GridToolBar } from '../../../../components/utilities/GridToolBar';
import CustomPagination from '../../../../components/utilities/CustomPagination';

const ProfessorsWithoutGroup = () => {
    const Auth = useAuth();
    const dispatch = useDispatch();
    const schoolId = Auth.getUser().school_id;
    const cycleSelected = useSelector(getCycleSelected);
    const fetchingStatus = useSelector(
        selectComponentProfessorsWithoutGroupStatusServer
    );

    const professorsWithoutGroup = useFetchResource(
        () =>
            getProfessorsWithoutGroup({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            }),
        selectProfessorsWithoutGroup,
        [cycleSelected, schoolId]
    );

    const reload = () => {
        dispatch(
            getProfessorsWithoutGroup({
                schoolId,
                cycle_id:
                    cycleSelected.cycle_id !== -1 ? cycleSelected.cycle_id : '',
            })
        );
    };

    const headers = professorsWithoutGroup.value
        ? Object.keys(professorsWithoutGroup.value)
        : [];
    const rows = professorsWithoutGroup.value
        ? professorsWithoutGroup.value[headers[0]].map((_, index) => {
              const row = { id: index + 1 };
              headers.forEach((header) => {
                  row[header] =
                      professorsWithoutGroup.value[header][index]?.value || '';
              });
              return row;
          })
        : [];
    const columns = headers.map((header) => {
        if (header === 'Fotografía') {
            return {
                field: header,
                headerName: 'Fotografía',
                disableExport: true,
                renderCell: (params) => (
                    <Avatar
                        alt={params.row.Profesor}
                        src={params.value}
                        sx={{ width: 40, height: 40 }}
                    />
                ),
                sortable: false,
                width: 80,
            };
        }

        return {
            field: header,
            headerName: header,
            flex: 1,
            renderCell: (params) => (
                <Typography
                    variant="body2"
                    sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                    {params.value}
                </Typography>
            ),
        };
    });

    const CustomToolbar = (props) => (
        <GridToolbarContainer sx={{ justifyContent: 'flex-end' }}>
            <GridToolbarColumnsButton {...props} />
            <GridToolbarFilterButton {...props} />
            <GridToolbarExport
                csvOptions={{
                    utf8WithBom: true,
                    delimiter: ',',
                }}
            />
        </GridToolbarContainer>
    );

    return (
        <Box sx={{ width: '100%', overflowX: 'hidden' }}>
            {fetchingStatus === 'pending' && (
                <SkeletonProfessorsSmallTables items={9} />
            )}

            {fetchingStatus === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {fetchingStatus === 'fulfilled' && (
                <Card
                    sx={{
                        background: '#ffffff',
                        borderRadius: '10px',
                        boxShadow: 'none',
                        maxWidth: '100%',
                        margin: '20px auto',
                        padding: '16px',
                        overflow: 'visible',
                        position: 'relative',
                    }}>
                    <CardContent sx={{ width: '100%', padding: '24px' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography
                                    variant="h6"
                                    color="textPrimary"
                                    gutterBottom>
                                    {professorsWithoutGroup?.title ||
                                        'Profesores sin grupo'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{ height: 804, width: '100%' }}>
                                    <DataGrid
                                        localeText={{
                                            ...esES.components.MuiDataGrid
                                                .defaultProps.localeText,
                                            toolbarColumns: '',
                                            toolbarFilters: '',
                                            toolbarDensity: '',
                                            toolbarExport: '',
                                        }}
                                        rows={rows}
                                        columns={columns}
                                        pageSize={10}
                                        rowsPerPageOptions={[10]}
                                        disableDensitySelector
                                        disableSelectionOnClick
                                        components={{
                                            NoRowsOverlay: NoDataOverlay,
                                            NoResultsOverlay: NoDataOverlay,
                                            Toolbar: GridToolBar,
                                            Pagination: CustomPagination,
                                        }}
                                        componentsProps={{
                                            panel: {
                                                placement: 'bottom-end',
                                            },
                                            noResultsOverlay: {
                                                message:
                                                    'No se encontraron resultados para la búsqueda',
                                            },
                                            noRowsOverlay: {
                                                message:
                                                    'No hay datos disponibles',
                                            },
                                        }}
                                        sx={{
                                            '&.MuiDataGrid-root': {
                                                border: 'none',
                                            },
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            )}
        </Box>
    );
};

export default ProfessorsWithoutGroup;
