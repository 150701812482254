import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAuth, useFeedback } from '../../hooks';

import { Box, Card, CardContent, Button, Stack, Tooltip } from '@mui/material';
import { DataGrid, esES } from '@mui/x-data-grid';

import {
    invalidate,
    loadUI,
    selectFetchStatus,
} from '../store/home/fetchSlice';
import { selectAllGroupsItems, selectReports } from '../store/home/itemsSlice';
import { selectAllReportsCatalogs } from '../../store/slices/entities/reportscatalogs';

import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SyncIcon from '@mui/icons-material/Sync';
import ViewListIcon from '@mui/icons-material/ViewList';

import { Error } from '../../components/Feedback';
import { ReportsToolBar } from '../components/ReportsToolBar';
import ListCatalogReportsModal from '../components/ListCatalogReportsModal';
import SkeletonPage from '../components/SkeletonPage';

import { getTurnLevel, getLevelName } from '../../libs/utils';
import FeatureFlags from '../../service/FeatureFlags';
import { groupSelected } from '../store/home/uiSlice';
import { DateTime } from 'luxon';
import { selectGroupsUI, updateFilter } from '../store';
import NoDataOverlay from '../../components/utilities/NoDataOverlay';
import { GridToolBar } from '../../components/utilities/GridToolBar';
import CustomPagination from '../../components/utilities/CustomPagination';

/**
 * Pagina para mostrar el catalogo de los reportes
 *
 * @returns
 */
export const ReportsHome = () => {
    const history = useHistory();
    const Auth = useAuth();
    const dispatch = useDispatch();
    const feedbackApp = useFeedback();

    //////////////// SHARES STATE /////////////////

    const fetchStatus = useSelector(selectFetchStatus);
    const reports = useSelector(selectReports);
    const groups = useSelector(selectAllGroupsItems);
    const reportCatalog = useSelector(selectAllReportsCatalogs);
    const filtersUI = useSelector(selectGroupsUI);

    //////////////// LOCAL STATE //////////////////

    const [openModal, setOpenModal] = useState(false);

    /**
     * accion para seleccionar un grupo
     */
    const onSelectGroup = (group) => {
        dispatch(groupSelected(group.viewModel.id));
        history.push({ pathname: `/reportes/${group.group_id}` });
    };

    let columns = FeatureFlags.isFeatureFlagActive('NEW_REPORTS_STRCUTURE')
        ? [
              {
                  field: 'grade',
                  headerName: 'Grado',
                  flex: 0.5,
                  minWidth: 100,
                  editable: false,
                  headerAlign: 'center',
                  align: 'center',
                  valueSetter: (params) => `${params.row.grade}`,
              },
              {
                  field: 'group',
                  headerName: 'Grupo',
                  flex: 0.5,
                  minWidth: 100,
                  editable: false,
                  headerAlign: 'center',
                  align: 'center',
                  valueSetter: (params) => `${params.row.group}`,
              },
              {
                  field: 'turn',
                  headerName: 'Turno',
                  flex: 1,
                  minWidth: 200,
                  editable: false,
                  headerAlign: 'center',
                  align: 'center',
                  valueGetter: (params) => `${getTurnLevel(params.row.turn)}`,
              },
              {
                  field: 'level',
                  headerName: 'Nivel',
                  flex: 1,
                  minWidth: 200,
                  editable: false,
                  headerAlign: 'center',
                  align: 'center',
                  valueGetter: (params) => `${getLevelName(params.row.level)}`,
              },
              {
                  field: 'students',
                  headerName: 'Alumnos Reportados',
                  flex: 1,
                  minWidth: 200,
                  editable: false,
                  headerAlign: 'center',
                  align: 'center',
                  valueGetter: (params) =>
                      `${params.row.total_students_reported}`,
              },
              {
                  field: 'details',
                  headerName: 'Detalles',
                  sortable: false,
                  filterable: false,
                  flex: 1,
                  minWidth: 100,
                  headerAlign: 'center',
                  align: 'center',
                  disableExport: true,
                  renderCell: (params) => (
                      <strong>
                          <Button
                              size="small"
                              variant="outlined"
                              startIcon={
                                  <FontAwesomeIcon icon={faEye} size="lg" />
                              }
                              onClick={() => onSelectGroup(params.row)}>
                              VER
                          </Button>
                      </strong>
                  ),
              },
          ]
        : [
              {
                  field: 'expediente',
                  headerName: 'No. Ex.',
                  flex: 0.5,
                  minWidth: 100,
                  editable: false,
                  headerAlign: 'center',
                  align: 'center',
                  valueGetter: (params) => `${params.row.file.file_id}`,
                  renderCell: (params) => {
                      const FullExpediente = `${params.row.file.file_id}`;
                      return (
                          <div
                              style={{
                                  whiteSpace: 'pre-line',
                                  textAlign: 'center',
                              }}>
                              {FullExpediente}
                          </div>
                      );
                  },
              },
              {
                  field: 'matricula',
                  headerName: 'Matrícula',
                  flex: 0.5,
                  minWidth: 100,
                  editable: false,
                  headerAlign: 'center',
                  align: 'center',
                  valueGetter: (params) => `${params.row.student.folio}`,
                  renderCell: (params) => {
                      const FullFolio = `${params.row.student.folio}`;
                      return (
                          <div
                              style={{
                                  whiteSpace: 'pre-line',
                                  textAlign: 'center',
                              }}>
                              {FullFolio}
                          </div>
                      );
                  },
              },
              {
                  field: 'nombre',
                  headerName: 'Alumno',
                  flex: 1,
                  minWidth: 200,
                  editable: false,
                  headerAlign: 'center',
                  align: 'center',
                  renderCell: (params) => {
                      const FullName = `${params.row.student.name} ${params.row.student.last_name}`;
                      return (
                          <div
                              style={{
                                  whiteSpace: 'pre-line',
                                  textAlign: 'center',
                              }}>
                              {FullName}
                          </div>
                      );
                  },
              },
              {
                  field: 'grupo',
                  headerName: 'Grupo',
                  flex: 1,
                  minWidth: 200,
                  editable: false,
                  headerAlign: 'center',
                  align: 'center',
                  valueGetter: ({ row }) => {
                      let groupData = 'Sin grupo';

                      if (row.group) {
                          groupData = `${row.group?.grade} ${
                              row.group?.group
                          } ${getTurnLevel(row.group?.turn)}`;
                      }

                      return groupData;
                  },
              },
              {
                  field: 'categoria',
                  headerName: 'Categoría del reporte',
                  type: 'singleSelect',
                  flex: 1,
                  minWidth: 200,
                  editable: false,
                  headerAlign: 'center',
                  align: 'center',
                  valueGetter: (params) => `${params.row.reportcatalog.title}`,
                  valueOptions: reportCatalog.map((i) => i.title),
                  renderCell: (params) => {
                      const FullCat = `${params.row.reportcatalog.title}`;
                      return (
                          <div
                              style={{
                                  whiteSpace: 'pre-line',
                                  textAlign: 'center',
                              }}>
                              {FullCat}
                          </div>
                      );
                  },
              },
              {
                  field: 'description',
                  headerName: 'Descripción del reporte',
                  flex: 1,
                  minWidth: 200,
                  editable: false,
                  headerAlign: 'center',
                  align: 'center',
                  renderCell: (params) => {
                      const FullDescription = `${params.row.description}`;
                      return (
                          <div
                              style={{
                                  whiteSpace: 'pre-line',
                                  textAlign: 'center',
                              }}>
                              {FullDescription}
                          </div>
                      );
                  },
              },
              {
                  field: 'orientador',
                  headerName: 'Orientador educativo',
                  flex: 1,
                  minWidth: 200,
                  editable: false,
                  headerAlign: 'center',
                  align: 'center',
                  valueGetter: ({ row }) =>
                      `${row.assessor.name} ${row.assessor.last_name}`,
                  renderCell: ({ row }) => {
                      const FullOrientador = `${row.assessor.name} ${row.assessor.last_name}`;
                      return (
                          <div
                              style={{
                                  whiteSpace: 'pre-line',
                                  textAlign: 'center',
                              }}>
                              {FullOrientador}
                          </div>
                      );
                  },
              },
              {
                  field: 'reporter',
                  headerName: 'Reportador',
                  flex: 1,
                  minWidth: 200,
                  editable: false,
                  headerAlign: 'center',
                  align: 'center',
                  valueGetter: ({ row }) =>
                      `${row.reporter.name} ${row.reporter.last_name}`,
                  renderCell: ({ row }) => {
                      const FullReporter = `${row.reporter.name} ${row.reporter.last_name}`;
                      return (
                          <div
                              style={{
                                  whiteSpace: 'pre-line',
                                  textAlign: 'center',
                              }}>
                              {FullReporter}
                          </div>
                      );
                  },
              },
              {
                  field: 'created_at',
                  headerName: 'Fecha',
                  type: 'date',
                  flex: 1,
                  minWidth: 200,
                  editable: false,
                  headerAlign: 'center',
                  align: 'center',
                  valueGetter: ({ row }) => row.created_at,
                  valueFormatter: ({ value }) => {
                      return DateTime.fromSQL(value)
                          .setLocale('es')
                          .toFormat('yyyy LLL dd');
                  },
              },
          ];

    /////////////////////// ACTIONS ////////////////////

    /**
     * Si el usuario a entrado a la aplicacion
     * iniciamos el proceso de recuperacion de datos
     */
    useEffect(() => {
        dispatch(loadUI(Auth.getUser().school_id));
    }, []);

    /**
     * Invalida la UI
     */
    const invalidateUI = () => {
        dispatch(invalidate());
        reload();
    };

    /**
     * Funcion para recargar los datos del usuario
     */
    const reload = () => {
        dispatch(loadUI(Auth.getUser().school_id))
            .unwrap()
            .then((response) => {})
            .catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title,
                });
            });
    };

    const handleFilterChange = (event) => {
        const newFilterValues = {};

        event.items.forEach((filter) => {
            const { columnField, operatorValue, value } = filter;
            const filterKey = 'Filtro';

            newFilterValues[filterKey] = {
                columnField,
                operatorValue,
                value,
            };
        });
        dispatch(updateFilter({ filter: newFilterValues.Filtro }));
    };

    const dataGrid = FeatureFlags.isFeatureFlagActive('NEW_REPORTS_STRCUTURE')
        ? groups
        : reports;

    const ordeningDataGrid = dataGrid.slice().sort((a, b) => {
        if (a.level !== b.level) {
            return a.level - b.level;
        }
        if (a.turn !== b.turn) {
            return a.turn - b.turn;
        }
        if (a.grade !== b.grade) {
            return a.grade - b.grade;
        }
        return a.group.localeCompare(b.group);
    });

    const onOpenCatalog = () => {
        setOpenModal(true);
    };

    const anotherButtons = [
        {
            text: 'Catálogo',
            icon: <ViewListIcon />,
            onClick: onOpenCatalog,
        },
    ];

    return (
        <Box
            sx={{
                flexGrow: 1,
                paddingTop: {
                    xs: 1,
                    sm: 2,
                    md: 2,
                },
                paddingLeft: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingRight: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingBottom: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
            }}>
            <ListCatalogReportsModal
                openModal={openModal}
                setOpenModal={setOpenModal}
            />

            {fetchStatus === 'pending' && <SkeletonPage />}

            {fetchStatus === 'rejected' && (
                <Error
                    onRetry={reload}
                    message={'Estamos teniendo problemas'}
                />
            )}

            {fetchStatus === 'fulfilled' && (
                <>
                    <Stack
                        spacing={1}
                        direction="row"
                        alignItems={'flex-end'}
                        justifyContent={'flex-end'}>
                        <Tooltip title="Sincronizar información">
                            <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                onClick={invalidateUI}
                                startIcon={<SyncIcon />}>
                                Sincronizar
                            </Button>
                        </Tooltip>
                    </Stack>

                    <Card
                        sx={{
                            color: 'white',
                            borderRadius: '15px',
                            width: '100%',
                            height: '100%',
                            marginTop: '20px',
                        }}>
                        <DataGrid
                            localeText={{
                                ...esES.components.MuiDataGrid.defaultProps
                                    .localeText,
                                toolbarColumns: '',
                                toolbarFilters: '',
                                toolbarDensity: '',
                                toolbarExport: '',
                            }}
                            rows={ordeningDataGrid}
                            getRowId={(row) =>
                                FeatureFlags.isFeatureFlagActive(
                                    'NEW_REPORTS_STRCUTURE'
                                )
                                    ? row.group_id
                                    : row.report_id
                            }
                            columns={columns}
                            pageSize={10}
                            disableSelectionOnClick
                            autoHeight
                            components={{
                                NoRowsOverlay: NoDataOverlay,
                                NoResultsOverlay: NoDataOverlay,
                                Toolbar: GridToolBar,
                                Pagination: CustomPagination,
                            }}
                            componentsProps={{
                                panel: {
                                    placement: 'bottom-end',
                                },

                                noResultsOverlay: {
                                    message:
                                        'No se encontraron resultados para la búsqueda',
                                },
                                noRowsOverlay: {
                                    message:
                                        'No hay datos para mostrar en esta tabla',
                                },
                                toolbar: {
                                    AnotherButtons: anotherButtons,
                                },
                            }}
                            onFilterModelChange={(event) =>
                                handleFilterChange(event)
                            }
                            initialState={{
                                filter: {
                                    filterModel: {
                                        items: filtersUI.filter
                                            ? [
                                                  {
                                                      columnField:
                                                          filtersUI.filter
                                                              .columnField ||
                                                          '',
                                                      operatorValue:
                                                          filtersUI.filter
                                                              .operatorValue ||
                                                          '',
                                                      value:
                                                          filtersUI.filter
                                                              .value || '',
                                                  },
                                              ]
                                            : [],
                                    },
                                },
                            }}
                            disableDensitySelector
                        />
                    </Card>
                </>
            )}
        </Box>
    );
};

export default ReportsHome;
