import React from 'react';
import {
    Card,
    CardContent,
    Typography,
    Box,
    Tooltip,
    Zoom,
    Avatar,
    Divider,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { selectLastStudentsWithPayments } from '../../../store/home/tables';
import femaleAvatar from '../../../assets/img/female_student.png';
import maleAvatar from '../../../assets/img/male_student.png';
import defaultStudent from '../../../assets/img/default_student.png';

import { NewFeatures } from '../../../components/features/NewFeatures';
import { Layout } from 'antd';

import { ReactComponent as ListIcon } from '../../../assets/icons/list-square-svgrepo-com.svg';

const TopTransactionsCard = () => {
    const lastStudentsWithPayments = useSelector(
        selectLastStudentsWithPayments
    ).value;

    const rows = Array.isArray(lastStudentsWithPayments)
        ? lastStudentsWithPayments.slice(0, 5)
        : [];

    const getAvatarSrc = (row) => {
        if (row.avatar) {
            return row.avatar;
        }
        return row.gener === 'H'
            ? maleAvatar
            : row.gener === 'M'
            ? femaleAvatar
            : defaultStudent;
    };

    return (
        <Layout
            style={{
                width: '100%',
                height: '50em',
                boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
                borderRadius: '15px',
                backgroundColor: '#ffffff',
            }}>
            <Box position="relative">
                <Card
                    sx={{
                        backgroundColor: '#66ccff',
                        color: 'white',
                        borderRadius: '15px',
                        width: '100%',
                        height: '200px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '20px',
                        }}>
                        <Box
                            style={{
                                backgroundColor: '#8bd5fb',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: '10px',
                                paddingBottom: '10px',
                                borderRadius: '100px',
                                marginRight: '10px',
                            }}>
                            <ListIcon sx={{ width: 40, height: 40 }} />
                        </Box>
                        <Typography
                            variant="h3"
                            style={{
                                color: '#ffffff',

                                fontWeight: '800',
                                letterSpacing: '2px',
                            }}>
                            Resumen
                        </Typography>
                    </div>
                </Card>
            </Box>

            <Box
                sx={{
                    alignItems: 'center',
                    mt: 5,
                    padding: '10px',
                    width: '100%',
                }}>
                <Box>
                    <Typography
                        variant="h6"
                        component="div"
                        style={{
                            fontWeight: '800',
                            color: '#464646',
                            letterSpacing: '1px',
                        }}>
                        Últimos Pagos
                    </Typography>
                    <Box>
                        {rows.map((row, index) => (
                            <div key={index}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        mt: 3,
                                        borderRadius: '10px',
                                        padding: '10px',
                                    }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            borderRadius: '400px',
                                            mr: 2,
                                            width: '40px',
                                            height: '40px',
                                        }}>
                                        <Tooltip
                                            TransitionComponent={Zoom}
                                            title={row.label}
                                            arrow>
                                            <Avatar
                                                src={getAvatarSrc(row)}
                                                sx={{
                                                    width: '50px',
                                                    height: '50px',
                                                }}
                                            />
                                        </Tooltip>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}>
                                        <Box>
                                            <Typography
                                                variant="body2"
                                                component="div"
                                                style={{
                                                    fontWeight: '700',
                                                    color: '#000000',
                                                }}>
                                                {row.concept}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                component="div"
                                                style={{
                                                    fontWeight: '100',
                                                    color: '#000000',
                                                }}>
                                                {row.date}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            marginLeft: 'auto',
                                        }}>
                                        <Typography
                                            style={{
                                                fontWeight: '700',
                                                color: '#08bb0e',
                                            }}>
                                            + ${row.value}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Divider />
                            </div>
                        ))}
                    </Box>
                </Box>
            </Box>
        </Layout>
    );
};

export default TopTransactionsCard;
